import React, { useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
//import * as FxLog from '../fx/FxLog';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles({
  colorPrimary: {
    background: 'var(--ibs_color_cy_yellow)',
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'var(--ibs_color_cy_blue)'
    }
  },
  colorSecondary: {
    background: 'var(--ibs_color_red_dark)',
  },
});

const CxProgress = props => {
  const classes = useStyles(props);

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, 
    };
  });
  const getType = () => { return 'CxProgress' };

  return (
      <div style={{ height: '4px', backgroundColor: 'var(--ibs_color_gray_light)'}}>
        {props.wait && <LinearProgress classes={{ colorPrimary: classes.colorPrimary, colorSecondary: classes.colorSecondary, }} />}
      </div>
  );
};

export default CxProgress;
