import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettEmployee } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettEmployeeList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettEmployee, setStoreSettEmployee] = useSessionStorage('StoreSettEmployee', StoreSettEmployee);

  React.useEffect(() => {
    clearStore('StoreSettEmployee', storeSettEmployee);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettEmployee.itEmployee = null;
    } else {
      storeSettEmployee.itEmployee = row.original.itEmployee;
    }
    setStoreSettEmployee(storeSettEmployee);
    navTo("sett-employee-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.employees')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/employee"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itEmployee", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.employee')}`, accessor: "txTypeEmployee", width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettEmployeeList;
