import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxDat from '../../fx/FxDat';
import { useNav } from '../../hx/useNav';

import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreEmployee } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxEmployeeList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeEmployee, setStoreEmployee] = useSessionStorage('StoreEmployee', StoreEmployee);

  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreEmployee', storeEmployee);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storeEmployee.inEmployee = null;
    } else {
      storeEmployee.inEmployee = row.original.inEmployee;
    }
    setStoreEmployee(storeEmployee);

    navTo("employee-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inEmployee", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.employee'), accessor: row => r_list.current.getTable().highlightText(row["txEmployee"]), width: '40%' });
        cols.push({ id: id++, Header: trans('field.type'), accessor: "txTypeEmployee", width: '20%' });
        cols.push({ id: id++, Header: trans('field.email'), accessor: "txEmail", width: '20%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '10%' });
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '10%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.employee'), accessor: row => r_list.current.getTable().highlightText(row["txEmployee"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '30%' });
      }
      cols.push({ id: "B", Header: "linked_to_user", accessor: row => Boolean(row["inUser"]), show: false });
      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false });

    } catch(error) {
      FxLog.errorx('MxEmployeeList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.employees')}`}
        xref={r_list}
        tooltip={`${trans('field.employee')}`}
        fullscreen
        url="employees"
        onOpenForm={openForm}
        colorRefs={ [ 
          'table_cell_blue|' + trans('field.linked_to_x','field.user'),
          'table_cell_gray|' + trans('field.inactive'), 
        ] }
        columns={getTableColumns()}
      />

    </div>
  );

}

export default MxEmployeeList;
