import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import CxTooltip from './CxTooltip';


const useStyles = makeStyles(props => ({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: '20ch',
    borderRadius: '5px',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: '14px',
    color: 'var(--ibs_color_black)',
  },
}));


const ITEM_HEIGHT = 48;

export default function CxButtonMenu(props) {
  const classes = useStyles(props);
  const { onSelection, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const doClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const doSelection = (event) => {
    setAnchorEl(null); // handle close
    const { myValue } = event.currentTarget.dataset;
    if (onSelection) {
      if (rest.row) {
        onSelection(myValue, rest.row); // 2023-05-09 - daq: pasamos la línea, si el botón se usa desde una tabla.
      } else {
        onSelection(myValue);
      }
    }
  };

  return (
    <div>
      <CxTooltip text={rest.tooltip ? rest.tooltip : null}>
        <span>
          <IconButton className={rest.className} onClick={doClick} disabled={rest.disabled}>
            {!rest.icon && <MoreVertIcon fontSize="small" />}
            {rest.icon}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={doSelection}
            classes={{ paper: classes.paper }}
            anchorOrigin={{ // es el punto a partir del cual se despliega
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{ // es la dirección hacia la que se despliega
              vertical: rest.posV || "top",
              horizontal: rest.posH || "left",
            }}
          >
            {rest.options.map((option) => (
              <MenuItem key={option.id} data-my-value={option.id} onClick={doSelection} disabled={option.disabled || false}>
                {option.text}
              </MenuItem>
            ))}
          </Menu>
        </span>
      </CxTooltip>
    </div>
  );
}
