import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { useAccess } from '../../hx/useAccess';
import CxStatic from '../../cx/CxStatic';


const MxClientIncidenceForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navBack }] = useNav();

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);

  let r_form = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_txTypeStatusClientIncidence = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_date.current.setValToday();
      rf_txTypeStatusClientIncidence.current.setKeyAndVal("t_status_client_incidence", "txTypeStatusClientIncidence", "itStatusClientIncidence", "000001.SRV");
      
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
    } else {
      // se guardó exitosamente
      storeClient.inClientIncidence = lastSaveRv.theValue;
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        navBack();
      });
    }

  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client')}: ${trans('field.incidence')}`}
        xref={r_form}
        url="clients/incidence" 
        filterIdField='inClientIncidence' 
        filterIdValue={storeClient.inClientIncidence} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="n_client_incidence.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_client_incidence.dtClientIncidence" required/>
        
        <CxChooser xref={rf_txTypeStatusClientIncidence}
          label={trans('field.status')}
          dbfield="t_status_client_incidence.txTypeStatusClientIncidence" dbfieldkey="n_client_incidence.itStatusClientIncidence"
          table="t_status_client_incidence"
          col_id="itStatusClientIncidence"
          col_tx="txTypeStatusClientIncidence"
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.incidence')} dbfield="n_client_incidence.txClientIncidence" maxLength={50} speech required />
        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_client_incidence.txRemarks" minRows={2} speech />
        
      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      
    </div>
  );

}

export default MxClientIncidenceForm;
