import React from 'react';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import { getStoreValue, } from "../../gx/GxStore";


const MxAnimalListCP = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ navTo }] = useNav();
  
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);

  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreClient', storeClient);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storeClient.inClient = null;
      storeClient.txClient = null;
      storeClient.inAnimal = null;
      storeClient.txAnimal = null;
    } else {
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      storeClient.inAnimal = row.original.inAnimal;
      storeClient.txAnimal = row.original.txAnimal;
    }
    setStoreClient(storeClient);

    navTo("animal-form-cp");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inAnimal", show: false });
      cols.push({ id: 'xx1', Header: "_inClient", accessor: "inClient", show: false });
      cols.push({ id: 'xx2', Header: "_txClient", accessor: "txClient", show: false });
      cols.push({ id: id++, Header: trans('field.patient'), accessor: "txAnimal", width: '25%' });
      cols.push({ id: id++, Header: trans('field.species'), accessor: "txTypeAnimalSpecies", width: '25%' });
      cols.push({ id: id++, Header: trans('field.breed'), accessor: "txTypeAnimalBreed", width: '25%', show: size.isLarge });
      cols.push({ id: id++, Header: trans('field.gender'), accessor: row => row["boGender"] === '0' ? trans('field.animal_female') : trans('field.animal_male'), width: '25%', show: size.isLarge });

    } catch(error) {
      FxLog.errorx('MxAnimalListCP.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.my_x','field.pets')}
        xref={r_list}
        fullscreen
        hasSearchBox={false}
        hasButtonAdd={false}
        url="cp/animals"
        filter={`n_animal.inClient='${getStoreValue('StoreSession', 'inClientCP')}'`}
        onOpenForm={openForm}
        columns={getTableColumns()}
      />

    </div>
  );

}

export default MxAnimalListCP;
