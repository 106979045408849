import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

const CxButtonShowMore = props => {
  const { label, ...rest } = props;
  const [show, setShow] = React.useState(false);
  
  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, 
    };
  });
  const getType = () => { return 'CxButtonShowMore' };

  return (
    <span style={{ width: '100%', textAlign: 'left' }}>

      {!show && <button className="ibs_button_link" onClick={(e) => doClick(e)} {...rest} >
        {props.label}
      </button>}

      <span style={{ display: show ? 'flex' : 'none', flexDirection: 'row', flexFlow: 'row wrap', gap: 'var(--ibs_container_gap)' }}>
        {props.children}
      </span>

    </span>
  );

};

export default CxButtonShowMore;
