import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxTime from '../../cx/CxTime';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';


const MxSettCalendarWorkingHours = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  const r_snack_save = React.useRef(null);
  const r_snack_required = React.useRef(null);

  let r_form = React.useRef(null);

  const rf_txStartHour = React.useRef(null);
  const rf_txWorkTime1From = React.useRef(null);
  const rf_txWorkTime1To = React.useRef(null);
  const rf_txWorkTime2From = React.useRef(null);
  const rf_txWorkTime2To = React.useRef(null);


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('cal/sett', record)]).then((result) => {
      if (result[0]) {
        rf_txStartHour.current.setVal(result[0].txStartHour);
        rf_txWorkTime1From.current.setVal(result[0].txWorkTime1From);
        rf_txWorkTime1To.current.setVal(result[0].txWorkTime1To);
        rf_txWorkTime2From.current.setVal(result[0].txWorkTime2From);
        rf_txWorkTime2To.current.setVal(result[0].txWorkTime2To);
      }
    });

  }

  const formSaveCustom = async () => {

    if (rf_txStartHour.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txStartHour.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txWorkTime1From.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txWorkTime1From.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txWorkTime1To.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txWorkTime1To.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txWorkTime2From.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txWorkTime2From.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txWorkTime2To.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txWorkTime2To.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let record = {};
    record['txStartHour'] = rf_txStartHour.current.getVal();
    record['txWorkTime1From'] = rf_txWorkTime1From.current.getVal();
    record['txWorkTime1To'] = rf_txWorkTime1To.current.getVal();
    record['txWorkTime2From'] = rf_txWorkTime2From.current.getVal();
    record['txWorkTime2To'] = rf_txWorkTime2To.current.getVal();
    Promise.all([FxFetch.doJsonF("/sett/cal/working_hours/u", null, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.calendars')} - ${trans('field.working_hours')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxTime xref={rf_txStartHour} label={trans('field.hour') + ' ' + trans('field.start')} required />
        <CxTime xref={rf_txWorkTime1From} label={trans('field.hour_morning_start')} required />
        <CxTime xref={rf_txWorkTime1To} label={trans('field.hour_morning_end')} required />
        <CxTime xref={rf_txWorkTime2From} label={trans('field.hour_afternoon_start')} required />
        <CxTime xref={rf_txWorkTime2To} label={trans('field.hour_afternoon_end')} required />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSettCalendarWorkingHours;
