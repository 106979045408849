// Funciones para traducción.
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

export const useAccess = (modName) => {
  let mod = modName;
  let ar = JSON.parse(sessionStorage.getItem('access') || null);

  const _storeInSession = (ar) => {
    sessionStorage.setItem('access', JSON.stringify(ar));
  }

  const _getItem = (key) => {
    if (ar[key]) {
      return ar[key];
    } else {
      FxLog.errorx('FxTrans.get', 'NO HAY ACCESO PARA: ' + key);
      return key;
    }
  }

  const _isFullAccess = () => {
    if (_getItem(mod) === 'W') {
      return true;
    } else {
      return false;
    }
  }

  return [
    {
      storeAccessInSession: _storeInSession,
      access: _getItem,
      isFullAccess: _isFullAccess,
    },
  ];
};
