import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettCash } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettCashList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettCash, setStoreSettCash] = useSessionStorage('StoreSettCash', StoreSettCash);

  React.useEffect(() => {
    clearStore('StoreSettCash', storeSettCash);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCash.itCash = null;
    } else {
      storeSettCash.itCash = row.original.itCash;
    }
    setStoreSettCash(storeSettCash);
    navTo("sett-cash-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.cash_reg')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/cash"
        filter={`t_cash.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itCash", show: false },
          { id: "1", Header: trans('field.cash_reg'), accessor: "txTypeCash", width: '100%' },
        ]}
      />

    </div>
  );

}

export default MxSettCashList;
