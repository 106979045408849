import React from 'react';
import GxList from '../../gx/GxList';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreAnalytics } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';


const MxAnalyticsList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeAnalytics, setStoreAnalytics] = useSessionStorage('StoreAnalytics', StoreAnalytics);

  const r_list = React.useRef(null);
  let r_print = React.useRef(null);
  
  const r_snack_required = React.useRef(null);
  const r_snack_op_in_progress = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreAnalytics', storeAnalytics);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storeAnalytics.inAnalytics = null;
    } else {
      storeAnalytics.inAnalytics = row.original.inAnalytics;
    }
    setStoreAnalytics(storeAnalytics);

    navTo("analytics-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inAnalytics", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '10%' });
        cols.push({ id: id++, Header: trans('field.to'), accessor: row => ( FxTable.cellToLocalDate(row["dtTo"]) ), width: '10%' });
        cols.push({ id: id++, Header: trans('field.model'), accessor: "txModel", width: '20%' });
        cols.push({ id: id++, Header: trans('field.description'), accessor: row => r_list.current.getTable().highlightText(row["txDescription"]), width: '30%' });
        cols.push({ id: id++, Header: trans('field.incomes'), accessor: row => FxTable.cellTo2r00(row["nuAmountInc"]), width: '10%', align: 'right' });
        cols.push({ id: id++, Header: trans('field.expenses'), accessor: row => FxTable.cellTo2r00(row["nuAmountExp"]), width: '10%', align: 'right' });
        cols.push({ id: id++, Header: trans('field.balance'), accessor: row => FxTable.cellTo2r00(row["theBalance"]), width: '10%', align: 'right' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '30%' });
        cols.push({ id: id++, Header: trans('field.description'), accessor: row => r_list.current.getTable().highlightText(row["txDescription"]), width: '70%' });
      }

    } catch(error) {
      FxLog.errorx('MxAnalyticsList.getTableColumns', error)
    }
    return cols;
  }
 
  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    let record = {};
    //let inClient = storeClient.inClient;
    r_print.current.setScale(1);
    r_print.current.setUrl('analytics/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter), 
      r_print.current.setRecord(record), 
      //r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.analytics')}`}
        xref={r_list}
        tooltip={`${trans('field.description')}`}
        fullscreen
        url="analytics"
        onOpenForm={openForm}
        columns={getTableColumns()}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={doPrintX} tooltip={trans('field.print')} />
          </div>
        }
      />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print}/>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_op_in_progress} severity="info" value={trans('msg.operation_in_progress')} autoHideDuration={10000} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxAnalyticsList;
