import React from 'react';
import { useTranslation } from '../hx/useTranslation';
//import * as FxLog from '../fx/FxLog';
import CxDialog from '../cx/CxDialog';
import CxSearcher from '../cx/CxSearcher';

/** Selector independiente lanzado desde código, por ejemplo para seleccionar cliente o empleado sin necesitar un CxSearcher */

const GxDlgStandard = (props) => {
  const [{ trans }] = useTranslation();

  const [intermediateValues, setIntermediateValues] = React.useState({}); //para guardar estructura de valores intermedios.

  const [dlgSelectClient, setDlgSelectClient] = React.useState(false);
  let r_dlg_client = React.useRef(null);
  let rf_txClient = React.useRef(null);

  const [dlgSelectEmployee, setDlgSelectEmployee] = React.useState(false);
  let r_dlg_employee = React.useRef(null);
  let rf_txEmployee = React.useRef(null);

  const [dlgSelectPs, setDlgSelectPs] = React.useState(false);
  let r_dlg_ps = React.useRef(null);
  let rf_txPs = React.useRef(null);


  React.useImperativeHandle(props.xref, () => {
    return {
      doSelectClient, doSelectEmployee, doSelectPs, setIntermediateValues
    };
  });

  const doSelectClient = () => {
    setDlgSelectClient(true);
  };
  const doSelectionClient = () => {
    let inClient = rf_txClient.current.getKey();
    let txClient = rf_txClient.current.getVal();
    if (props.onSelectedClient) {
      props.onSelectedClient(inClient, txClient, intermediateValues);
    }
    setDlgSelectClient(false);
  }

  const doSelectEmployee = () => {
    setDlgSelectEmployee(true);
  };
  const doSelectionEmployee = () => {
    let inEmployee = rf_txEmployee.current.getKey();
    let txEmployee = rf_txEmployee.current.getVal();
    if (props.onSelectedEmployee) {
      props.onSelectedEmployee(inEmployee, txEmployee, intermediateValues);
    }
    setDlgSelectEmployee(false);
  }

  const doSelectPs = () => {
    setDlgSelectPs(true);
  };
  const doSelectionPs = () => {
    let inPs = rf_txPs.current.getKey();
    let txPs = rf_txPs.current.getVal();
    if (props.onSelectedPs) {
      props.onSelectedPs(inPs, txPs, intermediateValues);
    }
    setDlgSelectPs(false);
  }

  return (
    <div>

      <CxDialog
        xref={r_dlg_client}
        title={props.title || trans('msg.select_x','field.client')}
        open={dlgSelectClient}
        onOk={doSelectionClient}
        onCancel={() => setDlgSelectClient(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxSearcher xref={rf_txClient} label={trans('field.client')}
            id="n_client"
            url="clients/searcher"
            col_id="inClient"
            col_tx="txClient"
            colorRefs={['table_cell_red|' + trans('field.debtor')]}
            columns={[
              { id: "0", Header: "ID", accessor: "inClient", show: false },
              { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
              { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
            ]}
            readOnly={false}
          />
        </div>
      </CxDialog>

      <CxDialog
        xref={r_dlg_employee}
        title={props.title || trans('msg.select_x','field.employee')}
        open={dlgSelectEmployee}
        onOk={doSelectionEmployee}
        onCancel={() => setDlgSelectEmployee(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
            dbfield="n_employee.txEmployee" dbfieldkey="n_animal.inEmployee"
            url="employees" col_id="inEmployee" col_tx="txEmployee"
            columns={[
              { id: "0", Header: "ID", accessor: "inEmployee", show: false },
              { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
            ]}
          />
        </div>
      </CxDialog>

      <CxDialog
        xref={r_dlg_ps}
        title={props.title || trans('msg.select_x','field.ps')}
        open={dlgSelectPs}
        onOk={doSelectionPs}
        onCancel={() => setDlgSelectPs(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxSearcher xref={rf_txPs} label={trans('field.ps')}
            dbfield="n_ps.txPs" dbfieldkey="n_animal.inPs"
            url="pss" col_id="inPs" col_tx="txPs"
            tooltip={`${trans('field.ps')}`}
            columns={[
              { id: "0", Header: "ID", accessor: "inPs", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '100%' },
            ]}
          />
        </div>
      </CxDialog>

    </div>
  );
}

export default GxDlgStandard;
