// Funciones generales para enlace con servidor.
import * as FxLog from './FxLog';
import * as FxDat from './FxDat';
import * as FxInet from './FxInet';
import * as FxEdition from './FxEdition';


export const getServerURL = () => {
  let rv = null;
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") {
    let boUseLocalServer = true;
    if (boUseLocalServer) {
      // LOCAL SERVER
      rv = 'http://localhost:8082/';
    } else {
      // REMOTE SERVER PARA DEBUG
      console.log("ATENCIÓN: usando SERVIDOR REMOTO !!!");
      rv = 'https://staging-iberical-flow-backend.herokuapp.com/';
    }
  } else {
    // REMOTE SERVER
    if (window.location.hostname === "flow.iberical.cloud"
        || window.location.hostname === "cloud.iberical.com"
        || window.location.hostname === "iberical-flow-frontend.herokuapp.com"
    ) {
      rv = 'https://iberical-flow-backend.herokuapp.com/';
    }
    // REMOTE SERVER PARA DEBUG
    if (window.location.hostname === "staging-flow.iberical.cloud" 
        || window.location.hostname === "staging-cloud.iberical.com"
        || window.location.hostname === "free.iberical.com"
        || window.location.hostname === "staging-iberical-flow-frontend.herokuapp.com"
    ) {
      rv = 'https://staging-iberical-flow-backend.herokuapp.com/';
    }
  }
  FxLog.infox("FxFetch.getServerURL", "...rv:", rv);
  return rv;
}

export const doUrl = async (url, params) => {
  //-----------------------------------------------------------------------
  FxLog.infox("FxFetch.doUrl", "Fetching items...URL", `Fetching items...URL: ${url}/${params}`);
  if (!FxInet.isOnline()) {
    FxLog.infox('FxFetch.doUrl', '...', 'INTERNET OFF');
    return null;
  }
  try {
    url = getServerURL() + url;
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: params
    })
      .then((response) => response.json())
      .then((responseData) => {
        FxLog.infox("FxFetch.doUrl", "Service returns...responseData:", responseData);
        return responseData;
      })
      .catch(error => { return null });

  } catch (error) {
    FxLog.errorx("FxFetch.doUrl", error);
  }
  //-----------------------------------------------------------------------
}

export const getStoreSession = () => {
  // recuperamos la sesión y le añadimos fecha y hora.
  const theStore = JSON.parse(sessionStorage.getItem('StoreSession'));
  theStore['dtFetch'] = FxDat.timestamp();
  // guardamos sesión con los cambios y la devolvemos para ser enviada con la petición.
  let session = theStore; // xq luego stringify transforma a theStore.
  sessionStorage.setItem('StoreSession', JSON.stringify(theStore));
  FxLog.infox("FxFetch.getStoreSession", "...session:", session);
  return { session };
}

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const doJsonX = async (url, record) => {
  return doJson(url, { ...getStoreSession(), record: record });
}
export const doJsonF = async (url, filter, record) => {
  return doJson(url, { ...getStoreSession(), filter: filter, record: record });
}
export const doJson = async (url, params) => {
  if (!FxInet.isOnline()) {
    FxLog.infox('FxFetch.doJson', '...', 'INTERNET OFF');
    return null;
  }
  const abortController = new AbortController();
  const timeoutId = setTimeout(() => abortController.abort(), 20000); // timeout 20seg. No se puede poner más porque heroku no soporta más de 30seg (da error H12).
  //-----------------------------------------------------------------------
  if (FxEdition.isPremiumNeeded()) {
    console.log("FxFetch.doJson: MORE RESOURCES NEEDED!");
    sleep(1000 * 1); // espera 1 segundo
  }
  //-----------------------------------------------------------------------
  //FxLog.infox("FxFetch.doJson", "Fetching items...params:", params);
  try {
    url = getServerURL() + url;
    return fetch(url, {
      signal: abortController.signal, //timeout
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
      .then((response) => response.text())
      .then((responseText) => responseText.length ? JSON.parse(responseText) : {})
      .then((responseData) => {
        //FxLog.infox("FxFetch.doJson", "Service returns...responseData:", responseData);
        clearTimeout(timeoutId);
        return responseData;
      })
      .catch(error => {
        clearTimeout(timeoutId);
        switch (error.name) {
          case 'AbortError': 
            FxLog.errorx("FxFetch.doJson", "TIMEOUT");     
            break;
          case 'TypeError': 
            FxLog.errorx("FxFetch.doJson", "TypeError-> SERVER DISCONNECTED. URL: " + url);     
            break;
          case 'SyntaxError': 
            FxLog.errorx("FxFetch.doJson", "SyntaxError-> SERVER DISCONNECTED. URL: " + url);     
            break;
        
          default:
            FxLog.errorx("FxFetch.doJson", error);
            break;
        }
        return null;
      });

  } catch (error) {
    FxLog.errorx("FxFetch.doJson", error);
    return null;
  }
  //-----------------------------------------------------------------------
}
