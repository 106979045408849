import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxState from '../../cx/CxState';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, setStoreValue, isApp } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';


const MxSettSlsGeneral = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_save = React.useRef(null);

  let r_form = React.useRef(null);

  const rf_boDecimalsInDocs = React.useRef(null);
  const rf_boAnnualNumeration = React.useRef(null);
  const rf_boAutoCreateFq = React.useRef(null); //crea repl o vacc
  const rf_boAutoCreateComm = React.useRef(null); //crea comunicación
  const rf_boAutoCreateEu = React.useRef(null); //crea eutanasia
  const rf_boAutoCreateSt = React.useRef(null); //crea esterilización
  const rf_boSpecialInvoice = React.useRef(null);
  const rf_boSpecialReceipt = React.useRef(null);
  const rf_boShowClientA4 = React.useRef(null); //muestra datos del cliente en la impresión
  const rf_boShowClientA5 = React.useRef(null); //muestra datos del cliente en la impresión
  const rf_boShowClientA8 = React.useRef(null); //muestra datos del cliente en la impresión
  const rf_boDefaultIsReceipt = React.useRef(null);
  const rf_boElectronicPurse = React.useRef(null);
  const rf_boAutoCreateMp = React.useRef(null); //creación de doc con múltiples pacientes 
  //const rf_boUseInvoices = React.useRef(null); // 2023-05-31 - daq: se controlan por sql Ej-> update s_branch set boUseInvoices='0';
  //const rf_boUseReceipts = React.useRef(null); // 2023-05-31 - daq: se controlan por sql Ej-> update s_branch set boUseReceipts='0';
  /* NO APLICAN A VWEB.
  const rf_boAutoCreateRx = React.useRef(null); 
  const rf_boAutoCreateRxForce = React.useRef(null); //fuerza creación de receta. 
  const rf_boFirstTabIsClient = React.useRef(null); 
  const rf_boConfirmationEnabled = React.useRef(null); 
  */

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/branch/r', record)]).then((result) => {
      if (result[0]) {
        rf_boDecimalsInDocs.current.setVal(result[0][0].boDecimalsInDocs);
        rf_boAnnualNumeration.current.setVal(result[0][0].boAnnualNumeration);
        if (isApp('opti') || isApp('vett')) rf_boAutoCreateFq.current.setVal(result[0][0].boAutoCreateFq);
        rf_boAutoCreateComm.current.setVal(result[0][0].boAutoCreateComm);
        if (isApp('vett')) rf_boAutoCreateEu.current.setVal(result[0][0].boAutoCreateEu);
        if (isApp('vett')) rf_boAutoCreateSt.current.setVal(result[0][0].boAutoCreateSt);
        if ((isApp('vett') || isApp('spaw'))) rf_boSpecialInvoice.current.setVal(result[0][0].boSpecialInvoice);
        if ((isApp('vett') || isApp('spaw'))) rf_boSpecialReceipt.current.setVal(result[0][0].boSpecialReceipt);
        rf_boShowClientA4.current.setVal(result[0][0].boShowClientA4);
        rf_boShowClientA5.current.setVal(result[0][0].boShowClientA5);
        rf_boShowClientA8.current.setVal(result[0][0].boShowClientA8);
        rf_boDefaultIsReceipt.current.setVal(result[0][0].boDefaultIsReceipt);
        rf_boElectronicPurse.current.setVal(result[0][0].boElectronicPurse);
        if (isApp('vett')) rf_boAutoCreateMp.current.setVal(result[0][0].boAutoCreateMp);
      }
    });
  }

  const formSaveCustom = async () => {

    let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
    let record = {};

    record['s_branch.boDecimalsInDocs'] = rf_boDecimalsInDocs.current.getVal();
    record['s_branch.boAnnualNumeration'] = rf_boAnnualNumeration.current.getVal();
    if (isApp('opti') || isApp('vett')) record['s_branch.boAutoCreateFq'] = rf_boAutoCreateFq.current.getVal();
    record['s_branch.boAutoCreateComm'] = rf_boAutoCreateComm.current.getVal();
    if (isApp('vett')) record['s_branch.boAutoCreateEu'] = rf_boAutoCreateEu.current.getVal();
    if (isApp('vett')) record['s_branch.boAutoCreateSt'] = rf_boAutoCreateSt.current.getVal();
    if ((isApp('vett') || isApp('spaw'))) record['s_branch.boSpecialInvoice'] = rf_boSpecialInvoice.current.getVal();
    if ((isApp('vett') || isApp('spaw'))) record['s_branch.boSpecialReceipt'] = rf_boSpecialReceipt.current.getVal();
    record['s_branch.boShowClientA4'] = rf_boShowClientA4.current.getVal();
    record['s_branch.boShowClientA5'] = rf_boShowClientA5.current.getVal();
    record['s_branch.boShowClientA8'] = rf_boShowClientA8.current.getVal();
    record['s_branch.boDefaultIsReceipt'] = rf_boDefaultIsReceipt.current.getVal();
    record['s_branch.boElectronicPurse'] = rf_boElectronicPurse.current.getVal();
    if (isApp('vett')) record['s_branch.boAutoCreateMp'] = rf_boAutoCreateMp.current.getVal();
    
    setStoreValue('StoreSession', 'boDecimalsInDocs', rf_boDecimalsInDocs.current.getVal());
    setStoreValue('StoreSession', 'boAnnualNumeration', rf_boAnnualNumeration.current.getVal());
    if (isApp('opti') || isApp('vett')) setStoreValue('StoreSession', 'boAutoCreateFq', rf_boAutoCreateFq.current.getVal());
    setStoreValue('StoreSession', 'boAutoCreateComm', rf_boAutoCreateComm.current.getVal());
    if (isApp('vett')) setStoreValue('StoreSession', 'boAutoCreateEu', rf_boAutoCreateEu.current.getVal());
    if (isApp('vett')) setStoreValue('StoreSession', 'boAutoCreateSt', rf_boAutoCreateSt.current.getVal());
    if ((isApp('vett') || isApp('spaw'))) setStoreValue('StoreSession', 'boSpecialInvoice', rf_boSpecialInvoice.current.getVal());
    if ((isApp('vett') || isApp('spaw'))) setStoreValue('StoreSession', 'boSpecialReceipt', rf_boSpecialReceipt.current.getVal());
    setStoreValue('StoreSession', 'boShowClientA4', rf_boShowClientA4.current.getVal());
    setStoreValue('StoreSession', 'boShowClientA5', rf_boShowClientA5.current.getVal());
    setStoreValue('StoreSession', 'boShowClientA8', rf_boShowClientA8.current.getVal());
    setStoreValue('StoreSession', 'boDefaultIsReceipt', rf_boDefaultIsReceipt.current.getVal());
    setStoreValue('StoreSession', 'boElectronicPurse', rf_boElectronicPurse.current.getVal());
    if (isApp('vett')) setStoreValue('StoreSession', 'boAutoCreateMp', rf_boAutoCreateMp.current.getVal());


    Promise.all([FxFetch.doJsonF("/sett/branch/u", filter, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.sales')} - ${trans('field.general')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >

        <CxState mode='checkbox' xref={rf_boDecimalsInDocs} dbfield="s_branch.boDecimalsInDocs" label={trans('field.sett_decimals_in_docs')} />
        <CxState mode='checkbox' xref={rf_boAnnualNumeration} dbfield="s_branch.boAnnualNumeration" label={trans('field.sett_annual_numeration')} />
        <CxState mode='checkbox' xref={rf_boShowClientA4} dbfield="s_branch.boShowClientA4" label={trans('field.sett_show_client') + " A4"} />
        <CxState mode='checkbox' xref={rf_boShowClientA5} dbfield="s_branch.boShowClientA5" label={trans('field.sett_show_client') + " A5"} />
        <CxState mode='checkbox' xref={rf_boShowClientA8} dbfield="s_branch.boShowClientA8" label={trans('field.sett_show_client') + " A8"} />
        <CxState mode='checkbox' xref={rf_boDefaultIsReceipt} dbfield="s_branch.boDefaultIsReceipt" label={trans('field.sett_default_receipt')} />
        <CxState mode='checkbox' xref={rf_boElectronicPurse} dbfield="s_branch.boElectronicPurse" label={trans('field.sett_electronic_purse')} />

        {isApp('vett') && <CxState mode='checkbox' xref={rf_boAutoCreateMp} dbfield="s_branch.boAutoCreateMp" label={trans('field.sett_auto_create_mp')} />}
        {isApp('opti') && <CxState mode='checkbox' xref={rf_boAutoCreateFq} dbfield="s_branch.boAutoCreateFq" label={trans('field.sett_auto_create_fq_repl')} />}
        {isApp('vett') && <CxState mode='checkbox' xref={rf_boAutoCreateFq} dbfield="s_branch.boAutoCreateFq" label={trans('field.sett_auto_create_fq_vacc')} />}
        {(isApp('opti') || isApp('vett') || isApp('spaw')) && <CxState mode='checkbox' xref={rf_boAutoCreateComm} dbfield="s_branch.boAutoCreateComm" label={trans('field.sett_auto_create_comm')}
          tooltip={trans("field.required") + ": " + (isApp('opti') ? trans("field.sett_auto_create_fq_repl") : trans("field.sett_auto_create_fq_vacc"))}
          tooltipType="alert"
          />}

        {isApp('vett') && <CxState mode='checkbox' xref={rf_boAutoCreateEu} dbfield="s_branch.boAutoCreateEu" label={trans('field.sett_auto_create_eu')} />}
        {isApp('vett') && <CxState mode='checkbox' xref={rf_boAutoCreateSt} dbfield="s_branch.boAutoCreateSt" label={trans('field.sett_auto_create_st')} />}
        {(isApp('vett') || isApp('spaw')) && <CxState mode='checkbox' xref={rf_boSpecialInvoice} dbfield="s_branch.boSpecialInvoice" label={trans('field.sett_spec_x','field.invoices')} />}
        {(isApp('vett') || isApp('spaw')) && <CxState mode='checkbox' xref={rf_boSpecialReceipt} dbfield="s_branch.boSpecialReceipt" label={trans('field.sett_spec_x','field.receipts')} />}

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettSlsGeneral;
