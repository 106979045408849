import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxState from '../../cx/CxState';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettCpAccess, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettCpAccessForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  let rf_txTypeCpAccess = React.useRef(null);

  let rf_boOptiRx = React.useRef(null);
  let rf_boVettAnimalHistory = React.useRef(null);
  let rf_boVettAnimalRxAuto = React.useRef(null);

  const [storeSettCpAccess, setStoreSettCpAccess] = useSessionStorage('StoreSettCpAccess', StoreSettCpAccess);

  const [disabled, setDisabled] = React.useState(true);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    } else {
      setDisabled(rf_txTypeCpAccess.current.getVal()==='Standard');
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettCpAccess.itCpAccess = lastSaveRv.theValue;
      Promise.all([setStoreSettCpAccess(storeSettCpAccess)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client_portal')} - ${trans('field.type')}`}
        xref={r_form}
        url="sett/cp_access" 
        filterIdField='itCpAccess'
        filterIdValue={storeSettCpAccess.itCpAccess} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={!disabled && formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_cp_access.itCpAccess" value={storeSettCpAccess.itCpAccess || ''} />
        <CxInput xref={rf_txTypeCpAccess} label={`${trans('field.client_portal')} - ${trans('field.type')}`} dbfield="t_cp_access.txTypeCpAccess" disabled={disabled} required />
        
        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.appointments')} dbfield="t_cp_access.boAppointments" />
        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.quotations')} dbfield="t_cp_access.boQuotations" />
        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.invoices')} dbfield="t_cp_access.boInvoices" />
        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.receipts')} dbfield="t_cp_access.boReceipts" />

        {isApp('opti') && <CxState mode='checkbox' xref={rf_boOptiRx} label={trans('field.rxs')} dbfield="t_cp_access.boOptiRx" />}

        {isApp('vett') && <CxState mode='checkbox' xref={rf_boVettAnimalHistory} label={trans('field.history')} dbfield="t_cp_access.boVettAnimalHistory" />}
        {isApp('vett') && <CxState mode='checkbox' xref={rf_boVettAnimalRxAuto} label={trans('field.rxs')} dbfield="t_cp_access.boVettAnimalRxAuto" />}

      </GxForm>

    </div>
  );

}

export default MxSettCpAccessForm;
