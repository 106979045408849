import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettImage } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettImageForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const [storeSettImage, setStoreSettImage] = useSessionStorage('StoreSettImage', StoreSettImage);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettImage.itImage = lastSaveRv.theValue;
      Promise.all([setStoreSettImage(storeSettImage)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.type')} - ${trans('field.image')}`}
        xref={r_form}
        url="sett/image" 
        filterIdField='itImage'
        filterIdValue={storeSettImage.itImage} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_image.itImage" value={storeSettImage.itImage || ''} />
        <CxInput xref={useRef(null)} label={`${trans('field.type')} - ${trans('field.image')}`} dbfield="t_image.txTypeImage" required />
        
      </GxForm>

    </div>
  );

}

export default MxSettImageForm;
