import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxDate from '../../cx/CxDate';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';
import * as FxFetch from '../../fx/FxFetch';
import * as FxStr from '../../fx/FxStr';
import GxForm from '../../gx/GxForm';
import { getStoreValue, getStoreValueBo, StoreSlsDoc, StoreSlsLst } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import CxDialog from '../../cx/CxDialog';
import CxStatic from '../../cx/CxStatic';
import CxSnackbar from '../../cx/CxSnackbar';
import { useAccess } from '../../hx/useAccess';
import * as MxSlsDocFormPaymH1 from './MxSlsDocFormPaymH1';


const MxSlsDocFormPaym = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  let r_form = React.useRef(null);
  let r_idx = React.useRef(storeSlsLst.table_paym_idx);

  const r_snack_error = React.useRef(null);

  const [docTypeName, ] = React.useState(FxStr.choose(storeSlsDoc.docType, 'q|i|r', trans('field.quotation'), trans('field.invoice'), trans('field.receipt')));

  const [purseBalance, setPurseBalance] = React.useState(0);

  const rf_dtPayment = React.useRef(null);
  const rf_txTypeCash = React.useRef(null);
  const rf_txTypePayment = React.useRef(null);
  const rf_txTypePaymentDet = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const [dlgAmountDelivered, setDlgAmountDelivered] = React.useState(false);
  const rf_amount_delivered = React.useRef(null);
  const r_delivered = React.useRef(null);
  const rf_change = React.useRef(null);

  React.useEffect(() => {
    getPurseBalance();
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    if (storeSlsLst.table_paym_idx === null) { //nuevo registro
      rf_dtPayment.current.setValToday();
      rf_txTypeCash.current.setKey(getStoreValue('StoreSession', 'itCash'));
      rf_txTypeCash.current.setVal(getStoreValue('StoreSession', 'txTypeCash'));
      //rf_txTypePayment.current.setKeyAndVal('t_payment', 'txTypePayment', 'itPayment', '000001.SRV');/* DAQ 2022.05.30 - innecesario porque abre ventana de selección */
      let balance = FxNum.to2r(storeSlsDoc.balance * -1);
      r_delivered.current = balance;
      //rf_nuAmount.current.setVal(balance);
      rf_txTypePayment.current.setDlg(true); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de tipo de cobro */

    } else {
      rf_txTypePaymentDet.current.setFilter(`itPayment='${storeSlsDoc.arPayms[r_idx.current].itPayment}'`);
      rf_dtPayment.current.setVal(storeSlsDoc.arPayms[r_idx.current].dtPayment);
      rf_txTypeCash.current.setKey(storeSlsDoc.arPayms[r_idx.current].itCash);
      rf_txTypeCash.current.setVal(storeSlsDoc.arPayms[r_idx.current].txTypeCash);
      rf_txTypePayment.current.setKey(storeSlsDoc.arPayms[r_idx.current].itPayment);
      rf_txTypePayment.current.setVal(storeSlsDoc.arPayms[r_idx.current].txTypePayment);
      rf_txTypePaymentDet.current.setKey(storeSlsDoc.arPayms[r_idx.current].itPaymentDet);
      rf_txTypePaymentDet.current.setVal(storeSlsDoc.arPayms[r_idx.current].txTypePaymentDet);
      rf_nuAmount.current.setVal(storeSlsDoc.arPayms[r_idx.current].nuAmount);
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let paymData = {
      dtPayment: rf_dtPayment.current.getVal(),
      itCash: rf_txTypeCash.current.getKey(),
      txTypeCash: rf_txTypeCash.current.getVal(),
      itPayment: rf_txTypePayment.current.getKey(),
      txTypePayment: rf_txTypePayment.current.getVal(),
      itPaymentDet: rf_txTypePaymentDet.current.getKey(),
      txTypePaymentDet: rf_txTypePaymentDet.current.getVal(),
      nuAmount: rf_nuAmount.current.getVal(),
    };

    // Saving
    //---------------------------------------------
    Promise.all([MxSlsDocFormPaymH1.savePaymToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst.table_paym_idx, r_idx.current, paymData)]).then((result) => {
      navBack();
    });
  }

  const formDeleteCustom = () => {
    if (storeSlsLst.table_paym_idx !== null) {
      if (storeSlsDoc.arPayms[r_idx.current].inPayment) {
        storeSlsDoc.txPaymsDeleted = storeSlsDoc.arPayms[r_idx.current].inPayment + ' ' + storeSlsDoc.txPaymsDeleted;
      }
      storeSlsDoc.arPayms.splice(r_idx.current, 1); // 2nd parameter means remove one paym only
      // reorder
      storeSlsDoc.arPayms.forEach(function (paym, index) {
        paym.idx = index;
      })

      // asienta que los datos fueron cambiados
      storeSlsDoc.boDataChanged = '1';

      Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
        navBack();
      });
    }
  }

  const getPurseBalance = () => {
    // si el monedero no está configurado.
    if (!getStoreValueBo('StoreSession', 'boElectronicPurse')) {
      return;
    }
    // obtiene y devuelve el saldo del monedero.
    let record = {};
    record['inClient'] = storeSlsDoc.inClient;
    Promise.all([FxFetch.doJsonX("clients/purse_balance_get", record)]).then((result) => {
      if (result[0]) {
        setPurseBalance(FxNum.to2r00(result[0].theValue||0));
      }
    });
  }

  const onSelectionPayment = async (row) => {
    if (row !== null) {
      let itPayment = row.original.itPayment;
      rf_txTypePaymentDet.current.setFilter(`itPayment='${itPayment}'`);
      rf_txTypePaymentDet.current.clear();

      if (itPayment === '000005.SRV') {
        // si el pago es monedero-e, pero el monedero no está habilitado, cancela y avisa.
        if (!getStoreValueBo('StoreSession', 'boElectronicPurse')) {
          r_snack_error.current.setVal(trans('msg.x_not_configured_1', 'field.purse'));
          r_snack_error.current.setOpen(true);
          rf_txTypePayment.current.clear();
          rf_nuAmount.current.setVal(r_delivered.current);
          return;
        }
        // si el pago es monedero-e, y el cliente no tiene saldo suficiente, cancela y avisa.
        if (purseBalance < r_delivered.current) {
          r_snack_error.current.setVal(trans('msg.client_purse_balance', FxNum.toString(purseBalance)));
          r_snack_error.current.setOpen(true);
          rf_nuAmount.current.setVal(purseBalance);
          return;
        }
      }

      // Si el pago es efectivo y no hay importe, pregunta importe entregado por el cliente, para calcular el cambio.
      if (rf_nuAmount.current.isEmpty()) {
        if (itPayment === '000001.SRV') {
          Promise.all([setDlgAmountDelivered(true)]).then((result) => {
            rf_amount_delivered.current.setFocusSel();
          });
        } else {
          rf_nuAmount.current.setVal(r_delivered.current);
        }
      }
    }
  }

  const amountDeliveredChanged = (e) => {
    if (e.key === 'Enter') {
      doCalcChange();
    } else {
      let delivered = rf_amount_delivered.current.getFloatVal();
      let balance = parseFloat(FxNum.to2r(storeSlsDoc.balance * -1));
      let change = delivered - balance;
      if (change > 0.0) {
        rf_change.current.setLabel(`${trans('field.change')}: ${FxNum.toString(FxNum.to2r00(change))}`);
      } else {
        rf_change.current.setLabel(`${trans('field.change')}: ${FxNum.toString(FxNum.to2r00(0.0))}`);
      }
    }
  }

  const doCalcChange = () => {
    setDlgAmountDelivered(false);
    let delivered = rf_amount_delivered.current.getFloatVal();
    let balance = parseFloat(FxNum.to2r(storeSlsDoc.balance * -1));
    if (balance === delivered) {
      // entrega lo justo, cargamos el importe entregado, y no hay cambio que dar.
      rf_nuAmount.current.setVal(delivered);
    }
    if (balance > delivered) {
      // entrega menos que el pendiente, cargamos el importe entregado, y no hay cambio que dar.
      rf_nuAmount.current.setVal(delivered);
    }
    if (balance < delivered) {
      // entrega más que el pendiente, aquí SÍ HAY CAMBIO QUE DAR.
      // cargamos sólo el importe pendiente y avisamos al usuario la diferencia (cambio que dar al cliente)
      rf_nuAmount.current.setVal(balance);
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.sales')}: ${docTypeName}: ${trans('field.payment')}`}
        xref={r_form}
        toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            {storeSlsLst.table_paym_idx !== null && isFullAccess() &&
              <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar */}
            {isFullAccess() &&
              <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.confirm')} />}

          </div>
        }
      >
        <CxDate xref={rf_dtPayment} label={trans('field.date')} />

        <CxChooser xref={rf_txTypeCash} label={trans('field.cash_reg')}
          table="t_cash"
          col_id="itCash"
          col_tx="txTypeCash"
          readOnly={false}
          required
        />
        <CxChooser xref={rf_txTypePayment} label={trans('field.payment')}
          table="t_payment"
          col_id="itPayment"
          col_tx="txTypePayment"
          extra_filter_1={"not txTypePayment like 'OLD-%'" + (!storeSlsDoc.inClient ? " and itPayment<>'000005.SRV'" : "")} // 2024-07-07 - daq: si no eligió cliente, no hay monedero.
          readOnly={false}
          onSelection={onSelectionPayment}
          required
        />
        <CxChooser xref={rf_txTypePaymentDet} label={trans('field.detail')}
          table="t_payment_det"
          col_id="itPaymentDet"
          col_tx="txTypePaymentDet"
          readOnly={false}
        />

        <CxNumber xref={rf_nuAmount} label={trans('field.amount')}
          decimals={2}
          decimalsfixed="true"
          required
        />

        <CxDialog title={trans('field.amount')}
          open={dlgAmountDelivered}
          onOk={doCalcChange}
          onCancel={() => setDlgAmountDelivered(false)}
          height='200px'
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_md)' }}>
            <CxNumber xref={rf_amount_delivered}
              onKeyUp={amountDeliveredChanged}
              onClear={() => { rf_change.current.setLabel(`${trans('field.change')}: ${FxNum.toString(FxNum.to2r00(0.0))}`) }}
              label={`${trans('field.delivered')}`}
              decimals={2}
              decimalsfixed="true"
              required
              width='var(--ibs_control_width_md)'
              defaultValue={r_delivered.current}
            />
            <CxStatic xref={rf_change} label={`${trans('field.change')}: ${FxNum.toString(FxNum.to2r00(0.0))}`}
              height='20px'
              width='var(--ibs_control_width_md)'
              fontcolor='var(--ibs_color_blue)'
            />
          </div>
        </CxDialog>

      </GxForm>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxSlsDocFormPaym;
