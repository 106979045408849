// funciones para internet.
//import * as FxLog from './FxLog';

export const isDebug = () => window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "";
export const isOnline = () => typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;

export const getIP = async () => {
  return fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => data.ip)
    .catch(error => { 
      //FxLog.errorx("FxInet.getIP", error);
      return null;
    });
}
