// HELPER
import * as FxFetch from '../../fx/FxFetch';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';


export const load = (storePurLst, storePurDoc) => {
  let record = {};
  record['idValue'] = storePurDoc.inPurXXX;
  return Promise.all([FxFetch.doJsonX(`pur/doc/${storePurLst.listType}/r`, record)]).then((result) => {
    if (result[0]) {
      // cabecera
      storePurDoc.boDataChanged = '0';
      storePurDoc.docType = storePurLst.listType;
      storePurDoc.nuStatus = result[0].record[0].nuStatus;
      storePurDoc.inPurXXX = result[0].record[0].[storePurLst.table_idField];
      storePurDoc.txPurXXX = result[0].record[0].[storePurLst.table_txField];
      storePurDoc.dtPurXXX = result[0].record[0].[storePurLst.table_dtField];
      storePurDoc.itSeries = result[0].record[0].itSeries;
      storePurDoc.txTypeSeries = result[0].record[0].txTypeSeries;
      storePurDoc.inPurReceipt = result[0].record[0].inPurReceipt;
      storePurDoc.inSupplier = result[0].record[0].inSupplier;
      storePurDoc.txSupplier = result[0].record[0].txSupplier;
      storePurDoc.inBranch = result[0].record[0].inBranch;
      storePurDoc.txBranch = result[0].record[0].txBranch;

      storePurDoc.inEmployee = result[0].record[0].inEmployee;
      storePurDoc.txEmployee = result[0].record[0].txEmployee;
      storePurDoc.txContact = result[0].record[0].txContact;
      storePurDoc.txReference = result[0].record[0].txReference;
      storePurDoc.txNotes = result[0].record[0].txNotes;
      
      storePurDoc.nuTotalDiscount = result[0].record[0].nuTotalDiscount;
      storePurDoc.nuTotalSurcharge = result[0].record[0].nuTotalSurcharge;
      storePurDoc.nuTotalTaxable = result[0].record[0].nuTotalTaxable;
      storePurDoc.nuTotalTax = result[0].record[0].nuTotalTax;
      storePurDoc.nuTotalAmount = result[0].record[0].nuTotalAmount;
      storePurDoc.nuTotalPayment = result[0].record[0].nuTotalPayment;

      if (storePurLst.listType === 'o' || storePurLst.listType === 'i') {
        // items
        var items = storePurDoc.arItems || [];
        for (let i = 0; i < result[0].items.length; i++) {
          var item = {
            idx: i,
            inPurXxxItem: (result[0].items[i].inPurOrderItem || result[0].items[i].inPurInvoiceItem),
            inPs: result[0].items[i].inPs,
            txPs: result[0].items[i].txPs,
            nuPackages: result[0].items[i].nuPackages,
            nuConversionFactor: result[0].items[i].nuConversionFactor,
            txLotCode: result[0].items[i].txLotCode,
            dtLotExpr: result[0].items[i].dtLotExpr,

            nuQuantity: result[0].items[i].nuQuantity,
            nuPrice: result[0].items[i].nuPrice,
            nuTaxable: result[0].items[i].nuTaxable,
            nuTaxPerc: result[0].items[i].nuTaxPerc,
            nuTax: result[0].items[i].nuTax,
            nuDiscountPerc: result[0].items[i].nuDiscountPerc,
            nuDiscount: result[0].items[i].nuDiscount,
            nuSurchargePerc: result[0].items[i].nuSurchargePerc,
            nuSurcharge: result[0].items[i].nuSurcharge,
            nuAmount: result[0].items[i].nuAmount,

            txPsAlias: result[0].items[i].txPsAlias,
            txRemarks: result[0].items[i].txRemarks,
          };
          items.push(item);
        }
        storePurDoc.arItems = items;
      }

      if (storePurLst.listType === 'i') {
        // payms
        var payms = storePurDoc.arPayms || [];
        for (let i = 0; i < result[0].payms.length; i++) {
          var paym = {
            idx: i,
            inPurPayment: result[0].payms[i].inPurPayment,
            dtPurPayment: result[0].payms[i].dtPurPayment,
            itPayment: result[0].payms[i].itPayment,
            txTypePayment: result[0].payms[i].txTypePayment,
            nuAmount: result[0].payms[i].nuAmount,
          };
          payms.push(paym);
        }
        storePurDoc.arPayms = payms;
      }

    }
  });
}

