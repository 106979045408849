import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxCombo from '../../cx/CxCombo';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxButton from '../../cx/CxButton';
import CxToolbar from '../../cx/CxToolbar';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
//import * as FxDat from '../../fx/FxDat';
import { StoreMassBilling } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { StoreLongTask, clearStore } from "../../gx/GxStore";
import { useNav } from '../../hx/useNav';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
  button_frame: {
    margin: '20px 0 0 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
}));

const MxMassBillingOption = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const classes = useStyles(props);

  const rf_mode = React.useRef(null);
  const rf_date = React.useRef(null);
  const rf_txTypeSeries = React.useRef(null);
  
  const r_snack_error = React.useRef(null);
  
  const [disableDate, setDisableDate] = React.useState(true);
  
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);

  // eslint-disable-next-line
  const [storeMassBilling, setStoreMassBilling] = useSessionStorage('StoreMassBilling', StoreMassBilling);

  React.useEffect(() => {
    clearStore('StoreLongTask', storeLongTask);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const onSelectionMode = async (val) => {
    try {
      if (val.split('|')[0] === '0') {
        setDisableDate(true);
        rf_date.current.clear();
      } else {
        Promise.all([setDisableDate(false)]).then((result) => {
          rf_date.current.setFocus();
        });
      }

    } catch (error) {
      FxLog.errorx("MxMassBillingOption.onSelectionMode", error);
    }
  }

  const issueInvoices = (op) => {
    try {
      if (rf_mode.current.getKey() === '1' && rf_date.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ': ' + trans('field.date'));
        r_snack_error.current.setOpen(true);
        return;
      }

      storeLongTask._url = 'mass_billing/issue';
      storeLongTask._canCancel = true;
      storeLongTask._record = { 
        txTaskName: "mass_billing", 
        op: op, 
        dtReceipt: rf_date.current.getVal(),
        itSeries: rf_txTypeSeries.current.getKey(),
        arListedId: storeMassBilling.arListedId,
      };
      Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
        navTo('mass-billing-option-tsk');
      });

    } catch (error) {
      FxLog.errorx("MxMassBillingOption.issueInvoices", error);
    }
  }

  return (
    <div>
      <CxToolbar moduleName={`${trans('field.mass_billing')}: ${trans('field.options')}`} />

      <div className={classes.container}>

        <CxCombo xref={rf_mode}
          label={`${trans('field.mode')}`}
          list={[
            { id: '0', type: trans('field.use_x_date', 'field.invoice') },
            { id: '1', type: trans('field.use_x_date', 'field.selected_0') },
          ]}
          clearDisabled
          autoSelectFirst
          onSelection={onSelectionMode}
        />

        <CxDate xref={rf_date} label={trans('field.date')} disabled={disableDate} />

        <CxChooser xref={rf_txTypeSeries} label={trans('field.series')}
          table="t_series"
          col_id="itSeries"
          col_tx="txTypeSeries"
          extra_filter_1={"boActive='1'"}
        />

        <div className={classes.button_frame}>
          <CxButton label={trans('field.inv_issuing_88')} style={{ width: 'var(--ibs_control_width_lg)' }} onClick={() => issueInvoices('88')} />
          <CxButton label={trans('field.inv_issuing_81')} style={{ width: 'var(--ibs_control_width_lg)' }} onClick={() => issueInvoices('81')} />
        </div>


      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMassBillingOption;
