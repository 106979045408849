import React, { useState, useImperativeHandle } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { useStyles } from "./StylesCx";


const useStylesLocal = makeStyles({
  switch_label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 2px',
    //color: 'var(--ibs_color_gray)',
    "& .MuiTypography-body1": {
      fontSize: 'var(--ibs_control_label_font_size_radio)',
    },
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },
  switch_base: {
    color: 'var(--ibs_color_gray_light)',
    "&.Mui-disabled": {
      color: 'var(--ibs_color_gray_light)'
    },
    "&.Mui-checked": {
      color: 'var(--ibs_color_black)',
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: 'var(--ibs_color_black)',
    }
  },
});

const CxSwitch = props => {
  const classes = useStyles(props);
  const classesL = useStylesLocal(props);
  const [myValue, setMyValue] = useState('0');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = useState(false);
  
  const rf_label = React.useRef('');
  const [labelColor, setLabelColor] = useState('var(--ibs_color_gray)');

  React.useEffect(() => {
    setIsRequired(props.required);

    // eslint-disable-next-line
  }, []);

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getValBool, getIsRequired, getLabel, getDbfield, isChecked, wasChanged, setChanged, clear,
    };
  });
  const setVal = (newValue) => {
    setMyValue(newValue);
  }
  const getVal = () => { return myValue; };
  const getValBool = () => {
    return (myValue === '1' ? true : false);
  };

  const clear = () => { setMyValue('0'); };
  const getType = () => { return 'CxSwitch' };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const isChecked = () => { return (myValue === '1' ? true : false) }; //OJO: no siempre da resultado actualizado.
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const doClick = () => {
    if (!props.disabled) {
      let newValue = (myValue === '1' ? '0' : '1');
      setMyValue(newValue);
      setChanged(true);
      if (props.onChange) {
        props.onChange(newValue === "1" ? true : false);
      }
    }
  };

  const doFocus = (action) => {
    if (action==='focused') setLabelColor('var(--ibs_color_focused)');
    if (action==='unfocused') setLabelColor('var(--ibs_color_unfocused)');
  }

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <FormControlLabel ref={rf_label}
          classes={{ root: classesL.switch_label, }}
          style={{ color: labelColor }}
          control={<Switch
            disableTouchRipple
            classes={{ switchBase: classesL.switch_base, }}
            style={{ backgroundColor: 'transparent' }}
            disabled={props.disabled}
          />}
          label={props.labelIfChecked ? (myValue === '1' ? props.labelIfChecked : props.label) : props.label}
          labelPlacement="start"
          checked={myValue === '1' ? true : false}
          onClick={doClick}
          onFocus={() => doFocus('focused')}
          onBlur={() => doFocus('unfocused')}
        />
      </div>
    </div>
  );
};

export default CxSwitch;
