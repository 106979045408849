import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';
//import { useNav } from '../../hx/useNav';


const MxSettPsBillingCodeForm = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ navBack }] = useNav();
  const r_snack_required = React.useRef(null);
  const r_snack_op_in_progress = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  let r_form = React.useRef(null);

  const rf_txTypePsGroup = React.useRef(null);
  const rf_nuBillingCode = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doOp = () => {
    // chequea requeridos
    if (rf_txTypePsGroup.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypePsGroup.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_nuBillingCode.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuBillingCode.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let itPsGroup = rf_txTypePsGroup.current.getKey();
    let nuBillingCode = rf_nuBillingCode.current.getVal();

    let record = {};
    record['itPsGroup'] = itPsGroup;
    record['nuBillingCode'] = nuBillingCode;
    r_snack_op_in_progress.current.setOpen(true);
    Promise.all([FxFetch.doJsonX(`/sett/ps/billing_code/do`, record)]).then((result) => {
      rf_txTypePsGroup.current.clear();
      rf_nuBillingCode.current.clearToEmpty();
      r_snack_op_in_progress.current.setOpen(false);
      r_snack_op_successful.current.setOpen(true);
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.billing_code')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={doOp} tooltip={trans('field.execute')} />
          </div>
        }
      >

        <CxChooser xref={rf_txTypePsGroup}
          label={`${trans('field.group')}`}
          dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_ps.itPsGroup'
          table="t_ps_group"
          col_id="itPsGroup"
          col_tx="txTypePsGroup"
          filter={`t_ps_group.boActive='${'1'}'`}
          onClear={() => rf_nuBillingCode.current.clearToEmpty() }
          required
        />

        <CxNumber xref={rf_nuBillingCode} label={trans('field.billing_code')} decimals={0} required />

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_op_in_progress} severity="info" value={trans('msg.operation_in_progress')} autoHideDuration={10000} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
    </div>
  );

}

export default MxSettPsBillingCodeForm;
