import React, { useRef } from 'react';
import GxPrint from '../../gx/GxPrint';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxAccordion from '../../cx/CxAccordion';
import CxButton from '../../cx/CxButton';
import CxSearcher from '../../cx/CxSearcher';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxSnackbar from '../../cx/CxSnackbar';
import * as FxLog from '../../fx/FxLog';
import { StoreClient, StoreLabOrder } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import * as FxTable from '../../fx/FxTable';
import CxIconButton from '../../cx/CxIconButton';
import CxStatic from '../../cx/CxStatic';


const MxClientLabOrderForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navBack }] = useNav();

  let r_print = React.useRef(null);

  const r_dlg_alert = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeLabOrder, setStoreLabOrder] = useSessionStorage('StoreLabOrder', StoreLabOrder);

  const [isRecalc, setRecalc] = React.useState(false);

  let r_form = React.useRef(null);

  const rf_from = React.useRef(null);
  const rf_to = React.useRef(null);
  const rf_txLab = React.useRef(null);
  const rf_inRxGlas = React.useRef(null);

  const control_width_sm = 'var(--ibs_control_width_xs)';
  const control_width_lg = 'var(--ibs_control_width_lg)';


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_from.current.setValToday();
      rxGlasCleared();
    } else {
      calcSphN();
      calcSphI();
      calcOu();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeLabOrder.inLabOrder = lastSaveRv.theValue;
    Promise.all([setStoreLabOrder(storeLabOrder)]).then((result) => {
      navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  React.useEffect(() => {
    setRecalc(false);
    calcSphN();
    calcSphI();

  }, [isRecalc]);

  const doTranspose = () => {
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');
    let _nuOdCyl = r_form.current.getRef('n_rx_glas.nuOdCyl');
    let _nuOdAxis = r_form.current.getRef('n_rx_glas.nuOdAxis');

    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');
    let _nuOsCyl = r_form.current.getRef('n_rx_glas.nuOsCyl');
    let _nuOsAxis = r_form.current.getRef('n_rx_glas.nuOsAxis');

    //Od
    _nuOdSphD.setFloatVal(transpose('SPH', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdCyl.setFloatVal(transpose('CYL', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdAxis.setFloatVal(transpose('AXIS', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    //Os
    _nuOsSphD.setFloatVal(transpose('SPH', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsCyl.setFloatVal(transpose('CYL', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsAxis.setFloatVal(transpose('AXIS', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));


    //recálculos
    setRecalc(true);

  }

  const transpose = (tr, sph, cyl, axis) => {
    let rv = 0.0;
    try {
      let nuSph = (sph);
      let nuCyl = (cyl);
      let nuAxis = (axis);

      nuCyl = nuCyl * -1;
      nuSph = nuSph - nuCyl;
      if (nuAxis > 90) {
        nuAxis = nuAxis - 90;
      } else {
        nuAxis = nuAxis + 90;
      }
      switch (tr) {
        case 'SPH':
          rv = nuSph;
          break;
        case 'CYL':
          rv = nuCyl;
          break;
        case 'AXIS':
          rv = nuAxis;
          break;
        default:
          rv = '0.0';
          break;
      }

    } catch (error) {
      FxLog.errorx("MxClientLabOrderForm.transpose", error);
    }
    return rv;
  }

  const calcSphN = () => {
    let _nuOdAddNe = r_form.current.getRef('n_rx_glas.nuOdAddNe');
    let _nuOdSphN = r_form.current.getRef('_nuOdSphN');
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');

    let _nuOsAddNe = r_form.current.getRef('n_rx_glas.nuOsAddNe');
    let _nuOsSphN = r_form.current.getRef('_nuOsSphN');
    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');

    if (_nuOdAddNe.getFloatVal() === 0.0) {
      _nuOdSphN.clear();
    } else {
      _nuOdSphN.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddNe.getFloatVal());
    }
    if (_nuOsAddNe.getFloatVal() === 0.0) {
      _nuOsSphN.clear();
    } else {
      _nuOsSphN.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddNe.getFloatVal());
    }
  }

  const calcSphI = () => {
    let _nuOdAddIn = r_form.current.getRef('n_rx_glas.nuOdAddIn');
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');
    let _nuOdSphI = r_form.current.getRef('_nuOdSphI');

    let _nuOsAddIn = r_form.current.getRef('n_rx_glas.nuOsAddIn');
    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');
    let _nuOsSphI = r_form.current.getRef('_nuOsSphI');

    if (_nuOdAddIn.getFloatVal() === 0.0) {
      _nuOdSphI.clear();
    } else {
      _nuOdSphI.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddIn.getFloatVal());
    }
    if (_nuOsAddIn.getFloatVal() === 0.0) {
      _nuOsSphI.clear();
    } else {
      _nuOsSphI.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddIn.getFloatVal());
    }
  }

  const calcOu = () => {
    let _nuOuPdDi = r_form.current.getRef('_nuOuPdDi');
    let _nuOuPdNe = r_form.current.getRef('_nuOuPdNe');
    let _nuOdPdDi = r_form.current.getRef('n_rx_glas.nuOdPdDi');
    let _nuOsPdDi = r_form.current.getRef('n_rx_glas.nuOsPdDi');
    let _nuOdPdNe = r_form.current.getRef('n_rx_glas.nuOdPdNe');
    let _nuOsPdNe = r_form.current.getRef('n_rx_glas.nuOsPdNe');

    _nuOuPdDi.setFloatVal(_nuOdPdDi.getFloatVal() + _nuOsPdDi.getFloatVal());
    _nuOuPdNe.setFloatVal(_nuOdPdNe.getFloatVal() + _nuOsPdNe.getFloatVal());
  }

  const rxGlasCleared = () => {
    // 2023-01-05 - daq: limpia datos de graduación.
    r_form.current.getRef('n_rx_glas.nuOdSph').clear();
    r_form.current.getRef('n_rx_glas.nuOdCyl').clear();
    r_form.current.getRef('n_rx_glas.nuOdAxis').clear();
    r_form.current.getRef('n_rx_glas.nuOdAddNe').clear();
    r_form.current.getRef('_nuOdSphN').clear();
    r_form.current.getRef('n_rx_glas.nuOdAddIn').clear();
    r_form.current.getRef('_nuOdSphI').clear();

    r_form.current.getRef('n_rx_glas.nuOsSph').clear();
    r_form.current.getRef('n_rx_glas.nuOsCyl').clear();
    r_form.current.getRef('n_rx_glas.nuOsAxis').clear();
    r_form.current.getRef('n_rx_glas.nuOsAddNe').clear();
    r_form.current.getRef('_nuOsSphN').clear();
    r_form.current.getRef('n_rx_glas.nuOsAddIn').clear();
    r_form.current.getRef('_nuOsSphI').clear();

    r_form.current.getRef('n_rx_glas.txOdVaSc').clear();
    r_form.current.getRef('n_rx_glas.txOdVaCc').clear();
    r_form.current.getRef('n_rx_glas.nuOdPdDi').clear();
    r_form.current.getRef('n_rx_glas.nuOdPdNe').clear();
    r_form.current.getRef('n_rx_glas.nuOdIop').clear();
    r_form.current.getRef('n_rx_glas.txOdPrism').clear();

    r_form.current.getRef('n_rx_glas.txOsVaSc').clear();
    r_form.current.getRef('n_rx_glas.txOsVaCc').clear();
    r_form.current.getRef('n_rx_glas.nuOsPdDi').clear();
    r_form.current.getRef('n_rx_glas.nuOsPdNe').clear();
    r_form.current.getRef('n_rx_glas.nuOsIop').clear();
    r_form.current.getRef('n_rx_glas.txOsPrism').clear();

    r_form.current.getRef('n_rx_glas.txOuVa').clear();
    r_form.current.getRef('_nuOuPdDi').clear();
    r_form.current.getRef('_nuOuPdNe').clear();
  }

  const rxGlasSelected = (row) => {
    // 2023-01-05 - daq: completa datos de graduación.
    r_form.current.getRef('n_rx_glas.nuOdSph').setFloatVal(row.original.nuOdSph);
    r_form.current.getRef('n_rx_glas.nuOdCyl').setFloatVal(row.original.nuOdCyl);
    r_form.current.getRef('n_rx_glas.nuOdAxis').setFloatVal(row.original.nuOdAxis);
    r_form.current.getRef('n_rx_glas.nuOdAddNe').setFloatVal(row.original.nuOdAddNe);
    //r_form.current.getRef('_nuOdSphN').setFloatVal(row.original._nuOdSphN);
    r_form.current.getRef('n_rx_glas.nuOdAddIn').setFloatVal(row.original.nuOdAddIn);
    //r_form.current.getRef('_nuOdSphI').setFloatVal(row.original._nuOdSphI);

    r_form.current.getRef('n_rx_glas.nuOsSph').setFloatVal(row.original.nuOsSph);
    r_form.current.getRef('n_rx_glas.nuOsCyl').setFloatVal(row.original.nuOsCyl);
    r_form.current.getRef('n_rx_glas.nuOsAxis').setFloatVal(row.original.nuOsAxis);
    r_form.current.getRef('n_rx_glas.nuOsAddNe').setFloatVal(row.original.nuOsAddNe);
    //r_form.current.getRef('_nuOsSphN').setFloatVal(row.original._nuOsSphN);
    r_form.current.getRef('n_rx_glas.nuOsAddIn').setFloatVal(row.original.nuOsAddIn);
    //r_form.current.getRef('_nuOsSphI').setFloatVal(row.original._nuOsSphI);

    r_form.current.getRef('n_rx_glas.txOdVaSc').setVal(row.original.txOdVaSc);
    r_form.current.getRef('n_rx_glas.txOdVaCc').setVal(row.original.txOdVaCc);
    r_form.current.getRef('n_rx_glas.nuOdPdDi').setFloatVal(row.original.nuOdPdDi);
    r_form.current.getRef('n_rx_glas.nuOdPdNe').setFloatVal(row.original.nuOdPdNe);
    r_form.current.getRef('n_rx_glas.nuOdIop').setFloatVal(row.original.nuOdIop);
    r_form.current.getRef('n_rx_glas.txOdPrism').setVal(row.original.txOdPrism);

    r_form.current.getRef('n_rx_glas.txOsVaSc').setVal(row.original.txOsVaSc);
    r_form.current.getRef('n_rx_glas.txOsVaCc').setVal(row.original.txOsVaCc);
    r_form.current.getRef('n_rx_glas.nuOsPdDi').setFloatVal(row.original.nuOsPdDi);
    r_form.current.getRef('n_rx_glas.nuOsPdNe').setFloatVal(row.original.nuOsPdNe);
    r_form.current.getRef('n_rx_glas.nuOsIop').setFloatVal(row.original.nuOsIop);
    r_form.current.getRef('n_rx_glas.txOsPrism').setVal(row.original.txOsPrism);

    r_form.current.getRef('n_rx_glas.txOuVa').setVal(row.original.txOuVa);
    //r_form.current.getRef('_nuOuPdDi').setFloatVal(row.original._nuOuPdDi);
    //r_form.current.getRef('_nuOuPdNe').setFloatVal(row.original._nuOuPdNe);

    setRecalc(false);
    calcSphN();
    calcSphI();
    calcOu();
  }

  const doPrintX = async (option) => {
    let inLab = rf_txLab.current.getKey();
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inLabOrder'] = `${storeLabOrder.inLabOrder}`;
    filter['inRxGlas'] = `${storeLabOrder.inRxGlas}`;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('lab_order/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInLab(inLab)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.client')}: ${trans('field.lab_order')}`}
        xref={r_form}
        url="lab_order"
        filterIdField='inLabOrder'
        filterIdValue={storeLabOrder.inLabOrder}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeLabOrder.inLabOrder
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inLabOrder" value={storeLabOrder.inLabOrder || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inRxGlas" value={storeLabOrder.inRxGlas || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_from} label={trans('field.from')} dbfield="n_lab_order.dtFrom" required />

        <CxSearcher xref={rf_txLab} label={trans('field.lab')}
          dbfield="n_lab.txLab" dbfieldkey={`n_lab_order.inLab`}
          url="labs"
          col_id="inLab"
          col_tx="txLab"
          columns={[
            { id: "0", Header: "ID", accessor: "inLab", show: false },
            { id: "1", Header: trans('field.lab'), accessor: row => rf_txLab.current.getTable().highlightText(row["txLab"]), width: '100%' },
          ]}
          required
        />

        <CxSearcher xref={rf_inRxGlas} label={trans('field.rx_glas')}
          dbfield="txReasonRxGlas" dbfieldkey={`n_lab_order.inRxGlas`}
          url="lab_order/rx_glas"
          tooltip={`${trans('field.reason')}`}
          filter={`inClient='${storeClient.inClient}'`}
          col_id="inRxGlas"
          col_tx="txReason"
          onClear={rxGlasCleared}
          onSelection={rxGlasSelected}
          columns={[
            { id: "0", Header: "ID", accessor: "inRxGlas", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
            { id: "2", Header: trans('field.reason'), accessor: row => rf_inRxGlas.current.getTable().highlightText(row["txReason"]), width: '75%' },
          ]}
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.status')}
          dbfield="t_status_lab_order.txTypeStatusLabOrder" dbfieldkey={`n_lab_order.itStatusLabOrder`}
          table="t_status_lab_order"
          col_id="itStatusLabOrder"
          col_tx="txTypeStatusLabOrder"
          required
        />

        <CxDate xref={rf_to} label={trans('field.to')} dbfield="n_lab_order.dtTo" />

        <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_lab_order.txReason" maxLength={100} />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_lab_order.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={useRef(null)} label={trans('field.lab_tech')} dbfield="n_lab_tech.txLabTech" dbfieldkey={`n_lab_order.inLabTech`}
          id="n_lab_tech"
          table="n_lab_tech"
          col_id="inLabTech"
          col_tx="txLabTech"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_lab_order.txReference" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.special_instruction')} dbfield="n_lab_order.txSpecialInstruction" maxLength={100} />

        <br />

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_dist')} dbfield='n_rx_glas.nuOdSph' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_glas.nuOdCyl' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_glas.nuOdAxis' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_near')} dbfield='n_rx_glas.nuOdAddNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_near')} dbfield='_nuOdSphN' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_inter')} dbfield='n_rx_glas.nuOdAddIn' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_inter')} dbfield='_nuOdSphI' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_dist')} dbfield='n_rx_glas.nuOsSph' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_glas.nuOsCyl' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_glas.nuOsAxis' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_near')} dbfield='n_rx_glas.nuOsAddNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_near')} dbfield='_nuOsSphN' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_inter')} dbfield='n_rx_glas.nuOsAddIn' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_inter')} dbfield='_nuOsSphI' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxButton label={trans('field.xx_transpose')} onClick={doTranspose} style={{ width: control_width_lg }} autoselect="true" />
        <br /><br /><br />

        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_vasc')} maxLength={10} dbfield='n_rx_glas.txOdVaSc' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_vacc')} maxLength={10} dbfield='n_rx_glas.txOdVaCc' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='n_rx_glas.nuOdPdDi' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='n_rx_glas.nuOdPdNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_iop')} dbfield='n_rx_glas.nuOdIop' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_prism')} maxLength={16} dbfield='n_rx_glas.txOdPrism' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_vasc')} maxLength={10} dbfield='n_rx_glas.txOsVaSc' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_vacc')} maxLength={10} dbfield='n_rx_glas.txOsVaCc' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='n_rx_glas.nuOsPdDi' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='n_rx_glas.nuOsPdNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_iop')} dbfield='n_rx_glas.nuOsIop' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_prism')} maxLength={16} dbfield='n_rx_glas.txOsPrism' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_both')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_va')} maxLength={10} dbfield='n_rx_glas.txOuVa' disabled hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='_nuOuPdDi' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='_nuOuPdNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_lab_order.txNotes" minRows={4} speech />

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

    </div>
  );

}

export default MxClientLabOrderForm;
