import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettCalendarCol } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettCalendarColList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettCalendarCol, setStoreSettCalendarCol] = useSessionStorage('StoreSettCalendarCol', StoreSettCalendarCol);

  React.useEffect(() => {
    clearStore('StoreSettCalendarCol', storeSettCalendarCol);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCalendarCol.itCalendarCol = null;
    } else {
      storeSettCalendarCol.itCalendarCol = row.original.itCalendarCol;
    }
    setStoreSettCalendarCol(storeSettCalendarCol);
    navTo("sett-calendar-col-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.calendars')}: ${trans('field.columns')}`}
        xref={r_list}
        tooltip={`${trans('field.column')}`}
        fullscreen
        url="sett/cal_col"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itCalendarCol", show: false },
          { id: "1", Header: trans('field.name'), accessor: row => r_list.current.getTable().highlightText(row["txTypeCalendarCol"]), width: '70%' },
          { id: "2", Header: trans('field.sequence'), accessor: "nuSequence", width: '30%', align: 'right' },
        ]}
      />
    </div>
  );
}

export default MxSettCalendarColList;
