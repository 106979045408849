import React from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
import CxNumber from '../../cx/CxNumber';
import CxHidden from '../../cx/CxHidden';
import CxChooser from '../../cx/CxChooser';
import CxDate from '../../cx/CxDate';
import * as FxFetch from "../../fx/FxFetch";
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxStr from '../../fx/FxStr';
import MxPsOTF from '../common/otf/MxPsOTF';
import '../../../styles/main.css';
import { StoreRxAuto, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import CxDlgAlert from '../../cx/CxDlgAlert';
import { useWindowSize } from '../../hx/useWindowSize';
import { useAccess } from '../../hx/useAccess';
import CxSnackbar from '../../cx/CxSnackbar';
import CxCombo from '../../cx/CxCombo';


const MxAnimalRxAutoFormItem = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.rxs'));
  const [{ navBack }] = useNav();
  const size = useWindowSize();

  let r_form = React.useRef(null);
  let r_print = React.useRef(null);
  let r_dlg_new_ps = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  const rf_inRxAuto = React.useRef(null);
  const rf_txPs = React.useRef(null);
  const rf_nuQuantity = React.useRef(null);
  const rf_txLotCode = React.useRef(null);
  const rf_dtLotExpr = React.useRef(null);
  const rf_txRegime = React.useRef(null);
  const rf_txTypeDispensing = React.useRef(null);
  const rf_nuDrugPerc = React.useRef(null);
  const rf_txTypeTreatment = React.useRef(null);
  const rf_txDurationTreatment = React.useRef(null);
  const rf_txDirections = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);

  let r_idx = React.useRef(storeRxAuto.idx);

  React.useEffect(() => {
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    if (storeRxAuto.idx === null) { //nuevo registro
      rf_txPs.current.setDlg(true); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de producto-servicio */

    } else { // registro existente
      rf_inRxAuto.current.setVal(storeRxAuto.arItems[r_idx.current].inRxAuto);

      rf_txPs.current.setKey(storeRxAuto.arItems[r_idx.current].inPs);
      rf_txPs.current.setVal(storeRxAuto.arItems[r_idx.current].txPs);

      rf_nuQuantity.current.setVal(storeRxAuto.arItems[r_idx.current].nuQuantity);
      rf_txLotCode.current.setVal(storeRxAuto.arItems[r_idx.current].txLotCode);
      rf_dtLotExpr.current.setVal(storeRxAuto.arItems[r_idx.current].dtLotExpr);
      
      /* VISIBLE EN FORM RX */
      rf_txRegime.current.setKey(storeRxAuto.arItems[r_idx.current].itRxAuto);
      rf_txRegime.current.setVal(storeRxAuto.arItems[r_idx.current].txRegime);
      rf_txRegime.current.setFilter(`t_rx_auto.inPs='${storeRxAuto.arItems[r_idx.current].inPs}'`);

      rf_txTypeDispensing.current.setKeyAndVal(
        storeRxAuto.arItems[r_idx.current].txTypeDispensing,
        FxStr.choose(storeRxAuto.arItems[r_idx.current].txTypeDispensing, 'D|N|K',
          trans('field.rx_dispensing_dispensation'),
          trans('field.rx_dispensing_dispensation_no'),
          trans('field.rx_dispensing_kit')
        ));

      rf_nuDrugPerc.current.setVal(storeRxAuto.arItems[r_idx.current].nuDrugPerc);

      rf_txTypeTreatment.current.setKeyAndVal(
        storeRxAuto.arItems[r_idx.current].txTypeTreatment,
        FxStr.choose(storeRxAuto.arItems[r_idx.current].txTypeTreatment, 'T|M|P',
          trans('field.rx_treatment_therapeutic'),
          trans('field.rx_treatment_metaphylactic'),
          trans('field.rx_treatment_prophylactic')
        ));

      rf_txDurationTreatment.current.setVal(storeRxAuto.arItems[r_idx.current].txDurationTreatment);

      rf_txDirections.current.setVal(storeRxAuto.arItems[r_idx.current].txDirections);
    }
  }
  const formDeleteCustom = () => {
    FxLog.infox("MxAnimalRxAutoFormItem.formDeleteCustom", "..." + r_idx.current);
    if (storeRxAuto.idx !== null) {
      if (storeRxAuto.arItems[r_idx.current].inRxAuto) {
        storeRxAuto.txItemsDeleted = storeRxAuto.arItems[r_idx.current].inRxAuto + ' ' + storeRxAuto.txItemsDeleted;
      }
      storeRxAuto.arItems.splice(r_idx.current, 1); // 2nd parameter means remove one item only
      // reorder
      storeRxAuto.arItems.forEach(function (item, index) {
        item.idx = index;
      })

      // asienta que los datos fueron cambiados
      storeRxAuto.boDataChanged = '1';

      Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
        navBack();
      });
    }
  }

  const formLoadSelected = async (inPs) => {
    try {
      let record = {};
      record['idValue'] = inPs;
      Promise.all([FxFetch.doJsonX(`rx_auto/r/ps/load_item_selected`, record)]).then((result) => {
        if (result[0]) {
          // cargar controles
          rf_nuQuantity.current.setVal(result[0][0].nuQuantity);
        }
      });

    } catch (error) {
      FxLog.errorx("MxAnimalRxAutoFormItem.formLoadSelected", error);
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let inPs = rf_txPs.current.getKey();
    Promise.all([saveItemToStore(inPs)]).then((result) => {
      navBack();
    });

  }

  const saveItemToStore = (inPs) => {
    try {
      let items = storeRxAuto.arItems || [];
      /*console.log("# of items: " + items.length);
      items.forEach(function(item, index) {
        console.log("[" + index + "]: " + item.txPs);
      });*/

      let _inPs = rf_txPs.current.getKey();
      let _txPs = rf_txPs.current.getVal();
      let _nuQuantity = rf_nuQuantity.current.getFloatVal();
      let _txLotCode = rf_txLotCode.current.getVal();
      let _dtLotExpr = rf_dtLotExpr.current.getVal();
      let _itRxAuto = rf_txRegime.current.getKey();
      let _txRegime = rf_txRegime.current.getVal();
      let _txTypeDispensing = rf_txTypeDispensing.current.getKey();
      let _nuDrugPerc = rf_nuDrugPerc.current.getVal();
      let _txTypeTreatment = rf_txTypeTreatment.current.getKey();
      let _txDurationTreatment = rf_txDurationTreatment.current.getVal();
      let _txDirections = rf_txDirections.current.getVal();
  
      if (storeRxAuto.idx === null) {
        // Adding
        let item = {
          idx: 0,
          inRxAuto: FxStr.getRandomID(), // 2024-03-08 - daq: crea un identificador al azar.
          inPs: _inPs,
          txPs: _txPs,
          nuQuantity: _nuQuantity,
          txLotCode: _txLotCode,
          dtLotExpr: _dtLotExpr,
          itRxAuto: _itRxAuto,
          txRegime: _txRegime,
          txTypeDispensing: _txTypeDispensing,
          nuDrugPerc: _nuDrugPerc,
          txTypeTreatment: _txTypeTreatment,
          txDurationTreatment: _txDurationTreatment,
          txDirections: _txDirections,
        };
        items.unshift(item);
        // reorder
        items.forEach(function (item, index) {
          item.idx = index;
        })
      } else {
        // Modifying
        storeRxAuto.arItems[r_idx.current].idx = storeRxAuto.idx;
        storeRxAuto.arItems[r_idx.current].inPs = _inPs;
        storeRxAuto.arItems[r_idx.current].txPs = _txPs;
        storeRxAuto.arItems[r_idx.current].nuQuantity = _nuQuantity;
        storeRxAuto.arItems[r_idx.current].txLotCode = _txLotCode;
        storeRxAuto.arItems[r_idx.current].dtLotExpr = _dtLotExpr;
        storeRxAuto.arItems[r_idx.current].itRxAuto = _itRxAuto;
        storeRxAuto.arItems[r_idx.current].txRegime = _txRegime;
        storeRxAuto.arItems[r_idx.current].txTypeDispensing = _txTypeDispensing;
        storeRxAuto.arItems[r_idx.current].nuDrugPerc = _nuDrugPerc;
        storeRxAuto.arItems[r_idx.current].txTypeTreatment = _txTypeTreatment;
        storeRxAuto.arItems[r_idx.current].txDurationTreatment = _txDurationTreatment;
        storeRxAuto.arItems[r_idx.current].txDirections = _txDirections;
      }

      //console.log("Added item: " + item.txPs);
      storeRxAuto.arItems = items;

      // asienta que los datos fueron cambiados
      storeRxAuto.boDataChanged = '1';

      // Saving
      setStoreRxAuto(storeRxAuto);

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoFormItem.saveItemToStore', error)
    }

  }

  const lotChanged = async (row) => {
    if (row) {
      rf_dtLotExpr.current.setVal(row.original.dtLotExpr);
    }
  }

  const psClear = () => {
    rf_nuQuantity.current.setVal('');
    rf_txLotCode.current.setVal('');
    rf_txLotCode.current.setFilter(`false`);
    rf_dtLotExpr.current.setVal('');
    rf_txRegime.current.setVal('');
    rf_txRegime.current.setFilter(`false`);
    rf_txTypeDispensing.current.setKeyAndVal('D', trans('field.rx_dispensing_dispensation'));
    rf_nuDrugPerc.current.setVal('100.0');
    rf_txTypeTreatment.current.setKeyAndVal('T', trans('field.rx_treatment_therapeutic'));
    rf_txDurationTreatment.current.setVal('');
    rf_txDirections.current.setVal('');
  }

  const psChanged = async (row) => {
    if (row !== null) {
      psClear();
      let inPs = row.original.inPs;
      formLoadSelected(inPs);
      // lote
      rf_txLotCode.current.setFilter(`n_pur_invoice_item.inPs='${inPs}'`);
      // régimen
      rf_txRegime.current.setFilter(`t_rx_auto.inPs='${inPs}'`);
    }
  }

  const selectedPsOTF = (inPs, txPs) => {
    try {
      rf_txPs.current.setKey(inPs);
      rf_txPs.current.setVal(txPs);
      formLoadSelected(inPs);

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoFormItem.selectedPsOTF', error)
    }
  }

  const onSelectionRegime = (row) => {
    FxLog.infox('MxAnimalRxAutoFormItem.onSelectionRegime', '...row.original.itRxAuto:', row.original.itRxAuto);
    let record = {};
    record['idValue'] = row.original.itRxAuto;
    Promise.all([FxFetch.doJsonX(`pss/rx_auto/r`, record)]).then((result) => {
      if (result[0]) {
        // cargar controles
        rf_txTypeDispensing.current.setKeyAndVal(
          result[0][0].txTypeDispensing,
          FxStr.choose(result[0][0].txTypeDispensing, 'D|N|K',
            trans('field.rx_dispensing_dispensation'),
            trans('field.rx_dispensing_dispensation_no'),
            trans('field.rx_dispensing_kit')
          ));
    
        rf_nuDrugPerc.current.setVal(result[0][0].nuDrugPerc);
    
        rf_txTypeTreatment.current.setKeyAndVal(
          result[0][0].txTypeTreatment,
          FxStr.choose(result[0][0].txTypeTreatment, 'T|M|P',
            trans('field.rx_treatment_therapeutic'),
            trans('field.rx_treatment_metaphylactic'),
            trans('field.rx_treatment_prophylactic')
          ));
      }
    });


  }

  const doPrintXLabel = async (option) => {
    if (!rf_inRxAuto.current.getVal()) {
      r_snack_ask_save.current.setOpen(true);
      return;
    }
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inRxAuto'] = `${rf_inRxAuto.current.getVal()}`;
    filter['docSize'] = 'LB';
    let record = {};
    r_print.current.setScale(2);
    r_print.current.setUrl('rx_auto/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.rxs')}: ${trans('field.item')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeRxAuto.idx !== null && storeRxAuto.inRxAuto
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintXLabel()} tooltip={`${trans('field.print')} ${trans('field.label')}`} />}

            {/* botón borrar */}
            {storeRxAuto.idx !== null && isFullAccess()
              && <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}

            {/* botón guardar */}
            {isFullAccess()
              && <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}
          </div>
        }
      >

        <CxHidden xref={rf_inRxAuto} />

        <CxSearcher xref={rf_txPs} id='MxAnimalRxAutoFormItem_txPs'
          label={trans('field.rx_drug_name')}
          readOnly={storeRxAuto.rf_inRxAuto}
          onSelection={psChanged}
          onClear={psClear}
          onButtonAddOTF={() => {
            rf_txPs.current.setDlg(false);
            r_dlg_new_ps.current.setDlg(true);
          }}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          filter={'n_ps.nuNature = 0'}
          tooltip={`${trans('field.ps')} / ${trans('field.code')}`}
          colorRefs={['table_cell_brown|' + trans('field.composite'), 'table_cell_green|' + trans('field.frequency')]}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '50%' },
            { id: "2", Header: trans('field.code'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPsCode"]), width: '25%', align: 'right', show: size.isLarge },
            { id: "3", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["theTotalCost"]), width: '25%', align: 'right' },
            { id: "W", Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false },
            { id: "G", Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false },
          ]}
          required
        />

        <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} sumButton decimals={4} decimalsfixed="true" required />

        <CxSearcher xref={rf_txLotCode} id='MxAnimalRxAutoFormItem_txLotCode'
          label={trans('field.rx_lot_code')}
          onSelection={lotChanged}
          onClear={lotChanged}
          url="rx_auto/lots"
          col_id="inPurInvoiceItem"
          col_tx="txLotCode"
          tooltip={`${trans('field.lot')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inPurInvoiceItem", show: false },
            { id: "1", Header: trans('field.lot'), accessor: row => rf_txLotCode.current.getTable().highlightText(row["txLotCode"]), width: '50%' },
            { id: "2", Header: trans('field.expiration'), accessor: row => FxTable.cellToLocalDate(row["dtLotExpr"]), width: '25%' },
            { id: "3", Header: trans('field.quantity'), accessor: row => FxTable.cellTo2r00(row["nuQuantity"]), width: '25%', align: 'right' },
          ]}
        />
        <CxDate xref={rf_dtLotExpr} label={trans('field.rx_expiration')} />

        {/* --------------------------------------------------------------------------------------- */}
        {/* VISIBLE EN FORM RX */}
        <CxChooser xref={rf_txRegime} 
          label={trans('field.rx_regime')}
          table="t_rx_auto"
          col_id="itRxAuto"
          col_tx="txRegime"
          filter={`false`}
          onSelection={onSelectionRegime}
          />

        {/* 1-dispensación (almacenado en farmacia), 2-no-dispensación, 3-botiquín (almacenado en explotación) */}
        {/* VISIBLE EN FORM RX */}
        <CxCombo xref={rf_txTypeDispensing}
          label={`${trans('field.rx_type_dispensing')}`}
          list={[
            { id: 'D', type: trans('field.rx_dispensing_dispensation') },
            { id: 'N', type: trans('field.rx_dispensing_dispensation_no') },
            { id: 'K', type: trans('field.rx_dispensing_kit') },
          ]}
          defaultValue='D'
          clearDisabled
        />
        {/* VISIBLE EN FORM RX */}
        <CxNumber xref={rf_nuDrugPerc} label={trans('field.rx_drug_perc')} decimals={2} decimalsfixed="false" defaultValue={100} />

        {/* T-terapéutico, M-metafiláctico, P-profiláctico */}
        {/* VISIBLE EN FORM RX */}
        <CxCombo xref={rf_txTypeTreatment}
          label={`${trans('field.rx_type_treatment')}`}
          list={[
            { id: 'T', type: trans('field.rx_treatment_therapeutic') },
            { id: 'M', type: trans('field.rx_treatment_metaphylactic') },
            { id: 'P', type: trans('field.rx_treatment_prophylactic') },
          ]}
          defaultValue='T'
          clearDisabled
        />
        {/* VISIBLE EN FORM RX */}
        <CxInput xref={rf_txDurationTreatment} label={trans('field.rx_duration_treatment')} maxLength='70' />

        {/* VISIBLE EN FORM RX */}
        <CxInput xref={rf_txDirections} label={trans('field.rx_directions')} maxLength='500' minRows={4} />


        {/* --------------------------------------------------------------------------------------- */}

        <MxPsOTF xref={r_dlg_new_ps} onOk={selectedPsOTF} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_x_before', 'field.doc')} />
      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

    </div>
  );

}

export default MxAnimalRxAutoFormItem;
