import React, { useImperativeHandle } from "react";
import CxDialog from "./CxDialog";
import '../../styles/main.css';
import { useTranslation } from '../hx/useTranslation';


const CxDlgUpgrade = props => {
  const [{ trans }] = useTranslation();

  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setOpen
    };
  });
  const getType = () => { return 'CxDlgUpgrade' };

  return (
    <div>
      <CxDialog
        title={trans('field.information')}
        buttonCancelText={trans('field.ok')}
        open={open}
        onCancel={() => setOpen(false)}
        height='200px'>
        <div style={{ width: '250px', }}>{trans('msg.ask_upgrade_s')}</div>
      </CxDialog>
    </div>
  );
};

export default CxDlgUpgrade;
