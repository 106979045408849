import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxSnackbar from '../../../cx/CxSnackbar';
import '../../../../styles/main.css';


const MxClientOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const rf_txClient = React.useRef(null);
  const rf_txIc = React.useRef(null);
  const rf_txAddress = React.useRef(null);
  const rf_txCity = React.useRef(null);
  const rf_txState = React.useRef(null);
  const rf_txPostalCode = React.useRef(null);
  const rf_txMobile = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txClient.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txClient.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txClient = rf_txClient.current.getVal();
    let txIc = rf_txIc.current.getVal();
    let txAddress = rf_txAddress.current.getVal();
    let txCity = rf_txCity.current.getVal();
    let txState = rf_txState.current.getVal();
    let txPostalCode = rf_txPostalCode.current.getVal();
    let txMobile = rf_txMobile.current.getVal();
  
    setDisabled(true);

    let record = {};
    record['txClient'] = txClient;
    record['txIc'] = txIc;
    record['txAddress'] = txAddress;
    record['txCity'] = txCity;
    record['txState'] = txState;
    record['txPostalCode'] = txPostalCode;
    record['txMobile'] = txMobile;

    Promise.all([FxFetch.doJsonX("clients/otf", record)]).then((result) => {
      FxLog.infox("MxClientOTF.formSave", "...result[0]:", result[0]);
      if (result[0]) {
        if (result[0].theValue === null) {
          FxLog.infox("MxClientOTF.formSave", "El cliente ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists','field.client'));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxClientOTF.formSave", "El cliente ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let inClient = result[0].theValue;
          if (props.onOk) props.onOk(inClient, txClient);
        }
      }
    });

  }

  return (
    <div>
      <CxDialog title={trans('field.create_x','field.client')} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

          <CxInput xref={rf_txClient} label={trans('field.client')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={100} required />
          <CxInput xref={rf_txIc} label={trans('field.ic')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={15} />
          <CxInput xref={rf_txAddress} label={trans('field.address')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={100} />
          <CxInput xref={rf_txCity} label={trans('field.city')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={50} />
          <CxInput xref={rf_txState} label={trans('field.state')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={50} />
          <CxInput xref={rf_txPostalCode} label={trans('field.postal_code')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={10} />
          <CxInput xref={rf_txMobile} label={trans('field.mobile')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={50} />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />


    </div>
  );

}

export default MxClientOTF;
