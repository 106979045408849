// funciones complementarias de GxPrint.
// eslint-disable-next-line no-unused-vars
import * as FxLog from './FxLog';
import * as FxFetch from './FxFetch';

export const getClientInfo = async (inClient) => {
  if (inClient) {
    let record = {};
    record['idValue'] = inClient;
    return Promise.all([FxFetch.doJsonF("clients/r", null, record)]).then((result) => {
      if (result[0]) {
        let r = result[0][0];
        return {
          addressee_xxType: 'client',
          addressee_xxId: r.inClient,
          addressee_txName: r.txClient,
          addressee_txEmail: r.txEmail,
          addressee_txMobile: r.txMobile,
          addressee_txCity: r.txCity,
          addressee_txState: r.txState,
          addressee_txCountry: r.txCountry,
        };
      }
    });
  } else {
    return null;
  }
};

export const getSupplierInfo = async (inSupplier) => {
  if (inSupplier) {
    let record = {};
    record['idValue'] = inSupplier;
    return Promise.all([FxFetch.doJsonF("suppliers/r", null, record)]).then((result) => {
      if (result[0]) {
        let r = result[0][0];
        return {
          addressee_xxType: 'supplier',
          addressee_xxId: r.inSupplier,
          addressee_txName: r.txSupplier,
          addressee_txEmail: r.txEmail,
          addressee_txMobile: r.txMobile,
          addressee_txCity: r.txCity,
          addressee_txState: r.txState,
          addressee_txCountry: r.txCountry,
        };
      }
    });
  } else {
    return null;
  }
};

export const getLabInfo = async (inLab) => {
  if (inLab) {
    let record = {};
    record['idValue'] = inLab;
    return Promise.all([FxFetch.doJsonF("labs/r", null, record)]).then((result) => {
      if (result[0]) {
        let r = result[0][0];
        return {
          addressee_xxType: 'lab',
          addressee_xxId: r.inLab,
          addressee_txName: r.txLab,
          addressee_txEmail: r.txEmail,
          addressee_txMobile: r.txMobile,
          addressee_txCity: r.txCity,
          addressee_txState: r.txState,
          addressee_txCountry: r.txCountry,
        };
      }
    });
  } else {
    return null;
  }
};
