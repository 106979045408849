import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettImage } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettImageList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettImage, setStoreSettImage] = useSessionStorage('StoreSettImage', StoreSettImage);

  React.useEffect(() => {
    clearStore('StoreSettImage', storeSettImage);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettImage.itImage = null;
    } else {
      storeSettImage.itImage = row.original.itImage;
    }
    setStoreSettImage(storeSettImage);
    navTo("sett-client-image-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.images')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/image"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itImage", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.image')}`, accessor: "txTypeImage", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettImageList;
