import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import CxDialog from '../../cx/CxDialog';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import { useTranslation } from '../../hx/useTranslation';


const MxSlsDocFormClientDataDlg = props => {
  const [{ trans }] = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [inReceipt, setInReceipt] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const r_snack_op_successful = React.useRef(null);

  // para cambiar datos del cliente en factura
  const rf_clientdata_txClient = React.useRef(null);
  const rf_clientdata_txMobile = React.useRef(null);
  const rf_clientdata_txIc = React.useRef(null);
  const rf_clientdata_txAddress = React.useRef(null);
  const rf_clientdata_txCity = React.useRef(null);
  const rf_clientdata_txState = React.useRef(null);
  const rf_clientdata_txPostalCode = React.useRef(null);


  useImperativeHandle(props.xref, () => {
    return {
      getType, openDlg,
    };
  });
  const getType = () => { return 'MxSlsDocFormClientDataDlg' };

  const openDlg = (inReceipt) => {
    setInReceipt(inReceipt)
    
    let record = {};
    record['idValue'] = inReceipt;
    Promise.all([FxFetch.doJsonX('sls/doc/r/r', record)]).then((result) => {
      if (result[0]) {
        let rec = result[0].record[0];
        rf_clientdata_txClient.current.setVal(rec.txClient);
        rf_clientdata_txMobile.current.setVal(rec.txMobile);
        rf_clientdata_txIc.current.setVal(rec.txIc);
        rf_clientdata_txAddress.current.setVal(rec.txAddress);
        rf_clientdata_txCity.current.setVal(rec.txCity);
        rf_clientdata_txState.current.setVal(rec.txState);
        rf_clientdata_txPostalCode.current.setVal(rec.txPostalCode);
      }
    });

    setOpen(true);
  }

  const doSave = () => {
    let record = {};
    record['inReceipt'] = inReceipt;
    record['txClient'] = rf_clientdata_txClient.current.getVal();
    record['txMobile'] = rf_clientdata_txMobile.current.getVal();
    record['txIc'] = rf_clientdata_txIc.current.getVal();
    record['txAddress'] = rf_clientdata_txAddress.current.getVal();
    record['txCity'] = rf_clientdata_txCity.current.getVal();
    record['txState'] = rf_clientdata_txState.current.getVal();
    record['txPostalCode'] = rf_clientdata_txPostalCode.current.getVal();
    Promise.all([FxFetch.doJsonX(`/sls/doc/change_client_data_receipt`, record)]).then((result) => {
    });
    setDisabled(true);
    if (props.onOk) {
      props.onOk(rf_clientdata_txClient.current.getVal());
    }
    r_snack_op_successful.current.setOpen(true);
  }

  return (
    <div>

      <CxDialog
        title={trans('msg.change_client_data_on_receipt')}
        open={open}
        onClose={() => setOpen(false)}
        onOk={() => doSave()}
        height='500px'
        disabled={disabled}
        >

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxInput xref={rf_clientdata_txClient} label={trans('field.client')} maxLength={100} disabled={disabled} />
          <CxInput xref={rf_clientdata_txMobile} label={trans('field.mobile')} maxLength={20} disabled={disabled} />
          <CxInput xref={rf_clientdata_txIc} label={trans('field.ic')} maxLength={15} disabled={disabled} />
          <CxInput xref={rf_clientdata_txAddress} label={trans('field.address')} disabled={disabled} maxLength={100} />
          <CxInput xref={rf_clientdata_txCity} label={trans('field.city')} maxLength={50} disabled={disabled} />
          <CxInput xref={rf_clientdata_txState} label={trans('field.state')} maxLength={50} disabled={disabled} />
          <CxInput xref={rf_clientdata_txPostalCode} label={trans('field.postal_code')} maxLength={10} disabled={disabled} />
        </div>
      </CxDialog>

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} onClose={() => {setOpen(false); setDisabled(false);}} />

    </div>
  );
};

export default MxSlsDocFormClientDataDlg;
