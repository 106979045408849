import React from "react";
import '../../styles/main.css';
//import * as FxLog from '../fx/FxLog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  myAlert: {
    backgroundColor: props => {
      let rv = '';
      switch (props.severity) {
        case 'error': rv = 'var(--ibs_color_snackbar_error)'; break;
        case 'warning': rv = 'var(--ibs_color_snackbar_warning)'; break;
        case 'info': rv = 'var(--ibs_color_snackbar_info)'; break;
        case 'success': rv = 'var(--ibs_color_snackbar_success)'; break;
        default: rv = 'var(--ibs_color_snackbar_info)'; break;
      }
      return rv;
    },
  }
});

const CxSnackbar = props => {
  const { autoHideDuration, ...rest } = props;
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [myValue, setMyValue] = React.useState(props.value);

  // para poder usar setContent desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, setOpen, setValAndOpen, 
    };
  });
  const setVal = (newValue) => { setMyValue(newValue); };
  const setValAndOpen = (newValue) => { setVal(newValue); setOpen(true) };
  const getVal = () => { return myValue; };
  const getType = () => { return 'CxSnackbar' };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (props.onClose !== undefined) {
      props.onClose();
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={autoHideDuration || 2000} onClose={handleClose}>
        <Alert elevation={6} variant="filled" className={classes.myAlert} action={''} {...rest} >
          {myValue}
        </Alert>
      </Snackbar>
    </div >
  );
};

export default CxSnackbar;
