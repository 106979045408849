import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettProf } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettProfList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettProf, setStoreSettProf] = useSessionStorage('StoreSettProf', StoreSettProf);

  React.useEffect(() => {
    clearStore('StoreSettProf', storeSettProf);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettProf.inProf = null;
    } else {
      storeSettProf.inProf = row.original.inProf;
    }
    setStoreSettProf(storeSettProf);
    navTo("sett-prof-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.professionals')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/prof"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "inProf", show: false },
          { id: "1", Header: `${trans('field.professional')}`, accessor: "txProf", width: '50%' },
          { id: "2", Header: `${trans('field.branch')}`, accessor: "txBranch", width: '50%' },
        ]}
      />
    </div>
  );
}

export default MxSettProfList;
