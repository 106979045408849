import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
import CxButton from './CxButton';
import CxInput from './CxInput';
import { useTranslation } from '../hx/useTranslation';
import { getStoreValue } from "../gx/GxStore";
import CxSnackbar from '../cx/CxSnackbar';


const useStyles = makeStyles(props => ({
  dlgTitle: {
    backgroundColor: 'var(--ibs_color_red)',
    color: 'var(--ibs_color_white)',
    "& .MuiBox-root": {
      fontSize: "16px",
    },
    "& .MuiIconButton-root": {
      padding: '0px',
    },
  },
  dlgContent: {
    backgroundColor: 'var(--ibs_color_white)',
    color: 'var(--ibs_color_black)',
    width: props => props.width,
    height: props => props.height,
  },
  dlgActions: {
    backgroundColor: 'var(--ibs_color_white)',
    display: 'flex',
    justifyContent: 'center',
  },
  dlgLabel: {
    fontSize: "12px",
    margin: "0px",
    color: 'var(--ibs_color_black)',
  },
}));

const CxDlgPin = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  
  const { onOk, ...rest } = props;

  let rf_txPin = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const r_snack_error = React.useRef(null);

  const handleEntered = () => {
    if (rf_txPin.current != null) {
      rf_txPin.current.setFocus();
    }
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, getInput: getInput, setOpen,
    };
  });
  const getType = () => { return 'CxDlgPin' };
  const getInput = () => { return rf_txPin.current };


  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    let storedPIN = getStoreValue('StoreSession', 'txPin');
    let enteredPIN = rf_txPin.current.getVal();
    FxLog.infox("CxDlgPin.handleOk", "stored/entered: " + storedPIN + "/" + enteredPIN);
    if (enteredPIN===storedPIN) {
      onOk();
      setOpen(false);
    } else {
      r_snack_error.current.setVal(trans('msg.incorrect_x', 'field.pin'));
      r_snack_error.current.setOpen(true);
      rf_txPin.current.clear();
      rf_txPin.current.setFocus();
    }
  }

  return (
    <div>
      <Dialog TransitionProps={{ onEntered: handleEntered, /*onExiting: handleExiting, onExited: handleExited*/ }} open={open} {...rest}>
        <DialogTitle className={classes.dlgTitle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{trans('msg.confirm_operation')}</Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dlgContent}>
          <CxInput xref={rf_txPin} label={trans('field.pin')} /*type="password"*/ width='var(--ibs_control_width_sm)' maxLength={4} />
        </DialogContent>
        <DialogActions className={classes.dlgActions}>
          <CxButton label={trans('field.cancel')} onClick={handleCancel} />
          <CxButton label={trans('field.ok')} onClick={handleOk} />
        </DialogActions>
      </Dialog>

      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );
};

export default CxDlgPin;
