import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxDate from '../../cx/CxDate';
import CxState from '../../cx/CxState';
import CxButton from '../../cx/CxButton';
import CxStatic from '../../cx/CxStatic';
import CxToolbar from '../../cx/CxToolbar';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
//import * as FxDat from '../../fx/FxDat';
import { StoreIntegrationPurInvoices } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { StoreLongTask, clearStore } from "../../gx/GxStore";
import { useNav } from '../../hx/useNav';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
  button_frame: {
    margin: '20px 0 0 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
}));

const MxIntegrationPurInvoicesOption = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const classes = useStyles(props);

  const rf_from = React.useRef(null);
  const rf_create_pss = React.useRef(null);
  
  const r_snack_error = React.useRef(null);

  const [wait, setWait] = React.useState(false);
  
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);

  // eslint-disable-next-line
  const [storeIntegrationPurInvoices, setStoreIntegrationPurInvoices] = useSessionStorage('StoreIntegrationPurInvoices', StoreIntegrationPurInvoices);

  React.useEffect(() => {
    clearStore('StoreLongTask', storeLongTask);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const doTask = (op) => {
    try {
      if (rf_from.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ': ' + trans('field.from'));
        r_snack_error.current.setOpen(true);
        return;
      }

      setWait(true);
      let record = {};
      record['strSupplierName'] = storeIntegrationPurInvoices.strSupplierName;
      record['dtFrom'] = rf_from.current.getVal();
      Promise.all([FxFetch.doJsonX('/pur/doclist/check_invoice_qy', record)]).then((result) => {
        if (result[0]) {
          FxLog.infox('MxIntegrationPurInvoicesOption.doTask', '...result[0]:', result[0]);
          let rv = result[0].theValue;
          setWait(false);
          if (rv.startsWith('Error')) {
            r_snack_error.current.setVal(`${trans('msg.error')}: ${rv.split('|')[1]}`);
            r_snack_error.current.setOpen(true);
            return;
          }
          if (rv === "0") {
            r_snack_error.current.setVal(trans('msg.no_x_found_p', 'field.invoices'));
            r_snack_error.current.setOpen(true);
          } else {
            // pasamos a la pag de importación
            storeLongTask._url = 'pur/doclist/import_invoices';
            storeLongTask._canCancel = true;
            storeLongTask._record = { 
              txTaskName: "import_invoices", 
              strSupplierName: storeIntegrationPurInvoices.strSupplierName, 
              dtFrom: rf_from.current.getVal(),
              boCreatePss: rf_create_pss.current.getVal(),
            };
            Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
              navTo('integration-pur-invoices-option-tsk', { quantity: rv});
            });
          }
        }
      });
  

    } catch (error) {
      FxLog.errorx("MxIntegrationPurInvoicesOption.doTask", error);
    }
  }

  return (
    <div>
      <CxToolbar moduleName={`${trans('field.integration')}: ${trans('field.options')}`} />

      <div className={classes.container}>

        <CxDate xref={rf_from} label={trans('field.from')} required />
        <CxState mode='checkbox' xref={rf_create_pss} label={trans('field.create_x', 'field.pss')} />

        <div className={classes.button_frame}>
          <CxButton label={trans('field.continue')} style={{ width: 'var(--ibs_control_width_lg)' }} onClick={() => doTask()} disabled={wait} />
          {wait && <CxStatic label={`${trans('msg.please_wait').toUpperCase()}...`} border='none' fontcolor='var(--ibs_color_red)' fontsize='14px' />}
        </div>

      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxIntegrationPurInvoicesOption;
