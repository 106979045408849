//import { FaArrowsAlt } from "react-icons/fa"; 
import { IoFileTrayFull } from "react-icons/io5";
import { HiOutlineStatusOffline } from "react-icons/hi";
import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import { useNav } from '../hx/useNav';
import { useSessionStorage } from '../hx/useSessionStorage';
import { useAccess } from '../hx/useAccess';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TxTimerComms from '../modules/common/TxTimerComms';
import TxTimerLogins from '../modules/common/TxTimerLogins';
import { Outlet } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxStr from '../fx/FxStr';
import * as FxDat from '../fx/FxDat';
import * as FxFetch from '../fx/FxFetch';
import * as FxEdition from '../fx/FxEdition';
import * as FxInet from '../fx/FxInet';
import * as FxFile from '../fx/FxFile';
import { MxMainList, MxMainListItem, MxMainListGroup } from './MxMainList';
import {
  StoreHistory, StoreCalendarView, StoreWaiting, StoreCash, StoreMassBilling,  
  clearStore, getStoreValue, setStoreValue, isApp, getStoreValueBo
} from "../gx/GxStore";
import CxDialog from '../cx/CxDialog';
import CxDlgTable from '../cx/CxDlgTable';
import CxDlgCalendar from '../cx/CxDlgCalendar';
import CxTooltip from '../cx/CxTooltip';
import CxState from '../cx/CxState';
import CxSnackbar from '../cx/CxSnackbar';
import CxIconButtonMenu from '../cx/CxIconButtonMenu';
import MenuIcon from '@material-ui/icons/Menu';
import { BiExit } from "react-icons/bi";
import { AiOutlineWarning } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import {
  FaShoppingBasket, FaCoins, FaCashRegister, FaBox, FaBoxes, FaTruck, FaToolbox, FaFolderOpen, FaHistory,
  FaChair, FaMicroscope, FaShieldAlt
} from "react-icons/fa";
import { ImStatsBars, ImUser, ImUserTie, ImUserCheck } from "react-icons/im";
import { BsCashCoin, BsFillCalendarFill, BsFillGearFill, BsFillCollectionFill } from "react-icons/bs";
import { MdSend, MdCenterFocusStrong } from "react-icons/md";
import { IoMdAnalytics, IoMdOptions } from "react-icons/io";
import { FiUser, FiUsers } from "react-icons/fi";
import { Detector } from "react-detect-offline";
import { useWindowSize } from '../hx/useWindowSize';

const DRAWER_WIDTH = 270;

const getAppbarBackground = () => {
  let hex = FxStr.rgb2hex(`rgb(${(getStoreValue('StoreSession', 'txBranchRgb') || '255-255-255')})`);
  //console.log(hex);
  return hex;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1, // pone el drawer debajo del appbar
    padding: '0 0 0 12px',
    height: 'var(--ibs_appbar_height)',
    background: getAppbarBackground(),
    color: 'var(--ibs_color_black)',
    "&.MuiPaper-elevation4": {
      /* boxShadow: 'none',*/
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.06) 0px 1px 0px 0px',
      /* boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px', */
    },
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    padding: ' 0 0 0 9px',
    marginRight: '0px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appbarTitle: {
    width: '100%',
    //background: 'blue',
    paddingLeft: '6px',
    paddingBottom: '5px',
    textAlign: 'left',
    //color: 'var(--ibs_color_cy_blue)',
    color: theme.palette.getContrastText(getAppbarBackground()),
    fontFamily: 'var(--ibs_brand_font_family)',
    fontSize: '11px',
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingLeft: '0px',
      lineHeight: '20px',
    },
  },
  appbarWarning: {
    color: 'red',
    paddingTop: '10px',
    paddingLeft: '6px',
  },
  appbarExit: {
    color: theme.palette.getContrastText(getAppbarBackground()),
  },
  appbarText: {
    lineHeight: 1.2,
    //background: 'yellow',
    color: theme.palette.getContrastText(getAppbarBackground()),
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: '9px',
    display: 'inline',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {},
    '&:hover': { backgroundColor: 'yellow' },
  },
  appbarActions: {
    //background: 'yellow',
    paddingLeft: '6px',
    paddingBottom: '6px',
    display: 'flex',
  },
  appbarDivider: {
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      height: '42px',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    //background: 'red',
    padding: '0px',
    //minHeight: APPBAR_HEIGHT, // reemplazado por MxMainList div logo.
  },
  drawerPaper: {
    //background: 'var(--ibs_color_gray_light)',
    width: DRAWER_WIDTH,
  },
  content: {
    marginTop: '55px', // define espacio entre el contenido y la appbar
    background: 'var(--ibs_color_white)',
    flexGrow: 1,
    padding: theme.spacing(0),
  },
}));

function MxMain(props) {
  const [{ navTo, navClear, navExit }] = useNav();
  const [{ trans }] = useTranslation();
  const [{ access }] = useAccess();
  const classes = useStyles();
  const theme = useTheme();
  const size = useWindowSize();

  const [useReceipts,] = React.useState(getStoreValueBo('StoreSession', 'boUseReceipts'));

  const r_dlgtable_branches = React.useRef(null);
  const r_boDontAskShowFirstSteps = React.useRef(null);

  const r_snack_internet_off = React.useRef(null);
  const r_snack_internet_on = React.useRef(null);

  const [dlgAccessDenied, setDlgAccessDenied] = React.useState(false);
  const [dlgChangeBranch, setDlgChangeBranch] = React.useState(false);
  const [dlgChangeDate, setDlgChangeDate] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(`${FxDat.toLocalDate(getStoreValue('StoreSession', 'dtLogin'))}`);
  const [currentDateColor, setCurrentDateColor] = React.useState(getStoreValue('StoreSession', 'dtLogin') === FxDat.todayReal() ? 'black' : 'red');

  const [storeMassBilling, ] = useSessionStorage('StoreMassBilling', StoreMassBilling);
  const [storeCash, ] = useSessionStorage('StoreCash', StoreCash);
  const [storeHistory, ] = useSessionStorage('StoreHistory', StoreHistory);
  const [storeCalendarView, ] = useSessionStorage('StoreCalendarView', StoreCalendarView);
  const [storeWaiting, ] = useSessionStorage('StoreWaiting', StoreWaiting);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [groupFiles, setGroupFiles] = React.useState(true);
  const [groupSales, setGroupSales] = React.useState(true);
  const [groupUtilities, setGroupUtilities] = React.useState(true);
  const [groupSettings, setGroupSettings] = React.useState(true);

  const [dlgAskShowFirstSteps, setDlgAskShowFirstSteps] = React.useState(false);


  React.useEffect(() => {
    let boDontAskShowFirstSteps = Boolean((localStorage.getItem('MxMain.boDontAskShowFirstSteps') || '0') === '1');
    if (!boDontAskShowFirstSteps) {
      setDlgAskShowFirstSteps(true);
    }
    if (getStoreValue('StoreSession', 'inUser') === '000001.SRV') {
      // 2024-03-04 - daq: de momento lo deshabilitamos porque es un poco molesto
      //r_snack_error.current.setVal(trans('msg.login_master'));
      //r_snack_error.current.setOpen(true);
    }

    // eslint-disable-next-line
  }, []);

  const goto = (txSecAccess, url) => {
    if (access(txSecAccess) === 'N') {
      setDlgAccessDenied(true);

    } else {
      // quita menú
      setMobileOpen(false);

      // limpia objetos de sesión
      clearStore('StoreHistory', storeHistory);
      clearStore('StoreCalendarView', storeCalendarView);
      clearStore('StoreWaiting', storeWaiting);
      clearStore('StoreCash', storeCash);
      clearStore('StoreMassBilling', storeMassBilling);
      //clearStore('StoreSlsLst', storeSlsLst); //lo dejamos que recuerde.

      // limpia stack de url 
      navClear();

      // va al módulo pedido
      navTo(url);
    }
  };

  const getItem = (icon, moduleKey, moduleName, accessName, url) => {
    let iconColor = '';
    switch (access(accessName)) {
      case 'N': iconColor = 'var(--ibs_color_red)'; break;
      case 'R': iconColor = 'var(--ibs_color_yellow)'; break;
      default: iconColor = 'var(--ibs_color_black)'; break;
    }
    let isInEdition = FxEdition.isInEdition(moduleKey);
    return <MxMainListItem
      icon={icon}
      iconColor={iconColor}
      text={moduleName}
      textColor={isInEdition ? 'var(--ibs_color_black)' : 'var(--ibs_color_gray)'}
      type='nested'
      onClick={() => goto(accessName, isInEdition ? url : 'upgrade')}
    />;
  }

  //const isFree = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').includes('free')) }

  const doExit = () => {
    let record = {};
    record['process'] = 'silent_login_set_exit';
    Promise.all([FxFetch.doJsonX('login/set_exit', record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        navExit();
      }
    });
  }

  const doOptions = (option) => {
    if (option === 'cache') {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
      }
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
    if (option === 'anydesk') {
      FxFile.downloadFile('https://www.iberical.com/downloads/AnyDesk.exe', 'Iberical Anydesk.exe');
    }
  }

  const doChangeBranch = (row) => {
    try {
      setDlgChangeBranch(false);
      if (row) {
        localStorage.setItem('MxLogin.branch', `${getStoreValue('StoreSession', 'txIdi')}|${row.original.inBranch}`);
        navExit();
      }

    } catch (error) {
      FxLog.errorx("MxMain.doChangeBranch", error);
    }
  }

  const doChangeDate = (d) => {
    try {
      let isoDate = d || '';
      setDlgChangeDate(false);
      if (isoDate) {
        setStoreValue('StoreSession', 'dtLogin', isoDate);
        setCurrentDate(FxDat.toLocalDate(isoDate));
        // si la fecha no es la de hoy, ponerla en rojo
        if (isoDate === FxDat.todayReal()) {
          setCurrentDateColor('black');
        } else {
          setCurrentDateColor('red');
        }
      }

    } catch (error) {
      FxLog.errorx("MxMain.doChangeDate", error);
    }
  }

  const openFirstSteps = (boOpen) => {
    setDlgAskShowFirstSteps(false);
    localStorage.setItem('MxMain.boDontAskShowFirstSteps', r_boDontAskShowFirstSteps.current.getVal());
    if (boOpen) {
      window.open(`https://iberical.com/ibericalhelp/${getStoreValue('StoreSession', 'txLocale').substring(0, 2)}_${getStoreValue('StoreSession', 'txApp')}.php`, '_blank', 'noreferrer');
    }
  }

  const getCompanyName = () => {
    let rv = getStoreValue('StoreSession', 'txCompany').toUpperCase();
    let txIdi = getStoreValue('StoreSession', 'txIdi');
    //if (Boolean(txIdi.includes('test'))) rv += " TEST"; // 2024-03-14 - daq: lo quito xq no queda bien en las capturas de pantalla para la ayuda del programa.
    if (Boolean(txIdi.includes('demo'))) rv += " DEMO";
    return rv;
  }

  const internetChanged = (online) => {
    if (online) {
      //console.log('You are ONLINE');
      r_snack_internet_on.current.setOpen(true);
    } else {
      //console.log('You are OFFLINE!!!');
      r_snack_internet_off.current.setOpen(true);
    }
  }

  const drawer = (
    <div style={{ overflowY: 'scroll', }}>
      <div className={classes.toolbar} />
      <Divider />
      <MxMainList>
        <MxMainListGroup icon={<FaFolderOpen />} text={trans('field.files')} group={groupFiles} onClick={() => setGroupFiles(!groupFiles)} >
          {getItem(<ImUser fontSize={18} />, 'CLI', trans('field.clients'), trans('field.clients'), 'client-list')}
          {getItem(<ImUserCheck fontSize={18} />, 'REF', trans('field.referrers'), trans('field.referrers'), 'referrer-list')}
          {getItem(<ImUserTie fontSize={18} />, 'EMP', trans('field.employees'), trans('field.employees'), 'employee-list')}
          {getItem(<FaTruck />, 'SUP', trans('field.suppliers'), trans('field.suppliers'), 'supplier-list')}
          {isApp('opti') && getItem(<FaMicroscope />, 'LAB', trans('field.labs'), trans('field.labs'), 'lab-list')}
          {getItem(<FaBox fontSize={15} />, 'PSS', trans('field.pss'), trans('field.pss'), 'ps-list')}
        </MxMainListGroup>

        <MxMainListGroup icon={<BsCashCoin />} text={trans('field.financial')} group={groupSales} onClick={() => setGroupSales(!groupSales)} >
          {getItem(<FaCoins />, 'SLS', trans('field.sales'), trans('field.pos'), 'sls-doc-list')}
          {getItem(<FaShoppingBasket />, 'PUR', trans('field.purchases'), trans('field.purchases'), 'pur-doc-list')}
          {getItem(<FaHistory />, 'HIS', trans('field.history'), trans('field.pos'), 'history-filter')}
          {getItem(<FaCashRegister />, 'CSH', trans('field.cash_reg'), trans('field.cash_reg'), 'cash-filter')}
          {getItem(<FaBoxes />, 'STO', trans('field.stockrooms'), trans('field.stockrooms'), 'stock-list')}
          {getItem(<ImStatsBars />, 'STA_bas', trans('field.stats'), trans('field.stats'), 'stats')}
          {getItem(<IoMdAnalytics />, 'ANL', trans('field.analytics'), trans('field.analytics'), 'analytics-list')}
          {useReceipts && getItem(<BsFillCollectionFill />, 'BLG', trans('field.mass_billing'), trans('field.mass_billing'), 'mass-billing-filter')}
        </MxMainListGroup>

        <MxMainListGroup icon={<FaToolbox />} text={trans('field.utilities')} group={groupUtilities} onClick={() => setGroupUtilities(!groupUtilities)} >
          {getItem(<IoFileTrayFull fontSize={19} style={{ margin: '0 0 0 -2px' }} />, 'MME', trans('field.multimedia_archieve'), trans('field.multimedia'), 'multimedia-list')}
          {getItem(<BsFillCalendarFill fontSize={15} />, 'CAL', trans('field.calendar'), trans('field.calendar'), 'calendar-view')}
          {getItem(<MdSend />, 'COM', trans('field.comms'), trans('field.comms'), 'comm-list')}

          {/* {getItem(<FaArrowsAlt fontSize={16} />, 'MKT', trans('field.marketing'), trans('field.marketing'), 'mass-comm-filter')} */}
          
          {isApp('vett') && getItem(<FaChair />, 'WAI', trans('field.waiting_room'), trans('field.waiting_room'), 'waiting-list')}
          {getItem(<FaShieldAlt />, 'PLN', trans('field.plans'), trans('field.plans'), 'plan-list')}
        </MxMainListGroup>

        <MxMainListGroup icon={<BsFillGearFill />} text={trans('field.settings')} group={groupSettings} onClick={() => setGroupSettings(!groupSettings)} >
          {getItem(<MdCenterFocusStrong fontSize={20} />, 'BCH', trans('field.branches'), trans('field.branches'), 'sett-branch-list')}
          {getItem(<IoMdOptions />, 'OPT', trans('field.options'), trans('field.options'), 'options')}
          {getItem(<FiUser />, 'USR', trans('field.users'), trans('field.security'), 'sett-sec-user-list')}
          {getItem(<FiUsers />, 'GRP', trans('field.groups'), trans('field.security'), 'sett-sec-group-list')}
        </MxMainListGroup> 

      </MxMainList>
      <Divider />
    </div>
  );


  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} color="inherit" edge="start" onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.appbarTitle}>
            {/* NOMBRE DE LA EMPRESA */}
            {getCompanyName() + ((FxInet.isDebug() && !getCompanyName().includes('DEMO')) ? ' (DEBUG)' : '')}
          </Typography>
          <div className={classes.appbarActions}>
            <Divider className={classes.appbarDivider} orientation="vertical" light={true} flexItem />
            {FxEdition.isPremiumNeeded() &&
              <CxTooltip text={trans('msg.app_server_resources_required')} arrow>
                <Icon className={classes.appbarWarning} ><AiOutlineWarning /></Icon>
              </CxTooltip>
            }
            <Detector //detecta si hay internet
              render={({ online }) => (
                !online && <CxTooltip text={trans('msg.app_internet_off')} arrow>
                  <Icon className={classes.appbarWarning} ><HiOutlineStatusOffline /></Icon>
                </CxTooltip>
              )}
              onChange={internetChanged}
            />
            <CxIconButtonMenu onSelection={doOptions} defaultIconColor='var(--ibs_color_black)'
              options={size.isLarge ?
                [
                  { id: 'cache', text: trans('field.clear_cache'), },
                  { id: 'anydesk', text: trans('field.download_x', 'Anydesk'), },
                ] :
                [
                  { id: 'cache', text: trans('field.clear_cache'), },
                ]
              }
            />
            <Divider className={classes.appbarDivider} orientation="vertical" light={true} flexItem />
            <CxTooltip text={trans('field.exit')} arrow>
              <IconButton className={classes.appbarExit} onClick={() => doExit()} ><BiExit /></IconButton>
            </CxTooltip>
            <Divider className={classes.appbarDivider} orientation="vertical" light={true} flexItem />
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 4px 0 4px', gap: '3px' }}>

              <CxTooltip text={`${trans('field.user')} / ${trans('field.branch_id')}`} arrow>
                <Typography noWrap className={classes.appbarText} style={{ borderTop: '1px dotted var(--ibs_color_gray)' }}
                  onClick={() => { if (FxEdition.getEdition() !== 'FR') { setDlgChangeBranch(true); } }}>
                  {`${getStoreValue('StoreSession', 'txUser')}-${getStoreValue('StoreSession', 'txBranchId')}`}
                </Typography>
              </CxTooltip>

              <CxTooltip text={`${trans('field.work_date')}`} arrow>
                <Typography noWrap className={classes.appbarText} style={{ borderBottom: '1px dotted var(--ibs_color_gray)', color: currentDateColor }}
                  onClick={() => { if (FxEdition.getEdition() !== 'FR') { setDlgChangeDate(true); } }}>
                  {currentDate}
                </Typography>
              </CxTooltip>

            </div>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper, }}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            ModalProps={{
              keepMounted: true, /* Better open performance on mobile. */
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper, }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <TxTimerComms />
        <TxTimerLogins />
        <Outlet />

        <CxDialog title={trans('field.help')} open={dlgAskShowFirstSteps}
          onOk={() => openFirstSteps(true)}
          onCancel={() => openFirstSteps(false)}
          buttonOkText={trans('field.yes')}
          buttonCancelText={trans('field.no')}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginRight: '3px' }}>
            <span>{trans('msg.ask_learn_basics')}</span>
            <CxState mode='checkbox' xref={r_boDontAskShowFirstSteps} label={trans('msg.dont_ask_again')} width="250px" />
          </div>
        </CxDialog>

        <CxDialog title={trans('field.security')} open={dlgAccessDenied} onOk={() => setDlgAccessDenied(false)} height='200px'>
          <div style={{ width: '200px', display: 'flex', alignItems: 'center', gap: 10 }}>
            <GiCancel fontSize="30px" style={{ color: 'var(--ibs_color_red)' }} />
            <span>{trans('msg.access_denied')}</span>
          </div>
        </CxDialog>

        <CxDlgTable
          xref={r_dlgtable_branches}
          open={dlgChangeBranch}
          onSelect={doChangeBranch}
          onClose={() => setDlgChangeBranch(false)}
          url={'sett/branches'}
          columns={[
            { id: "0", Header: "ID", accessor: "inBranch", show: false },
            { id: "1", Header: trans('field.branch'), accessor: "txBranch", width: '75%' },
            { id: "2", Header: trans('field.branch_id'), accessor: "txBranchId", width: '25%' },
          ]}
          title={trans('field.branch_restart')}
        />

        <CxDlgCalendar
          open={dlgChangeDate}
          onClose={(d) => doChangeDate(d)}
          title={trans('field.change_x', 'field.work_date')}
        />

        <CxSnackbar xref={r_snack_internet_off} severity="error" value={trans('msg.app_internet_off')} />
        <CxSnackbar xref={r_snack_internet_on} severity="success" value={trans('msg.app_internet_on')} />

      </main>
    </div>
  );
}

export default MxMain;
