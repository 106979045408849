import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxDate from '../../cx/CxDate';
import CxTable from '../../cx/CxTable';
import CxIconButton from '../../cx/CxIconButton';
import * as FxTable from '../../fx/FxTable';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreSettRate } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettSlsRateForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo, /* navBack */ }] = useNav();
  const size = useWindowSize();

  let r_form = React.useRef(null);
  let r_list = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  const [storeSettRate, setStoreSettRate] = useSessionStorage('StoreSettRate', StoreSettRate);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus === 'error') {
    } else {
      storeSettRate.itRate = lastSaveRv.theValue;
      Promise.all([setStoreSettRate(storeSettRate)]).then((result) => {
        r_list.current.setFilter(`r_rate_ps.itRate='${storeSettRate.itRate}'`);
        forceUIUpdate();
        //navBack(); // 2024-09-17 - daq: anulado porque debo permitir meter los productos.
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const deleteItem = (row) => {
    let record = {};
    record['idValue'] = row.irRatePs;
    Promise.all([FxFetch.doJsonX('sett/rate/ps/d', record)]).then((result) => {
      if (result[0].theValue==='OK') {
        r_list.current.refresh();
      }
    });
  }

  const openForm = async (op, row) => {
    if (!storeSettRate.itRate) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        navTo("sett-sls-rate-ps-form");
      }
    }

  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.rate')}
        xref={r_form}
        url="sett/rate"
        filterIdField='itRate'
        filterIdValue={storeSettRate.itRate}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={storeSettRate.itRate && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="t_rate.itRate" value={storeSettRate.itRate || ''} />
        <CxInput xref={useRef(null)} label={trans('field.name')} dbfield="t_rate.txTypeRate" maxLength={50} required />
        <CxInput xref={useRef(null)} label={trans('field.name_short')} dbfield="t_rate.txShort" maxLength={4} />
        <CxDate xref={useRef(null)} label={trans('field.expiration')} dbfield="t_rate.dtRateTo" />

        <br/>
        <CxTable
          xref={r_list}
          url="sett/rate/ps"
          filter={`r_rate_ps.itRate='${storeSettRate.itRate}'`}
          onOpenForm={openForm}
          hasSearchBox
          hasButtonAdd={true}
          columns={[
            { id: "0", Header: "ID", accessor: "irRatePs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => r_list.current.highlightText(row["txPs"]), width: '50%' },
            { id: "2", Header: trans('field.group'), accessor: "txTypePsGroup", width: '0%', show: false },
            { id: "3", Header: trans('field.subgroup'), accessor: "txTypePsSubgroup", width: '30%', show: size.isLarge },
            { id: "4", Header: trans('field.discount_perc'), accessor: row => (FxTable.cellTo2r00(row["nuDiscountPerc"])), align: 'right', width: '15%' },
            { id: "5", accessor: row => { return <CxIconButton type="delete" classType="ibs_iconbutton_table" data={row} onClick={deleteItem} />}, align: 'center', width: '5%' }
          ]}
        />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxSettSlsRateForm;
