import React from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import CxTable from '../../cx/CxTable';
import * as FxTable from '../../fx/FxTable';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import { clearStore, getStoreValue, StoreClient, StoreRxGlas, StoreRxClen, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';
import { useNav } from '../../hx/useNav';


const MxRxFormCP = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxGlas,] = useSessionStorage('StoreRxGlas', StoreRxGlas);
  const [storeRxClen,] = useSessionStorage('StoreRxClen', StoreRxClen);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_form = React.useRef(null);
  let r_print = React.useRef(null);

  const r_inClientCP = React.useRef(getStoreValue('StoreSession', 'inClientCP'));

  const r_table_rx_glass = React.useRef(null);
  const r_table_rx_clen = React.useRef(null);
  const r_table_repl = React.useRef(null);
  const r_table_multimedia = React.useRef(null);


  React.useEffect(() => {
    clearStore('StoreRxGlas', storeRxGlas);
    clearStore('StoreRxClen', storeRxClen);

    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
  }

  const openFormMultimedia = async (op, row) => {
    if (row === null) {
      storeClient.inMultimedia = null;
    } else {
      storeClient.inMultimedia = row.original.inMultimedia;
    }
    setStoreClient(storeClient);
    navTo("client-multimedia-form-cp");
  }

  const openFormRxGlas = async (op, row) => {
    doPrintX('rx_glas', row.original.inRxGlas);
  }

  const openFormRxClen = async (op, row) => {
    doPrintX('rx_clen', row.original.inRxClen);
  }

  const doPrintX = async (option, inXXX) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = r_inClientCP.current;
    if (option === 'rx_glas') {
      filter['inRxGlas'] = inXXX;
    } else {
      filter['inRxClen'] = inXXX;
    }
    let record = {};
    r_print.current.setScale(1);
    r_print.current.setUrl(option + '/report');
    r_print.current.setCanSend(false);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      //r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.my_x', 'field.rxs')}
        xref={r_form}
        url="clients"
        filterIdField='inClient'
        filterIdValue={r_inClientCP.current}
        onFormLoad={formLoad}
      >
        <CxTable
          xref={r_table_rx_glass}
          url="rx_glas"
          filter={`n_rx_glas.inClient='${r_inClientCP.current}'`}
          onOpenForm={openFormRxGlas}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inRxGlas", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
            { id: "2", Header: trans('field.rx_glas') + " - " + trans('field.reason'), accessor: "txReason", width: '70%' },
          ]}
        />

        <CxTable
          xref={r_table_rx_clen}
          url="rx_clen"
          filter={`n_rx_clen.inClient='${r_inClientCP.current}'`}
          onOpenForm={openFormRxClen}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inRxClen", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
            { id: "2", Header: trans('field.rx_clen') + " - " + trans('field.reason'), accessor: "txReason", width: '70%' },
          ]}
        />

        <CxTable
          xref={r_table_repl}
          url="repls"
          filter={`inClient='${r_inClientCP.current}'`}
          hasButtonAdd={false}
          disabled={true}
          columns={[
            { id: "0", Header: "ID", accessor: "inRepl", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
            { id: "2", Header: trans('field.repls'), accessor: "txPs", width: '70%' },
          ]}
        />

        <CxTable
          title={`${trans('field.files2')}`}
          xref={r_table_multimedia}
          url="multimedia"
          filter={`n_multimedia.txLinkTable='n_client' and n_multimedia.txLinkId='${r_inClientCP.current}'`}
          onOpenForm={openFormMultimedia}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inMultimedia", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtMultimedia"]), width: '15%' },
            { id: "2", Header: trans('field.type'), accessor: "txTypeMultimedia", width: '15%' },
            { id: "3", Header: trans('field.issue'), accessor: "txIssue", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
          ]}
        />

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print}/>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_exists} severity="error" value={trans('msg.existing_x', 'field.ic')} />

    </div>
  );

}

export default MxRxFormCP;
