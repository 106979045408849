// Funciones generales para fechas.
import moment from 'moment';
import 'moment/locale/es';
import { getStoreValue } from "../gx/GxStore";
// eslint-disable-next-line no-unused-vars
import * as FxLog from './FxLog';
import i18n from '../../i18n';


export const getDateToday = () => {
  let today = getStoreValue('StoreSession', 'dtLogin');
  if (!today) {
    // el objeto session no existe, así que usamos Date
    let newDate = new Date()
    let date = newDate.getDate();
    date = date < 10 ? `0${date}` : `${date}`;
    let month = newDate.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;
    let year = newDate.getFullYear();
    today = `${year}-${month}-${date}`;
  }
  return today;
};

export const getDateFormat = () => {
  let dateFormat = getStoreValue('StoreSession', 'txDateFormat');
  if (!dateFormat) {
    // el objeto session no existe, así que usamos i18next
    let txBrowserLang = i18n.language;
    switch (txBrowserLang) {
      case 'en-US':
        dateFormat = 'MM-dd-yyyy';
        break;

      default:
        dateFormat = 'dd-MM-yyyy';
        break;
    }
  }
  return dateFormat;
};

export const toMMMYYYY = (locale, dt) => {
  if (dt) {
    /* DAQ 2022.05.23 - OJO: requiere importar el locale del idioma correspondiente.
       Ej: import 'moment/locale/es'; 
    */
    return moment(dt).locale(locale).format('MMM YYYY');
  } else {
    return '';
  }
}

export const toLocalDate = (dt) => {
  if (dt) {
    return moment(dt).format(getDateFormat().toUpperCase());
  } else {
    return '';
  }
}

export const toLocalTime = (dt) => {
  if (dt) {
    return moment(dt).format(moment.HTML5_FMT.TIME);
  } else {
    return '';
  }
}

export const toLocalDateTime = (dt) => {
  if (dt) {
    let theFormat = getDateFormat().toUpperCase() + " HH:mm";
    return moment(dt).format(theFormat);
  } else {
    return '';
  }
}

export const toIso = (dt) => {
  return moment(dt).format(moment.HTML5_FMT.DATE);
}

export const toIsoDate = (dt) => {
  let yyyy = null;
  let MM = null;
  let dd = null;
  let dateFormat = getDateFormat();
  if (dateFormat === 'MM-dd-yyyy') {
    MM = parseInt(dt.substring(0, 2)).toString().padStart(2, '0');
    dd = parseInt(dt.substring(3, 5)).toString().padStart(2, '0');
    yyyy = parseInt(dt.substring(6, 10)).toString().padStart(4, '0');
  }
  if (dateFormat === 'dd-MM-yyyy') {
    dd = parseInt(dt.substring(0, 2)).toString().padStart(2, '0');
    MM = parseInt(dt.substring(3, 5)).toString().padStart(2, '0');
    yyyy = parseInt(dt.substring(6, 10)).toString().padStart(4, '0');
  }
  let rv = `${yyyy}-${MM}-${dd}`;
  let boValid = isValidDtIso(rv);
  if (!boValid) {
    return '';
  } else {
    return rv;
  }
}

/* Validate time in format HH:mm */
export const isValidTime = (tm) => {
  let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(tm);
  return isValid;
}

/* Validate date in format YYYY-MM-DD
  A simple JS function to validate that a date string in the format YYYY-MM-DD is a valid date. 
  Will validate that the day is correct for the given month, including leap years 
*/
export const isValidDtIso = (dt) => {
  // Date format: YYYY-MM-DD
  var datePattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

  // Check if the date string format is a match
  var matchArray = dt.match(datePattern);
  if (matchArray == null) {
    return false;
  }

  // Remove any non digit characters
  var cleanDateString = dt.replace(/\D/g, '');

  // Parse integer values from date string
  var year = parseInt(cleanDateString.substr(0, 4));
  var month = parseInt(cleanDateString.substr(4, 2));
  var day = parseInt(cleanDateString.substr(6, 2));

  // Define number of days per month
  var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    daysInMonth[1] = 29;
  }

  // check month and day range
  if (month < 1 || month > 12 || day < 1 || day > daysInMonth[month - 1]) {
    return false;
  }

  // You made it through!
  return true;
}

/** recibe YYYY-MM-DD y la devuelve mismo formato con días añadidos o sustraídos según se pase un valor positivo o negativo */
export const addDays = (startdate, days) => {
  return moment(startdate, "YYYY-MM-DD").add(days, 'days').format('YYYY-MM-DD');
}

/** devuelve si la fecha inicial es anterior a la final */
export const isInOrder = (startDate, endDate) => {
  return moment(endDate).isAfter(startDate) || moment(endDate).isSame(startDate);
}

export const isExpired = (dt) => {
  if (!dt) {
    return false;    
  } else {
    var theDate = moment(dt);
    var today = moment().utc();
    var daysDiff = theDate.diff(today, 'days');
    if (daysDiff > 0) {
      //theDate is bigger than today.
      return false;
    } else if (daysDiff < 0) {
      //theDate is smaller than today.
      return true
    } else {
      //theDate =  b
      return false;
    }
  }
}

export const timestamp = () => {
  return `${today()} ${now()}`;
}

/* da fecha real del sistema en formato ISO, y no la fecha de session */
export const todayReal = () => {
  return moment().utc().format("YYYY-MM-DD");
}

export const today = () => {
  return moment(getDateToday()).format(moment.HTML5_FMT.DATE);
}

export const todayInLocal = () => {
  let theFormat = getDateFormat().toUpperCase() + " HH:mm";
  return moment(getDateToday()).format(theFormat);
}

export const now = () => {
  return moment().format(moment.HTML5_FMT.TIME);
}

export const getAge = (birthday) => {
  var a = moment();
  var b = moment(birthday, 'YYYY-MM-DD');

  var years = a.diff(b, 'year');
  b.add(years, 'years');

  var months = a.diff(b, 'months');
  b.add(months, 'months');

  var days = a.diff(b, 'days');

  let ar = JSON.parse(sessionStorage.getItem('trans') || null);
  return years + '' + ar['field.years'][0].toLowerCase() + ' ' +
    months + '' + ar['field.months'][0].toLowerCase() + ' ' +
    days + '' + ar['field.days'][0].toLowerCase() + '';
}
