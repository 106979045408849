import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettRxsSeries } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettRxSeriesList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettRxsSeries, setStoreSettRxsSeries] = useSessionStorage('StoreSettRxsSeries', StoreSettRxsSeries);

  React.useEffect(() => {
    clearStore('StoreSettRxsSeries', storeSettRxsSeries);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettRxsSeries.itSeriesRx = null;
    } else {
      storeSettRxsSeries.itSeriesRx = row.original.itSeriesRx;
    }
    setStoreSettRxsSeries(storeSettRxsSeries);
    navTo("sett-rxs-series-form");
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.series_p')}
        fullscreen
        hasSearchBox={false}
        url="sett/series_rx"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itSeriesRx", show: false },
          { id: "1", Header: trans('field.series'), accessor: "txTypeSeriesRx", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettRxSeriesList;
