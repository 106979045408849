import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import CxTable from '../../cx/CxTable';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButton from '../../cx/CxIconButton';
import * as FxTable from '../../fx/FxTable';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClientActivityOptiLst, StoreClient, StoreRepl, StoreRxClen, StoreRxGlas } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxToolbar from '../../cx/CxToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { TbReplace } from "react-icons/tb";
import { TbHemisphere } from "react-icons/tb";
import { LuGlasses } from "react-icons/lu";
import * as FxFetch from '../../fx/FxFetch';


const useStyles = makeStyles({
  toggleButtonGroup: {
    background: 'var(--ibs_color_gray_light)',
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'var(--ibs_color_white)',
    },
    '& .MuiToggleButton-root': {
      padding: '0px 10px 0px 10px',
    },
  },
});

const MxClientActivityOptiList = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const rf_table = React.useRef(null);
  let r_snack_error = React.useRef(null);

  const r_snack_required = React.useRef(null);

  const [storeClientActivityOptiLst, setStoreClientActivityOptiLst] = useSessionStorage('StoreClientActivityOptiLst', StoreClientActivityOptiLst);
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRepl, setStoreRepl] = useSessionStorage('StoreRepl', StoreRepl);
  const [storeRxGlas, setStoreRxGlas] = useSessionStorage('StoreRxGlas', StoreRxGlas);
  const [storeRxClen, setStoreRxClen] = useSessionStorage('StoreRxClen', StoreRxClen);

  const [docTitle, setDocTitle] = React.useState(trans('field.rx_glas'));

  React.useEffect(() => {
    changeType(null, storeClientActivityOptiLst.listType || 'g');

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    StoreClient.inClient = row.original.inClient;
    StoreClient.txClient = row.original.txClient;
    Promise.all([setStoreClient(StoreClient)]).then((result) => {
      switch (storeClientActivityOptiLst.listType) {
        case 'r': openFormRepl(op, row); break;
        case 'g': openFormRxGlas(op, row); break;
        case 'c': openFormRxClen(op, row); break;
        default: break;
      }
    });

  }

  const openFormRepl = async (op, row) => {
    if (row === null) {
      storeRepl.inRepl = null;
    } else {
      storeRepl.inRepl = row.original._id;
    }
    setStoreRepl(storeRepl);
    navTo("client-repl-form");
  }

  const openFormRxGlas = async (op, row) => {
    if (row === null) {
      storeRxGlas.inRxGlas = null;
    } else {
      storeRxGlas.inRxGlas = row.original._id;
    }
    setStoreRxGlas(storeRxGlas);
    navTo("client-rx-glas-form");
  }

  const openFormRxClen = async (op, row) => {
    if (row === null) {
      storeRxClen.inRxClen = null;
    } else {
      storeRxClen.inRxClen = row.original._id;
    }
    setStoreRxClen(storeRxClen);
    navTo("client-rx-clen-form");
  }

  const changeType = (event, newType) => {
    clearStore('StoreClient', storeClient);
    clearStore('StoreRepl', storeRepl);
    clearStore('StoreRxGlas', storeRxGlas);
    clearStore('StoreRxClen', storeRxClen);

    if (newType === null) {
      return;
    }
    let hasTypeChanged = false;
    if (newType !== storeClientActivityOptiLst.listType) {
      hasTypeChanged = true;
    }
    switch (newType) {
      case 'r':
        storeClientActivityOptiLst.listType = 'r';
        storeClientActivityOptiLst.listTypeBackground = 'var(--ibs_color_yellow_light)';
        storeClientActivityOptiLst.table = 'n_repl';
        storeClientActivityOptiLst.table_idField = 'inRepl';
        setStoreClientActivityOptiLst(storeClientActivityOptiLst);
        setDocTitle(trans('field.repls'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/opti/typelist/r');
        break;
      case 'g':
        storeClientActivityOptiLst.listType = 'g';
        storeClientActivityOptiLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storeClientActivityOptiLst.table = 'n_rx_glas';
        storeClientActivityOptiLst.table_idField = 'inRxGlas';
        setStoreClientActivityOptiLst(storeClientActivityOptiLst);
        setDocTitle(trans('field.rx_glas'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/opti/typelist/g');
        break;
      case 'c':
        storeClientActivityOptiLst.listType = 'c';
        storeClientActivityOptiLst.listTypeBackground = 'var(--ibs_color_green_light)';
        storeClientActivityOptiLst.table = 'n_rx_clen';
        storeClientActivityOptiLst.table_idField = 'inRxClen';
        setStoreClientActivityOptiLst(storeClientActivityOptiLst);
        setDocTitle(trans('field.rx_clen'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/opti/typelist/c');
        break;

      default:
        FxLog.errorx("MxClientActivityOptiList.openForm", "Cannot find type!!! -> " + newType);
        break;
    }
  };

  const doLastClientSearch = () => {
    let lastInClient = (localStorage.getItem('MxClientForm.last_client') || '');
    if (lastInClient) {
      Promise.all([FxFetch.doJsonX("general/r", { table: 'n_client', field: 'txClient', where: `inClient='${lastInClient}'` } )]).then((result) => {
        if (result[0]) {
          let lastTxClient = result[0].theValue;
          rf_table.current.setSearchForced(lastTxClient);
        }
      });
    } else {
      r_snack_error.current.setVal(trans('msg.no_x_file_opened','field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: storeClientActivityOptiLst.table_idField, show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
      
      switch (storeClientActivityOptiLst.listType) {
        case 'r':
          cols.push({ id: id++, Header: trans('field.next'), accessor: row => (FxTable.cellToLocalDate(row["dtNext"])), width: '10%' });
          cols.push({ id: id++, Header: trans('field.ps'), accessor: row => row["txPs"], width: '50%' });
          break;
        case 'g':
          cols.push({ id: id++, Header: trans('field.reason'), accessor: row => row["txReason"], width: '30%' });
          cols.push({ id: id++, Header: trans('field.status'), accessor: row => row["txTypeStatusRx"], width: '15%' });
          cols.push({ id: id++, Header: trans('field.treated_by'), accessor: row => row["txProf"], width: '15%' });
          break;
        case 'c':
            cols.push({ id: id++, Header: trans('field.reason'), accessor: row => row["txReason"], width: '30%' });
            cols.push({ id: id++, Header: trans('field.status'), accessor: row => row["txTypeStatusRx"], width: '15%' });
            cols.push({ id: id++, Header: trans('field.treated_by'), accessor: row => row["txProf"], width: '15%' });
          break;
  
        default:
          break;
      }

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
    }
    return cols;
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.activity_history')}: ${docTitle}`}
        background={storeClientActivityOptiLst.listTypeBackground}
        addToolbarButtons={
          <>
            <CxIconButton type="last_client_search" classType="ibs_iconbutton_toolbar" onClick={doLastClientSearch} tooltip={trans('field.search_last_x','field.client')} />
            <ToggleButtonGroup value={storeClientActivityOptiLst.listType} exclusive onChange={changeType} className={classes.toggleButtonGroup} >
              <ToggleButton value="r">
                <TbReplace fontSize="21px" style={{ color: 'var(--ibs_color_toggle_yellow)' }} />
              </ToggleButton>
              <ToggleButton value="g">
                <LuGlasses fontSize="21px" style={{ color: 'var(--ibs_color_toggle_blue)' }} />
              </ToggleButton>
              <ToggleButton value="c">
                <TbHemisphere fontSize="21px" style={{ color: 'var(--ibs_color_toggle_green)' }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        }
      />

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxTable
          xref={rf_table}
          id="MxClientActivityOptiList"
          tooltip={`${trans('field.client')}`}
          url={`activity/opti/typelist/${storeClientActivityOptiLst.listType || 'g'}`}
          fullscreen
          hasSearchBox
          columns={getTableColumns()}
          onOpenForm={openForm}
          optionalFromTo={true}
          onOptions={() => {}} // para que muestre botón de opciones.
        >
        </CxTable>
      </div>
      
      <CxSnackbar xref={r_snack_required} severity="error" />

      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );

}

export default MxClientActivityOptiList;
