import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
//import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';
import GxList from '../../gx/GxList';
//import CxDialog from '../../cx/CxDialog';
import { clearStore, StoreStock } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';
//import CxIconButton from '../../cx/CxIconButton';
import { getStoreValue } from "../../gx/GxStore";
//import { useAccess } from '../../hx/useAccess';
import CxSnackbar from '../../cx/CxSnackbar';


const MxStockroomList = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ isFullAccess }] = useAccess(trans('field.stockrooms'));
  const [{ navTo }] = useNav();

  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const hasColumnStockMin = () => { return Boolean(localStorage.getItem('MxStockroomList.addcol_nuStockMin')==='1')};
  const hasColumnStockMax = () => { return Boolean(localStorage.getItem('MxStockroomList.addcol_nuStockMax')==='1')};
  const hasColumnSupplier = () => { return Boolean(localStorage.getItem('MxStockroomList.addcol_txSupplier')==='1')};

  const r_list = React.useRef(null);

  //const [dlgMoveStockTakingToStock, setDlgMoveStockTakingToStock] = React.useState(false);

  const [storeStock, setStoreStock] = useSessionStorage('StoreStock', StoreStock);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_stock_taking_is_empty = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreStock', storeStock);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    storeStock.inStock = row.original.inStock;
    storeStock.inPs = row.original.inPs;
    storeStock.txPs = row.original.txPs;
    Promise.all([setStoreStock(storeStock)]).then((result) => {
      navTo("stock-form");
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: "ID", accessor: "inStock", show: false });
      cols.push({ id: "inPs", Header: "inPs", accessor: "inPs", show: false });
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '15%' });
      cols.push({ id: id++, Header: trans('field.supplier'), accessor: row => (row["txSupplier"]), width: '20%', align: 'left', show: hasColumnSupplier() });
      cols.push({ id: id++, Header: trans('field.group'), accessor: "txTypePsGroup", width: '20%' });
      cols.push({ id: id++, Header: trans('field.subgroup'), accessor: "txTypePsSubgroup", width: '20%' });
      cols.push({ id: id++, Header: trans('field.stock_min'), accessor: row => (FxTable.cellTo2r00(row["nuStockMin"])), width: '10%', align: 'right', show: hasColumnStockMin() });
      cols.push({ id: id++, Header: trans('field.stock_max'), accessor: row => (FxTable.cellTo2r00(row["nuStockMax"])), width: '10%', align: 'right', show: hasColumnStockMax() });
      cols.push({ id: id++, Header: trans('field.stock'), accessor: row => (FxTable.cellTo2r00(row["nuStock"])), width: '10%', align: 'right' });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: "ID", accessor: "inStock", show: false });
      cols.push({ id: "inPs", Header: "inPs", accessor: "inPs", show: false });
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '50%' });
      cols.push({ id: id++, Header: trans('field.group'), accessor: "txTypePsGroup", width: '30%' });
      cols.push({ id: id++, Header: trans('field.stock'), accessor: row => (FxTable.cellTo2r00(row["nuStock"])), width: '20%', align: 'right' });
    }
    cols.push({ id: "R", Header: "boStockBelowMin", accessor: row => Boolean(row["boStockBelowMin"] === '1'), show: false });
    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({id: 'nuStockMin', name: trans('field.stock_min'), checked: hasColumnStockMin() ? '1' : '0'}); 
    cols.push({id: 'nuStockMax', name: trans('field.stock_max'), checked: hasColumnStockMax() ? '1' : '0'}); 
    cols.push({id: 'txSupplier', name: trans('field.supplier'), checked: hasColumnSupplier() ? '1' : '0'}); 
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxStockroomList.addcol_nuStockMin', options.includes('nuStockMin') ? '1' : '0');
    localStorage.setItem('MxStockroomList.addcol_nuStockMax', options.includes('nuStockMax') ? '1' : '0');
    localStorage.setItem('MxStockroomList.addcol_txSupplier', options.includes('txSupplier') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = `${trans('field.ps')} / ${trans('field.code')}`;
    if (hasColumnSupplier()) tip = tip + `${' / ' + trans('field.supplier')}`;
    
    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.stockroom')}: ${getStoreValue('StoreSession', 'txBranchId')}`}
        xref={r_list}
        id="MxStockroomList"
        //title={trans('field.stockroom')}
        tooltip={getTooltip()}
        fullscreen
        help="stock"
        url="stock"
        onOpenForm={openForm}
        hasButtonAdd={false}
        colorRefs={ [ 'table_cell_red|' + trans('field.stock_below_min') ] }
        columns={getTableColumns()}
        optionalColumns={getTableOptionalCols()}
        optionalSorting={[
          { id: 'txPs', type: trans('field.ps') },
          { id: 'txPsCode', type: trans('field.code') },
        ]}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* // 2023-12-11 - daq: para simplificar, el inventario se cargará sólo con el campo Stock.
            {isFullAccess() && <CxIconButton type="move" classType="ibs_iconbutton_toolbar" onClick={askMoveStockTakingToStock} 
              tooltip={trans('field.move_x_to_x','field.stock_taking','field.stock')} />}
            */}              
          </div>
        }
      />

{/* 
      <CxDialog title={trans('msg.confirm_operation')}
        open={dlgMoveStockTakingToStock}
        onOk={moveStockTakingToStock}
        onCancel={() => setDlgMoveStockTakingToStock(false)}
        height='200px'
      >
        {trans('msg.ask_move_stock_taking_to_stock')}
      </CxDialog>
 */}

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_stock_taking_is_empty} severity="error" value={trans('msg.stock_taking_is_empty')} />

    </div>
  );

}

export default MxStockroomList;
