import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { RgbColorPicker } from "react-colorful";
import { useTranslation } from '../hx/useTranslation';
import CxDialog from './CxDialog';
import { useStyles } from "./StylesCx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';


const useStylesLocal = makeStyles({
  ctl_label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 2px',
    cursor: 'default',
    //color: 'var(--ibs_color_gray)',
    "& .MuiTypography-body1": {
      fontSize: 'var(--ibs_control_label_font_size_radio)',
    },
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },
});

const CxColor = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const classesL = useStylesLocal(props);
  const defaultColor = '255-255-255';
  const [myValue, setMyValue] = React.useState(defaultColor);
  const [labelColor, setLabelColor] = React.useState('var(--ibs_color_gray)');
  const [pickedColor, setPickedColor] = React.useState({ r: 200, g: 150, b: 35 });
  const [dlgPicker, setDlgPicker] = React.useState(false);
  const [isRequired, setIsRequired] = React.useState(false);
  const [wasChanged, setChanged] = React.useState(false);

  const rf_label = React.useRef('');

  React.useEffect(() => {
    setIsRequired(props.required);

    // eslint-disable-next-line
  }, []);

  const getRgbString = (val) => {
    let rv = `rgb(${val.replaceAll('-', ',')})`;
    return rv;
  };

  const doClick = (e) => {
    setDlgPicker(true);
  };

  const doOk = () => {
    setMyValue(`${pickedColor.r}-${pickedColor.g}-${pickedColor.b}`);
    setDlgPicker(false);
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, getDbfield, isEmpty, wasChanged, setChanged
    };
  });
  const getType = () => { return 'CxColor' };
  const setVal = (newValue) => {
    setMyValue(newValue ? newValue : defaultColor);
    setChanged(true);
  };
  const getVal = () => { return myValue };
  const getIsRequired = () => { return isRequired };
  const getLabel = () => { return '' };
  const getDbfield = () => { return props.dbfield };
  const isEmpty = () => { return myValue.length === 0 };

  const doFocus = (action) => {
    if (action === 'focused') setLabelColor('var(--ibs_color_focused)');
    if (action === 'unfocused') setLabelColor('var(--ibs_color_unfocused)');
  }

  return (
    <div id='div_CxColor' className={classes.frame}>
      <div className={classes.frame_content}>
        <FormControlLabel ref={rf_label}
          classes={{ root: classesL.ctl_label, }}
          style={{ color: labelColor }}
          label={props.label}
          labelPlacement="start"
          control={
            <div onClick={(e) => doClick(e)}
              style={{
                background: getRgbString(myValue),
                border: '1px solid var(--ibs_color_gray)',
                borderRadius: '4px',
                height: '36px',
                width: '24px',
                cursor: 'pointer',
              }}
            />
          }
          onFocus={() => doFocus('focused')}
          onBlur={() => doFocus('unfocused')}
        />

        <CxDialog
          title={trans('field.color_refs')}
          onCancel={() => setDlgPicker(false)}
          onOk={doOk}
          open={dlgPicker}
          height='330px' width='400px'>

          <RgbColorPicker color={pickedColor} onChange={setPickedColor} />

        </CxDialog>

      </div>
    </div>
  );

};

export default CxColor;
