import React, { useImperativeHandle } from "react";
import '../../styles/main.css';
import CxSnackbar from './CxSnackbar';
import { useTranslation } from '../hx/useTranslation';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';


const CxSpeech = props => {
  const [{ trans }] = useTranslation();
  const { id, setVal, onSpeechFinish } = props;

  const r_active_id = React.useRef(null); //sirve para evitar que si hay varios inputs con reconocimiento de voz, escriba a todos juntos en lugar que al que corresponde.
  const r_snack_speak_now = React.useRef(null);
  const r_snack_browser_does_not_support = React.useRef(null);

  const {
    transcript,
    listening,
    //resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_active_id.current===id) {
      setVal(transcript);
    }
    // eslint-disable-next-line
  }, [transcript]);

  React.useEffect(() => {
    if (!listening) {
      r_active_id.current = '';
      if (onSpeechFinish) {
        onSpeechFinish();
      }
    }
    // eslint-disable-next-line
  }, [listening]);

  useImperativeHandle(props.xref, () => {
    return {
      getType, start, 
    };
  });
  const getType = () => { return 'CxSpeech' };

  const start = (activeId) => {
    r_active_id.current = activeId;
    SpeechRecognition.startListening({ continuous: false });
    if (browserSupportsSpeechRecognition) {
      r_snack_speak_now.current.setOpen(true);
    } else {
      r_snack_browser_does_not_support.current.setOpen(true);
    }
  };

  return (
    <>
      <CxSnackbar xref={r_snack_speak_now} severity="warning" value={trans('msg.speak_now')} />
      <CxSnackbar xref={r_snack_browser_does_not_support} severity="error" value={trans('msg.browser_doesnt_support_x', 'field.voice_recognition')} />
    </>
  );

};

export default CxSpeech;
