import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useNav } from '../../hx/useNav';
import * as MxSlsDocFormH1 from './MxSlsDocFormH1';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreSlsLst, StoreSlsDoc, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';

const MxSlsReceiptInvoicesList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  const [boUpdateDelete, setBoUpdateDelete] = React.useState(null);
  const [inReceipt, setInReceipt] = React.useState(null);

  const r_list = React.useRef(null);

  React.useEffect(() => {
    setBoUpdateDelete(storeSlsDoc.boUpdateDelete);
    setInReceipt(storeSlsDoc.inReceipt);

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    FxLog.infox('MxSlsReceiptInvoicesList.openForm', '...row.original.inInvoice:', row.original.inInvoice);
    clearStore('StoreSlsLst', storeSlsLst);
    storeSlsLst.listType = 'i';
    storeSlsLst.listTypeBackground = 'var(--ibs_color_blue_light)';
    storeSlsLst.table = 'n_invoice';
    storeSlsLst.table_idField = 'inInvoice';
    storeSlsLst.table_txField = 'txInvoice';
    storeSlsLst.table_dtField = 'dtInvoice';
    storeSlsLst.table_item = 'n_invoice_item';
    storeSlsLst.table_item_idField = 'inInvoiceItem';
    storeSlsLst.table_paym_idField = 'inPayment';
    setStoreSlsLst(storeSlsLst);

    clearStore('StoreSlsDoc', storeSlsDoc);
    storeSlsDoc.boFirstRender = true;
    storeSlsDoc.boDataChanged = '0';
    storeSlsDoc.docType = 'i';
    storeSlsDoc.inXXX = row.original.inInvoice;
    storeSlsDoc.boUpdateDelete = boUpdateDelete;
    Promise.all([MxSlsDocFormH1.load(storeSlsLst, storeSlsDoc)]).then((result) => {
      setStoreSlsDoc(storeSlsDoc);
      navTo("sls-doc-form");
    });
  }

  const goingBack = async () => {
    FxLog.infox('MxSlsReceiptInvoicesList.goingBack', '...inReceipt', inReceipt);
    clearStore('StoreSlsLst', storeSlsLst);
    storeSlsLst.listType = 'r';
    storeSlsLst.listTypeBackground = 'var(--ibs_color_green_light)';
    storeSlsLst.table = 'n_receipt';
    storeSlsLst.table_idField = 'inReceipt';
    storeSlsLst.table_txField = 'txReceipt';
    storeSlsLst.table_dtField = 'dtReceipt';
    storeSlsLst.table_item = 'n_invoice_item';
    storeSlsLst.table_item_idField = 'inInvoiceItem';
    storeSlsLst.table_paym_idField = 'inPayment';
    setStoreSlsLst(storeSlsLst);

    clearStore('StoreSlsDoc', storeSlsDoc);
    storeSlsDoc.boFirstRender = true;
    storeSlsDoc.boDataChanged = '0';
    storeSlsDoc.docType = 'r';
    storeSlsDoc.inXXX = inReceipt;
    storeSlsDoc.boUpdateDelete = boUpdateDelete;
    return Promise.all([MxSlsDocFormH1.load(storeSlsLst, storeSlsDoc)]).then((result) => {
      return Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
        FxLog.infox('MxSlsReceiptInvoicesList.goingBack', '...in promise. storeSlsDoc:', storeSlsDoc);
      });
    });
    
  }

  const getTableColumns = () => {
    let cols = [];
    try {
      cols.push({ id: "0", Header: "ID", accessor: "inInvoice", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: "1", Header: trans('field.doc'), accessor: "txInvoice", width: '10%' });
        cols.push({ id: "2", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '10%' });
        cols.push({ id: "3", Header: trans('field.taxable'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTaxable"])), align: 'right', width: '10%' });
        cols.push({ id: "4", Header: trans('field.discount'), accessor: row => (FxTable.cellTo2r00(row["nuTotalDiscount"])), align: 'right', width: '10%' });
        cols.push({ id: "5", Header: trans('field.tax'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTax"])), align: 'right', width: '10%' });
        cols.push({ id: "6", Header: trans('field.wtax_perc'), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtaxPerc"])), align: 'right', width: '10%' });
        cols.push({ id: "7", Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), align: 'right', width: '10%' });
        cols.push({ id: "8", Header: trans('field.paid'), accessor: row => (FxTable.cellTo2r00(row["nuTotalPayment"])), align: 'right', width: '10%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: "1", Header: trans('field.doc'), accessor: "txInvoice", width: '10%' });
        cols.push({ id: "2", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '10%' });
        cols.push({ id: "7", Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), align: 'right', width: '10%' });
      }

    } catch (error) {
      FxLog.errorx('MxSlsReceiptInvoicesList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        xref={r_list}
        moduleName={`${trans('field.invoices')}`}
        tooltip={`${trans('field.invoices')}`}
        fullscreen
        url="sls/doc/get_invoices_from_receipt"
        filter={`n_receipt.inReceipt='${storeSlsDoc.inReceipt}'`}
        onOpenForm={openForm}
        columns={getTableColumns()}
        //disabled={!storeSlsDoc.boUpdateDelete}
        hasSearchBox={false}
        hasButtonAdd={false}
        onGoBack={goingBack}
      />

    </div>
  );

}

export default MxSlsReceiptInvoicesList;
