import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettPosSeries } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsSeriesList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettPosSeries, setStoreSettPosSeries] = useSessionStorage('StoreSettPosSeries', StoreSettPosSeries);

  React.useEffect(() => {
    clearStore('StoreSettPosSeries', storeSettPosSeries);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPosSeries.itSeries = null;
    } else {
      storeSettPosSeries.itSeries = row.original.itSeries;
    }
    setStoreSettPosSeries(storeSettPosSeries);
    navTo("sett-sls-series-form");
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.series_p')}
        fullscreen
        hasSearchBox={false}
        url="sett/series"
        filter={`t_series.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itSeries", show: false },
          { id: "1", Header: trans('field.series'), accessor: "txTypeSeries", width: '50%' },
          { id: "2", Header: trans('field.notes'), accessor: "txNotes", width: '50%' },
        ]}
      />
    </div>
  );

}

export default MxSettSlsSeriesList;
