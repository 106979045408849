import React from 'react';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import * as FxTable from '../../fx/FxTable';
import { isApp } from '../../gx/GxStore';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxIconButton from '../../cx/CxIconButton';
import CxSnackbar from '../../cx/CxSnackbar';
import * as FxFetch from '../../fx/FxFetch';


const MxClientList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ navTo }] = useNav();

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);

  const r_list = React.useRef(null);
  let r_snack_error = React.useRef(null);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    clearStore('StoreClient', storeClient);
    // eslint-disable-next-line
  }, []);

  const hasColumnContact = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txContact') === '1') };
  const hasColumnCode = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txCode') === '1') };
  const hasColumnReference = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txReference') === '1') };
  const hasColumnCity = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txCity') === '1') };
  // OPCIONALES EN VETT/SPAW, OBLIGATORIO EN OTROS.
  const hasColumnEmail = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txEmail') === '1' || (!isApp('vett') && !isApp('spaw'))) };
  const hasColumnMobile = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txMobile') === '1' || (!isApp('vett') && !isApp('spaw'))) };
  const hasColumnPhone1 = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txPhone1') === '1' || (!isApp('vett') && !isApp('spaw'))) };
  // SÓLO EN VETT/SPAW
  const hasColumnAnimalNumber = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txNumber') === '1') }; // VETT, SPAW
  const hasColumnAnimalChip = () => { return Boolean(localStorage.getItem('MxClientList.addcol_txChip') === '1') }; // VETT, SPAW

  const openForm = async (op, row) => {
    FxLog.infox('MxClientList.openForm', '...row', row);

    if (row === null) {
      storeClient.inClient = null;
      storeClient.txClient = null;
      setStoreClient(storeClient);
      navTo("client-form");

    } else {
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      setStoreClient(storeClient);

      if ((isApp('vett') || isApp('spaw'))) {
        if (row.col_id==='txClient') {
          navTo("client-form");
        }
        if (row.col_id==='txAnimal') {
          storeClient.inAnimal = row.original.animal_inAnimal;
          storeClient.txAnimal = row.original.animal_txAnimal;
          FxLog.infox('MxClientList.openForm', '...storeClient.inAnimal:', storeClient.inAnimal);
          if (!storeClient.inAnimal) {
            storeClient.inAnimal = null;
            storeClient.txAnimal = null;
          }
          setStoreClient(storeClient);
          navTo("client-form");
          navTo("animal-form");
        }
      } else {
        navTo("client-form");
      }
  
    }

  }

  const doLastClientSearch = () => {
    let lastInClient = (localStorage.getItem('MxClientForm.last_client') || '');
    if (lastInClient) {
      Promise.all([FxFetch.doJsonX("general/r", { table: 'n_client', field: 'txClient', where: `inClient='${lastInClient}'` } )]).then((result) => {
        if (result[0]) {
          let lastTxClient = result[0].theValue;
          r_list.current.getTable().setSearchForced(lastTxClient);
        }
      });
    } else {
      r_snack_error.current.setVal(trans('msg.no_x_file_opened','field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  const getCursor = () => {
    if ((isApp('vett') || isApp('spaw'))) {
      return 'notallowed';
    } else {
      return '';
    }
  }

  const getTableColumns = () => {
    let cols = [];
    try {
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inClient", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: "txClient", Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '30%' });
        cols.push({ id: "txContact", Header: trans('field.contact'), accessor: row => r_list.current.getTable().highlightText(row["txContact"]), width: '15%', cursor: getCursor(), show: hasColumnContact() });
        cols.push({ id: "txCode", Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txCode"]), width: '15%', cursor: getCursor(), show: hasColumnCode() });
        cols.push({ id: "txReference", Header: trans('field.reference'), accessor: row => r_list.current.getTable().highlightText(row["txReference"]), width: '15%', cursor: getCursor(), show: hasColumnReference() });
        cols.push({ id: "txCity", Header: trans('field.city'), accessor: row => r_list.current.getTable().highlightText(row["txCity"]), width: '15%', cursor: getCursor(), show: hasColumnCity() });
        cols.push({ id: "txEmail", Header: trans('field.email'), accessor: row => r_list.current.getTable().highlightText(row["txEmail"]), width: '15%', cursor: getCursor(), show: hasColumnEmail() });
        cols.push({ id: "txMobile", Header: trans('field.mobile'), accessor: row => r_list.current.getTable().highlightText(row["txMobile"]), width: '15%', cursor: getCursor(), show: hasColumnMobile() });
        cols.push({ id: "txPhone1", Header: trans('field.phone1'), accessor: row => r_list.current.getTable().highlightText(row["txPhone1"]), width: '15%', cursor: getCursor(), show: hasColumnPhone1() });
        if ((isApp('vett') || isApp('spaw'))) {
          cols.push({ id: "inAnimal", Header: '_inAnimal', accessor: row => row["animal_inAnimal"], show: false });
          cols.push({ id: "txAnimal", Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '15%' });
          cols.push({ id: "txNumber", Header: trans('field.number'), accessor: row => r_list.current.getTable().highlightText(row["animal_txNumber"]), width: '15%', cursor: getCursor(), show: hasColumnAnimalNumber() });
          cols.push({ id: "txChip", Header: trans('field.chip'), accessor: row => r_list.current.getTable().highlightText(row["animal_txChip"]), width: '15%', cursor: getCursor(), show: hasColumnAnimalChip() });
          cols.push({ id: id++, Header: trans('field.species'), accessor: "animal_txTypeAnimalSpecies", width: '15%', cursor: getCursor() });
          cols.push({ id: id++, Header: trans('field.breed'), accessor: "animal_txTypeAnimalBreed", width: '15%', cursor: getCursor() });
        } else {
        }
        cols.push({ id: id++, Header: trans('field.balance'), accessor: row => FxTable.cellTo2r00(row["theBalance"]), width: '10%', cursor: getCursor(), align: 'right' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: "txClient", Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '70%' });
        if ((isApp('vett') || isApp('spaw'))) {
          cols.push({ id: "inAnimal", Header: '_inAnimal', accessor: row => row["animal_inAnimal"], show: false });
          cols.push({ id: "txAnimal", Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '30%' });
        } else {
          cols.push({ id: "txMobile", Header: trans('field.mobile'), accessor: "txMobile", width: '30%' });
        }

      }
      cols.push({ id: "B", Header: "cp_access", accessor: row => Boolean(row["inUser"]), show: false });
      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false });
      cols.push({ id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false });

    } catch (error) {
      FxLog.errorx('MxClientList.getTableColumns', error)
    }
    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txContact', name: trans('field.client') + '-' + trans('field.contact'), checked: hasColumnContact() ? '1' : '0' });
    cols.push({ id: 'txCode', name: trans('field.client') + '-' + trans('field.code'), checked: hasColumnCode() ? '1' : '0' });
    cols.push({ id: 'txReference', name: trans('field.client') + '-' + trans('field.reference'), checked: hasColumnReference() ? '1' : '0' });
    cols.push({ id: 'txCity', name: trans('field.client') + '-' + trans('field.city'), checked: hasColumnCity() ? '1' : '0' });
    if ((isApp('vett') || isApp('spaw'))) {
      cols.push({ id: 'txEmail', name: trans('field.client') + '-' + trans('field.email'), checked: hasColumnEmail() ? '1' : '0' });
      cols.push({ id: 'txMobile', name: trans('field.client') + '-' + trans('field.mobile'), checked: hasColumnMobile() ? '1' : '0' });
      cols.push({ id: 'txPhone1', name: trans('field.client') + '-' + trans('field.phone1'), checked: hasColumnPhone1() ? '1' : '0' });
      cols.push({ id: 'txNumber', name: trans('field.patient') + '-' + trans('field.number'), checked: hasColumnAnimalNumber() ? '1' : '0' });
      cols.push({ id: 'txChip', name: trans('field.patient') + '-' + trans('field.chip'), checked: hasColumnAnimalChip() ? '1' : '0' });
    } else {
    }
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxClientList.addcol_txContact', options.includes('txContact') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txCode', options.includes('txCode') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txReference', options.includes('txReference') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txCity', options.includes('txCity') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txEmail', options.includes('txEmail') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txMobile', options.includes('txMobile') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txPhone1', options.includes('txPhone1') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txNumber', options.includes('txNumber') ? '1' : '0');
    localStorage.setItem('MxClientList.addcol_txChip', options.includes('txChip') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = '';
    if ((isApp('vett') || isApp('spaw'))) {
      tip = `${trans('field.client')}${' / ' + trans('field.patient')}`;
      if (hasColumnAnimalNumber()) tip = tip + `${' / ' + trans('field.number')}`;
      if (hasColumnAnimalChip()) tip = tip + `${' / ' + trans('field.chip')}`;
    } else {
      tip = `${trans('field.client')}`;
    }
    if (hasColumnContact()) tip = tip + `${' / ' + trans('field.contact')}`;
    if (hasColumnCode()) tip = tip + `${' / ' + trans('field.code')}`;
    if (hasColumnReference()) tip = tip + `${' / ' + trans('field.reference')}`;
    if (hasColumnCity()) tip = tip + `${' / ' + trans('field.city')}`;
    if (hasColumnEmail()) tip = tip + `${' / ' + trans('field.email')}`;
    if (hasColumnMobile()) tip = tip + `${' / ' + trans('field.mobile')}`;
    if (hasColumnPhone1()) tip = tip + `${' / ' + trans('field.phone1')}`;

    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.clients')}
        xref={r_list}
        id="MxClientList"
        tooltip={getTooltip()}
        fullscreen
        showtip
        help="clients"
        url="clients"
        onOpenForm={openForm}
        colorRefs={[
          'table_cell_blue|' + trans('field.access_to_x', 'field.client_portal'),
          'table_cell_gray|' + trans('field.inactive'),
          'table_cell_red|' + trans('field.debtor')
        ]}
        columns={getTableColumns()}
        optionalColumns={getTableOptionalCols()}
        optionalSorting={[
          { id: 'txClient', type: trans('field.client') },
          { id: 'txCode', type: trans('field.code') },
          { id: 'txReference', type: trans('field.reference') },
        ]}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="last_client_search" classType="ibs_iconbutton_toolbar" onClick={doLastClientSearch} tooltip={trans('field.search_last_x', 'field.client')} />
            {isApp('opti') && <CxIconButton type="history" classType="ibs_iconbutton_toolbar" onClick={() => navTo("client-activity-opti-list")} tooltip={trans('field.activity_history')} />}
            {isApp('vett') && <CxIconButton type="history" classType="ibs_iconbutton_toolbar" onClick={() => navTo("client-activity-vett-list")} tooltip={trans('field.activity_history')} />}
          </div>
        }
        speechExternal
      />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxClientList;
