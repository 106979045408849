import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import CxTable from '../../cx/CxTable';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButton from '../../cx/CxIconButton';
import * as FxTable from '../../fx/FxTable';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClientActivityVettLst, StoreClient, StoreVacc, StoreGrooming, StoreCns } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxToolbar from '../../cx/CxToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { TbVaccine, TbStethoscope, TbCut } from "react-icons/tb";
import * as FxFetch from '../../fx/FxFetch';


const useStyles = makeStyles({
  toggleButtonGroup: {
    background: 'var(--ibs_color_gray_light)',
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'var(--ibs_color_white)',
    },
    '& .MuiToggleButton-root': {
      padding: '0px 10px 0px 10px',
    },
  },
});

const MxClientActivityVettList = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const rf_table = React.useRef(null);
  let r_snack_error = React.useRef(null);

  const r_snack_required = React.useRef(null);

  const [storeClientActivityVettLst, setStoreClientActivityVettLst] = useSessionStorage('StoreClientActivityVettLst', StoreClientActivityVettLst);
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeVacc, setStoreVacc] = useSessionStorage('StoreVacc', StoreVacc);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);
  const [storeGrooming, setStoreGrooming] = useSessionStorage('StoreGrooming', StoreGrooming);

  const [docTitle, setDocTitle] = React.useState(trans('field.cnss'));

  React.useEffect(() => {
    changeType(null, storeClientActivityVettLst.listType || 'c');

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    StoreClient.inClient = row.original.inClient;
    StoreClient.inAnimal = row.original.inAnimal;
    StoreClient.txClient = row.original.txClient;
    StoreClient.txAnimal = row.original.txAnimal;
    Promise.all([setStoreClient(StoreClient)]).then((result) => {
      switch (storeClientActivityVettLst.listType) {
        case 'v': openFormVacc(op, row); break;
        case 'c': openFormCns(op, row); break;
        case 'g': openFormGroo(op, row); break;
        default: break;
      }
    });

  }

  const openFormVacc = async (op, row) => {
    if (row === null) {
      storeVacc.inVacc = null;
    } else {
      storeVacc.inVacc = row.original._id;
    }
    setStoreVacc(storeVacc);
    navTo("animal-vacc-form");
  }

  const openFormCns = async (op, row) => {
    if (row === null) {
      storeCns.inCns = null;
    } else {
      storeCns.inCns = row.original._id;
    }
    setStoreCns(storeCns);
    if (row.original.txType === 'G') navTo("animal-cns-grl-form");
    if (row.original.txType === 'H') navTo("animal-cns-hos-form");
  }

  const openFormGroo = async (op, row) => {
    if (row === null) {
      storeGrooming.inGrooming = null;
    } else {
      storeGrooming.inGrooming = row.original._id;
    }
    setStoreGrooming(storeGrooming);
    navTo("animal-groo-form");
  }

  const changeType = (event, newType) => {
    clearStore('StoreClient', storeClient);
    clearStore('StoreVacc', storeVacc);
    clearStore('StoreCns', storeCns);
    clearStore('StoreGrooming', storeGrooming);

    if (newType === null) {
      return;
    }
    let hasTypeChanged = false;
    if (newType !== storeClientActivityVettLst.listType) {
      hasTypeChanged = true;
    }
    switch (newType) {
      case 'v':
        storeClientActivityVettLst.listType = 'v';
        storeClientActivityVettLst.listTypeBackground = 'var(--ibs_color_yellow_light)';
        storeClientActivityVettLst.table = 'n_vacc';
        storeClientActivityVettLst.table_idField = 'inVacc';
        setStoreClientActivityVettLst(storeClientActivityVettLst);
        setDocTitle(trans('field.vaccs'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/vett/typelist/v');
        break;
      case 'c':
        storeClientActivityVettLst.listType = 'c';
        storeClientActivityVettLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storeClientActivityVettLst.table = 'n_cns';
        storeClientActivityVettLst.table_idField = 'inCns';
        setStoreClientActivityVettLst(storeClientActivityVettLst);
        setDocTitle(trans('field.cnss'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/vett/typelist/c');
        break;
      case 'g':
        storeClientActivityVettLst.listType = 'g';
        storeClientActivityVettLst.listTypeBackground = 'var(--ibs_color_green_light)';
        storeClientActivityVettLst.table = 'n_grooming';
        storeClientActivityVettLst.table_idField = 'inGrooming';
        setStoreClientActivityVettLst(storeClientActivityVettLst);
        setDocTitle(trans('field.grooming'));
        if (hasTypeChanged) rf_table.current.redisplay('activity/vett/typelist/g');
        break;

      default:
        FxLog.errorx("MxClientActivityVettList.openForm", "Cannot find type!!! -> " + newType);
        break;
    }
  };

  const getCnsOptions = () => {
    return [
      { id: 'G', text: trans('field.cns_grl') },
      { id: 'H', text: trans('field.cns_hos') },
    ];
  }

  const doLastClientSearch = () => {
    let lastInClient = (localStorage.getItem('MxClientForm.last_client') || '');
    if (lastInClient) {
      Promise.all([FxFetch.doJsonX("general/r", { table: 'n_client', field: 'txClient', where: `inClient='${lastInClient}'` } )]).then((result) => {
        if (result[0]) {
          let lastTxClient = result[0].theValue;
          rf_table.current.setSearchForced(lastTxClient);
        }
      });
    } else {
      r_snack_error.current.setVal(trans('msg.no_x_file_opened','field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: storeClientActivityVettLst.table_idField, show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.patient'), accessor: row => rf_table.current.highlightText(row["txAnimal"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
      
      switch (storeClientActivityVettLst.listType) {
        case 'v':
          cols.push({ id: id++, Header: trans('field.next'), accessor: row => (FxTable.cellToLocalDate(row["dtNext"])), width: '10%' });
          cols.push({ id: id++, Header: trans('field.ps'), accessor: row => row["txPs"], width: '50%' });
          break;
        case 'c':
          cols.push({ id: id++, Header: trans('field.type'), accessor: row => FxTable.replace(row["txType"], getCnsOptions()), width: '10%' });
          cols.push({ id: id++, Header: trans('field.remarks'), accessor: row => row["txRemarks"], width: '50%' });
          break;
        case 'g':
          cols.push({ id: id++, Header: trans('field.groo_pickup'), accessor: row => (FxTable.cellToLocalTime(row["dtTo"])), width: '10%' });
          cols.push({ id: id++, Header: trans('field.remarks'), accessor: row => row["txRemarks"], width: '50%' });
          break;
  
        default:
          break;
      }

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.patient'), accessor: row => rf_table.current.highlightText(row["txAnimal"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
    }
    return cols;
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.activity_history')}: ${docTitle}`}
        background={storeClientActivityVettLst.listTypeBackground}
        addToolbarButtons={
          <>
            <CxIconButton type="last_client_search" classType="ibs_iconbutton_toolbar" onClick={doLastClientSearch} tooltip={trans('field.search_last_x','field.client')} />
            <ToggleButtonGroup value={storeClientActivityVettLst.listType} exclusive onChange={changeType} className={classes.toggleButtonGroup} >
              <ToggleButton value="v">
                <TbVaccine fontSize="21px" style={{ color: 'var(--ibs_color_toggle_yellow)' }} />
              </ToggleButton>
              <ToggleButton value="c">
                <TbStethoscope fontSize="21px" style={{ color: 'var(--ibs_color_toggle_blue)' }} />
              </ToggleButton>
              <ToggleButton value="g">
                <TbCut fontSize="21px" style={{ color: 'var(--ibs_color_toggle_green)' }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        }
      />

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxTable
          xref={rf_table}
          id="MxClientActivityVettList"
          tooltip={`${trans('field.client')} / ${trans('field.patient')}`}
          url={`activity/vett/typelist/${storeClientActivityVettLst.listType || 'c'}`}
          fullscreen
          hasSearchBox
          columns={getTableColumns()}
          onOpenForm={openForm}
          optionalFromTo={true}
          onOptions={() => {}} // para que muestre botón de opciones.
        >
        </CxTable>
      </div>
      
      <CxSnackbar xref={r_snack_required} severity="error" />

      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );

}

export default MxClientActivityVettList;
