/* Funciones de cálculo de la ficha del ps */
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';


/** Calcula el margen de ganancia.
 * http://math.tutorvista.com/number-system/markup.html
 * https://www.freshbooks.com/tools/markup-calculator#:~:text=Simply%20take%20the%20sales%20price,50%20x%20100%20%3D%2050%25.
 *
 * @param price precio de venta neto
 * @param cost costo neto
 * @param taxPercS iva venta
 * @param taxPercP iva compra
 * @return porcentaje de margen
 */
export const getMarkupPerc = (price, cost, taxPercS, taxPercP, surchargePercS, surchargePercP) => {
  let rv = 0;
  try {
    if (cost === 0) return 100;
    let taxS = FxNum.to4r(taxPercS === 0 ? 0 : price * taxPercS / 100);
    let taxP = FxNum.to4r(taxPercP === 0 ? 0 : cost * taxPercP / 100);
    let surS = FxNum.to4r(surchargePercS === 0 ? 0 : price * surchargePercS / 100);
    let surP = FxNum.to4r(surchargePercP === 0 ? 0 : cost * surchargePercP / 100);
    let totalPrice = FxNum.to4r(price + taxS + surS);
    let totalCost = FxNum.to4r(cost + taxP + surP);
    rv = FxNum.to4r(100 * ((totalPrice - totalCost) / totalCost));

  } catch (error) {
    FxLog.errorx('MxPsCalc.getMarkupPerc', error)
  }
  return rv;
}

export const getPrice = (markup, cost, taxPercS, taxPercP, surchargePercP) => {
  let rv = 0;
  try {
    if (cost === 0) return 100;
    let taxP = FxNum.to4r(taxPercP === 0 ? 0 : cost * taxPercP / 100);
    let surP = FxNum.to4r(surchargePercP === 0 ? 0 : cost * surchargePercP / 100);
    let totalCost = FxNum.to4r(cost + taxP + surP);
    let price = FxNum.to4r((markup / 100 * totalCost) + totalCost);
    rv = FxNum.to4r(price / parseFloat("1." + taxPercS)); //quitamos iva.

  } catch (error) {
    FxLog.errorx('MxPsCalc.getPrice', error)
  }
  return rv;
}

export const addTax = (amountWoTax, taxPerc) => {
  let rv = 0;
  try {
    if (amountWoTax === 0) return 0;
    rv = FxNum.to4r((amountWoTax * taxPerc / 100) + amountWoTax);

  } catch (error) {
    FxLog.errorx('MxPsCalc.addTax', error)
  }
  return rv;
}

export const removeTax = (amountIncTax, taxPerc) => {
  let rv = 0;
  try {
    rv = FxNum.to4r((100 * amountIncTax) / (taxPerc + 100));

  } catch (error) {
    FxLog.errorx('MxPsCalc.removeTax', error)
  }
  return rv;
}
