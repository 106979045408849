import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import CxIconButton from './CxIconButton';
import { useTranslation } from '../hx/useTranslation';
import { useImageIO } from '../hx/useImageIO';
import { Stage, Layer, Line, Image as ImageKonva } from 'react-konva';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useWindowSize } from '../hx/useWindowSize';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    //width: '100%',
    gap: 3,
    border: '1px solid var(--ibs_color_gray)',
    borderRadius: '2px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 1,
    padding: '0px 0 0px 0',
    background: 'var(--ibs_color_gray_light)',
  },
});

const CxImageEditor = props => {
  const [{ trans }] = useTranslation();
  const [{ imageLoad, imageSave, imageResize, imageCreateBlank }] = useImageIO();
  const classes = useStyles();
  const size = useWindowSize();

  const [img, setImg] = React.useState(null);

  const rf_inputfile = React.useRef(null);
  const rf_container = React.useRef(null);
  const rf_scroll = React.useRef(null);
  const rf_stage = React.useRef(null);
  const rf_layer = React.useRef(null);
  const rf_image = React.useRef(null);

  const [color, setColor] = React.useState('');
  const [lines, setLines] = React.useState([]);
  const isDrawing = React.useRef(false);


  React.useEffect(() => {
    if (props.blank) {
      loadCanvas(imageCreateBlank(props.blank.width, props.blank.height));
    }
    if (props.pen_black_default) {
      setColor('black');
    }

    Promise.all([imageLoad(props.url, props.inXXX)]).then((result) => {
      loadCanvas(result[0]);
    });

    // eslint-disable-next-line
  }, []);

  const loadCanvas = (base64) => {
    let htmlImg = new Image();
    htmlImg.src = base64;
    htmlImg.onload = function (e) {
      //cvs.height = img.height * cvs.width / img.width;
      setImg(htmlImg);
    }
  }

  useImperativeHandle(props.xref, () => {
    return {
      getType, save,
    };
  });
  const getType = () => { return 'CxImageEditor' };
  const save = (inXXX) => {
    if (rf_image.current) {
      imageSave(props.url, inXXX, rf_image.current.getStage().toDataURL());
    } else {
      imageSave(props.url, inXXX, null);
    }
  };
  const clear = () => {
    rf_inputfile.current.value = '';
    clearLayer();
    Promise.all([setImg(null)]).then((result) => {
      if (props.blank) {
        loadCanvas(imageCreateBlank(props.blank.width, props.blank.height));
      }
    });
  };
  const clearLayer = () => {
    setLines([]);
    if (rf_layer.current) {
      rf_layer.current.draw();
    }
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (file) {
      if (file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')) {
        // reducimos tamaño del archivo para no ocupar demasiado en db.
        Promise.all([imageResize(file)]).then((result) => {
          loadCanvas(result[0]);
        });

      } else {
        clear();
      }
    }
  }

  const handleMouseDown = (e) => {
    if (color && size.isLarge) {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      setLines([...lines, { color: color, points: [pos.x, pos.y] }]);
    }
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <div ref={rf_container} className={classes.container} >
      <div className={classes.buttonContainer}>
        <input type="file" accept="image/jpeg, image/jpg, image/png" ref={rf_inputfile} style={{ display: "none" }} onChange={onChangeFile} />
        {props.can_search && <CxIconButton type="search_image" classType="ibs_iconbutton_toolbar" onClick={() => rf_inputfile.current.click()} tooltip={trans('field.file_upload')} />}
        <CxIconButton type="delete2" classType="ibs_iconbutton_toolbar" onClick={() => clear()} tooltip={trans('field.delete')} />
        {props.pen_black && size.isLarge && <CxIconButton type="pencil" classType="ibs_iconbutton_toolbar" onClick={() => setColor('black')} style={{ color: 'black' }} />}
        {props.pen_red && size.isLarge && <CxIconButton type="pencil" classType="ibs_iconbutton_toolbar" onClick={() => setColor('red')} style={{ color: 'red' }} />}
        {props.pen_blue && size.isLarge && <CxIconButton type="pencil" classType="ibs_iconbutton_toolbar" onClick={() => setColor('blue')} style={{ color: 'blue' }} />}
        {props.pen_yellow && size.isLarge && <CxIconButton type="pencil" classType="ibs_iconbutton_toolbar" onClick={() => setColor('yellow')} style={{ color: 'yellow' }} />}
      </div>
      {img && <ScrollContainer
        ref={rf_scroll}
        hideScrollbars={true}
        style={{
          width: size.isLarge ? (img.width) : 'var(--ibs_control_width_lg)',
          height: size.isLarge ? (img.height) : 'var(--ibs_control_width_lg)',
        }}>
        <Stage ref={rf_stage}
          width={img.width}
          height={img.height}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
        //style={{ background: 'yellow' }}
        >
          <Layer ref={rf_layer}>
            <ImageKonva ref={rf_image} image={img} />
            {lines.map((line, i) => (
              <Line 
                key={i}
                points={line.points}
                stroke={line.color}
                strokeWidth={props.stroke||5}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation='source-over'
              />
            ))}
          </Layer>
        </Stage>
      </ScrollContainer>
      }
    </div >
  );

};

export default CxImageEditor;
