import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { getStoreValue } from "../../gx/GxStore";
import { clearStore, StoreSettPurSurcharge } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettPurSurchargeList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettPurSurcharge, setStoreSettPurSurcharge] = useSessionStorage('StoreSettPurSurcharge', StoreSettPurSurcharge);

  React.useEffect(() => {
    clearStore('StoreSettPurSurcharge', storeSettPurSurcharge);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPurSurcharge.itSurcharge = null;
    } else {
      storeSettPurSurcharge.itSurcharge = row.original.itSurcharge;
    }
    setStoreSettPurSurcharge(storeSettPurSurcharge);
    navTo("sett-pur-surcharge-form");
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.surcharges')}
        fullscreen
        hasSearchBox={false}
        url="sett/surcharge"
        filter={`t_surcharge.inBranch='${getStoreValue('StoreSession', 'inBranch')}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itSurcharge", show: false },
          { id: "1", Header: trans('field.type'), accessor: "txTypeSurchargeGroup", width: '80%' },
          { id: "2", Header: trans('field.surcharge_perc'), accessor: row => FxTable.cellTo2r00(row["nuSurchargePerc"]), width: '20%', align: 'right' },
        ]}
      />
    </div>

  );

}

export default MxSettPurSurchargeList;
