import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import * as FxTable from '../../fx/FxTable';
import GxForm from '../../gx/GxForm';
import { StoreSlsDoc, StoreSlsLst } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButton from '../../cx/CxIconButton';


const MxSlsDocFormLots = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  // eslint-disable-next-line no-unused-vars
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  let r_form = React.useRef(null);

  let r_arControls = React.useRef([]);

  const r_snack_required = React.useRef(null);


  React.useEffect(() => {
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
  }
  const formSaveCustom = () => {
    try{
      if (!isRequired()) {
        for (let index = 0; index < r_arControls.current.length; index++) {
          const ctl = r_arControls.current[index];
          if (ctl) {
            let txLotCode = ctl.getRow().original.txLotCode;
            let dtLotExpr = FxDat.toIso(ctl.getRow().original.dtLotExpr);
            storeSlsDoc.arItems[ctl.getItemIndex()].txLotCode = txLotCode;
            storeSlsDoc.arItems[ctl.getItemIndex()].dtLotExpr = dtLotExpr;
            setStoreSlsDoc(storeSlsDoc);
          }
        }
        navBack();
      }
      
    } catch(error) {
      FxLog.errorx('MxSlsDocFormLots.formSaveCustom', error)
    }
  }

  const isRequired = () => {
    let rv = false;
    try{
      for (let index = r_arControls.current.length-1; index >= 0; index--) {
        const ctl = r_arControls.current[index];
        if (ctl && ctl.getIsRequired()) {
          let label = ctl.getLabel();
          r_snack_required.current.setVal(trans('field.required') + ": " + label);
          r_snack_required.current.setOpen(true);
          rv = true;
        }
      }
      
    } catch(error) {
      FxLog.errorx('MxSlsDocFormLots.isRequired', error)
    }
    return rv;
  };

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.sales')}: ${trans('field.lots')}`}
        xref={r_form}
        toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        {storeSlsDoc.arItems.filter(item => item.txLotCode==='**pending**').map((item, i) =>

          <CxSearcher 
            key={item.idx} 
            itemIndex={item.idx}
            xref={(element) => r_arControls.current[item.idx] = element}
            label={item.txPs.substring(0,20) + ' (' + item.nuQuantity + ')'}
            url="sls/docitem/lots"
            filter={`n_pur_invoice_item.inPs='${item.inPs}'`}
            col_id="inPurInvoiceItem"
            col_tx="txLotCode"
            colorRefs={ [ 'table_cell_gray|' + trans('field.expired'), 'table_cell_red|' + trans('field.sold_out') ] }
            columns={[
              { id: "0", Header: "ID", accessor: "inPurInvoiceItem", show: false },
              { id: "1", Header: trans('field.lot'), accessor: "txLotCode", width: '35%' },
              { id: "2", Header: trans('field.expiration'), accessor: row => FxTable.cellToLocalDate(row["dtLotExpr"]), width: '35%' },
              { id: "3", Header: trans('field.quantity'), accessor: "nuQuantity", width: '30%', align: 'center' },
              { id: "A", Header: "_expired", accessor: row => FxDat.isExpired(row["dtLotExpr"]), show: false },
              { id: "R", Header: "_sold_out", accessor: row => Boolean(row["nuQuantity"] < 1), show: false },
            ]}
            required
          />

        )}

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSlsDocFormLots;
