import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import { useImageIO } from '../hx/useImageIO';
import { Stage, Layer, Line, Image as ImageKonva } from 'react-konva';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useWindowSize } from '../hx/useWindowSize';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    //width: '100%',
    gap: 3,
    border: '1px solid var(--ibs_color_gray)',
    borderRadius: '2px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 1,
    padding: '0px 0 0px 0',
    background: 'var(--ibs_color_gray_light)',
  },
});

const CxImageSignatureEditor = props => {
  const [{ imageSave, imageCreateBlank }] = useImageIO();
  const classes = useStyles();
  // eslint-disable-next-line
  const size = useWindowSize();

  const [img, setImg] = React.useState(null);

  const rf_container = React.useRef(null);
  const rf_scroll = React.useRef(null);
  const rf_stage = React.useRef(null);
  const rf_image = React.useRef(null);

  const [color, setColor] = React.useState('');
  const [lines, setLines] = React.useState([]);
  const isDrawing = React.useRef(false);


  React.useEffect(() => {
    /* blank image 300x200 */
    loadCanvas(imageCreateBlank(300, 200));
    setColor('black'); //color de tinta

    // quitamos menú contextual que se abría con click derecho.
    document.addEventListener('contextmenu', (e) => { e.preventDefault(); });

    return () => window.removeEventListener('contextmenu', (e) => { e.preventDefault(); });
    // eslint-disable-next-line
  }, []);

  const loadCanvas = (base64) => {
    let htmlImg = new Image();
    htmlImg.src = base64;
    htmlImg.onload = function (e) {
      //cvs.height = img.height * cvs.width / img.width;
      setImg(htmlImg);
    }
  }

  useImperativeHandle(props.xref, () => {
    return {
      getType, save,
    };
  });
  const getType = () => { return 'CxImageSignatureEditor' };
  const save = (inXXX) => {
    if (rf_image.current) {
      imageSave(props.url, inXXX, rf_image.current.getStage().toDataURL());
    } else {
      imageSave(props.url, inXXX, null);
    }
  };

  const doDrawStart = (e) => {
    if (color) {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      setLines([...lines, { color: color, points: [pos.x, pos.y] }]);
    }
  };

  const doDrawMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const doDrawEnd = () => {
    isDrawing.current = false;
  };

  const handleClick = (e) => {
  };

  return (
    <div ref={rf_container} className={classes.container} >
      {img && <ScrollContainer
        ref={rf_scroll}
        hideScrollbars={true}
        style={{
          width: (img.width),
          height: (img.height),
        }}>
        <Stage ref={rf_stage}
          width={img.width}
          height={img.height}
          onMouseDown={doDrawStart}
          onMousemove={doDrawMove}
          onMouseup={doDrawEnd}
          onTouchStart={doDrawStart}
          onTouchMove={doDrawMove}
          onTouchEnd={doDrawEnd}
          //style={{ background: 'yellow' }}
        >
          <Layer>
            <ImageKonva ref={rf_image} image={img} onClick={handleClick} />
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={line.color}
                strokeWidth={2}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation='source-over'
              />
            ))}
          </Layer>
        </Stage>
      </ScrollContainer>
      }
    </div >
  );

};

export default CxImageSignatureEditor;
