import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import * as FxDat from '../../fx/FxDat';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import { StoreWaiting } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import CxDlgCalendar from '../../cx/CxDlgCalendar';
import i18n from '../../../i18n';


const MxWaitingList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);
  const timer = React.useRef(null);
  const size = useWindowSize();
  const rf_cxDlgCalendar = React.useRef(null);
  
  const [storeWaiting, setStoreWaiting] = useSessionStorage('StoreWaiting', StoreWaiting);
  const _date = React.useRef(FxDat.today());

  const [dlgNewDate, setDlgNewDate] = React.useState(false);


  React.useEffect(() => {
    if (storeWaiting.dtIssue) {
      doDateSelectedFromCalendar(storeWaiting.dtIssue.substring(0,10));
    }

    timer.current = setInterval(() => {
      if (FxInet.isOnline()) {
        r_list.current.refresh();
      }
    }, 10000); //milisegundos

    return () => {
      // refresca listado para mostrar los estados.
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    r_list.current.refresh();
    // eslint-disable-next-line
  }, [size]);

  const openForm = async (op, row) => {
    if (row === null) {
      storeWaiting.inWaiting = null;
      storeWaiting.dtIssue = null;
    } else {
      storeWaiting.inWaiting = row.original.inWaiting;
      storeWaiting.dtIssue = row.original.dtIssue;
    }
    setStoreWaiting(storeWaiting);
    navTo("waiting-form");
  }

  const doDateSelectedFromCalendar = (d) => {
    setDlgNewDate(false);
    _date.current = d || '';
    Promise.all([r_list.current.setdeepFilter(`date(dtIssue)='${_date.current}'`)]).then((result) => {
      r_list.current.setModuleName(`${trans('field.waiting_room')}: ${FxDat.toLocalDate(_date.current)}`);
      r_list.current.refresh();
    });

  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inWaiting", show: false });
    cols.push({ id: 'A', Header: '_present', accessor: row => Boolean(row["boPresent"] === '0'), show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["txAnimal"]), width: '15%'});
      cols.push({ id: id++, Header: trans('field.time'), accessor: row => (FxTable.cellToLocalTime(row["dtIssue"])), width: '5%' });
      cols.push({ id: id++, Header: trans('field.issue'), accessor: row => r_list.current.getTable().highlightText(row["txIssue"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.status'), accessor: row => (Boolean(row["boPresent"] === '1') ? row["txTypeStatusWaiting"] : trans('field.absent')), width: '10%' });
      cols.push({ id: id++, Header: trans('field.balance'), accessor: row => (FxTable.cellTo2r00(row["nuBalance"])), width: '10%', align: 'right' });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '55%' });
      cols.push({ id: id++, Header: trans('field.time'), accessor: row => (FxTable.cellToLocalTime(row["dtIssue"])), width: '15%' });
      cols.push({ id: id++, Header: trans('field.status'), accessor: row => (Boolean(row["boPresent"] === '1') ? row["txTypeStatusWaiting"] : trans('field.absent')), width: '30%' });
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.waiting_room')}: ${FxDat.toLocalDate(_date.current)}`}
        xref={r_list}
        tooltip={`${trans('field.client')} / ${trans('field.patient')}`}
        fullscreen
        help="waiting"
        url="waiting"
        onOpenForm={openForm}
        colorRefs={ [ 'table_cell_black|' + trans('field.present'), 'table_cell_gray|' + trans('field.absent') ] }
        columns={getTableColumns()}
        filter={`date(dtIssue)='${_date.current}'`}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="calendar" classType="ibs_iconbutton_toolbar" onClick={() => setDlgNewDate(true)} tooltip={trans('field.date')} />
          </div>
        }
      />

      <CxDlgCalendar
        xref={rf_cxDlgCalendar}
        open={dlgNewDate}
        onClose={(d) => doDateSelectedFromCalendar(d)}
        title={i18n.t('msg.select_x').replace('%s', i18n.t('field.date'))}
      />

    </div>
  );

}

export default MxWaitingList;
