import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettAnimalSpecies } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettAnimalSpeciesList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettAnimalSpecies, setStoreSettAnimalSpecies] = useSessionStorage('StoreSettAnimalSpecies', StoreSettAnimalSpecies);

  React.useEffect(() => {
    clearStore('StoreSettAnimalSpecies', storeSettAnimalSpecies);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettAnimalSpecies.itAnimalSpecies = null;
    } else {
      storeSettAnimalSpecies.itAnimalSpecies = row.original.itAnimalSpecies;
    }
    setStoreSettAnimalSpecies(storeSettAnimalSpecies);
    navTo("sett-animal-species-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.patients')}: ${trans('field.species_p')}`}
        xref={r_list}
        tooltip={`${trans('field.species')}`}
        fullscreen
        url="sett/animal_species"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalSpecies", show: false },
          { id: "1", Header: trans('field.species'), accessor: row => r_list.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettAnimalSpeciesList;
