import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValueBo, StoreSupplier } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxChooser from '../../cx/CxChooser';
import { useAccess } from '../../hx/useAccess';


const MxSupplierForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.suppliers'));
  const [{ navBack }] = useNav();
  const r_snack_ask_save = React.useRef(null);

  const r_form = React.useRef(null);

  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);

  const [storeSupplier, setStoreSupplier] = useSessionStorage('StoreSupplier', StoreSupplier);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeSupplier.inSupplier = lastSaveRv.theValue;
    Promise.all([setStoreSupplier(storeSupplier)]).then((result) => {
      navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.supplier')}`}
        xref={r_form}
        url="suppliers"
        filterIdField='inSupplier'
        filterIdValue={storeSupplier.inSupplier}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxInput xref={useRef(null)} label={trans('field.supplier')} dbfield="n_supplier.txSupplier" capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} required />
        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_supplier.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_supplier.dtTo"} />

        <CxChooser xref={useRef(null)}
          label={trans('field.type')} 
          dbfield="t_supplier.txTypeSupplier" dbfieldkey="n_supplier.itSupplier"
          table="t_supplier"
          col_id="itSupplier"
          col_tx="txTypeSupplier"
        />

        <CxInput xref={useRef(null)} label={trans('field.ein')} dbfield="n_supplier.txEin" maxLength={15}  />
        <CxInput xref={useRef(null)} label={trans('field.x_number', 'field.client')} dbfield="n_supplier.txNumber" maxLength={30}  />
        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_supplier.txPhone1" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_supplier.txMobile" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_supplier.txEmail" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.web')} dbfield="n_supplier.txWeb" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_supplier.txAddress" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_supplier.txCity" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_supplier.txState" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_supplier.txPostalCode" maxLength={10}  />
        <CxInput xref={useRef(null)} label={trans('field.country')} dbfield="n_supplier.txCountry" maxLength={50} />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_supplier.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxSupplierForm;
