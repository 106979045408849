import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxSnackbar from '../../../cx/CxSnackbar';
import '../../../../styles/main.css';


const MxTypeOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const rf_txType = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txType.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txType.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txTypeXXX = rf_txType.current.getVal();
  
    setDisabled(true);

    let record = {};
    record['txType'] = txTypeXXX;
    if (props.parent) {
      record['itXXX_related'] = props.parent.current.getKey();
    }

    Promise.all([FxFetch.doJsonX(props.url, record)]).then((result) => {
      FxLog.infox("MxTypeOTF.formSave", "...result[0]:", result[0]);
      if (result[0]) {
        if (result[0].theValue === "rv_type_exists") {
          FxLog.infox("MxTypeOTF.formSave", "El tipo ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists', props.name));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxTypeOTF.formSave", "El tipo ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let itXXX = result[0].theValue;
          if (props.onOk) props.onOk(itXXX, txTypeXXX);
        }
        setDlg(false);
        setDisabled(false);
      }
    });

  }

  return (
    <div>
      <CxDialog title={trans('field.create_x', props.name)} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

          <CxInput xref={rf_txType} label={trans(props.name)} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={100} required />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />


    </div>
  );

}

export default MxTypeOTF;
