import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxLongTask from '../../gx/GxLongTask';


const MxSlsDocListSpecOptTsk = (props) => {
  const [{ trans }] = useTranslation();

  return (
    <div>
      <CxToolbar moduleName={trans('field.special_options')} />
      <GxLongTask />
    </div>
  );

}

export default MxSlsDocListSpecOptTsk;
