import { BiSend } from "react-icons/bi";
import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import '../../styles/main.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import CxSnackbar from '../cx/CxSnackbar';
import CxDlgAlert from '../cx/CxDlgAlert';
import CxToolbar from '../cx/CxToolbar';
import CxButton from '../cx/CxButton';
import CxIconButton from '../cx/CxIconButton';
import CxIconButtonNew from '../cx/CxIconButtonNew';
import CxDialog from '../cx/CxDialog';
import CxNumber from '../cx/CxNumber';
import CxImageSignatureEditor from '../cx/CxImageSignatureEditor';
import { StoreFile, isApp } from "./GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import * as FxFile from '../fx/FxFile';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "../../styles/ReactPdf/ReactPdf.css";
import ScrollContainer from 'react-indiana-drag-scroll';
//import { useWindowSize } from '../hx/useWindowSize';
import { useNav } from '../hx/useNav';
import { BsHandThumbsUp } from "react-icons/bs";
import GxDlgSend from './GxDlgSend';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    marginTop: '-15px', // 2024.07.05
  },
  toolbar: {
    background: 'var(--ibs_color_white)',
    position: 'fixed',// 2024.07.05
    zIndex: 1,// 2024.07.05
    padding: '5px 0 0 0', // 2024.07.05
    width: '100%',
    [theme.breakpoints.up('sm')]: { // 2024.07.05
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    padding: '55px 0 0 0', // 2024.07.05
    margin: 'var(--ibs_container_margin)',
  },
}));

const GxViewer = (props) => {
  const [{ trans }] = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ navBack }] = useNav();
  //const size = useWindowSize();

  //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  // eslint-disable-next-line no-unused-vars
  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  const r_snack_prepare_signature_pad = React.useRef(null);
  const r_snack_required = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  
  const r_dlg_send = React.useRef(null);
  const r_dlg_signature = React.useRef(null);
  const r_dlg_alert_signed = React.useRef(null);
  const rf_goto_page = React.useRef(null);
  const rf_image_signature = React.useRef(null);

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [dlgGotoPage, setDlgGotoPage] = React.useState(false);
  const [dlgSign, setDlgSign] = React.useState(false);

  const [zoom, setZoom] = React.useState(1.0);



  React.useEffect(() => {
    if (storeFile._scale) {
      setZoom(storeFile._scale);
    }

    return () => { /* cleanup */ }
    // eslint-disable-next-line
  }, []);

  function onDocumentLoadError(error) {
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function jump() {
    try {
      let thePage = rf_goto_page.current.getIntVal();
      if (thePage > numPages) thePage = numPages;
      if (thePage < 1) thePage = 1;
      gotoPage(thePage);
      setDlgGotoPage(false);

    } catch (error) {
      FxLog.errorx("GxViewer.jump", error);
    }
  }

  function gotoPage(thePageNumber) {
    setPageNumber(thePageNumber);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const exportToPdf = () => {
    let byteArray = FxFile.base64ToArrayBuffer(storeFile.obPDF.replace('data:application/pdf;base64,', ''));
    FxFile.saveByteArray('PDF', storeFile.txFileNameInput, byteArray);
  }

  const exportToExcel = () => {
    let byteArray = FxFile.base64ToArrayBuffer(storeFile.obCSV);
    FxFile.saveByteArray('CSV', storeFile.txFileNameInput, byteArray);
  }

  const printPDF = () => {
    FxFile.printPDF(storeFile.obPDF);
  }

  const handleSign = () => {
    r_snack_prepare_signature_pad.current.setOpen(true);
    setDlgSign(true);
  }

  const doSignSave = () => {
    FxLog.infox('GxViewer.doSignSave', '...', storeFile._sign);
    try {
      // Guarda un registro en n_client_doc
      let record = {};
      record['n_client_doc.inClientDoc'] = storeFile._sign.inClientDoc;
      record['n_client_doc.itDoc'] = storeFile._sign.itDoc;
      record['n_client_doc.inEmployee'] = storeFile._sign.inEmployee;
      record['n_client_doc.inClient'] = storeFile._sign.inClient;
      record['n_client_doc.dtSignature'] = storeFile._sign.dtSignature;
      if (isApp('vett') || isApp('spaw')) {
        record['n_client_doc.inAnimal'] = storeFile._sign.inAnimal;
      }

      Promise.all([FxFetch.doJsonX('clients/doc/cu', record)]).then((result) => {
        if (result[0]) {
          if (result[0].theValue === 'OK') {
            // Añade al registro la firma
            let inClientDoc = result[0].inClientDoc;
            FxLog.infox("GxViewer.doSignSave", "...inClientDoc:" + inClientDoc);
            rf_image_signature.current.save(inClientDoc);
            setDlgSign(false);
            r_dlg_alert_signed.current.setAutoClose(true);
            r_dlg_alert_signed.current.setOpen(true);
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxViewer.doSignSave", error);
    }
  }

  const doSignFinish = () => {
    Promise.all([setDlgSign(false)]).then((result) => {
      navBack();
    });
  }


  return (
    <div>
      <div id='myGxViewer' className={classes.parentDiv}>
        <div id='myCxToolbar' className={classes.toolbar}>
          <CxToolbar moduleName={trans('field.preview')} style={{ display: 'flex', gap: '15px' }}>
            {storeFile._sign && !storeFile._sign.isSigned && <CxButton label={trans('field.sign')}
              style={{ width: '100px', height: '23px', padding: '0px', borderRadius: '0px', backgroundColor: 'var(--ibs_color_red)', margin: '4px 10px 4px 4px' }}
              onClick={handleSign}
            />}
            <CxIconButton type="zoom_in" classType="ibs_iconbutton_toolbar" onClick={() => setZoom(zoom + 0.1)} />
            <CxIconButton type="zoom_out" classType="ibs_iconbutton_toolbar" onClick={() => setZoom(zoom - 0.1)} />
            <CxIconButton type="pdf" classType="ibs_iconbutton_toolbar" onClick={exportToPdf} tooltip={trans('field.export_to_x', 'PDF')} />
            <CxIconButton type="excel" classType="ibs_iconbutton_toolbar" onClick={exportToExcel} tooltip={trans('field.export_to_x', 'Excel')} />
            <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={printPDF} tooltip={trans('field.print')} />
            {storeFile._send && <CxIconButtonNew icon={<BiSend fontSize={25} />} classType="ibs_iconbutton_toolbar" onClick={() => r_dlg_send.current.setOpen(true)} tooltip={trans('field.send')} />}
            <CxIconButton type="move_first" classType="ibs_iconbutton_toolbar" onClick={() => gotoPage(1)} disabled={pageNumber <= 1} />
            <CxIconButton type="move_left" classType="ibs_iconbutton_toolbar" onClick={previousPage} disabled={pageNumber <= 1} />
            <div style={{ fontSize: '14px', marginTop: '4px', cursor: 'pointer' }} onClick={() => {
              Promise.all([setDlgGotoPage(true)]).then((result) => {
                rf_goto_page.current.setFocus();
              });
            }}>
              {pageNumber || (numPages ? 1 : '-')}/{numPages || '-'}
            </div>
            <CxIconButton type="move_right" classType="ibs_iconbutton_toolbar" onClick={nextPage} disabled={pageNumber >= numPages} />
            <CxIconButton type="move_last" classType="ibs_iconbutton_toolbar" onClick={() => gotoPage(numPages)} disabled={pageNumber >= numPages} />
          </CxToolbar>
        </div>

        <div className={classes.container}>
          <ScrollContainer
            style={{
              width: '100%',
              //height: '100%',
              //background: 'gray',
            }}>
            <Document
              file={storeFile.obPDF}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={`${trans('msg.loading')}...`}
              noData={trans('msg.report_too_much_data')}
            >
              <Page
                pageNumber={pageNumber}
                scale={zoom}
              />
            </Document>
          </ScrollContainer>
        </div>

        <CxDialog
          title={trans('msg.go_to_x').replaceAll('%s', trans('field.page') + '...')}
          open={dlgGotoPage}
          onOk={jump}
          onCancel={() => setDlgGotoPage(false)}
          height='200px'>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_sm)' }}>
            <CxNumber xref={rf_goto_page} label={trans('field.page')} onEnter={jump} width="var(--ibs_control_width_sm)" decimals={0} />
          </div>
        </CxDialog>

        <CxDialog
          xref={r_dlg_signature}
          title={trans('field.sign')}
          open={dlgSign}
          onOk={() => doSignSave()}
          onCancel={() => setDlgSign(false)}
          height='330px'>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_lg)' }}>
            <CxImageSignatureEditor xref={rf_image_signature} url='clients/doc/signature' />
          </div>
        </CxDialog>

        <GxDlgSend xref={r_dlg_send} />

        <CxSnackbar xref={r_snack_prepare_signature_pad} severity="warning" value={trans('msg.prepare_signature_pad')} />
        <CxSnackbar xref={r_snack_required} severity="error" />
        <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
        <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

        <CxDlgAlert xref={r_dlg_alert_signed}
          text={trans('field.signed_in_accordance')}
          textColor={'var(--ibs_color_green)'}
          icon={<BsHandThumbsUp fontSize="30px" style={{ fill: 'var(--ibs_color_green)', paddingBottom: '10px' }} />}
          onAutoClose={doSignFinish}
        />


      </div>
    </div>
  );
}

export default GxViewer;
