import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettSms } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';
import { useWindowSize } from '../../hx/useWindowSize';


const MxSettCommSmsList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ navTo }] = useNav();

  const [storeSettSms, setStoreSettSms] = useSessionStorage('StoreSettSms', StoreSettSms);
  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettSms', storeSettSms);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettSms.itSms = null;
    } else {
      storeSettSms.itSms = row.original.itSms;
    }
    setStoreSettSms(storeSettSms);
    navTo("sett-comm-sms-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "itSms", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.account'), accessor: row => r_list.current.getTable().highlightText(row["txName"]), width: '100%' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.account'), accessor: row => r_list.current.getTable().highlightText(row["txName"]), width: '100%' });
      
      }

    } catch(error) {
      FxLog.errorx('MxSettCommSmsList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.comms')}: ${trans('field.sms')}`}
        xref={r_list}
        tooltip={`${trans('field.name')}`}
        fullscreen
        url="sett/sms"
        onOpenForm={openForm}
        columns={getTableColumns()}
      />
    </div>
  );

}

export default MxSettCommSmsList;
