import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettBank } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';
import { useWindowSize } from '../../hx/useWindowSize';


const MxSettBankList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const [storeSettBank, setStoreSettBank] = useSessionStorage('StoreSettBank', StoreSettBank);
  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettBank', storeSettBank);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettBank.inBank = null;
    } else {
      storeSettBank.inBank = row.original.inBank;
    }
    setStoreSettBank(storeSettBank);
    navTo("sett-bank-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inBank", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.bank'), accessor: row => r_list.current.getTable().highlightText(row["txBank"]), width: '45%' });
        cols.push({ id: id++, Header: trans('field.agent'), accessor: "txAgent", width: '15%' });
        cols.push({ id: id++, Header: trans('field.bic'), accessor: "txBic", width: '10%' });
        cols.push({ id: id++, Header: trans('field.email'), accessor: "txEmail", width: '20%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '10%' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.bank'), accessor: row => r_list.current.getTable().highlightText(row["txBank"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.agent'), accessor: "txAgent", width: '30%' });
      
      }

    } catch(error) {
      FxLog.errorx('MxSettBankList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.banks')}
        xref={r_list}
        tooltip={`${trans('field.bank')}`}
        fullscreen
        url="sett/banks"
        onOpenForm={openForm}
        columns={getTableColumns()}
      />

    </div>

  );

}

export default MxSettBankList;
