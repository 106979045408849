import React, { useState, useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
//import * as FxLog from '../fx/FxLog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';


const useStylesLocal = makeStyles({
  // Applied to <Radio />
  radio_root: {
    width: 20,
    height: 15,
    paddingTop: 5,
    paddingBottom: 5,
    color: 'var(--ibs_color_cy_blue)',
    "&.Mui-checked": {
      color: 'var(--ibs_color_cy_blue)',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // Applied to <FormControlLabel />
  radio_label: {
    color: 'var(--ibs_color_cy_blue)',
    fontSize: 'var(--ibs_control_label_font_size_radio)',
    width: props => { return props.itemWidth ||'' },
    textAlign: 'left',
  },
});

const CxRadio = props => {
  const classesL = useStylesLocal(props);
  const { onSelection, defaultValue, ...rest } = props;
  const [myValue, setMyValue] = useState(rest.autoSelectFirst && rest.list[0] ? rest.list[0].id : null);
  const [wasChanged, setChanged] = useState(false);

  React.useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getDbfield, getIsRequired, wasChanged, setChanged, setSelectFirst, 
    };
  });
  const setVal = (newValue) => {
    setMyValue(newValue);
  };
  const setSelectFirst = () => {
    setMyValue(rest.list[0].id);
  };
  const getVal = () => { return (myValue); };
  const getDbfield = () => { return rest.dbfield };
  const getIsRequired = () => { return false };
  const getType = () => { return 'CxRadio' };

  const handleChange = (event) => {
    setChanged(true);
    setMyValue(event.target.value);
    if (onSelection !== undefined) onSelection(event.target.value);
  };

  return (
    <>
      <FormLabel style={{ fontSize: 'var(--ibs_control_label_font_size)', alignSelf: 'flex-start' }}>{rest.label}</FormLabel>
      <RadioGroup value={myValue} onChange={handleChange} style={{ display: 'flex', flexDirection: props.orientation||'column', }}>
        {rest.list.map(item => {
          return <FormControlLabel
            classes={{ label: classesL.radio_label }}
            value={item.id}
            key={item.id}
            label={item.type}
            control={<Radio size="small" classes={{ root: classesL.radio_root, colorPrimary: classesL.colorPrimary }}
            disabled={rest.disabled}
            />}
          />
        })}
      </RadioGroup>
    </>
  );
};

export default CxRadio;
