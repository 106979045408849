import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValueBo, StoreLab } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxChooser from '../../cx/CxChooser';
import { useAccess } from '../../hx/useAccess';


const MxLabForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.labs'));
  const [{ navBack }] = useNav();
  const r_snack_ask_save = React.useRef(null);

  const r_form = React.useRef(null);

  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);

  const [storeLab, setStoreLab] = useSessionStorage('StoreLab', StoreLab);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeLab.inLab = lastSaveRv.theValue;
    Promise.all([setStoreLab(storeLab)]).then((result) => {
      navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.lab')}`}
        xref={r_form}
        url="labs"
        filterIdField='inLab'
        filterIdValue={storeLab.inLab}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxInput xref={useRef(null)} label={trans('field.lab')} dbfield="n_lab.txLab" capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} required />
        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_lab.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_lab.dtTo"} />

        <CxChooser xref={useRef(null)}
          label={trans('field.type')} 
          dbfield="t_lab.txTypeLab" dbfieldkey="n_lab.inLab"
          table="t_lab"
          col_id="inLab"
          col_tx="txTypeLab"
        />

        <CxInput xref={useRef(null)} label={trans('field.ein')} dbfield="n_lab.txEin"  />
        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_lab.txPhone1"  />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_lab.txMobile"  />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_lab.txEmail" />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_lab.txAddress" />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_lab.txCity" />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_lab.txState" />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_lab.txPostalCode"  />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_lab.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxLabForm;
