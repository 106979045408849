import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettSecGroup } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import * as FxFetch from '../../fx/FxFetch';
import * as FxInet from '../../fx/FxInet';
import CxIconButton from '../../cx/CxIconButton';
//import { useAccess } from '../../hx/useAccess';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxDialog from '../../cx/CxDialog';


const MxSettSecGroupList = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ isFullAccess }] = useAccess(trans('field.security'));
  const [{ navTo, navExit }] = useNav();

  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);

  const [dlgReset, setDlgReset] = React.useState(false);

  const r_snack_success = React.useRef(null);

  const [storeSettSecGroup, setStoreSettSecGroup] = useSessionStorage('StoreSettSecGroup', StoreSettSecGroup);
  const r_list = React.useRef(null);

  const r_dlg_alert = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettSecGroup', storeSettSecGroup);
    formLoad();

    return () => {
      runTimer(false);
    }
    // eslint-disable-next-line
  }, []);

  const runTimer = (boStart) => {
    if (boStart) {
      //starting up timer...
      timer.current = setInterval(() => {
        if (FxInet.isOnline()) {
          doTaskCheck();
        }
      }, 1 * SEC);

    } else {
      if (timer.current) {
        //cleaning up timer...
        clearInterval(timer.current);
      }
    }
  }

  const doTaskCheck = () => {
    try {
      let record = {};
      record['txTaskName'] = 'reset_access';
      Promise.all([FxFetch.doJsonX('long_task/get_progress', record)]).then((result) => {
        if (result[0]) {
          let item = result[0].nuTaskItemCurrent;
          /* let total = result[0].nuTaskItemTotal; */
          switch (result[0].txTaskMessage) {
            case trans('msg.setting_up'):
              r_dlg_alert.current.setProgressMsg(trans('msg.setting_up') + '...');
              break;
              
            case trans('msg.building'):
              r_dlg_alert.current.setProgressMsg(`${trans('msg.building')} ${item+1}`);
              break;
              
            case trans('msg.operation_finished'):
              r_dlg_alert.current.setProgressMsg(`${trans('msg.building')} ${item+1}`);
              runTimer(false);
              r_dlg_alert.current.setOpen(false);
              navExit();
              break;
          
            default:
              r_dlg_alert.current.setProgressMsg('');
              break;
          }
        }
      });

    } catch (error) {
      FxLog.errorx("MxSettSecGroupList.doProgressCheck", error);
    }
  }

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettSecGroup.inSecGroup = null;
    } else {
      storeSettSecGroup.inSecGroup = row.original.inSecGroup;
    }
    setStoreSettSecGroup(storeSettSecGroup);
    navTo("sett-sec-group-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inSecGroup", show: false });
      cols.push({ id: id++, Header: trans('field.group'), accessor: row => r_list.current.getTable().highlightText(row["txSecGroup"]), width: '100%' });
      cols.push({ id: id++, Header: trans('field.users'), accessor: "users", align: 'center', width: '100px' });

    } catch(error) {
      FxLog.errorx('MxSettSecGroupList.getTableColumns', error)
    }
    return cols;
  }

  const resetAccess = () => {
    try{
      let record = {};
      setDlgReset(false);
      runTimer(true);
      r_dlg_alert.current.setOpen(true);
      Promise.all([FxFetch.doJsonX("sett/sec/groups/reset_access", record)]).then((result) => {
        if (result[0]) {
        }
      });

    } catch(error) {
      FxLog.errorx('MxSettSecGroupForm.resetAccess', error);
    }
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.groups')}
        xref={r_list}
        tooltip={`${trans('field.group')}`}
        fullscreen
        url="sett/sec/groups"
        onOpenForm={openForm}
        columns={getTableColumns()}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="reset" classType="ibs_iconbutton_toolbar" onClick={() => setDlgReset(true)} tooltip={trans('field.reset_x','field.access_p')} />
          </div>
        }
      />
      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />

      <CxDialog title={trans('msg.confirm_operation')} open={dlgReset} onCancel={() => setDlgReset(false)} onOk={resetAccess} height='220px' >
        <div style={{ display:'flex', textAlign:'left', whiteSpace:'pre-line' /*line breaks*/ }} >
          {trans('msg.delete_warning_x_1','field.access_p')}
        </div>
      </CxDialog>

      <CxDlgAlert xref={r_dlg_alert} />

    </div>
  );
}

export default MxSettSecGroupList;
