import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxDate from '../../cx/CxDate';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreClient, StoreAnimalWeight } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { useAccess } from '../../hx/useAccess';
import CxStatic from '../../cx/CxStatic';


const MxAnimalWeightForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navBack }] = useNav();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  
  const [storeAnimalWeight, setStoreAnimalWeight] = useSessionStorage('StoreAnimalWeight', StoreAnimalWeight);

  let r_form = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      r_form.current.getRef("n_animal_weight.dtWeight").setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
    } else {
      // se guardó exitosamente
      storeAnimalWeight.inAnimalWeight = lastSaveRv.theValue;
      Promise.all([setStoreAnimalWeight(storeAnimalWeight)]).then((result) => {
        navBack();
      });
    }

  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.patient')}: ${trans('field.weight')}`}
        xref={r_form}
        url="animals/weight" 
        filterIdField='inAnimalWeight' 
        filterIdValue={storeAnimalWeight.inAnimalWeight} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="n_animal_weight.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_animal_weight.inAnimal" value={storeClient.inAnimal || ''} />

        <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={useRef(null)} label={trans('field.date')} dbfield="n_animal_weight.dtWeight" required/>
        <CxNumber xref={useRef(null)} label={trans('field.weight')} dbfield="n_animal_weight.nuWeight" decimals={4} decimalsfixed="true" required />
        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_animal_weight.txRemarks" minRows={2} maxLength={150} />
        
      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      
    </div>
  );

}

export default MxAnimalWeightForm;
