import { CgImport } from "react-icons/cg"; 
import { BiRefresh } from "react-icons/bi"; 
import { CgFileDocument } from "react-icons/cg";
import { BiPrinter } from "react-icons/bi";
import React, { useRef } from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxDate from '../../cx/CxDate';
import CxStatic from '../../cx/CxStatic';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxAccordion from '../../cx/CxAccordion';
import CxTable from '../../cx/CxTable';
import CxButtonMenu from '../../cx/CxButtonMenu';
import CxDialog from '../../cx/CxDialog';
import CxDlgTable from '../../cx/CxDlgTable';
import CxDlgDoc from '../../cx/CxDlgDoc';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import * as FxFetch from '../../fx/FxFetch';
import * as FxFile from '../../fx/FxFile';
import * as FxDat from '../../fx/FxDat';
import * as FxInet from '../../fx/FxInet';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import GxDlgStandard from '../../gx/GxDlgStandard';
import { clearStore, getStoreValueBo, StoreClient, StoreRxAuto, StoreAnimalWeight, StoreGrooming, StoreCns, StoreVacc, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxSnackbar from '../../cx/CxSnackbar';
import CxState from '../../cx/CxState';
import CxCombo from '../../cx/CxCombo';
import { useWindowSize } from '../../hx/useWindowSize';
import { useAccess } from '../../hx/useAccess';
import * as MxRxAutoFormH1 from './MxAnimalRxAutoFormH1';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { IoAddCircleOutline } from "react-icons/io5";
import MxTypeOTF from '../common/otf/MxTypeOTF';
import CxButtonShowMore from '../../cx/CxButtonShowMore';
import CxDlgCertificate from '../../cx/CxDlgCertificate';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';


const MxAnimalForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);

  let r_dlg_doc = React.useRef(null);
  let r_dlg_upgrade = React.useRef(null);
  let rf_owner = React.useRef(null);
  let rf_inClient = React.useRef(null);
  let rf_txAnimal = React.useRef(null);

  let r_print = React.useRef(null);
  let r_dlg_std = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_snack_required = React.useRef(null);

  const r_dlg_alert = React.useRef(null);
  const r_dlgtable_rxs_invoice = React.useRef(null);
  const r_dlg_certificate = React.useRef(null);

  const r_dlg_new_species = React.useRef(null);
  const r_dlg_new_breed = React.useRef(null);
  const r_dlg_new_coat = React.useRef(null);

  const [dlgPrint, setDlgPrint] = React.useState(false);
  const [dlgRxsInvoice, setDlgRxsInvoice] = React.useState(false);

  const [certificate, setCertificate] = React.useState({});

  const rf_print_preview = React.useRef(null);
  const rf_print_cert_sign = React.useRef(null);

  const r_form = React.useRef(null);
  const r_table_vaccs = React.useRef(null);
  const r_table_cnss = React.useRef(null);
  const r_table_rxs = React.useRef(null);
  const r_table_groo = React.useRef(null);
  const r_table_images = React.useRef(null);
  const r_table_multimedia = React.useRef(null);
  const r_table_weight = React.useRef(null);
  const r_accordion_vaccs = React.useRef(null);
  const r_accordion_cnss = React.useRef(null);
  const r_accordion_rxs = React.useRef(null);
  const r_accordion_groo = React.useRef(null);
  const r_accordion_images = React.useRef(null);
  const r_accordion_multimedia = React.useRef(null);
  const r_accordion_weight = React.useRef(null);

  const rf_txNumber = React.useRef(null);
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txTypeAnimalSpecies = React.useRef(null);
  const rf_txTypeAnimalBreed = React.useRef(null);
  const rf_txTypeAnimalCoat = React.useRef(null);
  const rf_txTypeAnimalCondition = React.useRef(null);
  const rf_txNumberAnimals = React.useRef(null);
  const rf_txTypeRate = React.useRef(null);
  const rf_txEmployee = React.useRef(null);

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);
  const [storeAnimalWeight, setStoreAnimalWeight] = useSessionStorage('StoreAnimalWeight', StoreAnimalWeight);
  const [storeGrooming, setStoreGrooming] = useSessionStorage('StoreGrooming', StoreGrooming);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);
  const [storeVacc, setStoreVacc] = useSessionStorage('StoreVacc', StoreVacc);


  React.useEffect(() => {
    clearStore('StoreAnimalWeight', storeAnimalWeight);
    clearStore('StoreCns', storeCns);
    clearStore('StoreVacc', storeVacc);
    clearStore('StoreRxAuto', storeRxAuto);

    timer.current = setInterval(() => {
      FxLog.infox('MxAnimalForm.useEffect', 'timer...');
      if (FxInet.isOnline()) {
        // 2024-10-07 - daq: para mostrar nuevos archivos importados desde intech.
        r_table_multimedia.current.redisplay();
        r_accordion_multimedia.current.setFontWeight(r_table_multimedia.current.isEmpty() ? 'normal' : 'bold');
      }
  
    }, 10 * SEC);

    return () => {
      FxLog.infox("MxAnimalForm.useEffect", "cleaning up timer...");
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
      rf_txNumberAnimals.current.setVal('1');
    } else {
      rf_txTypeAnimalBreed.current.setFilter(`t_animal_breed.itAnimalSpecies='${data[0].itAnimalSpecies}'`);
      rf_txAnimal.current.setLocked(true);
      updateAccordions();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeClient.inAnimal = lastSaveRv.theValue;
    storeClient.txAnimal = rf_txAnimal.current.getVal();
    Promise.all([setStoreClient(storeClient)]).then((result) => {
      if (lastSaveRv.txNumber) {
        rf_txNumber.current.setVal(lastSaveRv.txNumber);
      }
      r_form.current.resetChanged();
      forceUIUpdate();
    });
  }
  const formDelete = async (rv) => {
  }

  const openFormVacc = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeVacc.inVacc = null;
      } else {
        storeVacc.inVacc = row.original.inVacc;
      }
      setStoreVacc(storeVacc);
      navTo("animal-vacc-form");
    }
  }

  const openFormCnsEdit = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      storeCns.inCns = row.original.inCns;
      setStoreCns(storeCns);
      if (row.original.txType === 'G') navTo("animal-cns-grl-form");
      if (row.original.txType === 'H') navTo("animal-cns-hos-form");
    }
  }

  const openFormCnsNew = async (option) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      storeCns.inCns = null;
      setStoreCns(storeCns);
      if (option === 'G') {
        navTo("animal-cns-grl-form");
      }
      if (option === 'H') {
        if (!FxEdition.isInEdition('CLI_hos')) {
          r_dlg_upgrade.current.setOpen(true);
        } else {
          navTo("animal-cns-hos-form");
        }
      }
    }
  }

  const openFormRxsEdit = async (op, row) => {

    storeRxAuto.boFirstRender = true;
    storeRxAuto.boDataChanged = '0';
    storeRxAuto.boAuto = Boolean(row.original.inInvoice);

    storeRxAuto.inClient = storeClient.inClient;
    storeRxAuto.inAnimal = storeClient.inAnimal;
    storeRxAuto.txClient = storeClient.txClient;
    storeRxAuto.txAnimal = storeClient.txAnimal;

    storeRxAuto.inRxAuto = row.original.inRxAuto;
    storeRxAuto.txRxAuto = row.original.txRxAuto;
    storeRxAuto.itSeriesRx = row.original.itSeriesRx;
    Promise.all([MxRxAutoFormH1.load(storeRxAuto)]).then((result) => {
      setStoreRxAuto(storeRxAuto);
      navTo("animal-rx-auto-form");
    });
  }
  const openFormRxsNew = async (option) => {

    storeRxAuto.boFirstRender = true;
    storeRxAuto.boDataChanged = '0';
    storeRxAuto.boAuto = Boolean(option === 'A');

    storeRxAuto.inClient = storeClient.inClient;
    storeRxAuto.inAnimal = storeClient.inAnimal;
    storeRxAuto.txClient = storeClient.txClient;
    storeRxAuto.txAnimal = storeClient.txAnimal;

    storeRxAuto.inRxAuto = null;
    storeRxAuto.txRxAuto = null;
    storeRxAuto.itSeriesRx = null;
    storeRxAuto.inInvoice = null;
    Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
      if (option === 'M') {
        navTo("animal-rx-auto-form");
      }
      if (option === 'A') {
        setDlgRxsInvoice(true);
      }
    });
  }
  const openFormRxsNewAuto = async (row) => {
    setDlgRxsInvoice(false);

    if (row) {
      storeRxAuto.inInvoice = row.original.inInvoice;
      Promise.all([MxRxAutoFormH1.loadFromInvoice(storeRxAuto)]).then((result) => {
        Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
          navTo("animal-rx-auto-form");
        });
      });
    }
  }

  const openFormGroo = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeGrooming.inGrooming = null;
      } else {
        storeGrooming.inGrooming = row.original.inGrooming;
      }
      setStoreGrooming(storeGrooming);
      navTo("animal-groo-form");
    }
  }

  const openFormImage = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inImage = null;
      } else {
        storeClient.inImage = row.original.inImage;
      }
      setStoreClient(storeClient);
      navTo("client-image-form");
    }
  }

  const openFormMultimedia = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inMultimedia = null;
      } else {
        storeClient.inMultimedia = row.original.inMultimedia;
      }
      setStoreClient(storeClient);
      navTo("client-multimedia-form");
    }
  }

  const openFormWeight = async (op, row) => {
    if (!storeClient.inAnimal) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeAnimalWeight.inAnimalWeight = null;
      } else {
        storeAnimalWeight.inAnimalWeight = row.original.inAnimalWeight;
      }
      setStoreAnimalWeight(storeAnimalWeight);
      navTo("animal-weight-form");
    }
  }

  const speciesChanged = async (row) => {
    if (row !== null) {
      // inyecta filtros en el selector paciente
      let itAnimalSpecies = row.original.itAnimalSpecies;
      rf_txTypeAnimalBreed.current.setFilter(`t_animal_breed.itAnimalSpecies='${itAnimalSpecies}'`);
      rf_txTypeAnimalBreed.current.clear();
    }
  }

  const clearBreed = () => {
    rf_txTypeAnimalBreed.current.clear();
  }

  const canOpenDlgBreed = () => {
    return rf_txTypeAnimalSpecies.current.getKey() !== '';
  }

  const doPrintX = async (option) => {
    let boPreview = rf_print_preview.current.isChecked();
    let boCertSign = rf_print_cert_sign.current.isChecked();
    let inEmployee = storeClient.inEmployee;
    let inClient = storeClient.inClient;
    let inAnimal = storeClient.inAnimal;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inAnimal'] = inAnimal;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('animals/report/history');
    r_print.current.setPreview(boPreview);
    r_print.current.setCertificate(boCertSign ? certificate : {});
    localStorage.setItem('MxAnimalForm.boPreview', boPreview ? '1' : '0');
    //----------------
    setDlgPrint(false);
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInEmployee(inEmployee),
      r_print.current.setInClient(inClient),
      r_print.current.setInAnimal(inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const getCnsOptions = () => {
    return [
      { id: 'G', text: trans('field.cns_grl') },
      { id: 'H', text: trans('field.cns_hos') },
    ];
  }

  const changeOwner = (inClient, txClient) => {
    let record = {};
    record['inAnimal'] = storeClient.inAnimal;
    record['inClient'] = inClient;
    Promise.all([FxFetch.doJsonX("animals/change_animal_owner", record)]).then((result) => {
      if (result[0]) {
        storeClient.inClient = inClient;
        storeClient.txClient = txClient;
        Promise.all([setStoreClient(setStoreClient)]).then((result) => {
          rf_owner.current.setLabel(`${trans('field.owner')}: ${storeClient.txClient}`);
          r_snack_op_successful.current.setOpen(true);
          // 2024-10-16 - daq: para que si el usuario guarda, no vuelva a asignar al cliente viejo.
          rf_inClient.current.setVal(storeClient.inClient);
        });
      }
    });
  }

  const updateAccordions = () => {
    isApp('vett') && r_accordion_weight.current.setFontWeight(r_table_weight.current.isEmpty() ? 'normal' : 'bold');
    isApp('vett') && r_accordion_vaccs.current.setFontWeight(r_table_vaccs.current.isEmpty() ? 'normal' : 'bold');
    isApp('vett') && r_accordion_cnss.current.setFontWeight(r_table_cnss.current.isEmpty() ? 'normal' : 'bold');
    isApp('vett') && r_accordion_rxs.current.setFontWeight(r_table_rxs.current.isEmpty() ? 'normal' : 'bold');
    isApp('vett') && r_accordion_groo.current.setFontWeight(r_table_groo.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_images.current.setFontWeight(r_table_images.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_multimedia.current.setFontWeight(r_table_multimedia.current.isEmpty() ? 'normal' : 'bold');
    forceUIUpdate();
  }

  const integrationImports = async (option) => {
    let data = { 
      client: { inClient: storeClient.inClient, txClient: storeClient.txClient },
      animal: { inAnimal: storeClient.inAnimal, txAnimal: storeClient.txAnimal },
    };
    if (option === 'intech') {
      let fileName = `ibs_localhub_intech_${FxDat.timestamp()}.json`;
      FxFile.saveJson(fileName, data);
    };
    if (option.startsWith('scil')) {
      if (rf_txTypeAnimalSpecies.current.isEmpty()) { r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.species')); return; }
      if (r_form.current.wasChanged()) {
        r_snack_ask_save.current.setOpen(true);
      } else {
        let fileName = `ibs_localhub_scil_${FxDat.timestamp()}.json`;
        data = {...data, ...{device: { name: option.split('-')[1] }}};
        FxFile.saveJson(fileName, data);
      }
    };
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}`}
        xref={r_form}
        url="animals"
        filterIdField='inAnimal'
        filterIdValue={storeClient.inAnimal}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión de historial */}
            {storeClient.inAnimal
              && <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar"
                tooltip={trans('field.print_x', 'field.history')}
                onClick={() => {
                  if (r_table_weight.current.isEmpty() && r_table_vaccs.current.isEmpty() && r_table_cnss.current.isEmpty()) {
                    r_snack_error.current.setValAndOpen(trans('msg.missing_information'));
                  } else {
                    setDlgPrint(true);
                  }
                }}
              />}

            {/* botón de documentos */}
            {storeClient.inAnimal
              && <CxIconButtonNew icon={<CgFileDocument fontSize="24px" />} classType="ibs_iconbutton_toolbar"
                tooltip={trans('field.docs')}
                onClick={() => r_dlg_doc.current.setDlgDocs(true)}
              />}

            {/* botón de importación de integraciones */}
            {size.isLarge && storeClient.inAnimal && isApp('vett') 
              && <CxIconButtonMenu icon={<CgImport fontSize="24px" />} tooltip={trans('field.import_x', 'field.result')} onSelection={integrationImports} className="ibs_iconbutton_toolbar" width='150px'
                options={[
                  { id: 'intech', text: 'Intech', },
                  /* { id: 'scil-SMT100', text: 'Scil Element RC', }, */
                  /* { id: 'scil-HT5', text: 'Scil Element HT5', }, */
                ]}
              />}
            

          </div>
        }
      >
        <CxHidden xref={rf_inClient} dbfield="n_animal.inClient" value={storeClient.inClient} />

        <CxStatic xref={rf_owner} label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' >
          <CxIconButtonNew icon={<BiRefresh fontSize={20} />} 
            style={{ color: 'var(--ibs_color_blue)', margin: '-3px 0 0 2px' }} 
            onClick={() => r_dlg_std.current.doSelectClient()} 
            tooltip={trans('field.change_x', 'field.owner')}
          />
        </CxStatic>
        
        <CxInput xref={rf_txAnimal} label={trans('field.patient')} dbfield="n_animal.txAnimal" capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} maxLength={50} required />

        <CxSearcher xref={rf_txTypeAnimalSpecies} label={trans('field.species')}
          dbfield="t_animal_species.txTypeAnimalSpecies" dbfieldkey="n_animal.itAnimalSpecies"
          onSelection={speciesChanged}
          onClear={clearBreed}
          onButtonAddOTF={() => {
            rf_txTypeAnimalSpecies.current.setDlg(false);
            r_dlg_new_species.current.setDlg(true);
          }}
          url="sett/animal_species"
          col_id="itAnimalSpecies"
          col_tx="txTypeAnimalSpecies"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalSpecies", show: false },
            { id: "1", Header: trans('field.species'), accessor: row => rf_txTypeAnimalSpecies.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '100%' },
          ]}
        />

        <CxSearcher xref={rf_txTypeAnimalBreed} label={trans('field.breed')}
          dbfield="t_animal_breed.txTypeAnimalBreed" dbfieldkey="n_animal.itAnimalBreed"
          canOpenDialog={canOpenDlgBreed}
          onButtonAddOTF={() => {
            rf_txTypeAnimalBreed.current.setDlg(false);
            r_dlg_new_breed.current.setDlg(true);
          }}
          url="sett/animal_breed"
          col_id="itAnimalBreed"
          col_tx="txTypeAnimalBreed"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalBreed", show: false },
            { id: "1", Header: trans('field.breed'), accessor: row => rf_txTypeAnimalBreed.current.getTable().highlightText(row["txTypeAnimalBreed"]), width: '100%' },
          ]}
        />

        <CxSearcher xref={rf_txTypeAnimalCoat} label={trans('field.coat')}
          dbfield="t_animal_coat.txTypeAnimalCoat" dbfieldkey="n_animal.itAnimalCoat"
          canOpenDialog={canOpenDlgBreed}
          onButtonAddOTF={() => {
            rf_txTypeAnimalCoat.current.setDlg(false);
            r_dlg_new_coat.current.setDlg(true);
          }}
          url="sett/animal_coat"
          col_id="itAnimalCoat"
          col_tx="txTypeAnimalCoat"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalCoat", show: false },
            { id: "1", Header: trans('field.coat'), accessor: row => rf_txTypeAnimalCoat.current.getTable().highlightText(row["txTypeAnimalCoat"]), width: '100%' },
          ]}
        />

        <CxDate xref={useRef(null)} label={trans('field.birth')} dbfield="n_animal.dtBirth" showAge />

        <CxCombo xref={useRef(null)}
          label={`${trans('field.gender')}`}
          dbfield='n_animal.boGender'
          list={[
            { id: '0', type: trans('field.animal_female') },
            { id: '1', type: trans('field.animal_male') },
            { id: '2', type: trans('field.na') }, //para animales no sexados
          ]}
        />

        <CxInput xref={useRef(null)} label={trans('field.alert')} dbfield="n_animal.txAlert" maxLength={200} redText />
        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_animal.txReference" maxLength={50} />
        <CxInput xref={rf_txNumber} label={trans('field.number')} dbfield="n_animal.txNumber" maxLength={30}
          placeholder={getStoreValueBo('StoreSession', 'boAutoNumFiles') ? 'AUTO' : ''}
          disabled={getStoreValueBo('StoreSession', 'boAutoNumFiles') && !storeClient.inAnimal}
        />
        <CxInput xref={useRef(null)} label={trans('field.chip')} dbfield="n_animal.txChip" maxLength={23} />

        <CxButtonShowMore label={`[${trans('field.show_more').toUpperCase()}]`} style={{ margin: '4px 0 4px 0px' }} >
          <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield="n_animal.dtFrom" />
          <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield="n_animal.dtTo" />
          <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_animal.txReason" maxLength={50} />
          <CxChooser xref={rf_txTypeAnimalCondition}
            label={trans('field.condition')}
            dbfield="t_animal_condition.txTypeAnimalCondition" dbfieldkey="n_animal.itAnimalCondition"
            table="t_animal_condition"
            col_id="itAnimalCondition"
            col_tx="txTypeAnimalCondition"
          />
          <CxState mode='checkbox' xref={useRef(null)} label={trans('field.reproduction')} dbfield="n_animal.boReproduction" />
          <CxState mode='checkbox' xref={useRef(null)} label={trans('field.pedigree')} dbfield="n_animal.boPedigree" />
          <CxState mode='checkbox' xref={useRef(null)} label={trans('field.sterilization')} dbfield="n_animal.boSterilization" />

          <CxInput xref={rf_txNumberAnimals} label={trans('field.number_animals')} dbfield="n_animal.txNumberAnimals" maxLength={10} />
          <CxInput xref={useRef(null)} label={trans('field.nature')} dbfield="n_animal.txNature" maxLength={50} />
          <CxInput xref={useRef(null)} label={trans('field.habitat')} dbfield="n_animal.txHabitat" maxLength={50} />
          <CxInput xref={useRef(null)} label={trans('field.feeding')} dbfield="n_animal.txFeeding" maxLength={50} />
          <CxChooser xref={rf_txTypeRate}
            label={trans('field.rate')}
            dbfield="t_rate.txTypeRate" dbfieldkey="n_animal.itRate"
            table="t_rate"
            col_id="itRate"
            col_tx="txTypeRate"
            filter={`t_rate.boActive='${'1'}'`}
          />
          <CxDate xref={useRef(null)} label={`${trans('field.rate')} ${trans('field.from')}`} dbfield={"n_animal.dtRateFrom"} />
          <CxDate xref={useRef(null)} label={`${trans('field.rate')} ${trans('field.to')}`} dbfield={"n_animal.dtRateTo"} />

          <CxInput xref={useRef(null)} label={trans('field.passport')} dbfield="n_animal.txPassport" maxLength={30} />
          <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
            dbfield="n_employee.txEmployee" dbfieldkey="n_animal.inEmployee"
            url="employees" col_id="inEmployee" col_tx="txEmployee"
            columns={[
              { id: "0", Header: "ID", accessor: "inEmployee", show: false },
              { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
            ]}
          />
        </CxButtonShowMore>

        {isApp('vett') &&
          <CxAccordion xref={r_accordion_weight} title={trans('field.weight')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_weight}
              margin="-15px 0px 0px 0px"
              url="animals/weight"
              filter={`inAnimal='${storeClient.inAnimal}'`}
              onOpenForm={openFormWeight}
              hasButtonAdd={isFullAccess()}
              columns={[
                { id: "0", Header: "ID", accessor: "inAnimalWeight", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtWeight"]), width: '15%' },
                { id: "2", Header: trans('field.weight'), accessor: row => FxTable.cellTo2r00(row["nuWeight"]), width: '15%' },
                { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '70%', show: size.isLarge },
              ]}
            />
          </CxAccordion>
        }

        {isApp('vett') &&
          <CxAccordion xref={r_accordion_vaccs} title={trans('field.vaccs')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_vaccs}
              margin="-15px 0px 0px 0px"
              url="vaccs"
              filter={`inAnimal='${storeClient.inAnimal}'`}
              onOpenForm={openFormVacc}
              hasButtonAdd={isFullAccess()}
              colorRefs={['table_cell_blue|' + trans('field.pending')]}
              columns={[
                { id: "0", Header: "ID", accessor: "inVacc", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
                { id: "3", Header: trans('field.next'), accessor: row => FxTable.cellToLocalDate(row["dtNext"]), width: '15%', show: size.isLarge },
                { id: "2", Header: trans('field.ps'), accessor: "txPs", width: '35%' },
                { id: "4", Header: trans('field.remarks'), accessor: "txRemarks", width: '35%', show: size.isLarge },
                { id: "B", Header: "pending", accessor: row => Boolean(!row["_inVaccNextSimilar"]), show: false },
              ]}
            />
          </CxAccordion>
        }

        {isApp('vett') &&
          <CxAccordion xref={r_accordion_cnss} title={trans('field.cnss')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_cnss}
              margin="-15px 0px 0px 0px"
              url="cnss"
              filter={`n_cns.inAnimal='${storeClient.inAnimal}'`}
              onOpenForm={openFormCnsEdit}
              hasButtonAdd={false}
              colorRefs={['table_cell_black|' + trans('field.pending'), 'table_cell_green|' + trans('field.finished_1')]}
              columns={[
                { id: "0", Header: "ID", accessor: "inCns", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDateTime(row["dtFrom"]), width: '15%' },
                { id: "2", Header: trans('field.type'), accessor: row => FxTable.replace(row["txType"], getCnsOptions()), width: '15%', show: size.isLarge },
                { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '35%' },
                { id: "4", Header: trans('field.notes'), accessor: "txNotes", width: '35%', show: size.isLarge },
                { id: "G", Header: "automatic", accessor: row => Boolean(row["itStatusCns"] === '000002.SRV'), show: false },
              ]}
              addTableButtonsRight={
                isFullAccess() && <CxButtonMenu onSelection={openFormCnsNew} className='table_button' icon={<IoAddCircleOutline fontSize="28px" />} options={getCnsOptions()} />
              }
            />
          </CxAccordion>
        }

        {isApp('vett') &&
          <CxAccordion xref={r_accordion_rxs} title={trans('field.rxs')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_rxs}
              margin="-15px 0px 0px 0px"
              url="rx_auto"
              filter={`n_rx_auto.inAnimal='${storeClient.inAnimal}'`}
              onOpenForm={openFormRxsEdit}
              hasButtonAdd={false}
              addTableButtonsRight={
                <CxButtonMenu onSelection={openFormRxsNew} className='table_button' icon={<IoAddCircleOutline fontSize="28px" />}
                  options={[
                    { id: 'M', text: trans('field.manual') },
                    { id: 'A', text: trans('field.automatic_0') },
                  ]}
                />
              }
              disabledUntilUpgrade={!FxEdition.isInEdition('CLI_rxs')}
              colorRefs={['table_cell_black|' + trans('field.manual'), 'table_cell_green|' + trans('field.automatic_0')]}
              columns={[
                { id: "0", Header: "ID", accessor: "inRxAuto", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtRxAuto"]), width: '15%' },
                { id: "2", Header: trans('field.rx'), accessor: "txRxAuto", width: '15%' },
                { id: "3", Header: trans('field.series'), accessor: "txTypeSeriesRx", width: '15%' },
                { id: "4", Header: trans('field.notes'), accessor: "txNotes", width: '55%', show: size.isLarge },
                { id: "G", Header: "automatic", accessor: row => Boolean(row["inInvoice"]), show: false },
              ]}
            />
          </CxAccordion>
        }

        <CxAccordion xref={r_accordion_groo} title={trans('field.grooming')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
          <CxTable
            xref={r_table_groo}
            margin="-15px 0px 0px 0px"
            url="groo"
            filter={`n_grooming.inAnimal='${storeClient.inAnimal}'`}
            onOpenForm={openFormGroo}
            hasButtonAdd={isFullAccess()}
            columns={[
              { id: "0", Header: "ID", accessor: "inGrooming", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDateTime(row["dtFrom"]), width: '15%' },
              { id: "2", Header: trans('field.groo_pickup'), accessor: row => FxTable.cellToLocalTime(row["dtTo"]), width: '15%', show: size.isLarge },
              { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '35%' },
              { id: "4", Header: trans('field.notes'), accessor: "txNotes", width: '35%', show: size.isLarge },
            ]}
          />
        </CxAccordion>

        <CxAccordion xref={r_accordion_images} title={trans('field.images')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
          <CxTable
            xref={r_table_images}
            margin="-15px 0px 0px 0px"
            url="images"
            filter={`n_image.inAnimal='${storeClient.inAnimal}'`}
            onOpenForm={openFormImage}
            hasButtonAdd={isFullAccess()}
            disabledUntilUpgrade={!FxEdition.isInEdition('CLI_img')}
            columns={[
              { id: "0", Header: "ID", accessor: "inImage", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
              { id: "2", Header: trans('field.type'), accessor: "txTypeImage", width: '15%' },
              { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
            ]}
          />
        </CxAccordion>

        <CxAccordion xref={r_accordion_multimedia} title={trans('field.files2')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
          <CxTable
            xref={r_table_multimedia}
            margin="-15px 0px 0px 0px"
            url="multimedia"
            filter={`n_multimedia.txLinkTable='n_animal' and n_multimedia.txLinkId='${storeClient.inAnimal}'`}
            onOpenForm={openFormMultimedia}
            hasButtonAdd={isFullAccess()}
            disabledUntilUpgrade={!FxEdition.isInEdition('CLI_mme')}
            columns={[
              { id: "0", Header: "ID", accessor: "inMultimedia", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtMultimedia"]), width: '15%' },
              { id: "2", Header: trans('field.type'), accessor: "txTypeMultimedia", width: '15%' },
              { id: "3", Header: trans('field.issue'), accessor: "txIssue", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
            ]}
          />
        </CxAccordion>

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_animal.txNotes" minRows={4} speech />

      </GxForm>

      <CxDlgTable
        xref={r_dlgtable_rxs_invoice}
        open={dlgRxsInvoice}
        onSelect={openFormRxsNewAuto}
        onClose={() => setDlgRxsInvoice(false)}
        url={'rx_auto/invoices'}
        filter={`n_invoice.inAnimal='${storeClient.inAnimal}'`}
        columns={[
          { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '50%' },
          { id: "2", Header: trans('field.invoice'), accessor: "txInvoice", width: '50%' },
        ]}
        title={trans('field.invoice')}
      />

      <CxDlgDoc xref={r_dlg_doc} inClient={storeClient.inClient} inAnimal={storeClient.inAnimal} txModule={'A'} />

      <MxTypeOTF xref={r_dlg_new_species}
        url="animals/species/otf"
        name='field.species'
        onOk={(itXXX, txTypeXXX) => {
          rf_txTypeAnimalSpecies.current.setKey(itXXX);
          rf_txTypeAnimalSpecies.current.setVal(txTypeXXX);
          rf_txTypeAnimalBreed.current.setFilter(`t_animal_breed.itAnimalSpecies='${itXXX}'`);
          rf_txTypeAnimalBreed.current.clear();
        }}
      />
      <MxTypeOTF xref={r_dlg_new_breed}
        url="animals/breed/otf"
        name='field.breed'
        parent={rf_txTypeAnimalSpecies}
        onOk={(itXXX, txTypeXXX) => {
          rf_txTypeAnimalBreed.current.setKey(itXXX);
          rf_txTypeAnimalBreed.current.setVal(txTypeXXX);
        }}
      />
      <MxTypeOTF xref={r_dlg_new_coat}
        url="animals/coat/otf"
        name='field.coat'
        onOk={(itXXX, txTypeXXX) => {
          rf_txTypeAnimalCoat.current.setKey(itXXX);
          rf_txTypeAnimalCoat.current.setVal(txTypeXXX);
        }}
      />

      <CxDialog title={trans('msg.ask_print_x', 'field.history')} open={dlgPrint} onCancel={() => setDlgPrint(false)} onOk={() => doPrintX()} height='290px'>
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxState mode='checkbox' xref={rf_print_preview}
            label={trans('field.preview')}
            width='var(--ibs_control_width_md)'
            value={localStorage.getItem('MxAnimalForm.boPreview') || '1'}
          />
          <CxState mode='checkbox' xref={rf_print_cert_sign}
            label={trans('field.certificate_based_signature')}
            width='var(--ibs_control_width_md)'
            value={'0'}
            setCertificate={setCertificate}
            onClick={(value) => { if (value === '1') {
              if (!FxEdition.isInEdition('XXX_sig')) {
                rf_print_cert_sign.current.setValBoolean(false);
                r_dlg_upgrade.current.setOpen(true);
              } else {
                r_dlg_certificate.current.setOpen(true);
              }
            }; }}
          />
        </div>
      </CxDialog>

      <CxDlgCertificate xref={r_dlg_certificate} setCertificate={setCertificate} height='290px'
        onCancel={() => {
          rf_print_cert_sign.current.setValBoolean(false);
        }}
      />

      <CxDlgAlert xref={r_dlg_alert} />
      <CxDlgUpgrade xref={r_dlg_upgrade} />
      <GxPrint xref={r_print} />
      <GxDlgStandard xref={r_dlg_std} onSelectedClient={changeOwner} title={trans('field.new_1_x', 'field.owner')} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_required} severity="error" />
    </div>
  );

}

export default MxAnimalForm;
