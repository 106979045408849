// Funciones para traducción.
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

export const useTranslation = () => {
  let ar = JSON.parse(sessionStorage.getItem('trans')||null);

  const _storeInSession = (ar) => {
    sessionStorage.setItem('trans', JSON.stringify(ar));
  }

  const _getItem = (key, ...replacers) => {
    if (ar && ar[key]) {
      let rv = ar[key];
      for (let i=0; i<replacers.length; i++) {
        rv = rv.replace('%s', ar[replacers[i]]||replacers[i]); // 2024-07-08 - daq: quitamos toLowerCase()
      }
      rv = rv.replace(' %s', ''); // 2024-02-05 - daq: si no se pasó parámetro, elimino variable.
      return rv;
    } else {
      FxLog.errorx('useTranslation._getItem', 'NO HAY TRADUCCIÓN PARA: ' + key);
      return key;
    }
  }

  return [
    {
      storeTransInSession: _storeInSession,
      trans: _getItem,
    },
  ];
};
