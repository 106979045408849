import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxDialog from '../../cx/CxDialog';
import CxDate from '../../cx/CxDate';
import CxColor from '../../cx/CxColor';
import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import { StoreSettBranch, getStoreValueBo, getStoreValue } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import CxState from '../../cx/CxState';
import CxChooser from '../../cx/CxChooser';
import * as FxFetch from '../../fx/FxFetch';
import { useAccess } from '../../hx/useAccess';


const MxSettBranchForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.stockrooms'));
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  let rf_txCompany = React.useRef(null);
  let rf_txBranchId = React.useRef(null);
  let rf_boMainOffice = React.useRef(null);
  let rf_txTypeCash_new = React.useRef(null);
  let rf_txTypeCash = React.useRef(null);
  let rf_txBank = React.useRef(null);

  const [mainOffice, setMainOffice] = React.useState(true);

  const r_snack_error = React.useRef(null);
  let r_error_message = React.useRef(null);

  const [storeSettBranch, setStoreSettBranch] = useSessionStorage('StoreSettBranch', StoreSettBranch);

  const [dlgNewCash, setDlgNewCash] = React.useState(false);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      let record = {};
      Promise.all([FxFetch.doJsonX('sett/branches/get_cy', record)]).then((result) => {
        if (result[0]) {
          rf_txCompany.current.setVal(result[0].theValue);
        }
      });
    } else { // registro existente
      setMainOffice(data[0].boMainOffice==='1');
    }
  }
  const formSave = async (lastSaveRv) => {

    if (lastSaveRv.theStatus === 'error') {
      if (lastSaveRv.theValue.startsWith("rv_")) {
        // errores al guardar
        if (lastSaveRv.theValue.startsWith("rv_branch_name_exists")) {
          r_snack_error.current.setVal(trans('msg.existing_x','field.name'));
        }
        if (lastSaveRv.theValue.startsWith("rv_branch_requires_4_chars")) {
          r_snack_error.current.setVal(`${trans('field.branch')}-ID: 4 ${trans('field.characters')}`);
        }
        if (lastSaveRv.theValue.startsWith("rv_branch_id_exists")) {
          r_snack_error.current.setVal(trans('msg.existing_x','field.id'));
        }
        if (lastSaveRv.theValue.startsWith("rv_main_office_doesnt_exists")) {
          r_snack_error.current.setVal(trans('msg.is_needed_x','field.office_main'));
        }
        r_snack_error.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeSettBranch.inBranch = lastSaveRv.theValue;
      Promise.all([setStoreSettBranch(storeSettBranch)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const createCashReg = () => {
    try{
      let txTypeCash = rf_txTypeCash_new.current.getVal();
      if (txTypeCash.length > 0) {
        // crea una nueva caja y la selecciona
        let filter = {};
        filter['itCash'] = null;
        let record = {};
        record['t_cash.txTypeCash'] = txTypeCash;
        Promise.all([FxFetch.doJsonF("sett/cash/cu", filter, record)]).then((result) => {
          if (result[0]) {
            rf_txTypeCash.current.setKey(result[0].theValue);
            rf_txTypeCash.current.setVal(txTypeCash);
          }
        });
    
      }
      setDlgNewCash(false);
      
    } catch(error) {
      FxLog.errorx('MxSettBranchForm.create', error);
    }
  }

  const getDValCashReg = () => {
    if (rf_txBranchId.current && rf_txBranchId.current.getVal().length>0) {
      return `${trans('field.cash_reg')} ${rf_txBranchId.current.getVal().toUpperCase()}`;
    }
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.branch')}
        xref={r_form}
        url="sett/branches"
        filterIdField='inBranch'
        filterIdValue={storeSettBranch.inBranch}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={!mainOffice && isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_branch.inBranch" value={storeSettBranch.inBranch || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_branch.txIDI" value={getStoreValue('StoreSession', 'txIdi') || ''} />

        <CxInput xref={rf_txCompany} label={trans('field.company')} dbfield="n_branch.txCompany" required maxLength={100} capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} />
        <CxInput xref={useRef(null)} label={trans('field.branch')} dbfield="n_branch.txBranch" required maxLength={100} capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} />
        <CxInput xref={rf_txBranchId} label={`${trans('field.branch')}-ID`} dbfield="n_branch.txBranchId" upperCase required maxLength={4} />
        <CxColor xref={useRef(null)} label={trans('field.color')} dbfield="n_branch.txBranchRgb" />
        <CxInput xref={useRef(null)} label={trans('field.ein')} dbfield="n_branch.txEin"  maxLength={15} />
        <CxState mode='checkbox' xref={rf_boMainOffice} label={trans('field.office_main')} dbfield="n_branch.boMainOffice" />

        <CxDate xref={useRef(null)} label={trans('field.from')} dbfield={"n_branch.dtFrom"} required />


        <CxChooser xref={rf_txTypeCash} 
          label={trans('field.cash_reg')} 
          dbfield="t_cash.txTypeCash" dbfieldkey="n_branch.itCash"
          id="t_cash"
          table="t_cash"
          col_id="itCash"
          col_tx="txTypeCash"
          readOnly={false}
          onCreate={() => setDlgNewCash(true)}
          required
        />

        <CxChooser xref={rf_txBank} 
          label={trans('field.bank')} 
          dbfield="n_bank.txBank" dbfieldkey="n_branch.inBank"
          id="n_bank"
          table="n_bank"
          col_id="inBank"
          col_tx="txBank"
          readOnly={false}
        />

        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_branch.txPhone1" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.phone2')} dbfield="n_branch.txPhone2" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_branch.txMobile" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_branch.txEmail" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.web')} dbfield="n_branch.txWeb" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.facebook')} dbfield="n_branch.txFacebook" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_branch.txAddress" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_branch.txCity" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_branch.txState" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_branch.txPostalCode"  maxLength={10} />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_branch.txNotes" minRows={4} />

      </GxForm>

      <CxDialog title={trans('field.create_x','field.cash_reg')} open={dlgNewCash} onOk={createCashReg} onCancel={() => setDlgNewCash(false)} height='200px'>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
          <CxInput xref={rf_txTypeCash_new} label={trans('field.name')} defaultValue={getDValCashReg()} required />
        </div>
      </CxDialog>

      <CxSnackbar xref={r_snack_error} severity="error" value={r_error_message.current} />

    </div>
  );

}

export default MxSettBranchForm;
