import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxCombo from '../../cx/CxCombo';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';


const MxSettSlsPrinting = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_save = React.useRef(null);
  
  const rf_sales_slip_width = React.useRef(null);
  const rf_sales_slip_margin_left = React.useRef(null);

  let r_form = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let txSalesSlipWidth = localStorage.getItem('MxSettSlsPrinting.sales_slip_width');
    let txSalesSlipMarginLeft = localStorage.getItem('MxSettSlsPrinting.sales_slip_margin_left');

    rf_sales_slip_width.current.setVal(txSalesSlipWidth||'60');
    rf_sales_slip_margin_left.current.setVal(txSalesSlipMarginLeft);
  }

  const formSaveCustom = async () => {

    localStorage.setItem('MxSettSlsPrinting.sales_slip_width', rf_sales_slip_width.current.getVal());
    localStorage.setItem('MxSettSlsPrinting.sales_slip_margin_left', rf_sales_slip_margin_left.current.getVal());

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.printing')}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxCombo xref={rf_sales_slip_width}
          label={`${trans('field.sales_slip')} - ${trans('field.width')}`}
          list={[
            { id: '45', type: '45mm' },
            { id: '60', type: '60mm' },
            { id: '70', type: '70mm' },
            { id: '80', type: '80mm' },
          ]}
        />
        <CxNumber xref={rf_sales_slip_margin_left} decimals={0}
          label={`${trans('field.sales_slip')} - ${trans('field.margin_left')}`} />

        
      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettSlsPrinting;
