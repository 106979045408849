import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
//import * as FxLog from '../fx/FxLog';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { CgEditBlackPoint } from "react-icons/cg";


const useStyles = makeStyles((props) => ({
  root: {
    color: 'var(--ibs_color_cy_blue)',
    '&:hover > $content': {
      //backgroundColor: 'var(--ibs_color_gray_light)',
    },
    '&:focus > $content, &$selected > $content': {
      //backgroundColor: 'var(--ibs_color_gray_light)',
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: 'var(--ibs_color_cy_blue)',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
    paddingRight: '6px',
    fontWeight: 'bold',
    '$expanded > &': {
      fontWeight: 'normal',
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: '0px',
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 4px 4px 4px',
  },
  icon: {
    color: props => props.color,
    margin: '0px 5px 5px 0px',
    height: '15px',
    width: '20px',
  },
  labelText: {
    color: props => props.labelColor||'var(--ibs_color_black)',
    fontWeight: 'inherit',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
}));

export const CxTreeItem = (props) => {
  const { labelText, icon, labelInfo, labelColor, color, bgColor, ...other } = props;
  const classes = useStyles(props);
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <div className={classes.icon}>
            {icon}
          </div>
          {!icon && <CgEditBlackPoint size={20} color="inherit" style={{ color: 'var(--ibs_color_red)', marginRight: '5px' }} />}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default CxTreeItem;