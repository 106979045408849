import React, { useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


const useStyles = makeStyles({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "white"
    },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent"
    }
  },
  root: {
    height: 264,
    flexGrow: 1,
  },
});

export const CxTree = props => {
  const classes = useStyles();

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'CxTree' };

  const doNodeToggle = (event, nodeIds) => {
    sessionStorage.setItem('CxTree.' + (props.id||''), JSON.stringify(nodeIds));
  };
  const getExpandedNodes = () => {
    return JSON.parse(sessionStorage.getItem('CxTree.' + (props.id||'')))||[];
  };

  return (
    <div id='div_CxTree'>
      <TreeView
        className={classes.root}
        defaultExpanded={getExpandedNodes()}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeToggle={doNodeToggle}
      >
        {props.children}

      </TreeView>
    </div>
  );
};

export default CxTree;
