import React from 'react';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import * as FxTable from '../../fx/FxTable';
import { isApp } from '../../gx/GxStore';
import { useWindowSize } from '../../hx/useWindowSize';
import { useTranslation } from '../../hx/useTranslation';
import { getStoreValue, } from "../../gx/GxStore";

const MxAppointmentListCP = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  
  const r_list = React.useRef(null);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inCalendar", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDateTime(row["dtFrom"])), width: '20%' });
        if (isApp('vett')) {
          cols.push({id: id++, Header: trans('field.patient'), accessor: "txAnimal", width: '20%'});
        }
        cols.push({ id: id++, Header: trans('field.issue'), accessor: "txRemarks", width: '60%' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDateTime(row["dtFrom"])), width: '40%' });
        if (isApp('vett')) {
          cols.push({id: id++, Header: trans('field.patient'), accessor: "txAnimal", width: '25%'});
        }
        cols.push({ id: id++, Header: trans('field.issue'), accessor: "txRemarks", width: '35%' });
      }

      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtFrom"]), show: false });

    } catch(error) {
      FxLog.errorx('MxAppointmentListCP.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.my_x','field.appointments')}
        xref={r_list}
        fullscreen
        hasSearchBox={false}
        hasButtonAdd={false}
        url="cp/appointments"
        filter={`n_calendar.inClient='${getStoreValue('StoreSession', 'inClientCP')}'`}
        /* onOpenForm={openForm} */
        colorRefs={ [ 
          'table_cell_gray|' + trans('field.past_0'), 
        ] }
        columns={getTableColumns()}
        disabled={true}
      />

    </div>
  );

}

export default MxAppointmentListCP;
