import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from './fx/FxLog';
//import { useTranslation } from './hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import { makeStyles } from '@material-ui/core/styles';
//import MxUpgradeCard from './MxUpgradeCard';
import { useTranslation } from './hx/useTranslation';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: '50px',
    padding: '15px',
    background: 'var(--ibs_color_gray_light)',
  },
  label: {
    display: 'block',
    fontSize: "16px",
    margin: "0px",
    color: 'var(--ibs_color_black)',
    whiteSpace: 'pre-line', // breaks lines
  },
}));

const MxUpgrade = (props) => {
  //const [{ navTo }] = useNav();
  const [{ trans }] = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {
    FxLog.infox("MxUpgrade.useEffect", "...");
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>

      <div className={classes.root}>
        <Typography className={classes.label}>
          {trans('msg.ask_upgrade')}
        </Typography>
      </div>

    </div>
  );
}

export default MxUpgrade;
