// Funciones para traducción.
// eslint-disable-next-line no-unused-vars
import React, { useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';

export const useBlob = (urlPartial, inXxx, inXxxBlob, txXxxBlob) => {
  const base64PrefixForPNG = 'data:image/png;base64,';
  const base64PrefixForJPEG = 'data:image/jpeg;base64,';
  const base64PrefixForPDF = 'data:application/pdf;base64,';

  let r_url = useRef(urlPartial);
  let r_inXxx = useRef(inXxx);
  let r_inXxxBlob = useRef(inXxxBlob);
  let r_txXxxBlob = useRef(txXxxBlob);


  const _delete = async (inXxxBlob) => {
    let record = {};
    if (inXxxBlob) {
      if (inXxxBlob !== null) {
        record[r_inXxxBlob.current] = inXxxBlob;
      }
      return Promise.all([FxFetch.doJsonX(`${r_url.current}/blob_del`, record)]).then((result) => {
        return result;
      });
    }
  };

  const _load = async (inXxxBlob, txXxxBlob) => {
    let record = {};
    if (inXxxBlob) {
      if (inXxxBlob !== null) {
        record[r_inXxxBlob.current] = inXxxBlob;
        record[r_txXxxBlob.current] = txXxxBlob;
      }
      return Promise.all([FxFetch.doJsonX(`${r_url.current}/blob_get`, record)]).then((result) => {
        let prefix = '';
        if (txXxxBlob.toLowerCase().endsWith('.png') || txXxxBlob.toLowerCase().endsWith('.jpg') || txXxxBlob.toLowerCase().endsWith('.jpeg')) { prefix = base64PrefixForPNG };
        if (txXxxBlob.toLowerCase().endsWith('.pdf')) { prefix = base64PrefixForPDF };
        return { 
          prefix: prefix, 
          obFile: result[0].obFile, 
          txFileFormat: result[0].txFileFormat, 
          txFileNameInput: result[0].txFileNameInput,
          txFileNameOutput: result[0].txFileNameOutput,
        };
      });
    }
  };

  const _save = async (inXxx, txXxxBlob, strBase64) => {
    let record = {};
    record[r_inXxx.current] = inXxx;
    record[r_txXxxBlob.current] = txXxxBlob;
    let prefix = '';
    if (txXxxBlob.toLowerCase().endsWith('.png')) { prefix = base64PrefixForPNG };
    if (txXxxBlob.toLowerCase().endsWith('.jpg')) { prefix = base64PrefixForJPEG };
    if (txXxxBlob.toLowerCase().endsWith('.jpeg')) { prefix = base64PrefixForJPEG };
    if (txXxxBlob.toLowerCase().endsWith('.pdf')) { prefix = base64PrefixForPDF };
    record['base64'] = strBase64 ? strBase64.replace(prefix, '') : null;
    return Promise.all([FxFetch.doJsonX(`${r_url.current}/blob_set`, record)]).then((result) => {
      return result[0];
    });
  };

  const _getBase64 = file => {
    return new Promise(resolve => {
      //let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader);
        baseURL = reader.result;
        //console.log(baseURL);
        resolve(baseURL);
      };
      //console.log(fileInfo);
    });
  };

  return [
    {
      _blobDelete: _delete,
      _blobLoad: _load,
      _blobSave: _save,
      _blobGetBase64: _getBase64,
    },
  ];
};
