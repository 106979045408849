import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import * as FxFetch from '../../fx/FxFetch';
import * as FxLog from '../../fx/FxLog';
import * as FxInet from '../../fx/FxInet';
import { hasSessionStarted } from "../../gx/GxStore";
import CxDialog from '../../cx/CxDialog';
import { BiExit } from "react-icons/bi";


/* Componente para control de logins */
const TxTimerLogins = (props) => {
  const [{ navExit }] = useNav();
  const [{ trans }] = useTranslation();
  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);

  const [dlg, setDlg] = React.useState(false);
  const [msg, setMsg] = React.useState(false);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (hasSessionStarted() && FxInet.isOnline()) {
        let record = {};
        record['process'] = 'silent_login_get_status';
        Promise.all([FxFetch.doJsonX('login/get_status', record)]).then((result) => {
          if (result[0]) {
            let txStatus = result[0].theValue;
            if (txStatus.includes('REPLACED')) {
              setMsg(trans('field.login_session_replaced'));
              setDlg(true);
            }
            if (txStatus.includes('EXPIRED')) {
              setMsg(trans('field.login_session_expired'));
              setDlg(true);
            }

          } else {
            doExit();
          }
        });
      }
    }, 60 * SEC);

    return () => {
      FxLog.infox("TxTimerLogins.useEffect.return()", "cleaning up timer...");
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, [dlg]);

  const doExit = () => {
    FxLog.infox("TxTimerLogins.doExit", "exiting...");
    clearInterval(timer.current);
    navExit();
  };

  return (
    <div>
      <CxDialog title={trans('field.exit')} open={dlg} onOk={() => doExit()} height='200px'>
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
          <BiExit fontSize="50px" style={{ color: 'var(--ibs_color_red)' }} />
          <span>{msg}</span>
        </div>
      </CxDialog>
    </div>
  );
}

export default TxTimerLogins;
