// funciones para sms.
// eslint-disable-next-line no-unused-vars
import * as FxLog from './FxLog';
import * as FxFetch from './FxFetch';
import * as FxNum from './FxNum';

export const getCredits = async (itSms) => {
  let record = {};
  record['itSms'] = itSms||null; // utiliza la primera cuenta disponible.
  return Promise.all([FxFetch.doJsonX(`sett/sms/get_credits`, record)]).then((result) => {
    if (result[0]) {
      if (result[0].theValue.startsWith("rv_")) {
        return FxNum.toString('0.00');
      } else {
        return FxNum.toString(result[0].theValue.toString());
      }
    } else {
      return 0;
    }
  });
}

export const purchaseCredits = async (your_name, your_email, quantity) => {
  let record = {};
  record['your_name'] = your_name;
  record['your_email'] = your_email;
  record['quantity'] = quantity;
  return Promise.all([FxFetch.doJsonX(`sett/sms/purchase`, record)]).then((result) => {
    if (result[0]) {
      if (result[0].theValue.startsWith("rv_")) {
        return 'error';
      } else {
        return 'OK';
      }
    }
  });
}
