import React, { useImperativeHandle } from "react";
import CxDialog from "./CxDialog";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import '../../styles/main.css';
import CxInput from './CxInput';
import CxFile from './CxFile';
import { useTranslation } from '../hx/useTranslation';
import CxSnackbar from './CxSnackbar';
import * as FxFile from '../fx/FxFile';


const CxDlgCertificate = props => {
  const [{ trans }] = useTranslation();

  const { onOk, onCancel, setCertificate } = props;

  const r_dlg = React.useRef(null);

  let rf_file = React.useRef(null);
  let rf_pin = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const r_snack_required = React.useRef(null);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, getPin, setOpen,
    };
  });
  const getType = () => { return 'CxDlgCertificate' };
  const getPin = () => { return rf_pin.current };


  const doCancel = () => {
    setCertificate({ base64KeyStore: null, pin: null });
    if (onCancel) onCancel();
    setOpen(false);
  }

  const doOk = () => {
    let file = rf_file.current.getFile();
    let pin = rf_pin.current.getVal();
    if (file && pin) {
      Promise.all([FxFile.getBase64FromFile(file)]).then((result) => {
        const base64Prefix = 'data:application/x-pkcs12;base64,';
        const base64KeyStore = result[0].replace(base64Prefix, '');
        setCertificate({ base64KeyStore: base64KeyStore, pin: pin });
        localStorage.setItem('CxDlgCertificate.certificate_file', base64KeyStore);
        localStorage.setItem('CxDlgCertificate.certificate_name', file.name);
        localStorage.setItem('CxDlgCertificate.certificate_pin', pin);
        setOpen(false);
        if (onOk) onOk();
      });
    } else {
      if (!file) {
        r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.file2'));
        return;
      }
      if (!pin) {
        r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.password'));
        return;
      }
    }
  }

  return (
    <div>
      <CxDialog
        xref={r_dlg}
        title={trans('msg.select_x', 'field.certificate')}
        open={open}
        onCancel={doCancel}
        onOk={doOk}
        height={props.height}
        >

        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>

          <CxFile xref={rf_file} label={trans('field.file2')}
            onLoad={() => {
              let fileName = localStorage.getItem('CxDlgCertificate.certificate_name');
              let base64File = localStorage.getItem('CxDlgCertificate.certificate_file');
              if (base64File) {
                Promise.all([FxFile.getFileFromBase64(base64File, fileName, 'application/x-pkcs12')]).then((result) => {
                  let file = result[0];
                  if (file) {
                    rf_file.current.setFile(file);
                    rf_file.current.setFileName(file.name);
                  }
                });
              }
            }}
            width='var(--ibs_control_width_md)'
            required
          />

          <CxInput xref={rf_pin} label={trans('field.password')} 
            type="password"
            defaultValue={localStorage.getItem('CxDlgCertificate.certificate_pin') || ''}
            width='var(--ibs_control_width_md)'
            required
          />

        </div>

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
    </div>
  );
};

export default CxDlgCertificate;
