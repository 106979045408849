import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettAnimalBreed } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettAnimalBreedForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_record_used = React.useRef(null);

  let r_form = React.useRef(null);
  let rf_txTypeAnimalSpecies = React.useRef(null);

  const [storeSettAnimalBreed, setStoreSettAnimalBreed] = useSessionStorage('StoreSettAnimalBreed', StoreSettAnimalBreed);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettAnimalBreed.itAnimalBreed = lastSaveRv.theValue;
      Promise.all([setStoreSettAnimalBreed(storeSettAnimalBreed)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue==='rv_error') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.patients')}: ${trans('field.breed')}`}
        xref={r_form}
        url="sett/animal_breed" 
        filterIdField='itAnimalBreed'
        filterIdValue={storeSettAnimalBreed.itAnimalBreed} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_animal_breed.itAnimalBreed" value={storeSettAnimalBreed.itAnimalBreed || ''} />

        <CxInput xref={useRef(null)} label={trans('field.name')} dbfield="t_animal_breed.txTypeAnimalBreed" required />

        <CxSearcher xref={rf_txTypeAnimalSpecies} label={trans('field.species')}
          dbfield="t_animal_species.txTypeAnimalSpecies" dbfieldkey={`t_animal_breed.itAnimalSpecies`}
          url="sett/animal_species"
          col_id="itAnimalSpecies"
          col_tx="txTypeAnimalSpecies"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalSpecies", show: false },
            { id: "1", Header: trans('field.species'), accessor: row => rf_txTypeAnimalSpecies.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '100%' },
          ]}
          required
        />
        
      </GxForm>

      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />

    </div>
  );

}

export default MxSettAnimalBreedForm;
