import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import { useNav } from '../hx/useNav';
import { useSessionStorage } from '../hx/useSessionStorage';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Outlet } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import * as FxFetch from '../fx/FxFetch';
import { MxMainListCP, MxMainListItemCP, MxMainListGroupCP } from './MxMainListCP';
import { clearStore, StoreCalendarView, StoreSlsLst, getStoreValue, isApp } from "../gx/GxStore";
import CxDialog from '../cx/CxDialog';
import CxTooltip from '../cx/CxTooltip';
import CxState from '../cx/CxState';

import MenuIcon from '@material-ui/icons/Menu';

import { BiExit } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";
import { FaCoins, FaFolderOpen, FaPrescription } from "react-icons/fa";
import { BsFillCalendarFill, BsFillPersonFill, } from "react-icons/bs";
import { MdPets } from "react-icons/md";

const DRAWER_WIDTH = 270;

const rgb2hex=c=>'#'+c.match(/\d+/g).map(x=>(+x).toString(16).padStart(2,0)).join``;
const getAppbarBackground = () => {
  let hex = rgb2hex(`rgb(${(getStoreValue('StoreSession', 'txBranchRgb')||'255-255-255')})`);
  //console.log(hex);
  return hex;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1, // pone el drawer debajo del appbar
    padding: '0 0 0 12px',
    height: 'var(--ibs_appbar_height)',
    background: getAppbarBackground(),
    color: 'var(--ibs_color_black)',
    "&.MuiPaper-elevation4": {
      /* boxShadow: 'none',*/
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.06) 0px 1px 0px 0px',
      /* boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px', */
    },
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    padding: ' 0 0 0 9px',
    marginRight: '0px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appbarTitle: {
    width: '100%',
    //background: 'blue',
    paddingLeft: '6px',
    paddingBottom: '5px',
    textAlign: 'left',
    //color: 'var(--ibs_color_cy_blue)',
    color: theme.palette.getContrastText(getAppbarBackground()),
    fontFamily: 'var(--ibs_brand_font_family)',
    fontSize: '11px',
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingLeft: '0px',
      lineHeight: '20px',
    },
  },
  appbarExit: {
    color: theme.palette.getContrastText(getAppbarBackground()),
  },
  appbarText: {
    lineHeight: 1.2,
    //background: 'yellow',
    margin: '15px 6px 15px 6px',
    padding: '1px 0px 0px 0px',
    color: theme.palette.getContrastText(getAppbarBackground()),
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: '9px',
    display: 'inline',
    cursor: 'default',
    [theme.breakpoints.up('sm')]: {
    },
  },
  appbarActions: {
    //background: 'yellow',
    paddingLeft: '6px',
    paddingBottom: '6px',
    display: 'flex',
  },
  appbarDivider: {
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      height: '42px',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    //background: 'red',
    padding: '0px',
    //minHeight: APPBAR_HEIGHT, // reemplazado por MxMainListCP div logo.
  },
  drawerPaper: {
    //background: 'var(--ibs_color_gray_light)',
    width: DRAWER_WIDTH,
  },
  content: {
    marginTop: 55,
    background: 'var(--ibs_color_white)',
    flexGrow: 1,
    padding: theme.spacing(0),
  },
}));

function MxMainCP(props) {
  const [{ navTo, navClear, navExit }] = useNav();
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const rf_boDontAskShowFirstSteps = React.useRef(null);

  const [dlgAccessDenied, setDlgAccessDenied] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [storeCalendarView, setStoreCalendarView] = useSessionStorage('StoreCalendarView', StoreCalendarView);
  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [groupFiles, setGroupFiles] = React.useState(true);

  const [dlgAskShowFirstSteps, setDlgAskShowFirstSteps] = React.useState(false);

    // muestra según permisos
    const [showAppointments, setShowAppointments] = React.useState(false);
    const [showBilling, setShowBilling] = React.useState(false);
  

  React.useEffect(() => {
    let boDontAskShowFirstSteps = Boolean((localStorage.getItem('MxMainCP.boDontAskShowFirstSteps')||'0')==='1');
    if (!boDontAskShowFirstSteps) {
      setDlgAskShowFirstSteps(true);
    }
 
    // acceso
    let record = {};
    //record['op'] = op;
    Promise.all([FxFetch.doJsonX('cp/animals/access', record)]).then((result) => {
      if (result[0]) {
        setShowAppointments(result[0][0].boAppointments==='1');
        if (result[0][0].boQuotations==='1' || result[0][0].boInvoices==='1' || result[0][0].boReceipts==='1') {
          setShowBilling(true);
          if ( result[0][0].boQuotations==='1') storeSlsLst.listType = 'q';
          if ( result[0][0].boReceipts==='1') storeSlsLst.listType = 'r';
          if ( result[0][0].boInvoices==='1') storeSlsLst.listType = 'i';
          setStoreSlsLst(storeSlsLst);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const goto = (url) => {
    // quita menú
    setMobileOpen(false);

    // limpia objetos de sesión
    clearStore('StoreCalendarView', storeCalendarView);

    // limpia stack de url 
    navClear();

    // va al módulo pedido
    navTo(url);
  };

  const getItem = (icon, moduleName, url) => {
    let iconColor = 'var(--ibs_color_black)';
    return <MxMainListItemCP 
      icon={icon} 
      iconColor={iconColor} 
      text={moduleName} 
      textColor= {'var(--ibs_color_black)'}
      type='nested' 
      onClick={() => goto(url)} 
      />;
  }

  const doExit = () => {
    let record = {};
    record['process'] = 'silent_login_set_exit';
    Promise.all([FxFetch.doJsonX('cp/login/set_exit', record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        navExit();
      }
    });
  }

  const openFirstSteps = (boOpen) => {
    setDlgAskShowFirstSteps(false);
    localStorage.setItem('MxMainCP.boDontAskShowFirstSteps', rf_boDontAskShowFirstSteps.current.getVal());
    if (boOpen) {
      window.open(`https://iberical.com/ibericalhelp/${getStoreValue('StoreSession', 'txLocale').substring(0,2)}_${getStoreValue('StoreSession', 'txApp')}.php`, '_blank', 'noreferrer');
    }
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <MxMainListCP>
        <MxMainListGroupCP icon={<FaFolderOpen />} text={trans('field.main')} group={groupFiles} onClick={() => setGroupFiles(!groupFiles)} >
          {showAppointments && getItem(<BsFillCalendarFill />, trans('field.my_x','field.appointments'), 'appointment-list-cp')}
          {isApp('opti') && getItem(<FaPrescription />, trans('field.my_x','field.rxs'), 'rx-form-cp')}
          {isApp('vett') && getItem(<MdPets />, trans('field.my_x','field.pets'), 'animal-list-cp')}
          {showBilling && getItem(<FaCoins />, trans('field.my_x','field.purchases'), 'sls-doc-list-cp')}
          {getItem(<BsFillPersonFill />, trans('field.my_x','field.data'), 'data-cp')}
        </MxMainListGroupCP>

      </MxMainListCP>
      <Divider />
    </div>
  );


  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} color="inherit" edge="start" onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.appbarTitle}>
            {getStoreValue('StoreSession', 'txCompany').toUpperCase()}
          </Typography>
          <div className={classes.appbarActions}>
            <Divider className={classes.appbarDivider} orientation="vertical" light={true} flexItem />
            <CxTooltip text={trans('field.exit')} arrow>
              <IconButton className={classes.appbarExit} onClick={() => doExit()} ><BiExit /></IconButton>
            </CxTooltip>
            <Divider className={classes.appbarDivider} orientation="vertical" light={true} flexItem />
            <CxTooltip text={`${trans('field.user')} / ${trans('field.branch_id')} / ${trans('field.date')}`} arrow>
              <Typography noWrap className={classes.appbarText} 
                style={{ borderTop: '1px dotted var(--ibs_color_gray)', borderBottom: '1px dotted var(--ibs_color_gray)' }}>
                {`${getStoreValue('StoreSession', 'txUser')}-${getStoreValue('StoreSession', 'txBranchId')}`}<br />
                {`${FxDat.toLocalDate(getStoreValue('StoreSession', 'dtLogin'))}`}<br />
              </Typography>
            </CxTooltip>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper, }}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            ModalProps={{
              keepMounted: true, /* Better open performance on mobile. */
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper, }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* <TxTimerLogins /> */}
        <Outlet />

        <CxDialog title={trans('field.help')} open={dlgAskShowFirstSteps} 
          onOk={() => openFirstSteps(true)}
          onCancel={() => openFirstSteps(false)}
          buttonOkText={trans('field.yes')} 
          buttonCancelText={trans('field.no')} 
          >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginRight: '3px' }}>
            <span>{trans('msg.ask_learn_basics')}</span>
            <CxState mode='checkbox' xref={rf_boDontAskShowFirstSteps} label={trans('msg.dont_ask_again')} width="250px" />
          </div>
        </CxDialog>

        <CxDialog title={trans('field.security')} open={dlgAccessDenied} onOk={() => setDlgAccessDenied(false)} height='200px'>
          <div style={{ width: '200px', display: 'flex', alignItems: 'center', gap: 10 }}>
            <GiCancel fontSize="30px" style={{ color: 'var(--ibs_color_red)' }} />
            <span>{trans('msg.access_denied')}</span>
          </div>
        </CxDialog>

      </main>
    </div>
  );
}

export default MxMainCP;
