import React, { useState, useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
//import * as FxLog from '../fx/FxLog';
import Chip from '@material-ui/core/Chip';
import CxTooltip from './CxTooltip';


const useStyles = makeStyles({
  colorPrimary: {
    color: 'var(--ibs_color_black)',
    borderColor: 'var(--ibs_color_gray_light)',
  },
  colorSecondary: {
    color: 'var(--ibs_color_red_dark)',
    borderColor: 'var(--ibs_color_red_dark)',
  },
  label: {
    color: 'var(--ibs_color_black)',
    fontSize: '13px',
  },
});

const CxChip = props => {
  const classes = useStyles(props);
  const [myLabel, setMyLabel] = useState(null);
  const [warn, setWarn] = useState(false);

  const rf_tooltip = React.useRef(null);

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setLabel, setTooltip, setWarn, 
    };
  });
  const getType = () => { return 'CxChip' };
  const setLabel = (newText) => {
    setMyLabel(newText);
  };
  const setTooltip = (newText) => {
    rf_tooltip.current.setTooltipText(newText);
  };

  return (
    <CxTooltip xref={rf_tooltip}>
      <div>
        <Chip
          classes={{ colorPrimary: classes.colorPrimary, colorSecondary: classes.colorSecondary, root: classes.label, }} 
          color={warn ? "secondary" : "primary"}
          icon={props.icon || null}
          label={props.label || myLabel}
          variant="outlined"
        />
      </div>
    </CxTooltip>
  );
};

export default CxChip;
