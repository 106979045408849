import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettClientList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettClient, setStoreSettClient] = useSessionStorage('StoreSettClient', StoreSettClient);

  React.useEffect(() => {
    clearStore('StoreSettClient', storeSettClient);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettClient.itClient = null;
    } else {
      storeSettClient.itClient = row.original.itClient;
    }
    setStoreSettClient(storeSettClient);
    navTo("sett-client-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.clients')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/client"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itClient", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.client')}`, accessor: "txTypeClient", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettClientList;
