import React, { useRef } from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxDate from '../../cx/CxDate';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxTable from '../../cx/CxTable';
//import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { clearStore, getStoreValueBo, StoreClient, StoreRxAuto, StoreAnimalWeight, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxSnackbar from '../../cx/CxSnackbar';
import CxState from '../../cx/CxState';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxFetch from '../../fx/FxFetch';
import CxIconButton from '../../cx/CxIconButton';


const MxAnimalFormCP = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const size = useWindowSize();
  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const r_dlg_alert = React.useRef(null);
  let r_print = React.useRef(null);

  const [disabled,] = React.useState(true);

  // muestra según permisos
  const [showHistory, setShowHistory] = React.useState(false);
  const [showRxAuto, setShowRxAuto] = React.useState(false);

  const r_form = React.useRef(null);
  const r_table_rxs = React.useRef(null);
  const r_table_multimedia = React.useRef(null);

  const rf_txNumber = React.useRef(null);
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txTypeAnimalSpecies = React.useRef(null);
  const rf_txTypeAnimalBreed = React.useRef(null);
  const rf_txTypeAnimalCoat = React.useRef(null);
  const rf_txTypeAnimalCondition = React.useRef(null);

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxAuto,] = useSessionStorage('StoreRxAuto', StoreRxAuto);
  const [storeAnimalWeight,] = useSessionStorage('StoreAnimalWeight', StoreAnimalWeight);


  React.useEffect(() => {
    clearStore('StoreAnimalWeight', storeAnimalWeight);
    clearStore('StoreRxAuto', storeRxAuto);
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    rf_txTypeAnimalBreed.current.setFilter(`t_animal_breed.itAnimalSpecies='${data[0].itAnimalSpecies}'`);

    // acceso
    let record = {};
    //record['op'] = op;
    Promise.all([FxFetch.doJsonX('cp/animals/access', record)]).then((result) => {
      if (result[0]) {
        setShowHistory(result[0][0].boVettAnimalHistory === '1');
        setShowRxAuto(result[0][0].boVettAnimalRxAuto === '1');
      }
    });
  }

  const openFormMultimedia = async (op, row) => {
    if (row === null) {
      storeClient.inMultimedia = null;
    } else {
      storeClient.inMultimedia = row.original.inMultimedia;
    }
    setStoreClient(storeClient);
    navTo("client-multimedia-form-cp");
  }

  const doPrintRxAuto = async (op, row) => {
    let inClient = row.original.inClient;
    let inAnimal = row.original.inAnimal;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['txRxAuto'] = row.original.txRxAuto;
    filter['itSeriesRx'] = row.original.itSeriesRx;
    filter['docSize'] = 'A4';
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_auto/report');
    r_print.current.setCanSend(false);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient),
      r_print.current.setInAnimal(inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = storeClient.inClient;
    filter['inAnimal'] = storeClient.inAnimal;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('cp/animals/report/history');
    r_print.current.setCanSend(false);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(storeClient.inClient),
      r_print.current.setInAnimal(storeClient.inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.patient')}
        xref={r_form}
        url="animals"
        filterIdField='inAnimal'
        filterIdValue={storeClient.inAnimal}
        onFormLoad={formLoad}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {showHistory && <span style={{ margin: '2px 5px' }} >{trans('field.history') + ':'}</span>}
            {showHistory && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_animal.inClient" value={storeClient.inClient} />

        <CxInput xref={useRef(null)} label={trans('field.patient')} dbfield="n_animal.txAnimal" capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} disabled={disabled} required />

        <CxChooser xref={rf_txTypeAnimalSpecies}
          label={trans('field.species')}
          dbfield="t_animal_species.txTypeAnimalSpecies" dbfieldkey="n_animal.itAnimalSpecies"
          table="t_animal_species"
          col_id="itAnimalSpecies"
          col_tx="txTypeAnimalSpecies"
          disabled={disabled}
        />

        <CxSearcher xref={rf_txTypeAnimalBreed} label={trans('field.breed')}
          dbfield="t_animal_breed.txTypeAnimalBreed" dbfieldkey="n_animal.itAnimalBreed"
          url="sett/animal_breed"
          col_id="itAnimalBreed"
          col_tx="txTypeAnimalBreed"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalBreed", show: false },
            { id: "1", Header: trans('field.breed'), accessor: row => rf_txTypeAnimalBreed.current.getTable().highlightText(row["txTypeAnimalBreed"]), width: '100%' },
          ]}
          disabled={disabled}
        />

        <CxChooser xref={rf_txTypeAnimalCoat}
          label={trans('field.coat')}
          dbfield="t_animal_coat.txTypeAnimalCoat" dbfieldkey="n_animal.itAnimalCoat"
          table="t_animal_coat"
          col_id="itAnimalCoat"
          col_tx="txTypeAnimalCoat"
          disabled={disabled}
        />

        <CxDate xref={useRef(null)} label={trans('field.birth')} dbfield="n_animal.dtBirth" showAge disabled={disabled} />

        <CxState mode='checkbox' xref={useRef(null)} label={`${trans('field.animal_female')}|${trans('field.animal_male')}`} dbfield="n_animal.boGender" disabled={disabled} />

        <CxInput xref={useRef(null)} label={trans('field.alert')} dbfield="n_animal.txAlert" maxLength={200} redText disabled={disabled} />

        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield="n_animal.dtFrom" disabled={disabled} />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield="n_animal.dtTo" disabled={disabled} />
        <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_animal.txReason" maxLength={50} disabled={disabled} />
        <CxChooser xref={rf_txTypeAnimalCondition}
          label={trans('field.condition')}
          dbfield="t_animal_condition.txTypeAnimalCondition" dbfieldkey="n_animal.itAnimalCondition"
          table="t_animal_condition"
          col_id="itAnimalCondition"
          col_tx="txTypeAnimalCondition"
          disabled={disabled}
        />
        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.reproduction')} dbfield="n_animal.boReproduction" disabled={disabled} />
        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.pedigree')} dbfield="n_animal.boPedigree" disabled={disabled} />
        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.sterilization')} dbfield="n_animal.boSterilization" disabled={disabled} />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_animal.txReference" maxLength={50} disabled={disabled} />

        <CxInput xref={rf_txNumber} label={trans('field.number')} dbfield="n_animal.txNumber" maxLength={30}
          placeholder={getStoreValueBo('StoreSession', 'boAutoNumFiles') ? 'AUTO' : ''}
          disabled={disabled || getStoreValueBo('StoreSession', 'boAutoNumFiles')}
        />

        <CxInput xref={useRef(null)} label={trans('field.chip')} dbfield="n_animal.txChip" maxLength={23} disabled={disabled} />
        <CxInput xref={useRef(null)} label={trans('field.passport')} dbfield="n_animal.txPassport" maxLength={30} disabled={disabled} />
        <CxInput xref={useRef(null)} label={trans('field.nature')} dbfield="n_animal.txNature" maxLength={50} disabled={disabled} />
        <CxInput xref={useRef(null)} label={trans('field.habitat')} dbfield="n_animal.txHabitat" maxLength={50} disabled={disabled} />
        <CxInput xref={useRef(null)} label={trans('field.feeding')} dbfield="n_animal.txFeeding" maxLength={50} disabled={disabled} />

        {showRxAuto && <CxTable
          title={trans('field.rxs')}
          xref={r_table_rxs}
          url="rx_auto"
          filter={`n_rx_auto.inAnimal='${storeClient.inAnimal}'`}
          onOpenForm={doPrintRxAuto}
          hasButtonAdd={false}
          columns={[
            { id: "xx1", Header: "ID", accessor: "inRxAuto", show: false },
            { id: "xx2", Header: "_itSeriesRx", accessor: "itSeriesRx", show: false },
            { id: "inClient", Header: "_inClient", accessor: "inClient", show: false },
            { id: "inAnimal", Header: "_inAnimal", accessor: "inAnimal", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtRxAuto"]), width: '15%' },
            { id: "2", Header: trans('field.rx'), accessor: "txRxAuto", width: '15%' },
            { id: "3", Header: trans('field.notes'), accessor: "txNotes", width: '70%', show: size.isLarge },
          ]}
        />}

        <CxTable
          title={`${trans('field.files2')}`}
          xref={r_table_multimedia}
          url="multimedia"
          filter={`n_multimedia.txLinkTable='n_animal' and n_multimedia.txLinkId='${storeClient.inAnimal}' and n_multimedia.boShowInCp='1'`}
          onOpenForm={openFormMultimedia}
          hasButtonAdd={false}
          disabledUntilUpgrade={!FxEdition.isInEdition('CLI_mme')}
          columns={[
            { id: "0", Header: "ID", accessor: "inMultimedia", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtMultimedia"]), width: '15%' },
            { id: "2", Header: trans('field.type'), accessor: "txTypeMultimedia", width: '15%' },
            { id: "3", Header: trans('field.issue'), accessor: "txIssue", width: '70%', show: size.isLarge },
          ]}
        />

        {/* <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_animal.txNotes" minRows={4} /> */}

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

    </div>
  );

}

export default MxAnimalFormCP;
