import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettPosSeries } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsSeriesForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const [storeSettPosSeries, setStoreSettPosSeries] = useSessionStorage('StoreSettPosSeries', StoreSettPosSeries);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettPosSeries.itSeries = lastSaveRv.theValue;
      Promise.all([setStoreSettPosSeries(storeSettPosSeries)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={trans('field.series')}
        xref={r_form}
        url="sett/series" 
        filterIdField='itSeries'
        filterIdValue={storeSettPosSeries.itSeries} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_series.itSeries" value={storeSettPosSeries.itSeries || ''} />
        <CxInput xref={useRef(null)} label={trans('field.series')} dbfield="t_series.txTypeSeries" maxLength={20} required />
        <CxInput xref={useRef(null)} label={trans('field.template')} dbfield="t_series.txTemplate" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="t_series.txNotes" minRows={8} maxLength={100} />
        
      </GxForm>

    </div>
  );

}

export default MxSettSlsSeriesForm;
