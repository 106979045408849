import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import { clearStore, StoreSettCalendarGrp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';
import { FaSquareFull } from "react-icons/fa";
import CxIconButtonNew from '../../cx/CxIconButtonNew';


const MxSettCalendarGrpList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettCalendarGrp, setStoreSettCalendarGrp] = useSessionStorage('StoreSettCalendarGrp', StoreSettCalendarGrp);

  React.useEffect(() => {
    clearStore('StoreSettCalendarGrp', storeSettCalendarGrp);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCalendarGrp.itCalendarGrp = null;
    } else {
      storeSettCalendarGrp.itCalendarGrp = row.original.itCalendarGrp;
    }
    setStoreSettCalendarGrp(storeSettCalendarGrp);
    navTo("sett-calendar-grp-form");
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "itCalendarGrp", show: false });
    cols.push({ id: id++, Header: trans('field.group'), accessor: row => r_list.current.getTable().highlightText(row["txTypeCalendarGrp"]), width: '50%' });
    cols.push({ id: id++, Header: trans('field.letter'), accessor: "txLetterGrp", width: '25%', align: 'center' });
    cols.push({
      id: id++, Header: trans('field.color'), accessor: row => {
        return <CxIconButtonNew icon={<FaSquareFull fontSize="20px" style={{ color: FxStr.rgb2hex(row["txRgb"]), border: '1px solid gray' }} />} />
      }, 
      width: '25px', align: 'center'
    });

    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.calendars')}: ${trans('field.groups')}`}
        xref={r_list}
        tooltip={`${trans('field.group')}`}
        fullscreen
        url="sett/cal_grp"
        onOpenForm={openForm}
        columns={getTableColumns()}
      />
    </div>
  );
}

export default MxSettCalendarGrpList;
