import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettPsGroup } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettPsGroupList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettPsGroup, setStoreSettPsGroup] = useSessionStorage('StoreSettPsGroup', StoreSettPsGroup);

  React.useEffect(() => {
    clearStore('StoreSettPsGroup', storeSettPsGroup);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPsGroup.itPsGroup = null;
    } else {
      storeSettPsGroup.itPsGroup = row.original.itPsGroup;
    }
    setStoreSettPsGroup(storeSettPsGroup);
    navTo("sett-ps-group-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.pss')}: ${trans('field.groups')}`}
        xref={r_list}
        tooltip={`${trans('field.group')}`}
        fullscreen
        url="sett/ps_group"
        filter={`t_ps_group.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
          { id: "1", Header: trans('field.group'), accessor: row => r_list.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettPsGroupList;
