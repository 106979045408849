import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import * as FxEdition from '../../fx/FxEdition';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import CxDialog from '../../cx/CxDialog';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxDate from '../../cx/CxDate';
import CxNumber from '../../cx/CxNumber';
import CxChooser from '../../cx/CxChooser';
import CxSnackbar from '../../cx/CxSnackbar';
import CxSearcher from '../../cx/CxSearcher';
import moment from 'moment';
import '../../../styles/main.css';
import '../../../styles/main.css';
import MxStatsCard from './MxStatsCard';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BsCashCoin, } from "react-icons/bs";
import { FaShoppingBasket, FaBox, FaExpandArrowsAlt, } from "react-icons/fa";
import { ImUser, ImUserTie, ImStatsBars, } from "react-icons/im";
import { RiMedicineBottleFill, } from "react-icons/ri";
import CxToolbar from '../../cx/CxToolbar';
import { clearStore, StoreSession, StoreFile, getStoreValue, getStoreValueBo, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    marginTop: '0px', // 2024.07.05
  },
  toolbar: {
    marginTop: '-60px', // 2024.07.05
    background: 'var(--ibs_color_white)',
    position: 'fixed',// 2024.07.05
    zIndex: 1,// 2024.07.05
    padding: '5px 0 0 0', // 2024.07.05
    width: '100%',
    [theme.breakpoints.up('sm')]: { // 2024.07.05
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  mainContainer: {
    margin: '55px 10px 20px 10px',
    /* background: 'yellow', */
  },
  searchContainer: {
    display: 'flex', 
    justifyContent: 'space-between', 
    flexWrap: 'wrap', 
    margin: '0px 0px 10px 0px',
  },
  cardContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    width: '100%',
  },
}));

const MxStats = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const MIN_WIDTH = 700;

  const [useInvoices,] = React.useState(getStoreValueBo('StoreSession', 'boUseInvoices'));
  const [useReceipts,] = React.useState(getStoreValueBo('StoreSession', 'boUseReceipts'));

  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);

  const rf_search = React.useRef(null);

  const r_dlg_alert = React.useRef(null);

  const [search, setSearch] = React.useState(sessionStorage.getItem('MxStats.search') || '');

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    dtFrom: '', dtTo: '',
    inBranch: '', txBranch: '',
    nuBillingCode: '', itCash: '', inClient: '', itClient: '', inSupplier: '', inPs: '',
    itPsGroup: '', itPsSubgroup: '', inInsur: '', inReferrer: '',
  }); //añadir también en openFilters, doReportBuild, isRequired
  const r_filters_shown = React.useRef('');

  const r_report_name = React.useRef(null);
  const rf_date_from = React.useRef(null);
  const rf_date_to = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_nuBillingCode = React.useRef(null);
  const rf_txNature = React.useRef(null);
  const rf_txTypeCash = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txTypeClient = React.useRef(null);
  const rf_txSupplier = React.useRef(null);
  const rf_txPs = React.useRef(null);
  const rf_txPs_forLike = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);
  const rf_txTypeSeries = React.useRef(null);
  const rf_txInsur = React.useRef(null);
  const rf_txEmployee = React.useRef(null);
  const rf_txReferrer = React.useRef(null);

  const r_snack_error = React.useRef(null);

  // eslint-disable-next-line
  const [storeSession, setStoreSession] = useSessionStorage('StoreSession', StoreSession);
  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  React.useEffect(() => {
    clearStore('StoreFile', storeFile);

    return () => {
      runTimer(false);
    }
    // eslint-disable-next-line
  }, []);

  const runTimer = (boStart) => {
    if (boStart) {
      //starting up timer...
      timer.current = setInterval(() => {
        if (FxInet.isOnline()) {
          doReportCheck();
        }
      }, 1 * SEC);

    } else {
      if (timer.current) {
        //cleaning up timer...
        clearInterval(timer.current);
      }
    }
  }

  const doReportCancel = () => {
    try {
      let record = {};
      record['txTaskName'] = 'report';
      Promise.all([FxFetch.doJsonX('long_task/cancel', record)]).then((result) => {
        if (result[0]) {
          if (result[0].theValue === 'OK') {
            runTimer(false);
            r_dlg_alert.current.setProgressMsg('');
            r_dlg_alert.current.setOpen(false);
          }
        }
      });

    } catch (error) {
      FxLog.errorx("MxStats.doReportCancel", error);
    }
  }

  const doReportCheck = () => {
    try {
      let record = {};
      record['txTaskName'] = 'report';
      Promise.all([FxFetch.doJsonX('long_task/get_progress', record)]).then((result) => {
        if (result[0]) {
          let page = result[0].nuTaskItemCurrent;
          switch (result[0].txTaskMessage) {
            case trans('msg.building_page'):
              if (result[0].nuTaskItemCurrent === '0') {
                r_dlg_alert.current.setProgressMsg(trans('msg.report_preparing'));
              } else {
                r_dlg_alert.current.setProgressMsg(trans('msg.building_page') + ' ' + page);
              }
              break;

            case trans('msg.operation_finished'):
              r_dlg_alert.current.setProgressMsg(trans('msg.building_page') + ' ' + page);
              runTimer(false);
              doReportGet();
              break;

            default:
              r_dlg_alert.current.setProgressMsg('');
              break;
          }
        }
      });

    } catch (error) {
      FxLog.errorx("MxStats.doReportCheck", error);
    }
  }

  const doReportBuild = () => {
    if (!isRequired()) {
      let filter = {};
      filter['reportName'] = `${r_report_name.current}`;
      filter['reportFormat'] = 'PDF';

      if (rf_date_from.current) sessionStorage.setItem('MxStats.last_dtFrom', `${rf_date_from.current.getVal()}`);
      if (rf_date_to.current) sessionStorage.setItem('MxStats.last_dtTo', `${rf_date_to.current.getVal()}`);

      if (r_filters_shown.current.includes('dtFrom')) filter['dtFrom'] = `${rf_date_from.current.getVal()}`;
      if (r_filters_shown.current.includes('dtTo')) filter['dtTo'] = `${rf_date_to.current.getVal()}`;
      if (r_filters_shown.current.includes('inBranch')) filter['inBranch'] = `${rf_txBranch.current.getKey()}`;
      if (r_filters_shown.current.includes('itCash')) filter['itCash'] = `${rf_txTypeCash.current.getKey()}`;
      if (r_filters_shown.current.includes('nuBillingCode')) filter['nuBillingCode'] = `${rf_nuBillingCode.current.getVal()}`;
      if (r_filters_shown.current.includes('inClient')) filter['inClient'] = `${rf_txClient.current.getKey()}`;
      if (r_filters_shown.current.includes('itClient')) filter['itClient'] = `${rf_txTypeClient.current.getKey()}`;
      if (r_filters_shown.current.includes('inSupplier')) filter['inSupplier'] = `${rf_txSupplier.current.getKey()}`;
      if (r_filters_shown.current.includes('inPs')) filter['inPs'] = `${rf_txPs.current.getKey()}`;
      if (r_filters_shown.current.includes('itPsGroup')) filter['itPsGroup'] = `${rf_txTypePsGroup.current.getKey()}`;
      if (r_filters_shown.current.includes('itPsSubgroup')) filter['itPsSubgroup'] = `${rf_txTypePsSubgroup.current.getKey()}`;
      if (r_filters_shown.current.includes('itSeries')) filter['itSeries'] = `${rf_txTypeSeries.current.getKey()}`;
      if (r_filters_shown.current.includes('inInsur')) filter['inInsur'] = `${rf_txInsur.current.getKey()}`;
      if (r_filters_shown.current.includes('inEmployee')) filter['inEmployee'] = `${rf_txEmployee.current.getKey()}`;
      if (r_filters_shown.current.includes('inReferrer')) filter['inReferrer'] = `${rf_txReferrer.current.getKey()}`;
      if (r_filters_shown.current.includes('txNature')) filter['txNature'] = `${rf_txNature.current.getVal()}`;
      if (r_filters_shown.current.includes('txPs')) filter['txPs'] = `${rf_txPs_forLike.current.getVal()}`;

      setFilterOpen(false);
      r_dlg_alert.current.clear();
      r_dlg_alert.current.setOpen(true);
      runTimer(true);
      Promise.all([FxFetch.doJsonF('stats/report/stats_build', filter, null)]).then((result) => {
        if (result[0] === null) {
          // timeout vencido
        } else {
          if ((result[0].txStatus || '').startsWith("OK")) {
          }
        }
      });
    }
  };

  const doReportGet = () => {
    try {
      let filter = {};
      Promise.all([FxFetch.doJsonF('stats/report/stats_get', filter, null)]).then((result) => {
        if (result[0] === null) {
        } else {
          if (result[0].txStatus.startsWith("OK")) {
            let txFileFormat = result[0].txFileFormat;
            let txFileNameInput = result[0].txFileNameInput;
            //let txFileNameOutput = result[0].txFileNameOutput;
            let obPDF = result[0].obPDF;
            let obCSV = result[0].obCSV;

            clearStore('StoreFile', storeFile);
            storeFile.txFileFormat = txFileFormat;
            storeFile.txFileNameInput = txFileNameInput;
            storeFile.obPDF = 'data:application/pdf;base64,' + obPDF;
            storeFile.obCSV = obCSV;

            r_dlg_alert.current.setOpen(false);
            Promise.all([setStoreFile(storeFile)]).then((result) => {
              navTo("file-viewer");
            });

          } else { // ERROR
            switch (result[0].txStatus) {
              case 'rv_empty':
                r_dlg_alert.current.setText(trans('msg.no_records'));
                r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
                r_dlg_alert.current.setProgressMsg('');
                r_dlg_alert.current.setHasButtonClose(true);
                break;

              default:
                break;
            }
          }
        }
        runTimer(false);
      });

    } catch (error) {
      FxLog.errorx("MxStats.doReportGet", error);
    }
  }

  const openFilters = (reportName, event) => {
    r_report_name.current = reportName;

    setFilters({ // valores predeterminados
      dtFrom: getDtFrom(),
      dtTo: getDtTo(),
      inBranch: getStoreValue('StoreSession', 'inBranch'),
      txBranch: getStoreValue('StoreSession', 'txBranch'),
    });

    let ff = '';
    switch (reportName) {
      case 'ibs_stats_inv_deta':
      case 'ibs_stats_rec_deta':
        ff = 'dtFrom* dtTo* inBranch itSeries nuBillingCode inPs inClient';
        break;

      case 'ibs_stats_inv_tax':
      case 'ibs_stats_rec_tax':
        ff = 'dtFrom* dtTo* inBranch* itSeries nuBillingCode itPsGroup';
        break;

      case 'ibs_stats_inv_subg':
      case 'ibs_stats_rec_subg':
        ff = 'dtFrom* dtTo* inBranch* itSeries inClient nuBillingCode';
        break;

      case 'ibs_stats_rec_pay':
      case 'ibs_stats_inv_pay':
      case 'ibs_stats_inv_pur_gral':
      case 'ibs_stats_inv_pur_subg':
      case 'ibs_stats_cli_billing':
        ff = 'dtFrom* dtTo* inBranch';
        break;

      case 'ibs_stats_ps_stock':
        ff = 'inBranch* nuBillingCode txPs itPsGroup inSupplier';
        break;

      case 'ibs_stats_ps_stock_val':
        ff = 'inBranch* nuBillingCode inSupplier';
        break;

      case 'ibs_stats_ps_stock_val_tax':
      case 'ibs_stats_ps_stock_min':
      case 'ibs_stats_ps_stock_max':
        ff = 'inBranch* inSupplier';
        break;

      case 'ibs_stats_ps_lot_expr':
        ff = 'dtFrom* dtTo* inBranch*';
        break;

      // nuevos
      case 'ibs_stats_cash':
      case 'ibs_stats_cash_dtf':
        ff = 'dtFrom* dtTo* inBranch* itCash';
        break;

      case 'ibs_stats_cli_animal':
      case 'ibs_stats_cli_animalbirth':
        ff = 'dtFrom* dtTo*';
        break;
      case 'ibs_stats_cli_best':
      case 'ibs_stats_cli_debtor1':
      case 'ibs_stats_cli_debtor2':
      case 'ibs_stats_cli_find_us':
        ff = '';
        break;
      case 'ibs_stats_cli_incidence':
      case 'ibs_stats_cli_doc':
        ff = 'dtFrom* dtTo* inBranch*';
        break;
      case 'ibs_stats_cli_lost':
        ff = 'dtFrom*';
        break;
      case 'ibs_stats_cli_type':
        ff = 'itClient*';
        break;

      case 'ibs_stats_drug_treatments':
        ff = 'dtFrom* dtTo* inClient';
        break;
      case 'ibs_stats_drugs_rx':
        ff = 'dtFrom* dtTo* txNature inClient';
        break;
      case 'ibs_stats_drugs_in':
      case 'ibs_stats_drugs_out':
        ff = 'dtFrom* dtTo* inPs';
        break;
      case 'ibs_stats_drugs_sold':
        ff = 'dtFrom* dtTo* itPsSubgroup';
        break;

      case 'ibs_stats_empl_commission':
        ff = 'dtFrom* dtTo* inBranch*';
        break;
      case 'ibs_stats_empl_sales':
        ff = 'dtFrom* dtTo* inBranch* itSeries nuBillingCode inClient';
        break;

      case 'ibs_stats_evo_cli_mov':
        ff = '';
        break;
      case 'ibs_stats_evo_csh':
      case 'ibs_stats_evo_inc':
      case 'ibs_stats_evo_pur_ps':
      case 'ibs_stats_evo_sls_gp':
      case 'ibs_stats_evo_sls_ps':
        ff = 'dtFrom* dtTo* inBranch*';
        break;

      case 'ibs_stats_insur_claim':
        ff = 'dtFrom* dtTo* inBranch* itSeries nuBillingCode inInsur';
        break;

      case 'ibs_stats_inv_debt':
        ff = 'dtFrom* dtTo* inBranch*';
        break;
      case 'ibs_stats_inv_empl':
        ff = 'dtFrom* dtTo* inBranch* inEmployee*';
        break;
      case 'ibs_stats_inv_referrers':
        ff = 'dtFrom* dtTo* inBranch* itSeries inReferrer';
        break;

      case 'ibs_stats_mkt_email':
      case 'ibs_stats_mkt_sms':
        ff = 'dtFrom* dtTo* inBranch* inClient';
        break;

      case 'ibs_stats_cli_pres':
        ff = 'dtFrom* dtTo* inBranch*';
        break;
      case 'ibs_stats_cli_summary':
        ff = 'dtFrom* dtTo* inClient*';
        break;

      case 'ibs_stats_ps_best_selling':
        ff = 'dtFrom* dtTo* inBranch* itSeries itPsGroup';
        break;
      case 'ibs_stats_ps_lot_missing':
        ff = 'dtFrom* inBranch*';
        break;
      case 'ibs_stats_ps_markup':
        ff = 'inBranch* txPs itPsGroup';
        break;
      case 'ibs_stats_ps_profit':
        ff = '';
        break;
      case 'ibs_stats_ps_stock_transfer':
        ff = 'dtFrom* dtTo* inBranchFrom inBranchTo';
        break;

      case 'ibs_stats_ps_vaccinations1':
      case 'ibs_stats_ps_vaccinations2':
      case 'ibs_stats_ps_vaccinations3':
      case 'ibs_stats_ps_replacements1':
        ff = 'dtFrom* dtTo* inBranch* inPs';
        break;
      case 'ibs_stats_ps_vaccinations4':
        ff = 'dtFrom* dtNow';
        break;

      case 'ibs_stats_rec_ori':
        ff = 'dtFrom* dtTo* inClient';
        break;
      case 'ibs_stats_rec_gpsg':
        ff = 'dtFrom* dtTo* inBranch* itSeries itPsSubgroup nuBillingCode';
        break;
      case 'ibs_stats_rec_pur_deta':
        ff = 'dtFrom* dtTo* inBranch* itSeries inPs nuBillingCode';
        break;
      case 'ibs_stats_rec_pur_pay':
        ff = 'dtFrom* dtTo* inBranch*';
        break;

      default: ff = ''; break;
    }

    r_filters_shown.current = ff;

    if (ff) {
      setFilterOpen(true);
    } else {
      doReportBuild();
    }
  }

  const getDtFrom = () => {
    let mDate = moment();
    let theDateFrom = mDate.subtract(1, 'month').format(moment.HTML5_FMT.DATE);
    let last_dtFrom = sessionStorage.getItem('MxStats.last_dtFrom');
    if (last_dtFrom) {
      return last_dtFrom;
    } else {
      return theDateFrom;
    }
  }

  const getDtTo = () => {
    let mDate = moment();
    let theDateTo = mDate.format(moment.HTML5_FMT.DATE);
    let last_dtTo = sessionStorage.getItem('MxStats.last_dtTo');
    if (last_dtTo) {
      return last_dtTo;
    } else {
      return theDateTo;
    }
  }

  const isRequired = () => {
    let rv = false;
    let cc = [
      rf_date_from, rf_date_to, rf_txBranch, rf_nuBillingCode, rf_txTypeCash, rf_txClient, rf_txTypeClient, rf_txPs,
      rf_txTypePsGroup, rf_txTypePsSubgroup, rf_txInsur, rf_txEmployee, rf_txReferrer
    ];
    cc.reverse().forEach((c, index) => {
      if (!c.current) {
        // Falta añadir la ref del control en 'let cc = ... !!!!
      } else {
        if (c.current.getIsRequired()) {
          let label = c.current.getLabel();
          r_snack_error.current.setVal(trans('field.required') + ": " + label);
          r_snack_error.current.setOpen(true);
          rv = true;
        }
      }
    });
    return rv;
  };

  const cardSales = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<BsCashCoin fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.sales')}
      background={background || 'var(--ibs_color_yellow_light)'} search={search}
    />
  }

  const cardPurchases = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<FaShoppingBasket fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.purchases')}
      background={background || 'var(--ibs_color_yellow_light)'} search={search}
    />
  }

  const cardClients = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<ImUser fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.clients')}
      background={background || 'var(--ibs_color_yellow_light)'} search={search}
    />
  }

  const cardPss = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<FaBox fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.pss')}
      background={background || 'var(--ibs_color_yellow_light)'}
      disabled={Boolean(!FxEdition.isInEdition('STA_adv'))} search={search}
    />
  }

  const cardDrugs = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<RiMedicineBottleFill fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.drugs')}
      background={background || 'var(--ibs_color_yellow_light)'}
      disabled={Boolean(!FxEdition.isInEdition('STA_adv'))} search={search}
    />
  }

  const cardMarketing = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<FaExpandArrowsAlt fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.marketing')}
      background={background || 'var(--ibs_color_yellow_light)'}
      disabled={Boolean(!FxEdition.isInEdition('STA_adv'))} search={search}
    />
  }

  const cardEmployees = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<ImUserTie fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.employees')}
      background={background || 'var(--ibs_color_yellow_light)'}
      disabled={Boolean(!FxEdition.isInEdition('STA_adv'))} search={search}
    />
  }

  // eslint-disable-next-line
  const cardEvolution = (reportName, title, color, background) => {
    if (!(!search || title.toLowerCase().includes(search.toLowerCase()))) return null;
    return <MxStatsCard icon={<ImStatsBars fontSize='26px' color={color || 'var(--ibs_color_cy_blue)'} />}
      onClick={(e) => openFilters(reportName, e)} title={title} subtitle={trans('field.biz_evol')}
      background={background || 'var(--ibs_color_yellow_light)'}
      disabled={Boolean(!FxEdition.isInEdition('STA_adv'))} search={search}
    />
  }

  const doSearch = () => {
    sessionStorage.setItem('MxStats.search', rf_search.current.getVal());
    setSearch(rf_search.current.getVal());
  }

  const clearSearch = () => {
    sessionStorage.removeItem('MxStats.search');
    setSearch('');
  }

  return (
    <div className={classes.parentDiv}>
      <div id='myCxToolbar' className={classes.toolbar}>
        <CxToolbar moduleName={trans('field.stats')} />
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.searchContainer} >
          <div className={'table_search_container'}>
            <CxInput
              xref={rf_search}
              label={trans('field.search')}
              onKeyDown={(e) => { e.keyCode === 13 && doSearch(); }}
              onClear={clearSearch}
              onSearch={doSearch}
              defaultValue={sessionStorage.getItem('MxStats.search') || ''}
              width={window.innerWidth > MIN_WIDTH ? 'var(--ibs_control_width_lg)' : 'var(--ibs_control_width_sm)'}
            />
          </div>
        </div>
        <div className={classes.cardContainer}>
          {useInvoices && cardSales('ibs_stats_inv_deta', trans('rpt.inv_deta'))}
          {useInvoices && cardSales('ibs_stats_inv_subg', trans('rpt.inv_subg'))}
          {useInvoices && cardSales('ibs_stats_inv_pay', trans('rpt.inv_pay'))}
          {useInvoices && cardSales('ibs_stats_inv_debt', trans('rpt.inv_debt'))}
          {useInvoices && cardSales('ibs_stats_inv_empl', trans('rpt.inv_empl'))}
          {useInvoices && cardSales('ibs_stats_inv_referrers', trans('rpt.referrers'))}
          {useInvoices && cardSales('ibs_stats_inv_tax', trans('rpt.inv_tax'))}
          {useReceipts && storeSession.boHiddenReports === '1' && cardSales('ibs_stats_rec_ori', trans('rpt.rec_ori'), 'var(--ibs_color_white)', 'var(--ibs_color_cy_blue)')}
          {useReceipts && cardSales('ibs_stats_rec_tax', trans('rpt.rec_tax'))}
          {useReceipts && cardSales('ibs_stats_rec_deta', trans('rpt.rec_deta'))}
          {useReceipts && cardSales('ibs_stats_rec_pay', trans('rpt.rec_pay'))}
          {useReceipts && cardSales('ibs_stats_rec_gpsg', trans('rpt.rec_gpsg'))}
          {useReceipts && cardSales('ibs_stats_rec_pur_deta', trans('rpt.rec_pur_deta'))}
          {useReceipts && cardSales('ibs_stats_rec_pur_pay', trans('rpt.rec_pur_pay'))}
          {useReceipts && cardSales('ibs_stats_rec_subg', trans('rpt.rec_subg'))}
          {cardSales('ibs_stats_cash', trans('rpt.cash'))}
          {cardSales('ibs_stats_cash_dtf', trans('rpt.cash_dtf'))}
          {cardSales('ibs_stats_insur_claim', trans('rpt.insur_claim'))}

          {cardPurchases('ibs_stats_inv_pur_gral', trans('rpt.inv_pur_gral'))}
          {cardPurchases('ibs_stats_inv_pur_subg', trans('rpt.inv_pur_subg'))}

          {cardClients('ibs_stats_cli_billing', trans('rpt.cli_billing'))}
          {(isApp('vett') || isApp('spaw')) && cardClients('ibs_stats_cli_animal', trans('rpt.cli_animal'))}
          {(isApp('vett') || isApp('spaw')) && cardClients('ibs_stats_cli_animalbirth', trans('rpt.cli_animal_birth'))}
          {isApp('opti') && cardClients('ibs_stats_cli_pres', trans('rpt.cli_pres'))}
          {isApp('opti') && cardClients('ibs_stats_cli_summary', trans('rpt.cli_summary'))}
          {cardClients('ibs_stats_cli_best', trans('rpt.cli_best'))}
          {cardClients('ibs_stats_cli_debtor1', trans('rpt.cli_debtor1'))}
          {cardClients('ibs_stats_cli_debtor2', trans('rpt.cli_debtor2'))}
          {cardClients('ibs_stats_cli_find_us', trans('rpt.cli_find_us'))}
          {cardClients('ibs_stats_cli_doc', trans('rpt.cli_doc'))}
          {cardClients('ibs_stats_cli_incidence', trans('rpt.cli_incidence'))}
          {cardClients('ibs_stats_cli_lost', trans('rpt.cli_lost'))}
          {cardClients('ibs_stats_cli_type', trans('rpt.cli_type'))}

          {cardPss('ibs_stats_ps_stock_val', trans('rpt.ps_stock_val'))}
          {cardPss('ibs_stats_ps_stock_val_tax', trans('rpt.ps_stock_val_tax'))}
          {cardPss('ibs_stats_ps_stock', trans('rpt.ps_stock'))}
          {cardPss('ibs_stats_ps_stock_min', trans('rpt.ps_stock_min'))}
          {cardPss('ibs_stats_ps_stock_max', trans('rpt.ps_stock_max'))}
          {cardPss('ibs_stats_ps_lot_expr', trans('rpt.ps_lot_expr'))}
          {cardPss('ibs_stats_ps_best_selling', trans('rpt.ps_best_selling'))}
          {cardPss('ibs_stats_ps_lot_missing', trans('rpt.ps_lot_missing'))}
          {cardPss('ibs_stats_ps_profit', trans('rpt.ps_profit'))}
          {cardPss('ibs_stats_ps_markup', trans('rpt.ps_markup'))}
          {cardPss('ibs_stats_ps_stock_transfer', trans('rpt.ps_stock_transfer'))}
          {(isApp('vett')) && cardPss('ibs_stats_ps_vaccinations1', trans('rpt.ps_vacc_to_be_administered'))}
          {(isApp('vett')) && cardPss('ibs_stats_ps_vaccinations2', trans('rpt.ps_vacc_administered'))}
          {(isApp('vett')) && cardPss('ibs_stats_ps_vaccinations3', trans('rpt.ps_vacc_administered'))}
          {(isApp('vett')) && cardPss('ibs_stats_ps_vaccinations4', trans('rpt.ps_vacc_pending'))}
          {isApp('opti') && cardPss('ibs_stats_ps_replacements1', trans('rpt.ps_replacements'))}

          {(isApp('vett')) && cardDrugs('ibs_stats_drugs_in', trans('rpt.drugs_in'))}
          {(isApp('vett')) && cardDrugs('ibs_stats_drugs_out', trans('rpt.drugs_out'))}
          {(isApp('vett')) && cardDrugs('ibs_stats_drugs_sold', trans('rpt.drugs_sold'))}
          {(isApp('vett')) && cardDrugs('ibs_stats_drug_treatments', trans('rpt.drug_treatments'))}
          {(isApp('vett')) && cardDrugs('ibs_stats_drugs_rx', trans('rpt.drugs_rx'))}

          {cardMarketing('ibs_stats_mkt_email', trans('rpt.mkt_email'))}
          {cardMarketing('ibs_stats_mkt_sms', trans('rpt.mkt_sms'))}

          {cardEmployees('ibs_stats_empl_commission', trans('rpt.empl_commission'))}
          {cardEmployees('ibs_stats_empl_sales', trans('rpt.empl_sales'))}

          {cardEvolution('ibs_stats_evo_cli_mov', trans('rpt.evo_cli_mov'))}
          {cardEvolution('ibs_stats_evo_csh', trans('rpt.evo_csh'))}
          {cardEvolution('ibs_stats_evo_inc', trans('rpt.evo_inc'))}
          {cardEvolution('ibs_stats_evo_pur_ps', trans('rpt.evo_pur_ps'))}
          {cardEvolution('ibs_stats_evo_sls_gp', trans('rpt.evo_sls_gp'))}
          {cardEvolution('ibs_stats_evo_sls_ps', trans('rpt.evo_sls_ps'))}
          
        </div>

        <CxDlgAlert xref={r_dlg_alert} onCancel={doReportCancel} />

        <CxDialog
          title={trans('field.report')}
          open={filterOpen}
          onOk={doReportBuild}
          onCancel={() => setFilterOpen(false)}
        //height='450px'
        >

          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >

            {r_filters_shown.current.includes('dtFrom') &&
              <CxDate xref={rf_date_from} label={trans('field.from')} defaultValue={filters.dtFrom} width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('dtFrom*'))} />
            }
            {r_filters_shown.current.includes('dtTo') &&
              <CxDate xref={rf_date_to} label={trans('field.to')} defaultValue={filters.dtTo} width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('dtTo*'))} />
            }

            {r_filters_shown.current.includes('inBranch') &&
              <CxChooser xref={rf_txBranch}
                label={trans('field.branch')}
                id="n_branch"
                table="n_branch"
                col_id="inBranch"
                col_tx="txBranch"
                extra_filter_1={"boActive='1'"}
                width='var(--ibs_control_width_md)'
                defaultKeyAndValue={filters.inBranch + '|' + filters.txBranch}
                required={Boolean(r_filters_shown.current.includes('inBranch*'))}
              />
            }

            {r_filters_shown.current.includes('itCash') &&
              <CxChooser xref={rf_txTypeCash}
                label={trans('field.cash_reg')}
                table="t_cash"
                col_id="itCash"
                col_tx="txTypeCash"
                readOnly={false}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('itCash*'))}
              />
            }

            {r_filters_shown.current.includes('nuBillingCode') &&
              <CxNumber xref={rf_nuBillingCode}
                label={trans('field.billing_code')}
                decimals={0}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('nuBillingCode*'))}
              />
            }

            {r_filters_shown.current.includes('itSeries') &&
              <CxChooser xref={rf_txTypeSeries} label={trans('field.series')}
                table="t_series"
                col_id="itSeries"
                col_tx="txTypeSeries"
                extra_filter_1={"boActive='1'"}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('itSeries*'))}
              />
            }

            {r_filters_shown.current.includes('inClient') &&
              <CxSearcher xref={rf_txClient} label={trans('field.client')}
                url="clients/searcher"
                col_id="inClient"
                col_tx="txClient"
                colorRefs={['table_cell_red|' + trans('field.debtor')]}
                tooltip={`${trans('field.client')}`}
                columns={[
                  { id: "0", Header: "ID", accessor: "inClient", show: false },
                  { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
                  { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
                ]}
                readOnly={false}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('inClient*'))}
              />
            }

            {r_filters_shown.current.includes('itClient') &&
              <CxChooser xref={rf_txTypeClient}
                label={trans('field.type')}
                table="t_client"
                col_id="itClient"
                col_tx="txTypeClient"
                readOnly={false}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('itClient*'))}
              />
            }

            {r_filters_shown.current.includes('inSupplier') &&
              <CxSearcher xref={rf_txSupplier} label={trans('field.supplier')}
                dbfield="n_supplier.txSupplier" dbfieldkey={`n_ps.inSupplier`}
                url="suppliers"
                col_id="inSupplier"
                col_tx="txSupplier"
                columns={[
                  { id: "0", Header: "ID", accessor: "inSupplier", show: false },
                  { id: "1", Header: trans('field.supplier'), accessor: row => rf_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
                ]}
                width='var(--ibs_control_width_md)'
              />
            }

            {r_filters_shown.current.includes('inPs') &&
              <CxSearcher xref={rf_txPs} label={trans('field.ps')}
                url="pss"
                col_id="inPs"
                col_tx="txPs"
                tooltip={`${trans('field.ps')}`}
                columns={[
                  { id: "0", Header: "ID", accessor: "inPs", show: false },
                  { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '75%' },
                  { id: "2", Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '25%', align: 'right' },
                ]}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('inPs*'))}
              />
            }

            {r_filters_shown.current.includes('txPs') &&
              <CxInput xref={rf_txPs_forLike}
                label={trans('field.ps')}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('txPs*'))}
              />
            }

            {r_filters_shown.current.includes('txNature') &&
              <CxInput xref={rf_txNature}
                label={trans('field.rx_nature')}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('txNature*'))}
              />
            }

            {r_filters_shown.current.includes('itPsGroup') &&
              <CxSearcher xref={rf_txTypePsGroup}
                label={trans('field.group')}
                url="sett/ps_group"
                col_id="itPsGroup"
                col_tx="txTypePsGroup"
                columns={[
                  { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
                  { id: "1", Header: trans('field.group'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
                ]}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('itPsGroup*'))}
              />
            }

            {r_filters_shown.current.includes('itPsSubgroup') &&
              <CxSearcher xref={rf_txTypePsSubgroup}
                label={trans('field.subgroup')}
                url="sett/ps_subgroup"
                col_id="itPsSubgroup"
                col_tx="txTypePsSubgroup"
                columns={[
                  { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
                  { id: "1", Header: trans('field.subgroup'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
                ]}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('itPsSubgroup*'))}
              />
            }

            {r_filters_shown.current.includes('inInsur') &&
              <CxChooser xref={rf_txInsur} label={trans('field.insurance')}
                table="n_insur"
                col_id="inInsur"
                col_tx="txInsur"
                extra_filter_1={"boActive='1'"}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('inInsur*'))}
              />
            }

            {r_filters_shown.current.includes('inEmployee') &&
              <CxChooser xref={rf_txEmployee} label={trans('field.employee')}
                table="n_employee"
                col_id="inEmployee"
                col_tx="txEmployee"
                extra_filter_1={"boActive='1'"}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('inEmployee*'))}
              />
            }

            {r_filters_shown.current.includes('inReferrer') &&
              <CxChooser xref={rf_txReferrer} label={trans('field.referrer')}
                table="n_referrer"
                col_id="inReferrer"
                col_tx="txReferrer"
                extra_filter_1={"boActive='1'"}
                width='var(--ibs_control_width_md)'
                required={Boolean(r_filters_shown.current.includes('inReferrer*'))}
              />
            }

          </div>
        </CxDialog>

        <CxSnackbar xref={r_snack_error} severity="error" autoHideDuration={3000} />

      </div>
    </div>
  );

}

export default MxStats;
