import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettPsSubgroup } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettPsSubgroupList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettPsSubgroup, setStoreSettPsSubgroup] = useSessionStorage('StoreSettPsSubgroup', StoreSettPsSubgroup);

  React.useEffect(() => {
    clearStore('StoreSettPsSubgroup', storeSettPsSubgroup);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPsSubgroup.itPsSubgroup = null;
    } else {
      storeSettPsSubgroup.itPsSubgroup = row.original.itPsSubgroup;
    }
    setStoreSettPsSubgroup(storeSettPsSubgroup);
    navTo("sett-ps-subgroup-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.pss')}: ${trans('field.subgroups')}`}
        xref={r_list}
        tooltip={`${trans('field.subgroup')} / ${trans('field.group')}`}
        fullscreen
        url="sett/ps_subgroup"
        filter={`t_ps_subgroup.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
          { id: "1", Header: trans('field.subgroup'), accessor: row => r_list.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '50%' },
          { id: "2", Header: trans('field.group'), accessor: row => r_list.current.getTable().highlightText(row["txTypePsGroup"]), width: '50%' },
        ]}
      />
    </div>
  );
}

export default MxSettPsSubgroupList;
