import * as FxFetch from '../../fx/FxFetch';
import * as FxLog from '../../fx/FxLog';
import { getStoreValue } from "../../gx/GxStore";
import * as FxNum from '../../fx/FxNum';

export const saveItemAttachedToStore = async (storeSlsDoc, setStoreSlsDoc, inPs, nuQuantity) => {
  try {
    let record = {};
    record['idValue'] = inPs;
    return Promise.all([FxFetch.doJsonX(`sls/docitem/load_attached`, record)]).then((result) => {
      if (result[0]) {
        // carga ps adjuntos en storeSlsDoc.arItems
        for (let i = 0; i < result[0].length; i++) {
          let items = storeSlsDoc.arItems || [];

          let _inPs = result[0][i].inPs;
          let _txPs = result[0][i].txPs;
          let _txPsAlias = result[0][i].txPsAlias;
          let _nuFrequency = result[0][i].nuFrequency;

          let _nuQuantity = FxNum.to2r(result[0][i].nuQuantity);
          if (result[0][i].att_boProportional==='1') {
            _nuQuantity = FxNum.to2r(nuQuantity * result[0][i].att_nuQuantity);
          }
          let _nuPrice = FxNum.to2r(result[0][i].nuPrice);
          let _nuDiscountPerc = FxNum.to2r(result[0][i].nuDiscountPerc);
          let _nuTaxPerc = FxNum.to2r(result[0][i].nuTaxPerc);
          let _nuDiscount = (_nuQuantity * _nuPrice * _nuDiscountPerc / 100);
          let _nuTaxable = ((_nuQuantity * _nuPrice) - _nuDiscount);
          let _nuTax = (_nuTaxable * _nuTaxPerc / 100);
          let _nuAmount = FxNum.to2r(_nuTaxable + _nuTax);

          let item = {
            idx: 0,
            inXxxItem: '',
            inPs: _inPs,
            txPs: _txPs,
            txLotCode: '',
            dtLotExpr: '',
            txPsAlias: _txPsAlias,
            nuFrequency: _nuFrequency,
            txRemarks: '',

            nuQuantity: _nuQuantity,
            nuPrice: _nuPrice,
            nuDiscountPerc: _nuDiscountPerc,
            nuTaxPerc: _nuTaxPerc,
            nuAmount: _nuAmount,
            //----
            nuTaxable: _nuTaxable,
            nuTax: _nuTax,
            nuDiscount: _nuDiscount,
          };

          // añade descuentos por tasas.
          if (storeSlsDoc.arRates) {
            const keys = Object.keys(storeSlsDoc.arRates[0]);
            let discPerc = 0;
            keys.forEach((key, index) => {
              //console.log(`${key}: ${storeSlsDoc.arRates[0][key]}`);
              if (key === inPs) {
                discPerc = storeSlsDoc.arRates[0][key];
                item.nuDiscountPerc = discPerc;
              }
            });
          }
          // marca los que necesitan que el usuario seleccione lote.
          if (storeSlsDoc.docType === 'i') {
            let hasLots = result[0][i].hasLots;
            if (hasLots === '1') {
              if (getStoreValue('StoreSession', 'boLotControl') === '1') {
                /* DAQ 2022.05.11 - indica a MxSlsDocForm.doLots() que este ps está pendiente de añadir lote */
                item.txLotCode = '**pending**';
              }
            }
          }

          items.unshift(item);
          // reorder
          items.forEach(function (item, index) {
            item.idx = index;
          })

          //console.log("Added item: " + item.txPs);
          storeSlsDoc.arItems = items;

          // asienta que los datos fueron cambiados
          storeSlsDoc.boDataChanged = '1';

          // Saving
          setStoreSlsDoc(storeSlsDoc);
        }
      }
    });

  } catch (error) {
    FxLog.errorx("MxSlsDocFormItemH1.saveItemAttachedToStore", error);
  }
}

export const saveItemToStore = async (storeSlsDoc, setStoreSlsDoc, storeSlsLst, idxForEdit, inPs, itemData) => {
  try {
    let items = storeSlsDoc.arItems || [];
    /*console.log("# of items: " + items.length);
    items.forEach(function(item, index) {
      console.log("[" + index + "]: " + item.txPs);
    });*/

    let _inPs = inPs;
    let _txPs = itemData.txPs;
    let _txLotCode = itemData.txLotCode;
    let _dtLotExpr = itemData.dtLotExpr;
    let _txPsAlias = itemData.txPsAlias;
    let _nuFrequency = itemData.nuFrequency;
    let _txAlert = itemData.txAlert;
    let _txRemarks = itemData.txRemarks;

    let _nuQuantity = itemData.nuQuantity;
    let _nuPrice = itemData.nuPrice;
    let _nuDiscountPerc = itemData.nuDiscountPerc;
    let _nuTaxPerc = itemData.nuTaxPerc;
    let _nuAmount = itemData.nuAmount;

    let _nuDiscount = (_nuQuantity * _nuPrice * _nuDiscountPerc / 100);
    let _nuTaxable = ((_nuQuantity * _nuPrice) - _nuDiscount);
    let _nuTax = (_nuTaxable * _nuTaxPerc / 100);

    if (storeSlsLst.table_item_idx === null) {
      // Adding
      let item = {
        idx: 0,
        inXxxItem: '',
        inPs: _inPs,
        txPs: _txPs,
        txLotCode: _txLotCode,
        dtLotExpr: _dtLotExpr,
        txPsAlias: _txPsAlias,
        nuFrequency: _nuFrequency,
        txAlert: _txAlert,
        txRemarks: _txRemarks,

        nuQuantity: _nuQuantity,
        nuPrice: _nuPrice,
        nuDiscountPerc: _nuDiscountPerc,
        nuTaxPerc: _nuTaxPerc,
        nuAmount: _nuAmount,
        //----
        nuTaxable: _nuTaxable,
        nuTax: _nuTax,
        nuDiscount: _nuDiscount,
      };
      items.unshift(item);
      // reorder
      items.forEach(function (item, index) {
        item.idx = index;
      })
    } else {
      // Modifying
      storeSlsDoc.arItems[idxForEdit].idx = storeSlsLst.table_item_idx;
      storeSlsDoc.arItems[idxForEdit].inPs = _inPs;
      storeSlsDoc.arItems[idxForEdit].txPs = _txPs;
      storeSlsDoc.arItems[idxForEdit].txLotCode = _txLotCode;
      storeSlsDoc.arItems[idxForEdit].dtLotExpr = _dtLotExpr;
      storeSlsDoc.arItems[idxForEdit].txPsAlias = _txPsAlias;
      storeSlsDoc.arItems[idxForEdit].nuFrequency = _nuFrequency;
      storeSlsDoc.arItems[idxForEdit].txAlert = _txAlert;
      storeSlsDoc.arItems[idxForEdit].txRemarks = _txRemarks;

      storeSlsDoc.arItems[idxForEdit].nuQuantity = _nuQuantity;
      storeSlsDoc.arItems[idxForEdit].nuPrice = _nuPrice;
      storeSlsDoc.arItems[idxForEdit].nuDiscountPerc = _nuDiscountPerc;
      storeSlsDoc.arItems[idxForEdit].nuTaxPerc = _nuTaxPerc;
      storeSlsDoc.arItems[idxForEdit].nuAmount = _nuAmount;
      //----
      storeSlsDoc.arItems[idxForEdit].nuTaxable = _nuTaxable;
      storeSlsDoc.arItems[idxForEdit].nuTax = _nuTax;
      storeSlsDoc.arItems[idxForEdit].nuDiscount = _nuDiscount;
    }

    //console.log("Added item: " + item.txPs);
    storeSlsDoc.arItems = items;

    // asienta que los datos fueron cambiados
    storeSlsDoc.boDataChanged = '1';

    // Saving
    setStoreSlsDoc(storeSlsDoc);

  } catch (error) {
    FxLog.errorx('MxSlsDocFormItemH1.saveItemToStore', error)
  }

}
