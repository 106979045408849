import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxState from '../../cx/CxState';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, setStoreValue } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';


const MxSettSlsLot = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  let r_form = React.useRef(null);
  const rf_default = React.useRef(null);
  const r_snack_save = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/branch/r', record)]).then((result) => {
      if (result[0]) {
        rf_default.current.setVal(result[0][0].boLotControl);
      }
    });
  }

  const formSaveCustom = async () => {

    let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
    let record = {};
    record['s_branch.boLotControl'] = rf_default.current.getVal();
    setStoreValue('StoreSession', 'boLotControl', rf_default.current.getVal());
    Promise.all([FxFetch.doJsonF("/sett/branch/u", filter, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.lots')}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxState mode='checkbox' xref={rf_default} label={trans('field.sett_lot_control')} dbfield="s_branch.boLotControl" />
      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettSlsLot;
