import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettAnimalSpecies } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettAnimalSpeciesForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_record_used = React.useRef(null);

  let r_form = React.useRef(null);

  const [storeSettAnimalSpecies, setStoreSettAnimalSpecies] = useSessionStorage('StoreSettAnimalSpecies', StoreSettAnimalSpecies);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettAnimalSpecies.itAnimalSpecies = lastSaveRv.theValue;
      Promise.all([setStoreSettAnimalSpecies(storeSettAnimalSpecies)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue==='rv_error') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.patients')}: ${trans('field.species')}`}
        xref={r_form}
        url="sett/animal_species" 
        filterIdField='itAnimalSpecies'
        filterIdValue={storeSettAnimalSpecies.itAnimalSpecies} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_animal_species.itAnimalSpecies" value={storeSettAnimalSpecies.itAnimalSpecies || ''} />
        <CxInput xref={useRef(null)} label={trans('field.species')} dbfield="t_animal_species.txTypeAnimalSpecies" required />
        
      </GxForm>

      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />

    </div>
  );

}

export default MxSettAnimalSpeciesForm;
