import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxLongTask from '../../gx/GxLongTask';
import { StoreLongTask } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSlsDocListSpecOptTsk = (props) => {
  const [{ trans }] = useTranslation();

  const [storeLongTask, ] = useSessionStorage('StoreLongTask', StoreLongTask);

  return (
    <div>
      <CxToolbar moduleName={`${trans('field.mass_billing')}: ${storeLongTask._record.op==='81' ? trans('field.inv_issuing_81') : trans('field.inv_issuing_88')}`} />
      <GxLongTask />
    </div>
  );

}

export default MxSlsDocListSpecOptTsk;
