import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettMultimedia } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettMultimediaList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettMultimedia, setStoreSettMultimedia] = useSessionStorage('StoreSettMultimedia', StoreSettMultimedia);

  React.useEffect(() => {
    clearStore('StoreSettMultimedia', storeSettMultimedia);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettMultimedia.itMultimedia = null;
    } else {
      storeSettMultimedia.itMultimedia = row.original.itMultimedia;
    }
    setStoreSettMultimedia(storeSettMultimedia);
    navTo("sett-client-multimedia-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.files2')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/multimedia"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itMultimedia", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.file2')}`, accessor: "txTypeMultimedia", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettMultimediaList;
