import { BiCalendarAlt } from "react-icons/bi"; 
import { MdClear } from "react-icons/md"; 
import React, { useState, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import '../../styles/main.css';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import CxDlgCalendar from './CxDlgCalendar';
import CxIconButtonNew from './CxIconButtonNew';
import { useStyles } from "./StylesCx";
import i18n from '../../i18n';


function InputFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <InputMask
      {...other}
      style={{
        border: 'none',
        outline: 'none',
        fontSize: 'var(--ibs_control_text_font_size)',
        width: '100px',
        margin: '0px 0px 0px 0px',
        color: props.disabled ? '#9E9E9E' : 'var(--ibs_color_black)',
      }}
      ref={props.inputRef}
      mask="99-99-9999"
      maskPlaceholder={FxDat.getDateFormat().toLowerCase()}
      value={props.value}
      onChange={props.onChange}
    />
  );
}

const CxDate = props => {
  const classes = useStyles(props);
  const { onBlur, onClear, InputLabelProps, InputProps, defaultValue, showAge, today, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const [myLabel, setMyLabel] = useState(rest.label);
  const [myAge, setMyAge] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = React.useState(false);
  const [dlg, setDlg] = React.useState(false);

  const r_dlg = React.useRef(null);
  const r_clearing = React.useRef(false);

  const rf_control = React.useRef(null);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    if (today) {
      setValToday();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, setLabel, getDbfield, setFocus, setFocusSel, isEmpty, clear, setValToday, wasChanged, setChanged,
    };
  });
  const setVal = (newValue) => {
    if (!newValue) {
      setMyValue('');
    } else {
      let yyyy = parseInt(newValue.substring(0, 4)).toString().padStart(4, '0');
      let MM = parseInt(newValue.substring(5, 7)).toString().padStart(2, '0');
      let dd = parseInt(newValue.substring(8, 10)).toString().padStart(2, '0');
      let dateFormat = FxDat.getDateFormat();
      if (dateFormat === 'MM-dd-yyyy') {
        setMyValue(`${MM}-${dd}-${yyyy}`);
      }
      if (dateFormat === 'dd-MM-yyyy') {
        setMyValue(`${dd}-${MM}-${yyyy}`);
      }
      if (showAge) {
        setMyAge(FxDat.getAge(newValue));
      }
    }
  };
  const getVal = () => {
    if (myValue === null) {
      return null;
    } else {
      return FxDat.toIsoDate(myValue);
    }
  };
  const getIsRequired = () => {
    return isRequired && (getVal() === null || (getVal()||'').length === 0);
  };
  const getLabel = () => { return myLabel };
  const setLabel = (newText) => setMyLabel(newText);
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const setFocusSel = () => rf_control.current.select();
  const isEmpty = () => { return myValue.length === 0 };
  const clear = () => { setMyValue(''); setChanged(true); };
  const getType = () => { return 'CxDate' };

  const setValToday = () => {
    let today = FxDat.todayInLocal();
    setMyValue(today);
  };

  const doClear = () => {
    r_clearing.current = true;
    setMyValue('');
    setMyAge('');
    setChanged(true);
    setFocusSel();
  }

  const doDateSelectedFromCalendar = (dateSelected) => {
    try {
      setDlg(false);
      if (dateSelected) {
        setVal(dateSelected);
      }

    } catch (error) {
      FxLog.errorx('CxDate.doDateSelectedFromCalendar', error)
    }
  }

  const getLabelText = () => {
    if (showAge && myAge) {
      return `${myLabel} (${myAge})`;
    } else {
      return myLabel;
    }
  }

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          {...rest}
          label={getLabelText()}
          inputRef={rf_control}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            inputComponent: InputFormatCustom,
            disableUnderline: true,
            classes: {
              root: classes.root,
            },
            ...InputProps
          }}
          value={myValue}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => {
            let newValue = e.target.value;
            setMyValue(newValue);
            if (props.onChange !== undefined) props.onChange(newValue);
          }}
          onFocus={event => {
            if (rest.autoselect) event.target.select();
          }}
          onKeyDown={() => setChanged(true)}
        />
      </div>

      {!props.hidebuttons && <div className={classes.frame_buttons}>
        <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={doClear} disabled={props.disabled} />
        <CxIconButtonNew icon={<BiCalendarAlt fontSize={20} />} classType={classes.frame_icons_new} onClick={() => setDlg(true)} disabled={props.disabled} />
      </div>}

      <CxDlgCalendar
        xref={r_dlg}
        open={dlg}
        onClose={(d) => doDateSelectedFromCalendar(d)}
        title={i18n.t('msg.select_x').replace('%s', i18n.t('field.date'))}
      />

    </div>
  );
};

export default CxDate;
