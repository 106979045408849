import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CxBackdrop = props => {
  const { hideProgress } = props;
  const classes = useStyles(props);

  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, 
    };
  });
  const getType = () => { return 'CxBackdrop' };

  return (
    <Backdrop open={props.open} className={classes.backdrop} >
      {!hideProgress && <CircularProgress color="inherit" />}
    </Backdrop>
  );

};

export default CxBackdrop;
