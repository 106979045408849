import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
import CxDialog from "./CxDialog";
import * as FxLog from '../fx/FxLog';


const useStylesLocal = makeStyles({
  frame: {
    backgroundColor: 'var(--ibs_color_white)',
    color: 'var(--ibs_color_black)',
    padding: '0px 0 0px 8px',
    height: props => props.height || '300px',
  },

  ctl_label: {
    padding: '5px 0 8px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 0px',
    cursor: 'default',
    textAlign: 'left',
    //color: 'var(--ibs_color_gray)',
    fontSize: 'var(--ibs_control_toolbar_popper_font_size)',
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },

  ctl_group: {
    margin: '5px 12px 8px 12px',
  },

  ctl_check: {
    "& .MuiTypography-body1": {
      textAlign: 'left',
      margin: '5px 0px 5px 5px',
      fontSize: 'var(--ibs_control_toolbar_popper_font_size)',
      width: props => { return props.itemWidth || 'var(--ibs_control_width_sm)' },
    },
  },
});

const CxDlgCheckList = props => {
  const classesL = useStylesLocal(props);

  const { onOk, ...rest } = props;
  const [list, setList] = React.useState(rest.list || []);
  const [open, setOpen] = React.useState(false);

  const [myValue, setMyValue] = React.useState('');
  const [wasChanged, setChanged] = React.useState(false);

  const rf_check = React.useRef([]);
  const r_dlg = React.useRef(null);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setOpen, setList, wasChanged, getVal, 
    };
  });
  const getType = () => { return 'CxDlgCheckList' };
  const setVal = (newValue) => {
    setMyValue(newValue || '');
  };
  const getVal = () => { return myValue || '' };

  const doOk = () => {
    onOk(getIdsChecked());
    setOpen(false);
  }

  const getIdsChecked = () => {
    FxLog.infox('CxDlgCheckList.getIdsChecked', '...');
    let newValue = '';

    for (var index = 0; index < rf_check.current.length; index++) {
      let ctl = rf_check.current[index];
      if (ctl.checked) {
        newValue += '|' + ctl.id;
      }
    }
    newValue = newValue.substring(1);

    setVal(newValue)
    setChanged(true);
    
    return newValue;
  };

  return (
    <div>
      <CxDialog
        xref={r_dlg}
        title={props.title}
        open={open}
        onClose={() => setOpen(false)}
        onOk={doOk}
        height='300px'>

        <div className={classesL.frame}>

          {list.map((item, i) => (
            <FormControlLabel classes={{ root: classesL.ctl_check, }}
              key={item.id}
              label={item.title || item.type}
              control={
                <label className="ibs_checkbox">
                  <input ref={(element) => rf_check.current[i] = element} type="checkbox"
                    id={item.id}
                    value={item.title || item.type}
                    defaultChecked={Boolean(item.checked === '1') || false}
                  />
                </label>
              }
            />
          ))}

        </div>

      </CxDialog>
    </div>
  );
};

export default CxDlgCheckList;
