import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettAnimalCoat } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettAnimalCoatList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettAnimalCoat, setStoreSettAnimalCoat] = useSessionStorage('StoreSettAnimalCoat', StoreSettAnimalCoat);

  React.useEffect(() => {
    clearStore('StoreSettAnimalCoat', storeSettAnimalCoat);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettAnimalCoat.itAnimalCoat = null;
    } else {
      storeSettAnimalCoat.itAnimalCoat = row.original.itAnimalCoat;
    }
    setStoreSettAnimalCoat(storeSettAnimalCoat);
    navTo("sett-animal-coat-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.patients')}: ${trans('field.coats')}`}
        xref={r_list}
        tooltip={`${trans('field.coat')}`}
        fullscreen
        url="sett/animal_coat"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalCoat", show: false },
          { id: "1", Header: trans('field.coat'), accessor: row => r_list.current.getTable().highlightText(row["txTypeAnimalCoat"]), width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettAnimalCoatList;
