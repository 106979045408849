import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettRate } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsRateList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettRate, setStoreSettRate] = useSessionStorage('StoreSettRate', StoreSettRate);

  React.useEffect(() => {
    clearStore('StoreSettRate', storeSettRate);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettRate.itRate = null;
    } else {
      storeSettRate.itRate = row.original.itRate;
    }
    setStoreSettRate(storeSettRate);
    navTo("sett-sls-rate-form");
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.rates')}
        fullscreen
        hasSearchBox={false}
        url="sett/rate"
        filter={`t_rate.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itRate", show: false },
          { id: "1", Header: trans('field.name'), accessor: "txTypeRate", width: '80%' },
          { id: "2", Header: trans('field.items'), accessor: "tot", width: '20%' },
        ]}
      />

    </div>

  );

}

export default MxSettSlsRateList;
