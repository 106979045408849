import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

const CxLink = props => {
  const { label, onClick, ...rest } = props;
  const [show, setShow] = React.useState(rest.show==='false' ? false : true);
  
  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, setShow, 
    };
  });
  const getType = () => { return 'CxLink' };

  return (
    <span style={{ width: '100%', textAlign: 'left' }}>
      {show && 
        <button className="ibs_button_link" onClick={(e) => doClick(e)} {...rest} >
          {props.label}
        </button>
      }
    </span>
  );

};

export default CxLink;
