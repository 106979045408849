import React from 'react';
import * as FxLog from '../fx/FxLog';
import CxToolbar from '../cx/CxToolbar';
import CxTable from '../cx/CxTable';
import { makeStyles } from '@material-ui/core/styles';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";


const useStyles = makeStyles({
  container: {
    margin: 'var(--ibs_container_margin)',
  },
});

const GxList = (props) => {
  const classes = useStyles();
  const r_table = React.useRef(null);
  const [moduleName, setModuleName] = React.useState('');
  const [filter, setFilter] = React.useState(props.filter || '');
  const [addParams, setAddParams] = React.useState(props.addParams || '');

  React.useEffect(() => {
    if (props.moduleName) {
      setModuleName(props.moduleName);
    }
    // eslint-disable-next-line
  }, []);

  React.useImperativeHandle(props.xref, () => {
    return {
      redisplay, refresh, isEmpty, getTable, setdeepFilter, setdeepAddParams, setModuleName,
    };
  });
  const setdeepFilter = (f) => {
    // 2023-04-09 - daq: permite reconfigurar filtro.
    setFilter(f);
    r_table.current.setFilter(f);
  };
  const setdeepAddParams = (f) => {
    // 2023-04-09 - daq: permite reconfigurar params.
    setAddParams(f);
    r_table.current.setAddParams(f);
  };
  const redisplay = () => {
    r_table.current.redisplay();
  };
  const refresh = () => {
    r_table.current.refresh();
  };
  const isEmpty = () => {
    return r_table.current.isEmpty();
  };
  const getTable = () => {
    return r_table.current;
  };

  const openForm = async (op, row) => {
    FxLog.infox("GxList.openForm", "Opening Form...", `...op: ${op}`);
    if (props.onOpenForm) {
      props.onOpenForm(op, row);
    }
  }

  const doScrollDown = (e) => {
    // 2024-02-01 - daq: funciona, pero de momento deshabilitamos porque la última página de la tabla se contrae y lo hace inefectivo.
    //r_table.current.goPageNext();
  }
  
  const doScrollUp = (e) => {
    // 2024-02-01 - daq: funciona, pero de momento deshabilitamos porque la última página de la tabla se contrae y lo hace inefectivo.
    //r_table.current.goPagePrev();
  }
  
  return (
    <ReactScrollWheelHandler upHandler={doScrollUp} downHandler={doScrollDown} >
      <div>
        <div id='myGxList'>
          <CxToolbar moduleName={moduleName || props.moduleName} addToolbarButtons={props.addToolbarButtons} help={props.help} onGoBack={props.onGoBack}/>
          <div className={classes.container} >
            <CxTable
              xref={r_table}
              id={props.id}
              title={props.title}
              tooltip={props.tooltip}
              fullscreen
              showtip={props.showtip}
              hasSearchBox={props.hasSearchBox !== undefined ? props.hasSearchBox : true}
              url={props.url}
              filter={filter}
              addParams={addParams}
              columns={props.columns}
              optionalColumns={props.optionalColumns}
              optionalSorting={props.optionalSorting}
              optionalFromTo={props.optionalFromTo}
              onOptions={props.onOptions}
              onOpenForm={openForm}
              hasButtonAdd={props.hasButtonAdd === false ? false : true} // tiene botón de manera predeterminada
              addTableButtonsLeft={props.addTableButtonsLeft}
              addTableButtonsRight={props.addTableButtonsRight}
              colorRefs={props.colorRefs}
              disabled={props.disabled}
              speechExternal={props.speechExternal}
            >
              {props.children}
            </CxTable>
          </div>
        </div>
      </div>
    </ReactScrollWheelHandler>

  );
}

export default GxList;
