import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationES from './locales/es.json';

// the translations
const resources = {
    en: { translation: translationEN },
    es: { translation: translationES },
};

const detectionOptions = {
  order: ['navigator', 'localStorage'],
  caches: ['localStorage', 'cookie'],
  lookupFromPathIndex: 0
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    detection: detectionOptions,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
