import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxLongTask from '../../gx/GxLongTask';


const MxSettStockFill = (props) => {
  const [{ trans }] = useTranslation();

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  return (
    <div>
      <CxToolbar moduleName={`${trans('field.stock_op_fill')}`} />
      <GxLongTask />
    </div>
  );

}

export default MxSettStockFill;
