import React from 'react';
import i18n from '../../i18n';
import '../../styles/main.css';
import CxInput from '../cx/CxInput';
import CxSnackbar from '../cx/CxSnackbar';
import CxButton from '../cx/CxButton';
import * as FxLog from '../fx/FxLog';
import packageJson from '../../../package.json';
import { StoreSession, StoreCyStats, clearStore, loadSession } from "../gx/GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import * as FxDat from '../fx/FxDat';
import * as FxFetch from '../fx/FxFetch';
import * as FxInet from '../fx/FxInet';
import CxSwitch from '../cx/CxSwitch';
//import { Navigate } from "react-router-dom";
import { useTranslation } from '../hx/useTranslation';
import { useAccess } from '../hx/useAccess';
import { useWindowSize } from '../hx/useWindowSize';
import { useNav } from '../hx/useNav';

const MxLogin = () => { 
  const size = useWindowSize();
  const [{ storeTransInSession }] = useTranslation();
  const [{ storeAccessInSession }] = useAccess();
  const [{ navTo }] = useNav();

  const formWidth = '250px';

  const rf_txIdi = React.useRef(null);
  const rf_txUsername = React.useRef(null);
  const rf_txPassword = React.useRef(null);
  const rf_rem = React.useRef(false);

  const [localIp, setLocalIp] = React.useState(null);

  const r_snack_loading = React.useRef(null);
  const r_snack_error = React.useRef(null);

  // eslint-disable-next-line
  const [storeSession, setStoreSession] = useSessionStorage('StoreSession', StoreSession);
  const [storeCyStats, setStoreCyStats] = useSessionStorage('StoreCyStats', StoreCyStats);

  const [disabled, setDisabled] = React.useState(false);


  // eslint-disable-next-line
  const [isSystemUpdated, setIsSystemUpdated] = React.useState(Boolean(localStorage.getItem('IBS.msg_updated') === '1'));


  React.useEffect(() => {
    localStorage.setItem('IBS.msg_updated', "0");

    let idi = localStorage.getItem('MxLogin.idi');
    let usr = localStorage.getItem('MxLogin.username');
    let pwd = localStorage.getItem('MxLogin.password');
    let rem = localStorage.getItem('MxLogin.remember');

    // si hay parámetros en la url, los usa
    // ex: http://localhost:3000/login?idi=es.iberical_test_opti&username=master&password=bubastis
    const queryParameters = new URLSearchParams(window.location.search)
    if (queryParameters.get("idi")) {
      idi = queryParameters.get("idi");
      usr = queryParameters.get("username");
      pwd = queryParameters.get("password");
      rem = null;
    }

    rf_txIdi.current.setVal(idi || '');
    rf_rem.current.setVal(rem);
    rf_txUsername.current.setVal(usr);
    rf_txPassword.current.setVal(pwd);

    clearStore('StoreSession', storeSession);
    clearStore('StoreCyStats', storeCyStats);

    Promise.all([FxInet.getIP()]).then((result) => {
      setLocalIp(result[0]||'');
    });

    // eslint-disable-next-line
  }, []);

  // Verifica si se puede acceder al programa.
  const login = () => {
    FxLog.infox("MxLogin.login", "checking if can login", null);
    //-----------------------------------------------------------------------
    //let txBrowserLang = i18n.language;
    //-----------------------------------------------------------------------
    let params = "";
    params += "txIp=" + localIp;
    params += "&txDevice=" + (size.isLarge ? 'Desktop/Tablet' : 'Mobile');
    params += "&dtLogin=" + FxDat.today(); // iso date
    params += "&txIdi=" + rf_txIdi.current.getVal().trim();
    params += "&inBranch=" + (localStorage.getItem('MxLogin.branch') || '');
    params += "&txUsername=" + rf_txUsername.current.getVal().trim();
    params += "&txPassword=" + rf_txPassword.current.getVal().trim();
    setDisabled(true);
    r_snack_loading.current.setOpen(true);
    Promise.all([FxFetch.doUrl("login", params)]).then((result) => {
      if (result[0] === null) {
        setDisabled(false);
        r_snack_loading.current.setOpen(false);
        r_snack_error.current.setVal(i18n.t('msg.connection_refused'));
        r_snack_error.current.setOpen(true);
      } else {
        if (result[0].theValue === 'OK') {
          // ACCESO ACEPTADO *********************************************************************
          localStorage.setItem('MxLogin.idi', rf_txIdi.current.getVal().trim());
          localStorage.setItem('MxLogin.branch', `${rf_txIdi.current.getVal().trim()}|${result[0].inBranch}`); // 2024-01-04 - daq: por si el servidor cambió de sucursal.
          if (rf_rem.current.isChecked()) {
            localStorage.setItem('MxLogin.username', rf_txUsername.current.getVal().trim());
            localStorage.setItem('MxLogin.password', rf_txPassword.current.getVal().trim());
            localStorage.setItem('MxLogin.remember', rf_rem.current.getVal());
          } else {
            localStorage.setItem('MxLogin.username', '');
            localStorage.setItem('MxLogin.password', '');
            localStorage.setItem('MxLogin.remember', '');
          }
          // INICIALIZACIÓN GENERAL
          Promise.all([
            loadSession(result[0]),
            loadRbl(),
            loadAccess(),
            loadMonthlyStats()
          ]).then((result) => {
            navTo("client-list");
          });
          // *************************************************************************************
        } else {
          setDisabled(false);
          r_snack_loading.current.setOpen(false);
          // ACCESO RECHAZADO ********************************************************************
          if (result[0].theValue === 'rv_cannot_add_db') {
            // no puede conectar a la base de datos; servidor caído?
            r_snack_error.current.setVal(i18n.t('msg.cannot_add_db'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_no_logins') {
            // no puede conectar a la base de datos; servidor caído?
            r_snack_error.current.setVal(i18n.t('msg.no_logins_available'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_cannot_connect_to_db') {
            // no puede conectar a la base de datos; servidor caído?
            r_snack_error.current.setVal(i18n.t('msg.cannot_connect_to_db'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incorrect_idi') {
            // el idi pasado en el login o el de la tabla n_branch es incorrecto.
            r_snack_error.current.setVal(i18n.t('msg.incorrect_idi'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incomplete_login_credentials') {
            // no se ha completado idi, usuario o password.
            r_snack_error.current.setVal(i18n.t('msg.incomplete_login_credentials'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_subscription_not_current') {
            r_snack_error.current.setVal(i18n.t('msg.subscription_not_current'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_application_db_not_active') {
            r_snack_error.current.setVal(i18n.t('msg.application_db_not_active'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incorrect_user_pwd') {
            // idi, usuario o pwd incorrectos.
            r_snack_error.current.setVal(i18n.t('msg.check_usr_pwd'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_user_access_failed') {
            r_snack_error.current.setVal(i18n.t('msg.permission_denied'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_outdated_db') {
            r_snack_error.current.setVal(i18n.t('msg.must_update_iberical'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_max_accesses_reached') {
            r_snack_error.current.setVal(i18n.t('msg.max_accesses_reached'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_desktop_only') {
            r_snack_error.current.setVal(i18n.t('msg.desktop_only'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_inactive') {
            r_snack_error.current.setVal(i18n.t('msg.cannot_open_app'));
            r_snack_error.current.setOpen(true);
          }
          // *************************************************************************************
        }
      }
    });
  }

  const loadRbl = () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('login/get_rbl', record)]).then((result) => {
        if (result[0]) {
          storeTransInSession(result[0]);
        }
      });

    } catch (error) {
      FxLog.errorx('MxLogin.loadRbl', error);
    }
  }

  const loadMonthlyStats = () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('login/get_monthly_stats', record)]).then((result) => {
        if (result[0]) {
          storeCyStats.nuSalesThisMonth = Number(result[0].sales_this_month);
          storeCyStats.nuSalesLastMonth = Number(result[0].sales_last_month);
          setStoreCyStats(storeCyStats);
        }
      });

    } catch (error) {
      FxLog.errorx('MxLogin.loadMonthlyStats', error);
    }
  }

  const loadAccess = () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('login/get_access', record)]).then((result) => {
        if (result[0]) {
          storeAccessInSession(result[0]);
        }
      });

    } catch (error) {
      FxLog.errorx('MxLogin.loadAccess', error);
    }
  }

  return (
    <div style={{ width: formWidth, margin: "10% auto" }}>
      <img src={process.env.PUBLIC_URL + '/logo192.png'} alt='' width="100" height="100" />
      <div >
        <h2 style={{ margin: '10px 0 0 0', color: 'var(--ibs_color_cy_yellow)', fontFamily: 'EBGaramondRegular' }}>{`${packageJson.name}`.toUpperCase()}</h2>
        <h3 style={{ margin: '0px', color: 'var(--ibs_color_cy_yellow)', fontFamily: 'Arial', fontSize: '14px' }}>{`${packageJson.version}`}</h3>
        <hr style={{ width: formWidth, border: '1px solid var(--ibs_color_cy_blue)' }} />
        {isSystemUpdated && <h3 style={{ marginTop: '10px', color: 'var(--ibs_color_red)', fontFamily: 'Arial', fontSize: '12px' }}>{i18n.t('msg.device_updated').toUpperCase()}</h3>}
      </div>
      <br />

      <div id='div_MxLogin_controls' style={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        gap: '10px',
      }}>
        <CxInput xref={rf_txIdi} label={i18n.t('field.idi')} width={formWidth} lowerCase disabled={disabled} />
        <CxInput xref={rf_txUsername} label={i18n.t('field.user')} width={formWidth} lowerCase disabled={disabled} />
        <CxInput xref={rf_txPassword} type="password" label={i18n.t('field.password')} width={formWidth} lowerCase disabled={disabled} />
        <CxSwitch xref={rf_rem} label={i18n.t('field.remember')} width={formWidth} disabled={disabled} />
      </div>

      <CxButton label={i18n.t('msg.login')} onClick={login} disabled={disabled} style={{ width: formWidth, marginTop: '30px' }} />

      <CxSnackbar xref={r_snack_error} severity="error" autoHideDuration={2500} />
      <CxSnackbar xref={r_snack_loading} severity="info" value={i18n.t('msg.loading') + '...'} autoHideDuration={10000} />
    </div>
  );
}

export default MxLogin;
