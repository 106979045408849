import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxTableAttachmentBlob from '../../cx/CxTableAttachmentBlob';
import GxForm from '../../gx/GxForm';
//import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import CxSnackbar from '../../cx/CxSnackbar';
import { StoreClient, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';

const MxClientMultimediaFormCP = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ navBack }] = useNav();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  
  // Es el inMultimedia. No puedo usar storeClient.inMultimedia porque al cambiarlo, react no lo vé como un cambio ya que lo que mantiene es la referencia al objeto storeClient.
  const [currentID, ] = React.useState(storeClient.inMultimedia);

  let r_form = React.useRef(null);
  let r_table_attachment_blob = React.useRef(null);

  const rf_date = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.file2')}`}
        xref={r_form}
        url="multimedia"
        filterIdField='inMultimedia'
        filterIdValue={storeClient.inMultimedia}
        onFormLoad={formLoad}
      >
        <CxHidden xref={useRef(null)} dbfield="inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkTable" value={isApp('vett') ? 'n_animal' : 'n_client'} />
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkId" value={isApp('vett') ? storeClient.inAnimal : storeClient.inClient || ''} />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_multimedia.dtMultimedia`} disabled={true} required />

        <CxInput xref={useRef(null)} label={trans('field.issue')} dbfield="n_multimedia.txIssue" maxLength={100} disabled={true} required />

        {/* <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_multimedia.txNotes" minRows={4} /> */}

        {currentID && <CxTableAttachmentBlob xref={r_table_attachment_blob}
          inXxx={currentID} 
          url={'multimedia'} 
          table={'n_multimedia_blob'} 
          fld_inXxx={'inMultimedia'} 
          fld_inXxxBlob={'inMultimediaBlob'} 
          fld_txXxxBlob={'txMultimediaBlob'} 
          disabled={true}
        />}

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxClientMultimediaFormCP;
