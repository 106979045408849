import * as FxLog from '../../fx/FxLog';

export const savePaymToStore = async (storeSlsDoc, setStoreSlsDoc, idxTablePaym, idxForEdit, paymData) => {
  try {
    var payms = storeSlsDoc.arPayms || [];
    /*console.log("# of payms: " + payms.length);
    payms.forEach(function(paym, index) {
      console.log("[" + index + "]: " + paym.txPs);
    });*/

    let _dtPayment = paymData.dtPayment;
    let _itCash = paymData.itCash;
    let _txTypeCash = paymData.txTypeCash;
    let _itPayment = paymData.itPayment;
    let _txTypePayment = paymData.txTypePayment;
    let _itPaymentDet = paymData.itPaymentDet;
    let _txTypePaymentDet = paymData.txTypePaymentDet;
    let _nuAmount = paymData.nuAmount;

    if (idxTablePaym === null) {
      // Adding
      var paym = {
        idx: 0,
        inPayment: '',
        dtPayment: _dtPayment,
        itCash: _itCash,
        txTypeCash: _txTypeCash,
        itPayment: _itPayment,
        txTypePayment: _txTypePayment,
        itPaymentDet: _itPaymentDet,
        txTypePaymentDet: _txTypePaymentDet,
        nuAmount: _nuAmount,
      };
      payms.unshift(paym);
      // reorder
      payms.forEach(function (paym, index) {
        paym.idx = index;
      })
    } else {
      // Modifying
      storeSlsDoc.arPayms[idxForEdit].idx = idxTablePaym;
      storeSlsDoc.arPayms[idxForEdit].dtPayment = _dtPayment;
      storeSlsDoc.arPayms[idxForEdit].itCash = _itCash;
      storeSlsDoc.arPayms[idxForEdit].txTypeCash = _txTypeCash;
      storeSlsDoc.arPayms[idxForEdit].itPayment = _itPayment;
      storeSlsDoc.arPayms[idxForEdit].txTypePayment = _txTypePayment;
      storeSlsDoc.arPayms[idxForEdit].itPaymentDet = _itPaymentDet;
      storeSlsDoc.arPayms[idxForEdit].txTypePaymentDet = _txTypePaymentDet;
      storeSlsDoc.arPayms[idxForEdit].nuAmount = _nuAmount;
    }

    //console.log("Added paym: " + paym.nuAmount);
    storeSlsDoc.arPayms = payms;

    // asienta que los datos fueron cambiados
    storeSlsDoc.boDataChanged = '1';

    // Saving
    setStoreSlsDoc(storeSlsDoc);

  } catch (error) {
    FxLog.errorx('MxSlsDocFormPaymH1.savePaymToStore', error)
  }

}
