import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettLabTech } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettLabTechList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettLabTech, setStoreSettLabTech] = useSessionStorage('StoreSettLabTech', StoreSettLabTech);

  React.useEffect(() => {
    clearStore('StoreSettLabTech', storeSettLabTech);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettLabTech.inLabTech = null;
    } else {
      storeSettLabTech.inLabTech = row.original.inLabTech;
    }
    setStoreSettLabTech(storeSettLabTech);
    navTo("sett-lab-tech-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.lab_techs')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/labs/tech"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "inLabTech", show: false },
          { id: "1", Header: `${trans('field.lab_tech')}`, accessor: "txLabTech", width: '50%' },
          { id: "2", Header: `${trans('field.lab')}`, accessor: "txLab", width: '50%' },
        ]}
      />
    </div>
  );
}

export default MxSettLabTechList;
