// Input para formularios
import React from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import '../../styles/main.css';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CxTooltip from './CxTooltip';

const useStylesLocal = makeStyles({
  ctl_button: {
    border: '1px solid var(--ibs_color_gray)',
    borderRadius: '3px',
    height: '26px',
    margin: '0px 4px 0px 0px',
    padding: '2px 4px 2px 4px',
    color: props => props.color || 'var(--ibs_color_cy_blue)',
    background: props => props.background || 'var(--ibs_color_white_transparent)',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: 'var(--ibs_control_toolbar_popper_font_size)',
    whiteSpace: 'nowrap', /* para que el texto no exceda el botón */
    overflow: 'hidden', /* para que el texto no exceda el botón */
    textOverflow: 'ellipsis', /* para que el texto no exceda el botón */
    width: props => {
      return props.width;
    },
    '&:hover': {
      backgroundColor: 'var(--ibs_color_gray_light)',
      color: 'var(--ibs_color_black_dark)',
    },
  },
  ctl_popper: {
    zIndex: 5,
    margin: '2px 0px 0px 0px',
  },
  ctl_option: {
    borderTop: '1px dotted gray',
    borderRight: '1px dotted gray',
    borderBottom: '1px dotted gray',
    borderLeft: '1px dotted gray',
    width: props => { return props.width },
    height: 'fit-content',
    paddingTop: 5,
    paddingBottom: 5,
    color: 'var(--ibs_color_black)',
    background: 'var(--ibs_color_white)',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: 'var(--ibs_control_toolbar_popper_font_size)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    //whiteSpace: 'nowrap', /* para que el texto no exceda el botón */
    overflow: 'hidden', /* para que el texto no exceda el botón */
    textOverflow: 'ellipsis', /* para que el texto no exceda el botón */
  },
});

const CxToolbarPopper = props => {
  const classesL = useStylesLocal(props);
  const { onValueChange, onSelection, onCustomClick, ...rest } = props;
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [wasChanged, setChanged] = React.useState(false);
  const [visible, setVisible] = React.useState(rest.visible);

  const rf_control = React.useRef(null);
  const rf_tooltip = React.useRef(null);

  React.useEffect(() => {
    if (onValueChange !== undefined) onValueChange();
    // eslint-disable-next-line
  }, [myValue]);

  React.useEffect(() => {
    if (props.value) {
      setMyValue(props.value);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, setKey, getKey, clear, isEmpty, wasChanged, setChanged, setVisible, setTooltipText,
    };
  });
  const setVal = (newValue) => {
    let item = rest.list.find((item) => item.id === newValue);
    if (item) {
      setMyKey(item.id);
      setMyValue(item.title || item.type);
    }
  };
  const getVal = () => { return myValue; };
  const setKey = (newId) => { setMyKey(newId) };
  const getKey = () => { return myKey };
  const getType = () => { return 'CxToolbarPopper' };

  const clear = () => {
    if (!props.readOnly && !props.disabled) {
      setMyKey('');
      setMyValue('');
      setChanged(true);
    }
  };

  const isEmpty = () => { return myValue.length === 0 };

  const setTooltipText = (text) => { rf_tooltip.current.setTooltipText(text) };

  const setSelected = async (item) => {
    let id = item.split('|')[0];
    let tx = item.split('|')[1];
    setMyKey(id);
    setMyValue(tx);
    setAnchorEl(null);
    setChanged(true);
    if (props.onSelection !== undefined) {
      props.onSelection(item);
    }
  }

  const doClick = (e) => {
    if (onCustomClick) {
      onCustomClick();
    } else {
      setAnchorEl(anchorEl ? null : rf_control.current);
    }
  };

  const open = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {visible &&
          <>
            <CxTooltip xref={rf_tooltip} text={rest.tooltip ? rest.tooltip : null} tooltipType={rest.tooltipType}>
              <button ref={rf_control} className={classesL.ctl_button} onClick={doClick}>
                {myValue}
              </button>
            </CxTooltip>
          </>
        }
        {!onCustomClick && <Popper
          open={open}
          anchorEl={anchorEl}
          className={classesL.ctl_popper}
          modifiers={{
            offset: {
              enabled: true,
              offset: '1, 0'
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
          }}
        >
          {rest.list && rest.list.map(item => {
            return <button key={item.id} value={`${item.id}|${item.title || item.type}`} className={classesL.ctl_option} onClick={(e) => setSelected(e.target.value)} >
              {(item.title || item.type)}
            </button>
          })}
        </Popper>}
      </div>
    </ClickAwayListener>
  );
};

export default CxToolbarPopper; 
