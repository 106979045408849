import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import { useNav } from '../hx/useNav';
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import * as FxFile from '../fx/FxFile';
import * as FxPrint from '../fx/FxPrint';
import CxDlgAlert from '../cx/CxDlgAlert';
import { clearStore, StoreFile, } from "./GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import * as FxInet from '../fx/FxInet';


const GxPrintLong = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);

  const r_dlg_alert = React.useRef(null);

  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  const [urlBuild, setUrlBuild] = React.useState('');
  const [urlGet, setUrlGet] = React.useState('');
  const [filter, setFilter] = React.useState({});
  const [record, setRecord] = React.useState({});
  const [scale, setScale] = React.useState(1.5);
  const [sign, setSign] = React.useState('');
  const [preview, setPreview] = React.useState(true);
  const [certificate, setCertificate] = React.useState({});
  const [canSend, setCanSend] = React.useState(true);

  const [inClient, setInClient] = React.useState('');
  const [inAnimal, setInAnimal] = React.useState('');
  const [inSupplier, setInSupplier] = React.useState('');
  const [inEmployee, setInEmployee] = React.useState('');
  const [inLab, setInLab] = React.useState('');


  React.useEffect(() => {
    clearStore('StoreFile', storeFile);

    return () => {
      runTimer(false);
    }
    // eslint-disable-next-line
  }, []);

  const runTimer = (boStart) => {
    if (boStart) {
      //starting up timer...
      timer.current = setInterval(() => {
        if (FxInet.isOnline()) {
          doReportCheck();
        }
      }, 1 * SEC);

    } else {
      if (timer.current) {
        //cleaning up timer...
        clearInterval(timer.current);
      }
    }
  }

  React.useImperativeHandle(props.xref, () => {
    return {
      doPrint, setUrlBuild, setUrlGet, setFilter, setRecord, setInClient, setInAnimal, setInSupplier, setInEmployee, setInLab, setScale, setSign,
      setPreview, setCertificate, setCanSend,
    };
  });

  const doPrint = () => {
    doReportBuild();
  };

  const doReportCheck = () => {
    try {
      let record = {};
      record['txTaskName'] = 'report';
      Promise.all([FxFetch.doJsonX('long_task/get_progress', record)]).then((result) => {
        if (result[0]) {
          let page = result[0].nuTaskItemCurrent;
          switch (result[0].txTaskMessage) {
            case trans('msg.building_page'):
              if (result[0].nuTaskItemCurrent === '0') {
                r_dlg_alert.current.setProgressMsg(trans('msg.report_preparing'));
              } else {
                r_dlg_alert.current.setProgressMsg(trans('msg.building_page') + ' ' + page);
              }
              break;

            case trans('msg.operation_finished'):
              r_dlg_alert.current.setProgressMsg(trans('msg.building_page') + ' ' + page);
              runTimer(false);
              doReportGet();
              break;

            default:
              r_dlg_alert.current.setProgressMsg('');
              break;
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxPrintLong.doReportCheck", error);
    }
  }

  const doReportBuild = () => {
    try {
      filter['reportFormat'] = `PDF`;
      filter['inClient'] = inClient;
      filter['inAnimal'] = inAnimal;
      filter['inSupplier'] = inSupplier;
      filter['inEmployee'] = inEmployee;
      filter['inLab'] = inLab;
      filter['certificate_base64KeyStore'] = certificate.base64KeyStore;
      filter['certificate_pin'] = certificate.pin;

      r_dlg_alert.current.clear();
      r_dlg_alert.current.setOpen(true);
      runTimer(true);
      Promise.all([FxFetch.doJsonF(urlBuild, filter, record)]).then((result) => {
        if (result[0]) {
        } else {
          // 2024-10-22 - daq: Hubo algún error en FxFetch, por ejemplo Timeout
          r_dlg_alert.current.setOpen(false);
        }

      });

    } catch (error) {
      FxLog.errorx("GxPrintLong.doReportBuild", error);
    }
  }

  const doReportGet = () => {
    try {
      let filter = {};
      Promise.all([FxFetch.doJsonF(urlGet, filter, null)]).then((result) => {
        if (result[0] === null) {
        } else {
          if (result[0].txStatus.startsWith("OK")) {
            let txFileFormat = result[0].txFileFormat;
            let txFileNameInput = result[0].txFileNameInput;
            //let txEmailResult = result[0].txEmailResult;
            let obPDF = result[0].obPDF;
            let obCSV = result[0].obCSV;

            // IMPRESIÓN -----------------------------------------------
            clearStore('StoreFile', storeFile);
            Promise.all([FxPrint.getClientInfo(inClient), FxPrint.getSupplierInfo(inSupplier), FxPrint.getLabInfo(inLab)]).then((info) => {
              let clientInfo = info[0];
              let supplierInfo = info[1];
              let labInfo = info[2];
              storeFile.url = urlBuild;
              storeFile.filter = filter;
              storeFile.addressee = clientInfo || supplierInfo || labInfo;
              storeFile.txFileFormat = txFileFormat;
              storeFile.txFileNameInput = txFileNameInput;
              storeFile.obPDF = 'data:application/pdf;base64,' + obPDF;
              storeFile.obCSV = obCSV;
              storeFile._scale = scale;
              storeFile._sign = sign;
              storeFile._send = canSend;

              switch (result[0].txStatus) {
                case 'OK_but_missing_template':
                  r_dlg_alert.current.setText(trans('msg.missing_x', 'field.template'));
                  r_dlg_alert.current.setHasButtonClose(true);
                  break;

                default:
                  r_dlg_alert.current.setOpen(false);
                  if (preview) {
                    Promise.all([setStoreFile(storeFile)]).then((result) => {
                      navTo("file-viewer");
                    });
                  } else {
                    FxFile.printPDF(storeFile.obPDF);
                  }
                  break;
              }
            });
          } else { // ERROR
            switch (result[0].txStatus) {
              case 'rv_empty':
                r_dlg_alert.current.setText(trans('msg.no_records'));
                r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
                r_dlg_alert.current.setHasButtonClose(true);
                break;
              case 'rv_invalid_certificate_or_pin':
                r_dlg_alert.current.setText(trans('msg.invalid_certificate_or_pin'));
                r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
                r_dlg_alert.current.setHasButtonClose(true);
                break;

              default:
                r_dlg_alert.current.setOpen(false);
                break;
            }
          }
        }
        runTimer(false);
      });

    } catch (error) {
      FxLog.errorx("MxStats.doReportGet", error);
    }
  }

  return (
    <div>

      <CxDlgAlert xref={r_dlg_alert} />

    </div>
  );
}

export default GxPrintLong;
