import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettPaymentDet } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsPaymDetList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  let rf_txTypePaymentDet = React.useRef(null);

  const [storeSettPaymentDet, setStoreSettPaymentDet] = useSessionStorage('StoreSettPaymentDet', StoreSettPaymentDet);

  React.useEffect(() => {
    clearStore('StoreSettPaymentDet', storeSettPaymentDet);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPaymentDet.itPaymentDet = null;
    } else {
      storeSettPaymentDet.itPaymentDet = row.original.itPaymentDet;
    }
    setStoreSettPaymentDet(storeSettPaymentDet);
    navTo("sett-sls-payment-det-form");
  }

  return (
    <div>
      <GxList
        xref={rf_txTypePaymentDet}
        moduleName={`${trans('field.types')} - ${trans('field.payments')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/pay_det"
        filter={`t_payment_det.boActive='${'1'}'`}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itPaymentDet", show: false },
          { id: "1", Header: trans('field.detail'), accessor: row => row["txTypePaymentDet"], width: '50%' },
          { id: "2", Header: trans('field.payment'), accessor: row => row["txTypePayment"], width: '50%' },
        ]}
      />

    </div>

  );

}

export default MxSettSlsPaymDetList;
