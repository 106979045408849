import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
//import * as FxTable from '../../fx/FxTable';
import GxForm from '../../gx/GxForm';
import { StoreSlsDoc, StoreSlsLst } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButton from '../../cx/CxIconButton';
import CxStatic from '../../cx/CxStatic';


const MxSlsDocFormPatientsFqPs = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  // eslint-disable-next-line no-unused-vars
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  let r_form = React.useRef(null);

  //let arItemsFq = getItemsFq(); 
  let r_arControls = React.useRef([]);

  const r_snack_required = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const getItemsFq = () => {
    let rv = [];
    try {
      let idx = 0;
      for (let index = 0; index < storeSlsDoc.arItems.length; index++) {
        const item = storeSlsDoc.arItems[index];
        if (item.nuFrequency>0 && item.nuQuantity>0) {
          if (item.nuQuantity>1) {
            for (let i = 0; i < item.nuQuantity; i++) {
              rv.push({ idx: idx++, inPs: item.inPs, txPs: item.txPs, });
            }
          } else {
            rv.push({ idx: idx++, inPs: item.inPs, txPs: item.txPs, });
          }
        }
      }
      FxLog.infox('MxSlsDocFormPatientsFqPs.getItemsFq', '...rv:', rv);

    } catch (error) {
      FxLog.errorx("MxSlsDocFormPatientsFqPs.getItemsFq", error);
    }
    return rv;
  }

  const isRequired = () => {
    let rv = false;
    try{
      for (let index = r_arControls.current.length-1; index >= 0; index--) {
        const ctl = r_arControls.current[index];
        if (ctl && ctl.getIsRequired()) {
          let label = ctl.getLabel();
          r_snack_required.current.setVal(trans('field.required') + ": " + label);
          r_snack_required.current.setOpen(true);
          rv = true;
        }
      }
      
    } catch(error) {
      FxLog.errorx('MxSlsDocFormPatientsFqPs.isRequired', error)
    }
    return rv;
  };

  const formSaveCustom = () => {
    try{
      if (!isRequired()) {
        let items = storeSlsDoc.arItemsFq || [];
        for (let index = 0; index < r_arControls.current.length; index++) {
          const ctl = r_arControls.current[index];
          if (ctl) {
            let item = {
              idx: 0,
              inPs: ctl.getMyCustom1(),
              txPs: ctl.getMyCustom2(),
              inAnimal: ctl.getRow().original.inAnimal,
              txAnimal: ctl.getVal(),
            };
            items.unshift(item);
            // reorder
            items.forEach(function (item, index) {
              item.idx = index;
            })
    
            storeSlsDoc.arItemsFq = items;
            setStoreSlsDoc(storeSlsDoc);
          }
        }
        navBack();
      }
      
    } catch(error) {
      FxLog.errorx('MxSlsDocFormPatientsFqPs.formSaveCustom', error)
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.sales')}: ${trans('field.patient')}/${trans('field.ps')}`}
        xref={r_form}
        toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxStatic label={`${trans('msg.select_x_for_each_x', 'field.patient', 'field.ps')}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />
        
        {getItemsFq().map((item, i) =>

          <CxSearcher 
            key={item.idx}
            itemIndex={item.idx}
            custom1={item.inPs}
            custom2={item.txPs}
            xref={(element) => r_arControls.current[item.idx] = element}
            label={`${item.txPs.substring(0,20)}`}
            url="animals"
            filter={`n_animal.inClient='${storeSlsDoc.inClient}'`}
            col_id="inAnimal"
            col_tx="txAnimal"
            colorRefs={['table_cell_gray|' + trans('field.inactive')]}
            columns={[
              { id: "0", Header: "ID", accessor: "inAnimal", show: false },
              { id: "1", Header: trans('field.patient'), accessor: row => r_arControls.current[item.idx].getTable().highlightText(row["txAnimal"]), width: '100%' },
              { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
            ]}
            required
          />

        )}

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSlsDocFormPatientsFqPs;
