import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxButtonShowMore from '../../cx/CxButtonShowMore';
import CxNumber from '../../cx/CxNumber';
import CxState from '../../cx/CxState';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValue, getStoreValueBo, StoreClient, StoreCns } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import { BiPrinter } from "react-icons/bi";
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';


const MxAnimalCnsGrlForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  let rf_date = React.useRef(null);
  let rf_time = React.useRef(null);
  let rf_txBranch = React.useRef(null);
  let rf_txEmployee = React.useRef(null);
  let rf_itStatusCns = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusCns.current.setKeyAndVal("t_status_cns", "txTypeStatusCns", "itStatusCns", "000001.SRV");
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
      if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
        rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
        rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    storeCns.inCns = lastSaveRv.theValue;
    Promise.all([setStoreCns(storeCns)]).then((result) => {
      r_sls_aux_table.current.setStrRelatedId(storeCns.inCns);
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let filter = {};
    filter['inCns'] = storeCns.inCns;
    let record = {};
    let inClient = storeClient.inClient;
    if (option === 'cns') {
      r_print.current.setUrl('cnss/report/grl');
    };
    if (option === 'ins') {
      r_print.current.setUrl('cnss/report/instructions');
    };
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}: ${trans('field.cns')}-${trans('field.cns_grl')}`}
        xref={r_form}
        url="cnss"
        filterIdField='inCns'
        filterIdValue={storeCns.inCns}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <CxIconButtonMenu onSelection={doPrintX} className="ibs_iconbutton_toolbar" icon={<BiPrinter />}
            options={[
              { id: 'cns', text: trans('field.print_x', 'field.cns'), },
              { id: 'ins', text: trans('field.print_x', 'field.instructions'), },
            ]}
          />
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_cns.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.inAnimal" value={storeClient.inAnimal || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.itStatusCns" value={'000001.SRV'} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.txType" value={'G'} />

        <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_cns.dtFrom`} required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield={`n_cns.dtFrom`} required />

        <CxChooser xref={rf_itStatusCns} label={trans('field.status')}
          dbfield="t_status_cns.txTypeStatusCns" dbfieldkey={`n_cns.itStatusCns`}
          table="t_status_cns"
          col_id="itStatusCns"
          col_tx="txTypeStatusCns"
          required
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_cns.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_cns.txRemarks" maxLength={255} />

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_cns.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxInput xref={useRef(null)} label={trans('field.soap_part_s')} dbfield="n_cns_grl.txSoapS" minRows={4} speech />
        <CxInput xref={useRef(null)} label={trans('field.soap_part_o')} dbfield="n_cns_grl.txSoapO" minRows={4} speech />

        <CxButtonShowMore label={`[${trans('field.show_more').toUpperCase()}]`} style={{ margin: '4px 0 4px 0px' }} >

          <CxNumber xref={React.useRef(null)} label={trans('field.soap_gen_temperature')} dbfield="n_cns_grl.nuTemp" decimals={2} decimalsfixed="true" />
          <CxNumber xref={React.useRef(null)} label={trans('field.soap_gen_pulse_rate')} dbfield="n_cns_grl.nuPuls" decimals={0} />
          <CxNumber xref={React.useRef(null)} label={trans('field.soap_gen_respiratory_rate')} dbfield="n_cns_grl.nuResp" decimals={0} />

          <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", gap: '10px', width: "100%"  }} >
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_integument')} dbfield="n_cns_grl.nuIntegument" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_eyes')} dbfield="n_cns_grl.nuEyes" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_ears')} dbfield="n_cns_grl.nuEars" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_musculoskeletal')} dbfield="n_cns_grl.nuMusculoskeletal" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_cardiovascular')} dbfield="n_cns_grl.nuCardiovascular" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_respiratory')} dbfield="n_cns_grl.nuRespiratory" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_gastrointestinal')} dbfield="n_cns_grl.nuGastrointestinal" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_urinary')} dbfield="n_cns_grl.nuUrinary" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_reproductive')} dbfield="n_cns_grl.nuReproductive" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_endocrine')} dbfield="n_cns_grl.nuEndocrine" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_nervous')} dbfield="n_cns_grl.nuNervous" />
            <CxState mode='checkbox' xref={useRef(null)} label={trans('field.soap_gen_lymphatic')} dbfield="n_cns_grl.nuLymphatic" />
          </div>

        </CxButtonShowMore>

        <CxInput xref={useRef(null)} label={trans('field.soap_part_a')} dbfield="n_cns_grl.txSoapA" minRows={4} speech />
        <CxInput xref={useRef(null)} label={trans('field.soap_part_p')} dbfield="n_cns_grl.txSoapP" minRows={4} speech />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_cns.txNotes" minRows={4} />
        <CxInput xref={useRef(null)} label={trans('field.instructions')} dbfield="n_cns.txInstructions" minRows={4} />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} strRelatedField={'inCns'} strRelatedId={storeCns.inCns} rf_txEmployee={rf_txEmployee} />

      <GxPrint xref={r_print} />

    </div>
  );

}

export default MxAnimalCnsGrlForm;
