import React from 'react';
import GxPrint from '../../gx/GxPrint';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxSearcher from '../../cx/CxSearcher';
import CxAccordion from '../../cx/CxAccordion';
import CxIconButton from '../../cx/CxIconButton';
import CxToolbar from '../../cx/CxToolbar';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
import CxCombo from '../../cx/CxCombo';
import CxRadio from '../../cx/CxRadio';
import CxDlgAlert from '../../cx/CxDlgAlert';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
}));

const MxPsListPrintLabelFilter = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);

  let r_print = React.useRef(null);

  const rf_txPs = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);

  const rf_nuRepeat = React.useRef(null);
  const rf_nuPosition = React.useRef(null);
  const rf_txPsLabel = React.useRef(null);
  const rf_txTemplateFile = React.useRef(null);

  const [templateList, setTemplateList] = React.useState([]);

  const r_dlg_alert = React.useRef(null);
  const r_snack_required = React.useRef(null);


  React.useEffect(() => {
    Promise.all([FxFetch.doJsonX('pss/create_price_labels/get_templates', null)]).then((result) => {
      if (result[0]) {
        Promise.all([setTemplateList(result[0])]).then((result) => {
          rf_txTemplateFile.current.setSelectFirst();
        });
      }
    });

    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const doPrintX = async (option) => {
    // chequea requeridos
    if (rf_nuRepeat.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuRepeat.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    if (rf_nuPosition.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuPosition.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['templateFile'] = rf_txTemplateFile.current.getVal();
    filter['txPs'] = rf_txPs.current.getVal();
    filter['itPsGroup'] = rf_txTypePsGroup.current.getVal();
    filter['itPsSubgroup'] = rf_txTypePsSubgroup.current.getVal();
    let record = {};
    record['nuRepeat'] = rf_nuRepeat.current.getVal();
    record['nuPosition'] = rf_nuPosition.current.getVal();
    record['txPsLabel'] = rf_txPsLabel.current.getVal();
    r_print.current.setScale(1.5);
    r_print.current.setUrl('pss/create_price_labels');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const groupCleared = () => {
    rf_txTypePsSubgroup.current.clear();
    rf_txTypePsSubgroup.current.setFilter(`undefined='${undefined}'`);
  }
  const groupSelected = (row) => {
    rf_txTypePsSubgroup.current.clear();
  }
  const subgroupOpen = () => {
    let itPsGroup = rf_txTypePsGroup.current.getKey();
    rf_txTypePsSubgroup.current.setFilter(`t_ps_subgroup.itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.pss')}: ${trans('field.price_labels')}`}
        addToolbarButtons={
          <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={doPrintX} />
        }
      />

      <div className={classes.container}>

        <CxAccordion defaultExpanded title={trans('field.filter')}>

          <CxInput xref={rf_txPs} label={trans('field.ps')} />

          <CxSearcher
            xref={rf_txTypePsGroup}
            label={trans('field.group')}
            dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_ps.itPsGroup'
            onClear={groupCleared}
            onSelection={groupSelected}
            url="sett/ps_group"
            col_id="itPsGroup"
            col_tx="txTypePsGroup"
            columns={[
              { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
            ]}
          />

          <CxSearcher
            xref={rf_txTypePsSubgroup}
            label={trans('field.subgroup')}
            dbfield='t_ps_subgroup.txTypePsSubgroup' dbfieldkey='n_ps.itPsSubgroup'
            canOpenDialog={subgroupOpen}
            url="sett/ps_subgroup"
            col_id="itPsSubgroup"
            col_tx="txTypePsSubgroup"
            columns={[
              { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
            ]}
          />

        </CxAccordion>

        <CxAccordion defaultExpanded title={trans('field.format')}>

          <CxNumber xref={rf_nuRepeat}
            label={`${trans('field.repeat')}`}
            decimals={0}
            defaultValue={'1'}
            required
          />

          <CxNumber xref={rf_nuPosition}
            label={`${trans('field.position')}`}
            decimals={0}
            defaultValue={'1'}
            required
          />

          <CxCombo xref={rf_txPsLabel}
            label={`${trans('field.use_x', 'field.name')}`}
            list={[
              { id: 'n_ps.txPs', type: trans('field.name') },
              { id: 'n_ps.txPsShort', type: trans('field.name_short') },
            ]}
            defaultValue={'n_ps.txPs'}
            clearDisabled
            required
          />

        </CxAccordion>

        <CxAccordion defaultExpanded title={trans('field.template')}>

          <CxRadio xref={rf_txTemplateFile}
            list={templateList}
            orientation="row"
            itemWidth="var(--ibs_control_width_xs)"
          />

        </CxAccordion>

      </div>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxPsListPrintLabelFilter;
