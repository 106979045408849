import React from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxTable from '../../cx/CxTable';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxDat from '../../fx/FxDat';
import { StoreAnalytics } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import { useWindowSize } from '../../hx/useWindowSize';


const MxAnalyticsForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.plans'));
  const size = useWindowSize();

  const r_snack_ask_save = React.useRef(null);
  const r_snack_incorrect_dates = React.useRef(null);

  const r_form = React.useRef(null);

  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txModel = React.useRef(null);
  const rf_txDescription = React.useRef(null);

  const rf_table_sls = React.useRef(null);
  const rf_table_pur = React.useRef(null);
  const rf_table_kpi = React.useRef(null);

  const [storeAnalytics, setStoreAnalytics] = useSessionStorage('StoreAnalytics', StoreAnalytics);
 
  // No puedo usar el storeAnalytics.inAnalytics porque al cambiarlo, react no lo vé como un cambio ya que lo que mantiene es la referencia al objeto storeAnalytics.
  const [currentID, setCurrentID] = React.useState(storeAnalytics.inAnalytics);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_txModel.current.setVal(trans('field.regular'));
      rf_txDescription.current.setVal(trans('field.activity_regular'));
    }
  }
  const formSave = async (lastSaveRv) => {
    storeAnalytics.inAnalytics = lastSaveRv.theValue;
    setCurrentID(lastSaveRv.theValue);
    Promise.all([setStoreAnalytics(storeAnalytics)]).then((result) => {
      rf_table_sls.current.redisplay();
      rf_table_pur.current.redisplay();
      rf_table_kpi.current.redisplay();
    });
  }
  const formDelete = async (rv) => {
  }
  
  const checkDatesOrder = () => {
    if (FxDat.isInOrder(rf_dtFrom.current.getVal(), rf_dtTo.current.getVal())) {
      return true;
    } else {
      r_snack_incorrect_dates.current.setOpen(true);
      return false;
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.analytics')}`}
        xref={r_form}
        url="analytics"
        filterIdField='inAnalytics'
        filterIdValue={storeAnalytics.inAnalytics}
        onFormLoad={formLoad}
        onFormBeforeSave={checkDatesOrder}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >

        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_analytics.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_analytics.dtTo"} required />

        <CxInput xref={rf_txModel} label={trans('field.model')} dbfield="n_analytics.txModel" maxLength={50} required />
        <CxInput xref={rf_txDescription} label={trans('field.description')} dbfield="n_analytics.txDescription" maxLength={100} required />

        {currentID && <CxTable
          xref={rf_table_sls}
          url="analytics/activity_sls"
          filter={`n_analytics_item.inAnalytics='${currentID}'`}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inAnalyticsItem", show: false },
            { id: "1", Header: trans('field.sales'), accessor: "txAnalyticsItem", width: '40%' },
            { id: "2", Header: trans('field.quantity'), accessor: row => (FxTable.cellTo2r00(row["nuQuantity"])), width: '20%', align: 'right' },
            { id: "3", Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '20%', align: 'right' },
            { id: "4", Header: trans('field.perc'), accessor: row => (FxTable.cellTo2r00(row["nuPercTot"])), width: '20%', align: 'right', show: size.isLarge },
          ]}
        />}

        {currentID && <CxTable
          xref={rf_table_pur}
          url="analytics/activity_pur"
          filter={`n_analytics_item.inAnalytics='${currentID}'`}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inAnalyticsItem", show: false },
            { id: "1", Header: trans('field.purchases'), accessor: "txAnalyticsItem", width: '40%' },
            { id: "2", Header: trans('field.quantity'), accessor: row => (FxTable.cellTo2r00(row["nuQuantity"])), width: '20%', align: 'right' },
            { id: "3", Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '20%', align: 'right' },
            { id: "4", Header: trans('field.perc'), accessor: row => (FxTable.cellTo2r00(row["nuPercTot"])), width: '20%', align: 'right', show: size.isLarge },
          ]}
        />}

        {currentID && <CxTable
          xref={rf_table_kpi}
          url="analytics/activity_kpi"
          filter={`n_analytics_kpi.inAnalytics='${currentID}'`}
          hasButtonAdd={false}
          columns={[
            { id: "0", Header: "ID", accessor: "inAnalyticsKpi", show: false },
            { id: "1", Header: trans('field.kpi'), accessor: "txKpiName", width: '40%' },
            { id: "2", Header: trans('field.period'), accessor: "txPeriod", width: '20%', show: size.isLarge },
            { id: "3", Header: trans('field.type'), accessor: "theType", width: '20%', show: size.isLarge },
            { id: "4", Header: trans('field.value'), accessor: row => (FxTable.cellTo2r00(row["nuValue"])), width: '20%', align: 'right' },
          ]}
        />}

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_incorrect_dates} severity="error" value={trans('msg.incorrect_dates')} />

    </div>
  );

}

export default MxAnalyticsForm;
