import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettComm } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettCommList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettComm, setStoreSettComm] = useSessionStorage('StoreSettComm', StoreSettComm);

  React.useEffect(() => {
    clearStore('StoreSettComm', storeSettComm);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettComm.itComm = null;
    } else {
      storeSettComm.itComm = row.original.itComm;
    }
    setStoreSettComm(storeSettComm);
    navTo("sett-comm-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.comms')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/comm"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itComm", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.comm')}`, accessor: "txTypeComm", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettCommList;
