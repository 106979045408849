import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxSearcher from '../../cx/CxSearcher';
import CxState from '../../cx/CxState';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { StorePsComponent } from "../../gx/GxStore";


const MxPsComponentForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storePsComponent, setStorePsComponent] = useSessionStorage('StorePsComponent', StorePsComponent);

  let r_form = React.useRef(null);

  const rf_txPs = React.useRef(null);
  const rf_nuQuantity = React.useRef(null);
  const rf_boProportional = React.useRef(null);
  
  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      clearValues();
    }
  }
  const formSave = async (lastSaveRv) => {
    navBack();
  }
  const formDelete = async (rv) => {
  }

  const clearValues = () => {
    rf_nuQuantity.current.setVal('1');
    rf_boProportional.current.setVal('1');
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.ps')}: ${trans('field.component')}`}
        xref={r_form}
        url="pss/components" 
        filterIdField='irPsPs' 
        filterIdValue={storePsComponent.irPsPs} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="inPs" value={storePsComponent.inPs || ''} />

        <CxSearcher xref={rf_txPs} label={trans('field.ps')}
          id='component' //para que la pag no sobreescriba la del listado general de pss
          dbfield={'txPsAttached'} dbfieldkey={'inPsAttached'}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          tooltip={`${trans('field.ps')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '75%' },
            { id: "2", Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '25%', align: 'right' },
          ]}
          required
        />

        <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} dbfield='nuQuantity' decimals={4} required />

        <CxState mode='checkbox' xref={rf_boProportional} label={trans('field.proportional')} dbfield="boProportional" />
        
      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      
    </div>
  );

}

export default MxPsComponentForm;
