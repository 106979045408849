import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import CxTable from '../../cx/CxTable';
import * as FxTable from '../../fx/FxTable';
import DocType from '@material-ui/icons/Receipt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreSlsLst, StoreSlsDoc, getStoreValue, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxToolbar from '../../cx/CxToolbar';
import { makeStyles } from '@material-ui/core/styles';
import * as FxFetch from '../../fx/FxFetch';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxPrint from '../../gx/GxPrint';

const useStyles = makeStyles({
  toggleButtonGroup: {
    background: 'var(--ibs_color_gray_light)',
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'var(--ibs_color_white)',
    },
    '& .MuiToggleButton-root': {
      padding: '0px 10px 0px 10px',
    },
  },
});

const MxSlsDocListCP = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  // muestra según permisos
  const [showQuotations, setShowQuotations] = React.useState(false);
  const [showInvoices, setShowInvoices] = React.useState(false);
  const [showReceipts, setShowReceipts] = React.useState(false);

  const rf_table = React.useRef(null);

  const r_dlg_alert = React.useRef(null);
  let r_print = React.useRef(null);

  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc,] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  const [docTitle, setDocTitle] = React.useState(trans('field.invoices'));

  React.useEffect(() => {
    changeDocType(null, storeSlsLst.listType || 'i');

    // acceso
    let record = {};
    //record['op'] = op;
    Promise.all([FxFetch.doJsonX('cp/animals/access', record)]).then((result) => {
      if (result[0]) {
        setShowQuotations(result[0][0].boQuotations === '1');
        setShowInvoices(result[0][0].boInvoices === '1');
        setShowReceipts(result[0][0].boReceipts === '1');
      }
    });

    // eslint-disable-next-line
  }, []);

  const optionsSelected = (options) => {
    localStorage.setItem('MxSlsDocListCP.addcol_txBranchId', options.includes('txBranchId') ? '1' : '0');
    localStorage.setItem('MxSlsDocListCP.addcol_txTypeSeries', options.includes('txTypeSeries') ? '1' : '0');
    forceUIUpdate();
  }

  const changeDocType = (event, newType) => {
    clearStore('StoreSlsDoc', storeSlsDoc);
    if (newType === null) {
      return;
    }
    let hasTypeChanged = false;
    if (newType !== storeSlsLst.listType) {
      hasTypeChanged = true;
    }
    switch (newType) {
      case 'q':
        storeSlsLst.listType = 'q';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_yellow_light)';
        storeSlsLst.table = 'n_quotation';
        storeSlsLst.table_idField = 'inQuotation';
        storeSlsLst.table_txField = 'txQuotation';
        storeSlsLst.table_dtField = 'dtQuotation';
        storeSlsLst.table_item = 'n_quotation_item';
        storeSlsLst.table_item_idField = 'inQuotationItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        setStoreSlsLst(storeSlsLst);
        setDocTitle(trans('field.quotations'));
        if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/q');
        break;
      case 'i':
        storeSlsLst.listType = 'i';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storeSlsLst.table = 'n_invoice';
        storeSlsLst.table_idField = 'inInvoice';
        storeSlsLst.table_txField = 'txInvoice';
        storeSlsLst.table_dtField = 'dtInvoice';
        storeSlsLst.table_item = 'n_invoice_item';
        storeSlsLst.table_item_idField = 'inInvoiceItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        setStoreSlsLst(storeSlsLst);
        setDocTitle(trans('field.invoices'));
        if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/i');
        break;
      case 'r':
        storeSlsLst.listType = 'r';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_green_light)';
        storeSlsLst.table = 'n_receipt';
        storeSlsLst.table_idField = 'inReceipt';
        storeSlsLst.table_txField = 'txReceipt';
        storeSlsLst.table_dtField = 'dtReceipt';
        storeSlsLst.table_item = 'n_invoice_item';
        storeSlsLst.table_item_idField = 'inInvoiceItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        setStoreSlsLst(storeSlsLst);
        setDocTitle(trans('field.receipts'));
        if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/r');
        break;

      default:
        FxLog.errorx("MxSlsDocListCP.openForm", "Cannot find doctype!!! -> " + newType);
        break;
    }
  };

  const doPrintX = async (op, row) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = row.original.inClient;
    filter['inXXX'] = row.original[storeSlsLst.table_idField];
    filter['docType'] = storeSlsLst.listType;
    filter['docSize'] = 'A4';
    let record = {};
    //let inClient = storeClient.inClient;
    r_print.current.setScale(1);
    r_print.current.setUrl('slsDoc/report');
    r_print.current.setCanSend(false);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      //r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const getColorRefs = () => {
    let rv;
    try {
      switch (storeSlsLst.listType) {
        case 'q':
          rv = ['table_cell_green|' + trans('field.accepted')];
          break;

        case 'i':
          rv = ['table_cell_red|' + trans('field.debtor'), 'table_cell_blue|' + trans('field.voided'), 'table_cell_green|' + trans('field.receipted')];
          break;

        case 'r':
          rv = ['table_cell_red|' + trans('field.debtor'), 'table_cell_blue|' + trans('field.voided')];
          break;

        default: break;
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocListCP.getColorRefs", error);
    }
    return rv;
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: storeSlsLst.table_idField, show: false });
    cols.push({ id: 'xx1', Header: "_itSeries", accessor: "itSeries", show: false }); // 2023-05-13 - daq: ATENCIÓN: con id numérico no la oculta.
    cols.push({ id: 'xx2', Header: "_inClient", accessor: "inClient", show: false }); // 2023-05-13 - daq: ATENCIÓN: con id numérico no la oculta.
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: "xx3", Header: trans('field.branch_id'), accessor: row => row["txBranchId"], width: '10%', show: Boolean(localStorage.getItem('MxSlsDocListCP.addcol_txBranchId') === '1') });
      cols.push({ id: "xx4", Header: trans('field.series'), accessor: row => row["txTypeSeries"], width: '10%', show: Boolean(localStorage.getItem('MxSlsDocListCP.addcol_txTypeSeries') === '1') });
      cols.push({ id: id++, Header: trans('field.doc'), accessor: row => row[storeSlsLst.table_txField], width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row[storeSlsLst.table_dtField])), width: '10%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => row["txClient"], width: (storeSlsLst.listType !== 'q' ? '30%' : '40%') });
      cols.push({ id: id++, Header: trans('field.taxable'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTaxable"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.discount'), accessor: row => (FxTable.cellTo2r00(row["nuTotalDiscount"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.tax'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTax"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.wtax_perc'), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtaxPerc"])), show: false });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.paid'), accessor: row => (FxTable.cellTo2r00(row["nuTotalPayment"])), width: '10%', align: 'right', show: (storeSlsLst.listType !== 'q') });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row[storeSlsLst.table_dtField])), width: '30%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => row["txClient"], width: '50%' });
      cols.push({ id: id++, Header: trans('field.wtax_perc'), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtaxPerc"])), show: false });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '20%', align: 'right' });
    }

    if (storeSlsLst.listType === 'q') {
      cols.push({ id: 'G', Header: "boAccepted", accessor: row => Boolean(row["boAccepted"] === '1'), show: false });
    }
    if (storeSlsLst.listType === 'i') {
      cols.push({ id: 'G', Header: "hasReceipt", accessor: row => Boolean(row["_inReceipt"] !== ''), show: false });
    }
    if (storeSlsLst.listType === 'i' || storeSlsLst.listType === 'r') {
      cols.push({ id: 'R', Header: "balance", accessor: row => Boolean((row["nuTotalPayment"] - row["nuTotalFinal"]) < 0 ? true : false), show: false });
    }
    if (storeSlsLst.listType === 'i' || storeSlsLst.listType === 'r') {
      cols.push({ id: 'B', Header: "isVoid", accessor: row => Boolean(row["_isVoid"]), show: false });
    }
    return cols;
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.my_x', 'field.purchases')}: ${docTitle}`}
        background={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <ToggleButtonGroup value={storeSlsLst.listType} exclusive onChange={changeDocType} className={classes.toggleButtonGroup} >
            {showQuotations && <ToggleButton value="q">
              <DocType style={{ color: 'var(--ibs_color_toggle_yellow)' }} />
            </ToggleButton>}
            {showInvoices && <ToggleButton value="i">
              <DocType style={{ color: 'var(--ibs_color_toggle_blue)' }} />
            </ToggleButton>}
            {showReceipts && <ToggleButton value="r">
              <DocType style={{ color: 'var(--ibs_color_toggle_green)' }} />
            </ToggleButton>}
          </ToggleButtonGroup>
        }
      />

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxTable
          xref={rf_table}
          url={`cp/sls/doclist/${storeSlsLst.listType || 'i'}`}
          filter={`inClient='${getStoreValue('StoreSession', 'inClientCP')}'`}
          fullscreen
          hasSearchBox={false}
          hasButtonAdd={false}
          colorRefs={getColorRefs()}
          onOpenForm={doPrintX}
          columns={getTableColumns()}
          optionalColumns={[
            { id: 'txBranchId', name: trans('field.branch_id'), checked: localStorage.getItem('MxSlsDocListCP.addcol_txBranchId') },
            { id: 'txTypeSeries', name: trans('field.series'), checked: localStorage.getItem('MxSlsDocListCP.addcol_txTypeSeries') },
          ]}
          onOptions={optionsSelected}
        >
        </CxTable>
      </div>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

    </div>
  );

}

export default MxSlsDocListCP;
