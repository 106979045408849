import { GrMoreVertical } from "react-icons/gr"; 
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import CxTooltip from './CxTooltip';


const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: props => { return props.width },
    borderRadius: '5px',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: '14px',
    color: 'var(--ibs_color_black)',
  },
  root: {
    fontSize: '14px',
    background: 'transparent',
  }
}));


const ITEM_HEIGHT = 48;

export default function CxIconButtonMenu(props) {
  const classes = useStyles(props);
  const { onSelection, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [icon, ] = React.useState(rest.icon);

  const doClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const doSelection = (event) => {
    setAnchorEl(null); // handle close
    const { myValue } = event.currentTarget.dataset;
    if (onSelection && myValue) {
      onSelection(myValue);
    }
  };

  return (
    <div>
      <CxTooltip text={rest.tooltip ? rest.tooltip : null}>
        <span>
          <IconButton className={rest.className} onClick={doClick}>
            {!icon && <GrMoreVertical fontSize="24px" color={props.defaultIconColor||'var(--ibs_color_black)'} />}
            {icon}
          </IconButton>
        </span>
      </CxTooltip>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={open}
        onClose={doSelection}
        classes={{ paper: classes.paper }}
        disableScrollLock={true}
      >
        {rest.options.map((option) => (
          <MenuItem
            classes={{ root: classes.root }}
            style={{ color: option.color || 'var(--ibs_color_black)' }}
            key={option.id}
            data-my-value={option.id}
            onClick={doSelection}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
