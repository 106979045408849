import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxAccordion from '../../cx/CxAccordion';
import CxButton from '../../cx/CxButton';
import * as FxLog from '../../fx/FxLog';
import { StoreClient, StoreRxGlas, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButton from '../../cx/CxIconButton';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';


const MxClientRxGlasForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxGlas, setStoreRxGlas] = useSessionStorage('StoreRxGlas', StoreRxGlas);

  const [isRecalc, setRecalc] = React.useState(false);

  const r_dlg_alert = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);

  const control_width_sm = 'var(--ibs_control_width_xs)';
  const control_width_lg = 'var(--ibs_control_width_lg)';


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
    } else {
      calcSphN();
      calcSphI();
      calcOu();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxGlas.inRxGlas = lastSaveRv.theValue;
    Promise.all([setStoreRxGlas(storeRxGlas)]).then((result) => {
      r_sls_aux_table.current.setStrRelatedId(storeRxGlas.inRxGlas);
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  React.useEffect(() => {
    setRecalc(false);
    calcSphN();
    calcSphI();

  }, [isRecalc]);

  const doTranspose = () => {
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');
    let _nuOdCyl = r_form.current.getRef('n_rx_glas.nuOdCyl');
    let _nuOdAxis = r_form.current.getRef('n_rx_glas.nuOdAxis');

    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');
    let _nuOsCyl = r_form.current.getRef('n_rx_glas.nuOsCyl');
    let _nuOsAxis = r_form.current.getRef('n_rx_glas.nuOsAxis');

    //Od
    _nuOdSphD.setFloatVal(transpose('SPH', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdCyl.setFloatVal(transpose('CYL', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdAxis.setFloatVal(transpose('AXIS', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    //Os
    _nuOsSphD.setFloatVal(transpose('SPH', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsCyl.setFloatVal(transpose('CYL', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsAxis.setFloatVal(transpose('AXIS', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));


    //recálculos
    setRecalc(true);

  }

  const transpose = (tr, sph, cyl, axis) => {
    let rv = 0.0;
    try {
      let nuSph = (sph);
      let nuCyl = (cyl);
      let nuAxis = (axis);

      nuCyl = nuCyl * -1;
      nuSph = nuSph - nuCyl;
      if (nuAxis > 90) {
        nuAxis = nuAxis - 90;
      } else {
        nuAxis = nuAxis + 90;
      }
      switch (tr) {
        case 'SPH':
          rv = nuSph;
          break;
        case 'CYL':
          rv = nuCyl;
          break;
        case 'AXIS':
          rv = nuAxis;
          break;
        default:
          rv = '0.0';
          break;
      }

    } catch (error) {
      FxLog.errorx("MxClientRxGlasForm.transpose", error);
    }
    return rv;
  }

  const calcSphN = () => {
    let _nuOdAddNe = r_form.current.getRef('n_rx_glas.nuOdAddNe');
    let _nuOdSphN = r_form.current.getRef('_nuOdSphN');
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');

    let _nuOsAddNe = r_form.current.getRef('n_rx_glas.nuOsAddNe');
    let _nuOsSphN = r_form.current.getRef('_nuOsSphN');
    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');

    if (_nuOdAddNe.getFloatVal() === 0.0) {
      _nuOdSphN.clear();
    } else {
      _nuOdSphN.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddNe.getFloatVal());
    }
    if (_nuOsAddNe.getFloatVal() === 0.0) {
      _nuOsSphN.clear();
    } else {
      _nuOsSphN.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddNe.getFloatVal());
    }
  }

  const calcSphI = () => {
    let _nuOdAddIn = r_form.current.getRef('n_rx_glas.nuOdAddIn');
    let _nuOdSphD = r_form.current.getRef('n_rx_glas.nuOdSph');
    let _nuOdSphI = r_form.current.getRef('_nuOdSphI');

    let _nuOsAddIn = r_form.current.getRef('n_rx_glas.nuOsAddIn');
    let _nuOsSphD = r_form.current.getRef('n_rx_glas.nuOsSph');
    let _nuOsSphI = r_form.current.getRef('_nuOsSphI');

    if (_nuOdAddIn.getFloatVal() === 0.0) {
      _nuOdSphI.clear();
    } else {
      _nuOdSphI.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddIn.getFloatVal());
    }
    if (_nuOsAddIn.getFloatVal() === 0.0) {
      _nuOsSphI.clear();
    } else {
      _nuOsSphI.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddIn.getFloatVal());
    }
  }

  const calcOu = () => {
    let _nuOuPdDi = r_form.current.getRef('_nuOuPdDi');
    let _nuOuPdNe = r_form.current.getRef('_nuOuPdNe');
    let _nuOdPdDi = r_form.current.getRef('n_rx_glas.nuOdPdDi');
    let _nuOsPdDi = r_form.current.getRef('n_rx_glas.nuOsPdDi');
    let _nuOdPdNe = r_form.current.getRef('n_rx_glas.nuOdPdNe');
    let _nuOsPdNe = r_form.current.getRef('n_rx_glas.nuOsPdNe');

    _nuOuPdDi.setFloatVal(_nuOdPdDi.getFloatVal() + _nuOsPdDi.getFloatVal());
    _nuOuPdNe.setFloatVal(_nuOdPdNe.getFloatVal() + _nuOsPdNe.getFloatVal());
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxGlas'] = storeRxGlas.inRxGlas;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_glas/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.client')}: ${trans('field.rx_glas')}`}
        xref={r_form}
        url="rx_glas"
        filterIdField='inRxGlas'
        filterIdValue={storeRxGlas.inRxGlas}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeClient.inClient
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_rx_glas.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_glas.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_glas.dtFrom" required />

        <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_rx_glas.txReason" maxLength={100} required />

        <CxChooser xref={useRef(null)} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_glas.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_glas.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={useRef(null)} label={trans('field.treated_by')} dbfield="n_prof.txProf" dbfieldkey={`n_rx_glas.inProf`}
          id="n_prof"
          table="n_prof"
          col_id="inProf"
          col_tx="txProf"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_rx_glas.txReference" maxLength={50} />
        <br />

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_dist')} dbfield='n_rx_glas.nuOdSph' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_glas.nuOdCyl' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_glas.nuOdAxis' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_near')} dbfield='n_rx_glas.nuOdAddNe' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_near')} dbfield='_nuOdSphN' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_inter')} dbfield='n_rx_glas.nuOdAddIn' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_inter')} dbfield='_nuOdSphI' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_dist')} dbfield='n_rx_glas.nuOsSph' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_glas.nuOsCyl' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_glas.nuOsAxis' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_near')} dbfield='n_rx_glas.nuOsAddNe' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_near')} dbfield='_nuOsSphN' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add_inter')} dbfield='n_rx_glas.nuOsAddIn' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph_inter')} dbfield='_nuOsSphI' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxButton label={trans('field.xx_transpose')} onClick={doTranspose} style={{ width: control_width_lg }} autoselect="true" />
        <br /><br /><br />

        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_vasc')} maxLength={10} dbfield='n_rx_glas.txOdVaSc' hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_vacc')} maxLength={10} dbfield='n_rx_glas.txOdVaCc' hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='n_rx_glas.nuOdPdDi' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='n_rx_glas.nuOdPdNe' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_iop')} dbfield='n_rx_glas.nuOdIop' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_prism')} maxLength={16} dbfield='n_rx_glas.txOdPrism' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_vasc')} maxLength={10} dbfield='n_rx_glas.txOsVaSc' hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_vacc')} maxLength={10} dbfield='n_rx_glas.txOsVaCc' hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='n_rx_glas.nuOsPdDi' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='n_rx_glas.nuOsPdNe' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" onBlur={() => { calcOu(); }} />
          <CxNumber xref={useRef(null)} label={trans('field.xx_iop')} dbfield='n_rx_glas.nuOsIop' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_prism')} maxLength={16} dbfield='n_rx_glas.txOsPrism' hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_both')}>
          <CxInput xref={useRef(null)} label={trans('field.xx_va')} maxLength={10} dbfield='n_rx_glas.txOuVa' hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_dist')} dbfield='_nuOuPdDi' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_pd_near')} dbfield='_nuOuPdNe' disabled decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_sm} autoselect="true" />
        </CxAccordion>

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_glas.txNotes" minRows={4} speech />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} strRelatedField={'inRxGlas'} strRelatedId={storeRxGlas.inRxGlas} /* rf_txEmployee={rf_txEmployee} */ />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

    </div>
  );

}

export default MxClientRxGlasForm;
