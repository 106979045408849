import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxAccordion from '../../cx/CxAccordion';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreClient, StoreRxClen, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButton from '../../cx/CxIconButton';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';


const MxClientRxClenForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxClen, setStoreRxClen] = useSessionStorage('StoreRxClen', StoreRxClen);

  const r_dlg_alert = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);

  const control_width_xs = 'var(--ibs_control_width_xs)';


  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
    } else {
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxClen.inRxClen = lastSaveRv.theValue;
    Promise.all([setStoreRxClen(storeRxClen)]).then((result) => {
      r_sls_aux_table.current.setStrRelatedId(storeRxClen.inRxClen);
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxClen'] = storeRxClen.inRxClen;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_clen/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client')}: ${trans('field.rx_clen')}`}
        xref={r_form}
        url="rx_clen" 
        filterIdField='inRxClen' 
        filterIdValue={storeRxClen.inRxClen} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            
            {/* botón de impresión */}
            {storeClient.inClient
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

          </div>
        }
        >
        <CxHidden xref={useRef(null)} dbfield="n_rx_clen.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_clen.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_clen.dtFrom" required />

        <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_rx_clen.txReason" maxLength={100} required />

        <CxChooser xref={useRef(null)} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_clen.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_clen.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={useRef(null)} label={trans('field.treated_by')} dbfield="n_prof.txProf" dbfieldkey={`n_rx_clen.inProf`}
          id="n_prof"
          table="n_prof"
          col_id="inProf"
          col_tx="txProf"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_rx_clen.txReference" maxLength={50} />

        <CxDate xref={useRef(null)} label={`${trans('field.cleaning')} - ${trans('field.date')}`} dbfield="n_rx_clen.dtClean" />
        <CxInput xref={useRef(null)} label={`${trans('field.cleaning')} - ${trans('field.fluid')}`} dbfield='n_rx_clen.txCleanFluid' maxLength={50} />

        <CxDate xref={useRef(null)} label={`${trans('field.service')} - ${trans('field.expiration')}`} dbfield="n_rx_clen.dtExpiration" />
        <CxDate xref={useRef(null)} label={`${trans('field.service')} - ${trans('field.warranty')}`} dbfield="n_rx_clen.dtWarranty" />

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_pd')} dbfield='n_rx_clen.nuOdClPd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_pa')} dbfield='n_rx_clen.nuOdClPa' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_cd')} dbfield='n_rx_clen.nuOdClCd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_cl_te')} dbfield='n_rx_clen.txOdClTe' maxLength={13} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_pd')} dbfield='n_rx_clen.nuOsClPd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_pa')} dbfield='n_rx_clen.nuOsClPa' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cl_cd')} dbfield='n_rx_clen.nuOsClCd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_cl_te')} dbfield='n_rx_clen.txOsClTe' maxLength={13} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>

        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_rad')} dbfield='n_rx_clen.nuOdR1' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph')} dbfield='n_rx_clen.nuOdSph' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_clen.nuOdCyl' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_clen.nuOdAxis' decimals={0} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_rad')} dbfield='n_rx_clen.nuOsR1' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_sph')} dbfield='n_rx_clen.nuOsSph' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_cyl')} dbfield='n_rx_clen.nuOsCyl' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_axis')} dbfield='n_rx_clen.nuOsAxis' decimals={0} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>

        <CxAccordion defaultExpanded title={trans('field.xx_eye_right')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_diam')} dbfield='n_rx_clen.nuOdDiam' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_zone')} dbfield='n_rx_clen.nuOdZone' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add')} dbfield='n_rx_clen.nuOdAdd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_va')} dbfield='n_rx_clen.txOdVa' maxLength={10} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>
        <CxAccordion defaultExpanded title={trans('field.xx_eye_left')}>
          <CxNumber xref={useRef(null)} label={trans('field.xx_diam')} dbfield='n_rx_clen.nuOsDiam' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_zone')} dbfield='n_rx_clen.nuOsZone' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxNumber xref={useRef(null)} label={trans('field.xx_add')} dbfield='n_rx_clen.nuOsAdd' decimals={2} decimalsfixed="true" hidebuttons="true" width={control_width_xs} autoselect="true" />
          <CxInput xref={useRef(null)} label={trans('field.xx_va')} dbfield='n_rx_clen.txOsVa' maxLength={10} hidebuttons="true" width={control_width_xs} autoselect="true" />
        </CxAccordion>

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br/>
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_clen.txNotes" minRows={4} speech />
        
      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} strRelatedField={'inRxClen'} strRelatedId={storeRxClen.inRxClen} /* rf_txEmployee={rf_txEmployee} */ />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

    </div>
  );

}

export default MxClientRxClenForm;
