import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettPosCounter } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsCounterList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettPosCounter, setStoreSettPosCounter] = useSessionStorage('StoreSettPosCounter', StoreSettPosCounter);

  React.useEffect(() => {
    clearStore('StoreSettPosCounter', storeSettPosCounter);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettPosCounter.iiCounter = null;
    } else {
      storeSettPosCounter.iiCounter = row.original.iiCounter;
    }
    setStoreSettPosCounter(storeSettPosCounter);
    navTo("sett-sls-counter-form");
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.counters')}
        fullscreen
        hasSearchBox={false}
        url="sett/counter"
        hasButtonAdd={false}
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "iiCounter", show: false },
          { id: "1", Header: trans('field.series'), accessor: "txTypeSeries", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettSlsCounterList;
