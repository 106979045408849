import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettAnimalBreed } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettAnimalBreedList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettAnimalBreed, setStoreSettAnimalBreed] = useSessionStorage('StoreSettAnimalBreed', StoreSettAnimalBreed);

  React.useEffect(() => {
    clearStore('StoreSettAnimalBreed', storeSettAnimalBreed);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettAnimalBreed.itAnimalBreed = null;
    } else {
      storeSettAnimalBreed.itAnimalBreed = row.original.itAnimalBreed;
    }
    setStoreSettAnimalBreed(storeSettAnimalBreed);
    navTo("sett-animal-breed-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.patients')}: ${trans('field.breeds')}`}
        xref={r_list}
        tooltip={`${trans('field.breed')}`}
        fullscreen
        url="sett/animal_breed"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalBreed", show: false },
          { id: "1", Header: trans('field.breed'), accessor: row => r_list.current.getTable().highlightText(row["txTypeAnimalBreed"]), width: '60%' },
          { id: "2", Header: trans('field.species'), accessor: row => r_list.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '40%' },
        ]}
      />
    </div>
  );
}

export default MxSettAnimalBreedList;
