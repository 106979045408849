// HELPER
import * as FxFetch from '../../fx/FxFetch';
import * as FxLog from '../../fx/FxLog';
import { isApp } from "../../gx/GxStore";


export const load = (storeSlsLst, storeSlsDoc) => {
  try {
    let record = {};
    record['idValue'] = storeSlsDoc.inXXX;
    return Promise.all([FxFetch.doJsonX(`sls/doc/${storeSlsLst.listType}/r`, record)]).then((result) => {
      if (result[0]) {
        // cabecera
        storeSlsDoc.boDataChanged = '0';
        storeSlsDoc.docType = storeSlsLst.listType;
        storeSlsDoc.inXXX = result[0].record[0].[storeSlsLst.table_idField];
        storeSlsDoc.txXXX = result[0].record[0].[storeSlsLst.table_txField];
        storeSlsDoc.dtXXX = result[0].record[0].[storeSlsLst.table_dtField];
        storeSlsDoc.itSeries = result[0].record[0].itSeries;
        storeSlsDoc.txTypeSeries = result[0].record[0].txTypeSeries;
        storeSlsDoc.inReceipt = result[0].record[0].inReceipt;
        storeSlsDoc.inClient = result[0].record[0].inClient;
        storeSlsDoc.txClient = result[0].record[0].txClient;
  
        if ((isApp('vett') || isApp('spaw'))) {
          storeSlsDoc.inAnimal = result[0].record[0].inAnimal;
          storeSlsDoc.txAnimal = result[0].record[0].txAnimal;
        }
        storeSlsDoc.inEmployee = result[0].record[0].inEmployee;
        storeSlsDoc.txEmployee = result[0].record[0].txEmployee;
        storeSlsDoc.txNotes = result[0].record[0].txNotes;
        
        storeSlsDoc.nuTotalDiscount = result[0].record[0].nuTotalDiscount;
        storeSlsDoc.nuTotalTaxable = result[0].record[0].nuTotalTaxable;
        storeSlsDoc.nuTotalTax = result[0].record[0].nuTotalTax;
        storeSlsDoc.nuTotalAmount = result[0].record[0].nuTotalAmount;
        storeSlsDoc.nuTotalWtaxPerc = result[0].record[0].nuTotalWtaxPerc;
        storeSlsDoc.nuTotalWtax = result[0].record[0].nuTotalWtax;
        storeSlsDoc.nuTotalFinal = result[0].record[0].nuTotalFinal;
        storeSlsDoc.nuTotalPayment = result[0].record[0].nuTotalPayment;
  
        if (storeSlsDoc.docType === 'i') {
          storeSlsDoc.inInvoiceOri = result[0].record[0].inInvoiceOri;
        }
        if (storeSlsDoc.docType === 'r') {
          storeSlsDoc.inReceiptOri = result[0].record[0].inReceiptOri;
        }
  
        storeSlsDoc.isVoid = (result[0].record[0]._isVoid === "1" ? true : false);
  
        if (storeSlsLst.listType === 'q' || storeSlsLst.listType === 'i') {
          // items
          var items = storeSlsDoc.arItems || [];
          for (let i = 0; i < result[0].items.length; i++) {
            var item = {
              idx: i,
              inXxxItem: (result[0].items[i].inQuotationItem || result[0].items[i].inInvoiceItem),
              inPs: result[0].items[i].inPs,
              txPs: result[0].items[i].txPs,
              nuFrequency: result[0].items[i].nuFrequency,
              txLotCode: result[0].items[i].txLotCode,
              dtLotExpr: result[0].items[i].dtLotExpr,
  
              nuQuantity: result[0].items[i].nuQuantity,
              nuPrice: result[0].items[i].nuPrice,
              nuTaxable: result[0].items[i].nuTaxable,
              nuTaxPerc: result[0].items[i].nuTaxPerc,
              nuTax: result[0].items[i].nuTax,
              nuDiscountPerc: result[0].items[i].nuDiscountPerc,
              nuDiscount: result[0].items[i].nuDiscount,
              nuAmount: result[0].items[i].nuAmount,
  
              txPsAlias: result[0].items[i].txPsAlias,
              txAlert: result[0].items[i].txAlert,
              txRemarks: result[0].items[i].txRemarks,
            };
            items.push(item);
          }
          storeSlsDoc.arItems = items;
        }
  
        if (storeSlsLst.listType === 'i') {
          // payms
          var payms = storeSlsDoc.arPayms || [];
          for (let i = 0; i < result[0].payms.length; i++) {
            var paym = {
              idx: i,
              inPayment: result[0].payms[i].inPayment,
              dtPayment: result[0].payms[i].dtPayment,
              itCash: result[0].payms[i].itCash,
              itPayment: result[0].payms[i].itPayment,
              itPaymentDet: result[0].payms[i].itPaymentDet,
              txTypeCash: result[0].payms[i].txTypeCash,
              txTypePayment: result[0].payms[i].txTypePayment,
              txTypePaymentDet: result[0].payms[i].txTypePaymentDet,
              nuAmount: result[0].payms[i].nuAmount,
            };
            payms.push(paym);
          }
          storeSlsDoc.arPayms = payms;
        }
      }
    });
    
  } catch (error) {
    FxLog.errorx("MxSlsDocFormH1.load", error);
  }
}

