import { AiFillStar } from "react-icons/ai"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
import CxStatic from '../../cx/CxStatic';
import CxNumber from '../../cx/CxNumber';
import * as FxFetch from "../../fx/FxFetch";
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';
import * as FxTable from '../../fx/FxTable';
import * as FxStr from '../../fx/FxStr';
import GxForm from '../../gx/GxForm';
import MxPsOTF from '../common/otf/MxPsOTF';
import '../../../styles/main.css';
import { StoreSlsLst, StoreSlsDoc, getStoreValue, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import { useWindowSize } from '../../hx/useWindowSize';
import { useAccess } from '../../hx/useAccess';
import * as MxSlsDocFormItemH1 from './MxSlsDocFormItemH1';


const MxSlsDocFormItem = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ access }] = useAccess();
  const [{ navBack }] = useNav();
  const size = useWindowSize();

  let r_form = React.useRef(null);
  let r_dlg_new_ps = React.useRef(null);
  let r_boComposite = React.useRef(false);

  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  const [docTypeName, ] = React.useState(FxStr.choose(storeSlsDoc.docType, 'q|i|r', trans('field.quotation'), trans('field.invoice'), trans('field.receipt')));

  const rf_txPs = React.useRef(null);
  const rf_nuFrequency = React.useRef(null);
  const rf_txLotCode = React.useRef(null);
  const rf_dtLotExpr = React.useRef(null);

  const rf_nuQuantity = React.useRef(null);
  const rf_nuPrice = React.useRef(null);
  const rf_nuDiscountPerc = React.useRef(null);
  const rf_nuTaxPerc = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const rf_txPsAlias = React.useRef(null);
  const rf_txRemarks = React.useRef(null);

  const [psAlert, setPsAlert] = React.useState('');

  let r_idx = React.useRef(storeSlsLst.table_item_idx);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const hasColumnPsCode = () => { return Boolean(localStorage.getItem('MxSlsDocFormItem.addcol_txPsCode')==='1')};
  const hasColumnReference = () => { return Boolean(localStorage.getItem('MxSlsDocFormItem.addcol_txReference') === '1') };

  const formLoadCustom = () => {
    if (storeSlsLst.table_item_idx === null) { //nuevo registro
      rf_txPs.current.setDlg(true); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de producto-servicio */

    } else { // registro existente
      rf_txPs.current.setKey(storeSlsDoc.arItems[r_idx.current].inPs);
      rf_txPs.current.setVal(storeSlsDoc.arItems[r_idx.current].txPs);
      rf_nuFrequency.current.setVal(storeSlsDoc.arItems[r_idx.current].nuFrequency);
      rf_txLotCode.current.setVal(storeSlsDoc.arItems[r_idx.current].txLotCode);
      rf_dtLotExpr.current.setVal(storeSlsDoc.arItems[r_idx.current].dtLotExpr);
      rf_txPsAlias.current.setVal(storeSlsDoc.arItems[r_idx.current].txPsAlias);
      rf_txRemarks.current.setVal(storeSlsDoc.arItems[r_idx.current].txRemarks);

      rf_nuQuantity.current.setVal(storeSlsDoc.arItems[r_idx.current].nuQuantity);
      rf_nuPrice.current.setVal(storeSlsDoc.arItems[r_idx.current].nuPrice);
      rf_nuDiscountPerc.current.setVal(storeSlsDoc.arItems[r_idx.current].nuDiscountPerc);
      rf_nuTaxPerc.current.setVal(storeSlsDoc.arItems[r_idx.current].nuTaxPerc);
      rf_nuAmount.current.setVal(storeSlsDoc.arItems[r_idx.current].nuAmount);

      setPsAlert(storeSlsDoc.arItems[r_idx.current].txAlert);
    }
  }
  const formDeleteCustom = () => {
    if (storeSlsLst.table_item_idx !== null) {
      if (storeSlsDoc.arItems[r_idx.current].inXxxItem) {
        storeSlsDoc.txItemsDeleted = storeSlsDoc.arItems[r_idx.current].inXxxItem + ' ' + storeSlsDoc.txItemsDeleted;
      }
      storeSlsDoc.arItems.splice(r_idx.current, 1); // 2nd parameter means remove one item only
      // reorder
      storeSlsDoc.arItems.forEach(function (item, index) {
        item.idx = index;
      })

      // asienta que los datos fueron cambiados
      storeSlsDoc.boDataChanged = '1';

      Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
        navBack();
      });
    }
  }

  const recalc = async (from) => {
    try {
      //realiza cálculos a partir de los valores
      let nuQuantity = rf_nuQuantity.current.getFloatVal();
      let nuPrice = rf_nuPrice.current.getFloatVal();
      let nuDiscountPerc = rf_nuDiscountPerc.current.getFloatVal();
      let nuTaxPerc = rf_nuTaxPerc.current.getFloatVal();
      let nuAmount = rf_nuAmount.current.getFloatVal();

      if (from === 'amount') {
        nuPrice = (-1 * ((10000 * nuAmount) / (((nuDiscountPerc - 100) * nuTaxPerc + 100 * nuDiscountPerc - 10000) * nuQuantity)));
      }
      let nuDiscount = (nuQuantity * nuPrice * nuDiscountPerc / 100);
      let nuTaxable = ((nuQuantity * nuPrice) - nuDiscount);
      let nuTax = (nuTaxable * nuTaxPerc / 100);
      nuAmount = FxNum.to3r(nuTaxable + nuTax);

      rf_nuPrice.current.setVal(nuPrice);
      rf_nuAmount.current.setVal(nuAmount);

    } catch (error) {
      FxLog.errorx("MxSlsDocFormItem.recalc", error);
    }
  }

  const searcherClearValues = () => {
    r_boComposite.current = false;
    rf_nuFrequency.current.setVal('');
    rf_txLotCode.current.setVal('');
    rf_dtLotExpr.current.setVal('');
    rf_nuQuantity.current.setVal('');
    rf_nuPrice.current.setVal('');
    rf_nuDiscountPerc.current.setVal('');
    rf_nuTaxPerc.current.setVal('');
    rf_nuAmount.current.setVal('');
    setPsAlert('');
  }

  const formLoadSelected = async (inPs) => {
    try {
      let record = {};
      record['idValue'] = inPs;
      Promise.all([FxFetch.doJsonX(`sls/docitem/load_selected`, record)]).then((result) => {
        if (result[0]) {
          // cargar controles
          rf_txPsAlias.current.setVal(result[0][0].txPsAlias);
          rf_nuFrequency.current.setVal(result[0][0].nuFrequency);
          rf_nuQuantity.current.setVal(result[0][0].nuQuantity);
          rf_nuPrice.current.setVal(result[0][0].nuPrice);
          rf_nuDiscountPerc.current.setVal(result[0][0].nuDiscountPerc);
          rf_nuTaxPerc.current.setVal(result[0][0].nuTaxPerc);
          rf_nuAmount.current.setVal("0");
          setPsAlert(result[0][0].txAlert);

          if (storeSlsDoc.arRates) {

            const keys = Object.keys(storeSlsDoc.arRates[0]);
            let discPerc = 0;
            keys.forEach((key, index) => {
              //console.log(`${key}: ${storeSlsDoc.arRates[0][key]}`);
              if (key === inPs) {
                discPerc = storeSlsDoc.arRates[0][key];
                rf_nuDiscountPerc.current.setVal(discPerc);
              }
            });
          }

          // recálculo
          recalc();

          // marca los que necesitan que el usuario seleccione lote.
          if (storeSlsDoc.docType === 'i') {
            let hasLots = result[0][0].hasLots;
            if (hasLots === '1') {
              if (getStoreValue('StoreSession', 'boLotControl') === '1') {
                /* DAQ 2022.05.11 - indica a MxSlsDocForm.doLots() que este ps está pendiente de añadir lote */
                rf_txLotCode.current.setVal('**pending**');
              }
            }
          }

          // levanta flag si es un ps compuesto (tiene adjuntos).
          let boComposite = result[0][0].boComposite;
          if (boComposite === '1') {
            r_boComposite.current = true;
          }

        }
      });

    } catch (error) {
      FxLog.errorx("MxSlsDocFormItem.formLoadSelected", error);
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let inPs = rf_txPs.current.getKey();

    let itemData = {
      inPs: inPs,
      txPs: rf_txPs.current.getVal(),
      nuFrequency: rf_nuFrequency.current.getVal(),
      txLotCode: rf_txLotCode.current.getVal(),
      dtLotExpr: rf_dtLotExpr.current.getVal(),
      txPsAlias: rf_txPsAlias.current.getVal(),
      txAlert: psAlert,
      txRemarks: rf_txRemarks.current.getVal(),

      nuQuantity: rf_nuQuantity.current.getFloatVal(),
      nuPrice: rf_nuPrice.current.getFloatVal(),
      nuDiscountPerc: rf_nuDiscountPerc.current.getFloatVal(),
      nuTaxPerc: rf_nuTaxPerc.current.getFloatVal(),
      nuAmount: FxNum.to2r(rf_nuAmount.current.getFloatVal()),
    };

    if (r_boComposite.current) {
      let nuQuantity = rf_nuQuantity.current.getFloatVal();
      Promise.all([MxSlsDocFormItemH1.saveItemAttachedToStore(storeSlsDoc, setStoreSlsDoc, inPs, nuQuantity)]).then((result) => {
        Promise.all([MxSlsDocFormItemH1.saveItemToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst, r_idx.current, inPs, itemData)]).then((result) => {
          navBack();
        });
      });
    } else {
      Promise.all([MxSlsDocFormItemH1.saveItemToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst, r_idx.current, inPs, itemData)]).then((result) => {
        navBack();
      });
    }

  }

  const searcherPsChanged = async (row) => {
    if (row !== null) {
      searcherClearValues();
      let inPs = row.original.inPs;
      formLoadSelected(inPs);
    }
  }

  const selectedPsOTF = (inPs, txPs) => {
    try {
      rf_txPs.current.setKey(inPs);
      rf_txPs.current.setVal(txPs);
      formLoadSelected(inPs);

    } catch (error) {
      FxLog.errorx('MxSlsDocFormItem.selectedPsOTF', error)
    }
  }

  const getSearcherTableOptionalCols = () => {
    let cols = [];
    cols.push({id: 'txPsCode', name: trans('field.code'), checked: hasColumnPsCode() ? '1' : '0'}); 
    cols.push({ id: 'txReference', name: trans('field.reference'), checked: hasColumnReference() ? '1' : '0' });
    return cols;
  }
  const searcherOptionsSelected = (options) => {
    localStorage.setItem('MxSlsDocFormItem.addcol_txReference', options.includes('txReference') ? '1' : '0');
    localStorage.setItem('MxSlsDocFormItem.addcol_txPsCode', options.includes('txPsCode') ? '1' : '0');
    rf_txPs.current.getTable().setTooltipText(getSearcherTooltip());
    rf_txPs.current.getTable().clearSearch();
    forceUIUpdate();
  }
  const getSearcherTooltip = () => {
    let tip = `${trans('field.ps')}`;
    if (hasColumnPsCode()) tip = tip + `${' / ' + trans('field.code')}`;
    if (hasColumnReference()) tip = tip + `${' / ' + trans('field.reference')}`;

    return tip;
  }

  const onFavorites = () => {
    if (rf_txPs.current.getTable().getFilter()==='') {
      Promise.all([rf_txPs.current.getTable().setFilter(`n_ps.boFavorite='1'`)]).then((result) => {
        rf_txPs.current.getTable().redisplay();
      });
    } else {
      Promise.all([rf_txPs.current.getTable().setFilter('')]).then((result) => {
        rf_txPs.current.getTable().redisplay();
      });
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.sales')}: ${docTypeName}: ${trans('field.item')}`}
        xref={r_form}
        toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            {storeSlsLst.table_item_idx!==null && isFullAccess() 
              && <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar */}
            {isFullAccess() 
              && <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.confirm')} />}
          </div>
        }
      >

        <CxHidden xref={rf_nuFrequency} />
        <CxHidden xref={rf_txLotCode} />
        <CxHidden xref={rf_dtLotExpr} />

        {psAlert && <CxStatic label={psAlert} border='red' />}

        <CxSearcher xref={rf_txPs} id='MxSlsDocFormItem_txPs'
          label={trans('field.ps')}
          readOnly={storeSlsLst.table_item_idValue}
          onSelection={searcherPsChanged}
          onBlur={recalc}
          onClear={searcherClearValues}
          onButtonAddOTF={access(trans('field.pss'))!=='W' ? false : () => {
            rf_txPs.current.setDlg(false);
            r_dlg_new_ps.current.setDlg(true);
          }}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          tooltip={getSearcherTooltip()}
          colorRefs={['table_cell_red|' + trans('field.stock_below_min'), 'table_cell_brown|' + trans('field.composite'), 'table_cell_green|' + trans('field.frequency')]}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '50%' },
            { id: "2", Header: trans('field.code'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPsCode"]), width: '25%', align: 'right', show: size.isLarge && hasColumnPsCode() },
            { id: "3", Header: trans('field.reference'), accessor: row => rf_txPs.current.getTable().highlightText(row["txReference"]), width: '25%', show: size.isLarge && hasColumnReference() },
            { id: "4", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["theTotalPrice"]), width: '25%', align: 'right' },
            { id: "W", Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false },
            { id: "G", Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false },
            { id: "R", Header: "_stock_below_min", accessor: row => Boolean(row["boStockControl"] === '1') && Boolean(row["nuStock"] < row["nuStockMin"]), show: false },
          ]}
          optionalColumns={getSearcherTableOptionalCols()}
          onOptions={searcherOptionsSelected}
          action1={ {
            iconOn: <AiFillStar fontSize="20px" style={{ margin: '0 0 0 0px', color: 'var(--ibs_color_yellow)' }} />, 
            iconOff: <AiFillStar fontSize="20px" style={{ margin: '0 0 0 0px', color: 'var(--ibs_color_gray)' }} />, 
            onClick: onFavorites, 
            tooltip: `${trans('field.favorites')}`
          } }
          required
        />

        <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} sumButton onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuPrice} label={trans('field.price')} onBlur={recalc} decimals={4} decimalsfixed="true" required />
        <CxNumber xref={rf_nuDiscountPerc} label={trans('field.discount_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuTaxPerc} label={trans('field.tax_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuAmount} label={trans('field.amount')} onBlur={() => recalc('amount')} decimals={2} decimalsfixed="true" required />
        <CxInput xref={rf_txPsAlias} label={trans('field.alias')} />
        <CxInput xref={rf_txRemarks} label={trans('field.remarks')} />

        <MxPsOTF xref={r_dlg_new_ps} onOk={selectedPsOTF} />

      </GxForm>

    </div>
  );

}

export default MxSlsDocFormItem;
