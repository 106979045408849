import React, { useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../hx/useTranslation';


const useStyles = makeStyles({
  root: {
    //background: 'var(--ibs_color_cy_blue)',
    paddingTop: 0,
  },
  logo: {
    border: '0px',
    padding: '1px 0 2px 16px',
    backgroundColor: 'var(--ibs_color_white)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoText: {
    border: '0px',
    padding: '1px 0 2px 4px',
    backgroundColor: 'var(--ibs_color_white)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  brandTitle: {
    padding: '0 0 0 8px',
    color: 'var(--ibs_color_cy_blue)',
    fontFamily: 'var(--ibs_brand_font_family)',
    fontSize: '16px',
  },
  brandSubtitle: {
    padding: '0 0 0 8px',
    color: 'var(--ibs_color_gray)',
    fontFamily: 'var(--ibs_brand_font_family)',
    fontSize: '10px',
  },
});

const useStylesItem = makeStyles((props) => ({
  root: {
    "&.MuiListItemIcon-root": {
      color: 'var(--ibs_color_black)',
      minWidth: '30px',
    },
  },
  innerList: {
    "&.MuiList-root": {
      paddingTop: '5px',
      paddingBottom: '10px',
    },
  },
  listItem: {
    height: 35,
    paddingLeft: props => (props.type === 'nested' ? '30px' : '10px'),
    paddingBottom: 12,
    "& .MuiTypography-body1": {
      fontFamily: 'var(--ibs_control_label_font_family)',
      fontSize: '14px',
      color: props => (props.textColor||'var(--ibs_color_black)'),
    },
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      height: 0,
      padding: '0 0 14px 0',
      color: props => (props.iconColor||'var(--ibs_color_black)'),
    },
  },
  listGroup: {
    height: 37,
    paddingLeft: '10px',
    background: 'var(--ibs_color_cy_blue)',
    color: 'var(--ibs_color_white)',
    "&:hover": {
      backgroundColor: "black"
    },
    "& .MuiListItemIcon-root": {
      color: 'var(--ibs_color_white)',
    },
    "& .MuiTypography-body1": {
      fontFamily: 'var(--ibs_control_label_font_family)',
      fontSize: '14px',
      //fontWeight: 'bold',
    },
  },
}));

export const MxMainListItemCP = (props) => {
  const classes = useStylesItem(props);
  const { onClick } = props;
  return (
    <ListItem button className={classes.listItem} key={props.text} onClick={onClick}>
      <ListItemIcon className={classes.listItem}>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
}

export const MxMainListGroupCP = (props) => {
  const classes = useStylesItem(props);
  const { onClick, group, ...rest } = props;
  return (
    <div>
      <ListItem button className={classes.listGroup} onClick={onClick}>
        <ListItemIcon className={classes.root}>{rest.icon}</ListItemIcon>
        <ListItemText primary={rest.text} />
        {group ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={group} timeout="auto" unmountOnExit>
        <List className={classes.innerList} component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </div>
  );
}

export const MxMainListCP = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'MxMainListCP' };

  return (
    <div>
      <div className={classes.logo}>
        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt='' width="50" height="50" />
        <div className={classes.logoText}>
          <span className={classes.brandTitle}>{trans('field.client_portal')}</span>
          <span className={classes.brandSubtitle}>POWERED BY IBERICAL</span>
        </div>
      </div>
      <List className={classes.root}>
        {props.children}
      </List>
    </div>
  );
};

