import React from 'react';
import './App.css';
import MxNavigator from './components/MxNavigator';
import { BrowserRouter } from "react-router-dom";
import packageJson from '../package.json';

const App = () => {

  React.useEffect(() => {
    caching();
    // eslint-disable-next-line
  }, []);

  const caching = () => {
    try {
      let ibs_version = localStorage.getItem('IBS.version');
      let ibs_version_build = localStorage.getItem('IBS.version_build'); //para actualizar cache aunque no haya cambio de versión.
      //ATENCIÓN: los logs sólo se verán configurando chrome: Developer Tools > Settings > Preferences > Network > "Preserve log"
      console.log(`IBERICAL ${packageJson.version} ${packageJson.version_build}`);

      // La versión ha cambiado, recargamos mostrando aviso al usuario.
      if (ibs_version !== packageJson.version) {
        console.log(`IBERICAL - Updating VERSION to... ${packageJson.version}`);
        doReload();
        localStorage.setItem('IBS.version', packageJson.version);
        // Marca como actualizada, para mostrar mensaje al usuario, si es que no es la primera vez que entra.
        if (ibs_version != null) {
          localStorage.setItem('IBS.msg_updated', "1");
        }

      } else {
        localStorage.setItem('IBS.msg_updated', "0");

        // El build ha cambiado, recargamos SIN mostrar aviso al usuario.
        if (ibs_version_build !== packageJson.version_build) {
          console.log(`IBERICAL - Updating BUILD to... ${packageJson.version_build}`);
          doReload();
          localStorage.setItem('IBS.version_build', packageJson.version_build);
        }
      }

    } catch (error) {
      console.log(`IBERICAL - ERROR on caching ${error}`);
    }
  };

  const doReload = () => {
    try {
      console.log(`IBERICAL - Reloading...`);
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
      }
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);

    } catch (error) {
      console.log(`IBERICAL - ERROR on reload ${error}`);
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <MxNavigator />
      </BrowserRouter>
    </div>
  );
}

export default App;
