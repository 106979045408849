import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import GxList from '../../gx/GxList';
import { useWindowSize } from '../../hx/useWindowSize';
import CxIconButton from '../../cx/CxIconButton';
import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';


const MxSettPsRestoreList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();

  const r_list = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const restoreItem = (row) => {
    let record = {};
    record['inPs'] = row.inPs;
    Promise.all([FxFetch.doJsonX('sett/ps/restore/do', record)]).then((result) => {
      if (result[0].theValue==='OK') {
        r_list.current.refresh();
      }
    });
  }

  const choose = (val) => {
    let rv = null;
    switch (val) {
      case '0': rv = trans('field.product'); break;
      case '1': rv = trans('field.service'); break;
      default: break;
    }
    return rv;
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inPs", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '25%' });
        cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '15%' });
        cols.push({ id: id++, Header: trans('field.nature'), accessor: row => (choose(row["nuNature"])), width: '10%' });
        cols.push({ id: id++, Header: trans('field.group'), accessor: "txTypePsGroup", width: '20%' });
        cols.push({ id: id++, Header: trans('field.subgroup'), accessor: "txTypePsSubgroup", width: '20%' });
        cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '10%', align: 'right' });
        cols.push({ id: id++, accessor: row => { return <CxIconButton type="restore" classType="ibs_iconbutton_table" data={row} onClick={restoreItem} tooltip={trans('field.restore')} />}, align: 'center', width: '5%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '50%' });
        cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '25%' });
        cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '25%', align: 'right' });
        cols.push({ id: id++, accessor: row => { return <CxIconButton type="restore" classType="ibs_iconbutton_table" data={row} onClick={restoreItem} tooltip={trans('field.restore')} />}, align: 'center', width: '10%' });
      }

    } catch(error) {
      FxLog.errorx('MxSettPsRestoreList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.deactivated_x').replace('%s',trans('field.pss'))}
        xref={r_list}
        tooltip={`${trans('field.ps')} / ${trans('field.code')}`}
        fullscreen
        url="sett/ps/restore"
        columns={getTableColumns()}
        hasButtonAdd={false}
      />

    </div>

  );

}

export default MxSettPsRestoreList;
