import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxTable from '../../cx/CxTable';
import CxHr from '../../cx/CxHr';
import CxImageEditor from '../../cx/CxImageEditor';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { getStoreValueBo, StoreEmployee } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import { useAccess } from '../../hx/useAccess';


const MxEmployeeForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.employees'));
  const [{ navTo }] = useNav();
  const r_snack_ask_save = React.useRef(null);

  const r_form = React.useRef(null);

  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txUser = React.useRef(null);

  const rf_table_commisions = React.useRef(null);
  const rf_image_signature = React.useRef(null);

  const [storeEmployee, setStoreEmployee] = useSessionStorage('StoreEmployee', StoreEmployee);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeEmployee.inEmployee = lastSaveRv.theValue;
    Promise.all([setStoreEmployee(storeEmployee)]).then((result) => {
      rf_image_signature.current.save(lastSaveRv.theValue);
      //navBack(); // 2023-05-24 - daq: xq a lo mejor quiere completar las comisiones
    });
  }
  const formDelete = async (rv) => {
  }

  const openFormCommission = async (op, row) => {
    if (!storeEmployee.inEmployee) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeEmployee.inCommission = null;
      } else {
        storeEmployee.inCommission = row.original.inCommission;
      }
      setStoreEmployee(storeEmployee);
      navTo("commission-form");
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.employee')}`}
        xref={r_form}
        url="employees"
        filterIdField='inEmployee'
        filterIdValue={storeEmployee.inEmployee}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxInput xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} required />
        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_employee.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_employee.dtTo"} />

        <CxChooser xref={useRef(null)}
          label={trans('field.type')}
          dbfield="t_employee.txTypeEmployee" dbfieldkey="n_employee.itEmployee"
          table="t_employee"
          col_id="itEmployee"
          col_tx="txTypeEmployee"
        />

        <CxSearcher xref={rf_txUser} label={trans('field.linked_to_x', 'field.user')}
          dbfield="n_user.txUser" dbfieldkey={`n_employee.inUser`}
          url="sett/sec/users"
          filter={`n_user.txType='E'`}
          col_id="inUser"
          col_tx="txUser"
          columns={[
            { id: "0", Header: "ID", accessor: "inUser", show: false },
            { id: "1", Header: trans('field.user'), accessor: row => rf_txUser.current.getTable().highlightText(row["txUser"]), width: '100%' },
          ]}
        />

        <CxInput xref={useRef(null)} label={trans('field.ic')} dbfield="n_employee.txIc" maxLength={15} />
        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_employee.txPhone1" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_employee.txMobile" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_employee.txEmail" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_employee.txAddress" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_employee.txCity" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_employee.txState" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_employee.txPostalCode" maxLength={10} />
        <CxInput xref={useRef(null)} label={trans('field.country')} dbfield="n_employee.txCountry" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.license')} dbfield="n_employee.txLicense" maxLength={50} />

        <CxTable
          title={trans('field.commissions')}
          xref={rf_table_commisions}
          url="employees/commission"
          filter={`n_commission.inEmployee='${storeEmployee.inEmployee}'`}
          onOpenForm={openFormCommission}
          hasButtonAdd={true}
          columns={[
            { id: "0", Header: "ID", accessor: "inCommission", show: false },
            { id: "1", Header: trans('field.group'), accessor: "txTypePsGroup", width: '40%' },
            { id: "2", Header: trans('field.subgroup'), accessor: "txTypePsSubgroup", width: '40%' },
            { id: "3", Header: trans('field.perc'), accessor: row => (FxTable.cellTo2r00(row["nuCommissionPerc"])), align: 'right', width: '20%' },
          ]}
        />

        <CxHr title={trans('field.signature')} />
        <div style={{ padding: '0 0 50px 0' }}>
          <CxImageEditor xref={rf_image_signature}
            url='employees/signature'
            inXXX={storeEmployee.inEmployee}
            pen_black_default
            blank={{ width: 600, height: 200 }}
            stroke={2}
          />
        </div>

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_employee.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxEmployeeForm;
