import { MdOutlineSettingsBackupRestore } from "react-icons/md"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDialog from '../../cx/CxDialog';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, setStoreValue } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';
import CxDate from '../../cx/CxDate';


const MxSettSlsHeaderFooter = (props) => {
  const [{ trans }] = useTranslation();
  
  let r_form = React.useRef(null);

  const r_snack_save = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);

  const rf_header = React.useRef(null);
  const rf_footer = React.useRef(null);
  const rf_change_date = React.useRef(null);
  
  const [dlgChangePrev, setDlgChangePrev] = React.useState(false);


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/branch/r', record)]).then((result) => {
      if (result[0]) {
        rf_header.current.setVal(result[0][0].txBranchHeader);
        rf_footer.current.setVal(result[0][0].txBranchFooter);
      }
    });

  }

  const formSaveCustom = async () => {
    let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
    let record = {};
    record['s_branch.txBranchHeader'] = rf_header.current.getVal();
    record['s_branch.txBranchFooter'] = rf_footer.current.getVal();
    setStoreValue('StoreSession', 'txBranchHeader', rf_header.current.getVal());
    setStoreValue('StoreSession', 'txBranchFooter', rf_footer.current.getVal());
    Promise.all([FxFetch.doJsonF("/sett/branch/u", filter, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  const doChangePrev = () => {
    setDlgChangePrev(false);
    let record = {};
    record['dtFrom'] = rf_change_date.current.getVal();
    record['txBranchHeader'] = rf_header.current.getVal();
    record['txBranchFooter'] = rf_footer.current.getVal();
    Promise.all([FxFetch.doJsonX("/sett/branch/change_prev_header_footer", record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_op_successful.current.setOpen(true);
        }
      }
    });

  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.branch_header')} / ${trans('field.branch_footer')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew
              onClick={() => setDlgChangePrev(true)} className="ibs_iconbutton_toolbar"
              icon={<MdOutlineSettingsBackupRestore fontSize="24px" style={{ marginTop: '0px' }} />}
              tooltip={trans('field.change_prev')}
            />

            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxInput xref={rf_header} label={trans('field.branch_header')} minRows={8} />
        <CxInput xref={rf_footer} label={trans('field.branch_footer')} minRows={8} />
        
      </GxForm>

      <CxDialog
        title={trans('field.from')}
        open={dlgChangePrev}
        onOk={() => doChangePrev()}
        onCancel={() => setDlgChangePrev(false)}
        height='200px'>

        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }} >
          <CxDate xref={rf_change_date} label={trans('field.date')} width='var(--ibs_control_width_md)' required />
        </div>
      </CxDialog>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxSettSlsHeaderFooter;
