import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxRadio from '../../../cx/CxRadio';
import CxSearcher from '../../../cx/CxSearcher';
import CxNumber from '../../../cx/CxNumber';
import CxSnackbar from '../../../cx/CxSnackbar';
import '../../../../styles/main.css';


const MxPsOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const rf_txPs = React.useRef(null);
  const rf_nuNature = React.useRef(null);
  const rf_nuTaxPerc = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txPs.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txPs.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_nuTaxPerc.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuTaxPerc.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypePsGroup.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypePsGroup.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypePsSubgroup.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypePsSubgroup.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_nuAmount.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuAmount.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txPs = rf_txPs.current.getVal();
    let nuNature = rf_nuNature.current.getVal();
    let nuTaxPerc = rf_nuTaxPerc.current.getVal();

    let itPsGroup = rf_txTypePsGroup.current.getKey();
    let itPsSubgroup = rf_txTypePsSubgroup.current.getKey();

    let nuQuantity = 1;
    let nuDiscountPerc = 0;

    setDisabled(true);

    let nuAmount = rf_nuAmount.current.getVal();
    let nuPrice = (-1 * ((10000 * nuAmount) / (((nuDiscountPerc - 100) * nuTaxPerc + 100 * nuDiscountPerc - 10000) * nuQuantity)));

    let record = {};
    record['txPs'] = txPs;
    record['nuNature'] = nuNature;
    record['nuTaxPerc'] = nuTaxPerc;
    record['itPsGroup'] = itPsGroup;
    record['itPsSubgroup'] = itPsSubgroup;
    record['nuPrice'] = nuPrice;

    Promise.all([FxFetch.doJsonX("pss/otf", record)]).then((result) => {
      FxLog.infox("MxPsOTF.formSave", "...result[0]:", result[0]);
      if (result[0]) {
        if (result[0].theValue === null) {
          FxLog.infox("MxPsOTF.formSave", "El ps ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists','field.ps'));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxPsOTF.formSave", "El ps ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let inPs = result[0].theValue;
          if (props.onOk) props.onOk(inPs, txPs);
        }
      }
    });

  }

  const groupCleared = () => {
    rf_txTypePsSubgroup.current.clear();
    rf_txTypePsSubgroup.current.setFilter(`undefined='${undefined}'`);
  }
  const groupSelected = (row) => {
    rf_txTypePsSubgroup.current.clear();
  }
  const subgroupOpen = () => {
    let itPsGroup = rf_txTypePsGroup.current.getKey();
    rf_txTypePsSubgroup.current.setFilter(`t_ps_subgroup.itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  return (
    <div>
      <CxDialog title={trans('field.create_x','field.ps')} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

          <CxInput xref={rf_txPs} label={trans('field.ps')}
            dbfield="n_ps.txPs"
            required
            disabled={disabled}
            width='var(--ibs_control_width_md)'
          />

          <CxRadio xref={rf_nuNature}
            dbfield="n_ps.nuNature"
            list={[{ id: '0', type: trans('field.product') }, { id: '1', type: trans('field.service') }]}
            required
            autoSelectFirst
            disabled={disabled}
            width='var(--ibs_control_width_md)'
          />


          <CxNumber xref={rf_nuTaxPerc}
            label={`${trans('field.tax_perc')}`}
            dbfield="nuTaxPerc"
            decimals={2}
            decimalsfixed="true"
            width='var(--ibs_control_width_md)'
            required
          />

          <CxNumber xref={rf_nuAmount}
            dbfield='dummy_amount'
            label={`${trans('field.price')}+${trans('field.tax')}`}
            decimals={2}
            decimalsfixed="true"
            required
            disabled={disabled}
            width='var(--ibs_control_width_md)'
          />

          <CxSearcher xref={rf_txTypePsGroup}
            label={trans('field.group')}
            dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_ps.itPsGroup'
            onClear={groupCleared}
            onSelection={groupSelected}
            url="sett/ps_group"
            col_id="itPsGroup"
            col_tx="txTypePsGroup"
            columns={[
              { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
            ]}
            disabled={disabled}
            required
            width='var(--ibs_control_width_md)'
          />

          <CxSearcher xref={rf_txTypePsSubgroup}
            label={trans('field.subgroup')}
            dbfield='t_ps_subgroup.txTypePsSubgroup' dbfieldkey='n_ps.itPsSubgroup'
            canOpenDialog={subgroupOpen}
            url="sett/ps_subgroup"
            col_id="itPsSubgroup"
            col_tx="txTypePsSubgroup"
            columns={[
              { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
            ]}
            disabled={disabled}
            required
            width='var(--ibs_control_width_md)'
          />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />


    </div>
  );

}

export default MxPsOTF;
