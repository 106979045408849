import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import Typography from '@material-ui/core/Typography';

const CxHr = props => {

  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'CxHr' };

  return (
    <div style={{ padding: '4px 2px 0px 2px', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
        <Typography style={{ color: props.color||'var(--ibs_color_cy_blue)', textAlign: 'left', height: '15px' }}>
          {props.title}
        </Typography>
        <div>
          {props.children}
        </div>
      </div>
      <hr />
    </div>
  );

};

export default CxHr;
