// HELPER
import * as FxFetch from '../../fx/FxFetch';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';


export const load = (storeRxAuto) => {
  let record = {};
  record['txRxAuto'] = storeRxAuto.txRxAuto;
  record['itSeriesRx'] = storeRxAuto.itSeriesRx;
  return Promise.all([FxFetch.doJsonX(`rx_auto/r`, record)]).then((result) => {
    if (result[0]) {
    
      storeRxAuto.inRxAuto = result[0].record[0].inRxAuto;
      storeRxAuto.txRxAuto = result[0].record[0].txRxAuto;
      storeRxAuto.dtRxAuto = result[0].record[0].dtRxAuto;
      storeRxAuto.inClient = result[0].record[0].inClient;
      storeRxAuto.txClient = result[0].record[0].txClient;
      storeRxAuto.inAnimal = result[0].record[0].inAnimal;
      storeRxAuto.txAnimal = result[0].record[0].txAnimal;
      storeRxAuto.txNumberAnimals = result[0].record[0].txNumberAnimals;
      storeRxAuto.txIdentification = result[0].record[0].txIdentification;
      storeRxAuto.inEmployee = result[0].record[0].inEmployee;
      storeRxAuto.txEmployee = result[0].record[0].txEmployee;
      storeRxAuto.itSeriesRx = result[0].record[0].itSeriesRx;
      storeRxAuto.txTypeSeriesRx = result[0].record[0].txTypeSeriesRx;
      storeRxAuto.txNumberRefills = result[0].record[0].txNumberRefills;
      storeRxAuto.boExceptional = result[0].record[0].boExceptional;
      storeRxAuto.txDaysValidity = result[0].record[0].txDaysValidity;
      storeRxAuto.txNotes = result[0].record[0].txNotes;
      storeRxAuto.txCodePresvet = result[0].record[0].txCodePresvet;
      storeRxAuto.inInvoice = result[0].record[0].inInvoice;
      storeRxAuto.txInvoice = result[0].record[0].txInvoice;
      storeRxAuto.dtInvoice = result[0].record[0].dtInvoice;

      // items
      var items = storeRxAuto.arItems || [];
      for (let i = 0; i < result[0].items.length; i++) {
        var item = {
          idx: i,
          inRxAuto: result[0].items[i].inRxAuto,
          inInvoiceItem: result[0].items[i].inInvoiceItem,
          inPs: result[0].items[i].inPs,
          txPs: result[0].items[i].txPs,
          nuQuantity: result[0].items[i].nuQuantity,
          txLotCode: result[0].items[i].txLotCode,
          dtLotExpr: result[0].items[i].dtLotExpr,
          
          itRxAuto: result[0].items[i].itRxAuto,
          txRegime: result[0].items[i].txRegime,
          txTypeDispensing: result[0].items[i].txTypeDispensing,
          txCode: result[0].items[i].txCode,
          txNature: result[0].items[i].txNature,
          txActiveIngredient: result[0].items[i].txActiveIngredient,
          txDrugStrength: result[0].items[i].txDrugStrength,
          nuDrugPerc: result[0].items[i].nuDrugPerc,
          txNumberRefills: result[0].items[i].txNumberRefills,
          txPharmaceuticalForm: result[0].items[i].txPharmaceuticalForm,
          txRouteAdministration: result[0].items[i].txRouteAdministration,
          txDosageInformation: result[0].items[i].txDosageInformation,
          txTypeTreatment: result[0].items[i].txTypeTreatment,
          txDurationTreatment: result[0].items[i].txDurationTreatment,
          txWithdrawalMilk: result[0].items[i].txWithdrawalMilk,
          txWithdrawalMeat: result[0].items[i].txWithdrawalMeat,
          txWithdrawalEggs: result[0].items[i].txWithdrawalEggs,
          txDirections: result[0].items[i].txDirections,
        };
        items.push(item);
      }
      storeRxAuto.arItems = items;

    }
  });
}

export const loadFromInvoice = (storeRxAuto) => {
  let record = {};
  record['inInvoice'] = storeRxAuto.inInvoice;
  return Promise.all([FxFetch.doJsonX(`rx_auto/r/from_invoice`, record)]).then((result) => {
    if (result[0]) {
    
      storeRxAuto.inClient = result[0].record[0].inClient;
      storeRxAuto.txClient = result[0].record[0].txClient;
      storeRxAuto.inAnimal = result[0].record[0].inAnimal;
      storeRxAuto.txAnimal = result[0].record[0].txAnimal;
      storeRxAuto.inInvoice = result[0].record[0].inInvoice;

      // items
      var items = storeRxAuto.arItems || [];
      for (let i = 0; i < result[0].items.length; i++) {
        var item = {
          idx: i,
          inRxAuto: FxStr.getRandomID(), // 2024-03-08 - daq: crea un identificador al azar.
          inInvoiceItem: result[0].items[i].inInvoiceItem,
          inPs: result[0].items[i].inPs,
          txPs: result[0].items[i].txPs,
          nuQuantity: result[0].items[i].nuQuantity,
          txLotCode: result[0].items[i].txLotCode,
          dtLotExpr: result[0].items[i].dtLotExpr,
          
          itRxAuto: result[0].items[i].itRxAuto,
          txRegime: result[0].items[i].txRegime,
          txTypeDispensing: result[0].items[i].txTypeDispensing,
          txCode: result[0].items[i].txCode,
          txNature: result[0].items[i].txNature,
          txActiveIngredient: result[0].items[i].txActiveIngredient,
          txDrugStrength: result[0].items[i].txDrugStrength,
          nuDrugPerc: result[0].items[i].nuDrugPerc,
          txNumberRefills: result[0].items[i].txNumberRefills,
          txPharmaceuticalForm: result[0].items[i].txPharmaceuticalForm,
          txRouteAdministration: result[0].items[i].txRouteAdministration,
          txDosageInformation: result[0].items[i].txDosageInformation,
          txTypeTreatment: result[0].items[i].txTypeTreatment,
          txDurationTreatment: result[0].items[i].txDurationTreatment,
          txWithdrawalMilk: result[0].items[i].txWithdrawalMilk,
          txWithdrawalMeat: result[0].items[i].txWithdrawalMeat,
          txWithdrawalEggs: result[0].items[i].txWithdrawalEggs,
          txDirections: result[0].items[i].txDirections,
        };
        items.push(item);
      }
      storeRxAuto.arItems = items;

    }
  });
}

