import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
import Calendar from 'react-calendar';
import '../../styles/ReactCalendar/ReactCalendar.css';
import CxIconButton from "./CxIconButton";


const useStyles = makeStyles(props => ({
  dlgTitle: {
    backgroundColor: 'var(--ibs_color_cy_blue)',
    color: 'var(--ibs_color_white)',
  },
  dlgContent: {
    backgroundColor: 'var(--ibs_color_white)',
    color: 'var(--ibs_color_black)',
    height: '320px',
  },
  dlgActions: {
    backgroundColor: 'var(--ibs_color_cy_yellow)',
    display: 'flex',
  },
  dlgLabel: {
    fontSize: "12px",
    margin: "0px",
    color: 'var(--ibs_color_black)',
  },
  dlgButton: {
    color: 'var(--ibs_color_white)',
    '&:hover': { backgroundColor: 'transparent', },
  },
}));

const CxDlgCalendar = props => {
  const classes = useStyles(props);
  const { onClose, open, ...rest } = props;

  let r_cal = React.useRef(null);

  const [date, setDate] = React.useState(new Date());

  const createLabel = (e) => {
    return FxDat.toMMMYYYY(e.locale, e.date).toUpperCase();
  }

  const dateSelected = (newDate) => {
    setDate(newDate);
    let dtSelected = FxDat.toIso(newDate);
    onClose(dtSelected);
  }

  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'CxDlgCalendar' };

  return (
    <div>
      <Dialog open={open} {...rest}>
        <DialogTitle className={classes.dlgTitle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{props.title}</Box>
            <Box>
              <CxIconButton type="close" classType={classes.dlgButton} onClick={() => onClose(null)} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dlgContent}>

          <Calendar
            ref={r_cal}
            onChange={(d) => dateSelected(d)}
            value={date}
            navigationLabel={createLabel}
          />

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CxDlgCalendar;
