import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxSearcher from '../../cx/CxSearcher';
import CxDate from '../../cx/CxDate';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import { StoreClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { clearStore, StoreComm, StoreRepl } from "../../gx/GxStore";
import CxIconButton from '../../cx/CxIconButton';
import { useAccess } from '../../hx/useAccess';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';


const MxClientReplForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo }] = useNav();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeComm, setStoreComm] = useSessionStorage('StoreComm', StoreComm);
  const [storeRepl, setStoreRepl] = useSessionStorage('StoreRepl', StoreRepl);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);

  const rf_ps = React.useRef(null);
  const rf_date = React.useRef(null);
  const rf_next = React.useRef(null);
  
  const r_snack_ask_save = React.useRef(null);
  const r_snack_ps_doesnt_exist = React.useRef(null);


  React.useEffect(() => {
    clearStore('StoreComm', storeComm);
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      r_form.current.getRef("n_repl.dtFrom").setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
      if (lastSaveRv.theValue.startsWith("rv_ps_doesnt_exist")) {
        r_snack_ps_doesnt_exist.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeRepl.inRepl = lastSaveRv.theValue;
      Promise.all([setStoreRepl(storeRepl)]).then((result) => {
        r_sls_aux_table.current.setStrRelatedId(storeRepl.inRepl);
        //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps o recordatorio.
      });
    }

  }
  const formDelete = async (rv) => {
  }

  const openCommForm = () => {
    if (storeRepl.inRepl) {
      storeComm.inComm = null;
      storeComm.inClient = storeClient.inClient;
      storeComm.inRepl = storeRepl.inRepl;
      storeComm.dtFrom = rf_next.current.getVal();
      setStoreComm(storeComm);
      navTo("comm-form");
    } else {
      r_snack_ask_save.current.setOpen(true);
    }
  }

  const psChanged = async (row) => {
    if (row !== null) {
      let nuFrequency = row.original.nuFrequency;
      let next = FxDat.addDays(rf_date.current.getVal(), nuFrequency);
      rf_next.current.setVal(next);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client')}: ${trans('field.repl')}`}
        xref={r_form}
        url="repls" 
        filterIdField='inRepl' 
        filterIdValue={storeRepl.inRepl} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón de creación de comm */}
            {isFullAccess() && <CxIconButton type="comms" classType="ibs_iconbutton_toolbar" onClick={openCommForm} tooltip={trans('field.comm')} />}
          </div>
        }
        >
        <CxHidden xref={useRef(null)} dbfield="n_repl.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxSearcher xref={rf_ps} label={trans('field.ps')}
          dbfield="n_ps.txPs" dbfieldkey={`n_repl.inPs`}
          filter={'n_ps.nuFrequency > 0'}
          onSelection={psChanged}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_ps.current.getTable().highlightText(row["txPs"]), width: '100%' },
            { id: "2", Header: trans('field.frequency'), accessor: "nuFrequency", show: false },
          ]}
          required
        />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_repl.dtFrom"/>
        <CxDate xref={rf_next} label={trans('field.next')} dbfield="n_repl.dtNext"/>
        
      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} strRelatedField={'inRepl'} strRelatedId={storeRepl.inRepl} /* rf_txEmployee={rf_txEmployee} */ />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_ps_doesnt_exist} severity="error" value={trans('msg.x_doesnt_exist_1','field.product')} />
      
    </div>
  );

}

export default MxClientReplForm;
