import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettCusTranslation } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettCusTranslationList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettCusTranslation, setStoreSettCusTranslation] = useSessionStorage('StoreSettCusTranslation', StoreSettCusTranslation);

  React.useEffect(() => {
    clearStore('StoreSettCusTranslation', storeSettCusTranslation);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCusTranslation.isTrans = null;
    } else {
      storeSettCusTranslation.isTrans = row.original.isTrans;
    }
    setStoreSettCusTranslation(storeSettCusTranslation);
    navTo("sett-cus-trans-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.customization')} - ${trans('field.translations')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/translation"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "isTrans", show: false },
          { id: "1", Header: trans('field.word_original'), accessor: "txFrom", width: '50%' },
          { id: "2", Header: trans('field.word_preferred'), accessor: "txTo", width: '50%' },
        ]}
      />
    </div>
  );

}

export default MxSettCusTranslationList;
