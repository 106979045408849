// Funciones generales para archivos.
import * as FxLog from './FxLog';

export const base64ToArrayBuffer = base64 => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const getFileFromBase64 = async (base64File, fileName, contentType) => {
  contentType = contentType || "";
  let sliceSize = 512;

  var byteCharacters = atob(base64File);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, fileName, { type: contentType });
}

export const getBase64FromFile = async file => {
  return new Promise(resolve => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      //console.log('baseURL --> ' + baseURL);
      resolve(baseURL);
    };
  });
};

export const saveByteArray = (reportFormat, reportName, byte) => {
  var mimeType = null;
  if (reportFormat === 'PDF') mimeType = "application/pdf";
  if (reportFormat === 'CSV') mimeType = "text/csv";
  var blob = new Blob([byte], { type: mimeType });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  FxLog.infox("FxFile.saveByteArray", "Creating file", reportName + ' / ' + fileName);
  link.click();
};

export const printPDF = (obPDF) => {
  let byteArray = base64ToArrayBuffer(obPDF.replace('data:application/pdf;base64,', ''));
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);
  iframe.contentWindow.print();
}

// Guarda un Json en la carpeta downloads del pc-cliente.
// Ej: xFile.saveJson(`ibs_localhub_intech_${FxDat.timestamp()}.json`, {client: { inClient: storeClient.inClient, txClient: storeClient.txClient },});
export const saveJson = (fileName, data) => {
  const fileData = JSON.stringify(data);
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.click();
}

export const downloadFile = (url, fileName) => {
  const fileUrl = url;
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
