import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { clearStore, StoreSettUserActions } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';
import { useWindowSize } from '../../hx/useWindowSize';


const MxSettUserActionList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();

  // eslint-disable-next-line
  const [storeSettUserActions, setStoreSettUserActions] = useSessionStorage('StoreSettUserActions', StoreSettUserActions);
  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettUserActions', storeSettUserActions);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "iiUser", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.date'), accessor: row => ( FxTable.cellToLocalDateTime(row["dtCrea"]) ), width: '12%' });
        cols.push({ id: id++, Header: trans('field.branch_id'), accessor: "txBranchId", width: '6%' });
        cols.push({ id: id++, Header: trans('field.user'), accessor: "txUser", width: '9%' });
        cols.push({ id: id++, Header: trans('field.module'), accessor: row => r_list.current.getTable().highlightText(row["txModule"]), width: '13%' });
        cols.push({ id: id++, Header: trans('field.detail'), accessor: row => r_list.current.getTable().highlightText(row["txDetail"]), width: '60%' });
        
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.date'), accessor: row => ( FxTable.cellToLocalDateTime(row["dtCrea"]) ), width: '30%' });
        cols.push({ id: id++, Header: trans('field.module'), accessor: row => r_list.current.getTable().highlightText(row["txModule"]), width: '30%' });
        cols.push({ id: id++, Header: trans('field.detail'), accessor: row => r_list.current.getTable().highlightText(row["txDetail"]), width: '40%' });
      
      }

    } catch(error) {
      FxLog.errorx('MxSettUserActionList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.sett_user_actions')}
        xref={r_list}
        tooltip={`${trans('field.module')} / ${trans('field.detail')}`}
        fullscreen
        url="sett/user_actions"
        columns={getTableColumns()}
        hasButtonAdd={false}
        disabled={true}
      />

    </div>

  );

}

export default MxSettUserActionList;
