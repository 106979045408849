import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxSearcher from '../../cx/CxSearcher';
import CxCombo from '../../cx/CxCombo';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { StorePlanPs } from "../../gx/GxStore";


const MxPlanPsForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storePlanPs, setStorePlanPs] = useSessionStorage('StorePlanPs', StorePlanPs);

  let r_form = React.useRef(null);

  const rf_txPs = React.useRef(null);
  const rf_nuPrice = React.useRef(null);
  const rf_nuQuantity = React.useRef(null);
  const rf_txTypeSeries = React.useRef(null);
  const rf_txTypePayment = React.useRef(null);
  const rf_txFrequency = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_nuQuantity.current.setVal('1');
    }
  }
  const formSave = async (lastSaveRv) => {
    navBack();
  }
  const formDelete = async (rv) => {
  }

  const onSelectionPs = (row) => {
    rf_nuPrice.current.setVal(row.original.nuPrice);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.plan')}: ${trans('field.ps')}`}
        xref={r_form}
        url="plans/pss"
        filterIdField='irPlanPs'
        filterIdValue={storePlanPs.irPlanPs}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="inPlan" value={storePlanPs.inPlan || ''} />

        <CxSearcher xref={rf_txPs} label={trans('field.ps')}
          id='plan_ps' //para que la pag no sobreescriba la del listado general de pss
          dbfield={'txPs'} dbfieldkey={'inPs'}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          tooltip={`${trans('field.ps')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '75%' },
            { id: "2", Header: trans('field.price'), accessor: row => (FxTable.cellTo2r00(row["nuPrice"])), width: '25%', align: 'right' },
          ]}
          onSelection={onSelectionPs}
          required
        />

        <CxNumber xref={rf_nuPrice}
          label={trans('field.price')}
          dbfield="nuPrice"
          decimals={4}
          decimalsfixed="true"
          required
        />

        <CxNumber xref={rf_nuQuantity}
          label={trans('field.quantity')}
          dbfield="nuQuantity"
          decimals={4}
          decimalsfixed="true"
          sumButton
          required
        />

        <CxCombo xref={rf_txFrequency}
          label={`${trans('field.frequency')}`}
          dbfield='txFrequency'
          list={[
            { id: 'm', type: trans('field.monthly') },
            { id: 'y', type: trans('field.yearly') },
          ]}
          required
          autoSelectFirst
        />

        <CxChooser xref={rf_txTypeSeries} label={trans('field.series')}
          dbfield='txTypeSeries' dbfieldkey="itSeries"
          table="t_series"
          col_id="itSeries"
          col_tx="txTypeSeries"
          extra_filter_1={"boActive='1'"}
        />

        <CxChooser xref={rf_txTypePayment}
          label={trans('field.payment')}
          dbfield="txTypePayment" dbfieldkey="itPayment"
          table="t_payment"
          col_id="itPayment"
          col_tx="txTypePayment"
          extra_filter_1={"not txTypePayment like 'OLD-%'"}
        />
      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxPlanPsForm;
