import { MdClear } from "react-icons/md"; 
import React, { useState, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import '../../styles/main.css';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import CxIconButtonNew from "./CxIconButtonNew";
import { useStyles } from "./StylesCx";


function InputFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <InputMask
      {...other}
      style={{
        border: 'none',
        outline: 'none',
        fontSize: 'var(--ibs_control_text_font_size)',
        width: '130px',
        margin: '0px 0px 0px 0px',
        color: props.disabled ? '#9E9E9E' : 'var(--ibs_color_black)',
      }}
      ref={props.inputRef}
      mask="99:99"
      maskPlaceholder='hh:mm'
      value={props.value}
      onChange={props.onChange}
    />
  );
}


const CxTime = props => {
  const classes = useStyles(props);
  const { onBlur, onClear, InputLabelProps, InputProps, defaultValue, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = React.useState(false);

  const r_clearing = React.useRef(false);

  const rf_control = React.useRef(null);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  const doClear = () => {
    r_clearing.current = true;
    setMyValue('');
    setChanged(true);
    setFocusSel();
  }

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, getDbfield, setFocus, setFocusSel, isEmpty, clear, wasChanged, setChanged, setValNow,
    };
  });
  const setVal = (newValue) => {
    if (newValue !== null) {
      if (newValue.length > 5) {
        // es fecha y hora
        newValue = newValue.substring(11, 16);
      } else {
        // es hora
      }
    }
    setMyValue(newValue);
  };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const setFocusSel = () => rf_control.current.select();
  const isEmpty = () => { return getVal() === null };
  const clear = () => { setMyValue(''); setChanged(true); };
  const getType = () => { return 'CxTime' };
  const getVal = () => { 
    if (FxDat.isValidTime(myValue)) {
      return myValue;
    } else {
      return null;
    }
  };

  const setValNow = () => {
    let now = FxDat.now();
    setMyValue(now);
  };

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          inputRef={rf_control}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            inputComponent: InputFormatCustom,
            disableUnderline: true,
            classes: {
              root: classes.root,
            },
            ...InputProps
          }}
          value={myValue}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => {
            let newValue = e.target.value;
            setMyValue(newValue);
            if (props.onChange !== undefined) props.onChange(newValue);
          }}
          onFocus={event => {
            if (rest.autoselect) event.target.select();
          }}
          onKeyDown={() => setChanged(true)}
          {...rest}
        />
      </div>

      {!props.hidebuttons && <div className={classes.frame_buttons}>
        <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={doClear} disabled={props.disabled} />
      </div>}

    </div>

  );
};

export default CxTime;
