import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
//import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';
import { getStoreValue, } from "../../gx/GxStore";
import * as FxFetch from '../../fx/FxFetch';


const MxClientDataCP = (props) => {
  const [{ trans }] = useTranslation();
  const r_snack_save = React.useRef(null);
  
  const rf_txPassword = React.useRef(null);

  let r_form = React.useRef(null);

  React.useEffect(() => {
    formLoadCustom();
    // eslint-disable-next-line
  }, []);

  const formLoadCustom = async () => {
    let record = {};
    record['idValue'] = getStoreValue('StoreSession', 'inClientCP');
    Promise.all([FxFetch.doJsonX('cp/client_data/r', record)]).then((result) => {
      if (result[0]) {
        rf_txPassword.current.setVal(result[0][0].txPassword);
      }
    });
  }
  const formSaveCustom = async () => {
    
    let record = {};
    record['txPassword'] = rf_txPassword.current.getVal();
    Promise.all([FxFetch.doJsonX('cp/client_data/cu', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.my_x','field.data')}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />
          </div>
        }
      >
        <CxInput xref={rf_txPassword} label={`${trans('field.password')}`} maxLength={50} />

        
      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} />
    </div>
  );

}

export default MxClientDataCP;
