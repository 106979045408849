import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
import CxStatic from '../../cx/CxStatic';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxLongTask from '../../gx/GxLongTask';
//import { StoreLongTask } from "../../gx/GxStore";
import { useLocation } from 'react-router-dom';


const MxIntegrationPurInvoicesOptionTsk = (props) => {
  const [{ trans }] = useTranslation();
  const { state } = useLocation(); //captura los parámetros de navBack()

  //const [storeLongTask, ] = useSessionStorage('StoreLongTask', StoreLongTask);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    if (state) {
      FxLog.infox('MxIntegrationPurInvoicesOptionTsk.formLoad', '...state.quantity:', state.quantity);
    }
  }

  return (
    <div>
      <CxToolbar moduleName={`${trans('field.integration')}`} />
      <GxLongTask />
      <div style={{ margin: '10px' }}>
        <CxStatic label={`${trans('field.invoices')}: ${state.quantity}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='14px' />
      </div>
    </div>
  );

}

export default MxIntegrationPurInvoicesOptionTsk;
