// Funciones auxiliares para formato de celdas de tablas.
import * as FxDat from '../fx/FxDat';
import * as FxNum from '../fx/FxNum';
import { getStoreValue } from "../gx/GxStore";

export const cellToLocalDate = (dt) => {
  return FxDat.toLocalDate(dt);
}

export const cellToLocalTime = (dt) => {
  return FxDat.toLocalTime(dt);
}

export const cellToLocalDateTime = (dt) => {
  return FxDat.toLocalDateTime(dt);
}

export const cellToYesNo = (bo) => {
  let ar = JSON.parse(sessionStorage.getItem('trans') || null);
  let strYes = ar['field.yes'];
  let strNo = ar['field.no'];
  return Boolean(bo === '1') ? strYes : strNo;
}

export const cellToInt = (num) => {
  return FxNum.toInt(num).toString();
}

export const cellTo2r00 = (num) => {
  return FxNum.to2r00(num).toString().replace('.', getStoreValue('StoreSession', 'txDecimalSeparator'));
}

export const cellTo4r00 = (num) => {
  return FxNum.to4r00(num).toString().replace('.', getStoreValue('StoreSession', 'txDecimalSeparator'));
}

/** reemplaza valores contenidos en txType por texto especificado. Por ejemplo en las cns reemplaza 'G' por 'General' */
export const replace = (text, options) => {
  let newText = text;
  options.map((option) => (
    newText = (newText===undefined ? newText : newText.toString().replace(option.id, option.text))
  ));
  return newText;
}
