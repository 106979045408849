import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettCpAccess } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettCpAccessList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettCpAccess, setStoreSettCpAccess] = useSessionStorage('StoreSettCpAccess', StoreSettCpAccess);

  React.useEffect(() => {
    clearStore('StoreSettCpAccess', storeSettCpAccess);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCpAccess.itCpAccess = null;
    } else {
      storeSettCpAccess.itCpAccess = row.original.itCpAccess;
    }
    setStoreSettCpAccess(storeSettCpAccess);
    navTo("sett-client-portal-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.client_portal')}`}
        fullscreen
        hasSearchBox={false}
        hasButtonAdd={false} //de momento no permitimos añadir nuevos tipos
        url="sett/cp_access"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itCpAccess", show: false },
          { id: "1", Header: `${trans('field.type')}`, accessor: "txTypeCpAccess", width: '100%' },
        ]}
      />
    </div>
  );

}

export default MxSettCpAccessList;
