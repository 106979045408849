import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxSearcher from '../../cx/CxSearcher';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreEmployee } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { useAccess } from '../../hx/useAccess';


const MxEmployeeCommissionForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navBack }] = useNav();

  const [storeEmployee, setStoreEmployee] = useSessionStorage('StoreEmployee', StoreEmployee);

  let r_form = React.useRef(null);

  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);
  const rf_nuCommissionPerc = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
    } else {
      // se guardó exitosamente
      storeEmployee.inCommission = lastSaveRv.theValue;
      Promise.all([setStoreEmployee(storeEmployee)]).then((result) => {
        navBack();
      });
    }

  }
  const formDelete = async (rv) => {
  }

  const groupCleared = () => {
    rf_txTypePsSubgroup.current.clear();
    rf_txTypePsSubgroup.current.setFilter(`undefined='${undefined}'`);
  }
  const groupSelected = (row) => {
    rf_txTypePsSubgroup.current.clear();
  }
  const subgroupOpen = () => {
    let itPsGroup = rf_txTypePsGroup.current.getKey();
    rf_txTypePsSubgroup.current.setFilter(`t_ps_subgroup.itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.employee')}: ${trans('field.commission')}`}
        xref={r_form}
        url="employees/commission"
        filterIdField='inCommission'
        filterIdValue={storeEmployee.inCommission}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_commission.inEmployee" value={storeEmployee.inEmployee || ''} />


        <CxSearcher xref={rf_txTypePsGroup}
          label={trans('field.group')}
          dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_commission.itPsGroup'
          onClear={groupCleared}
          onSelection={groupSelected}
          url="sett/ps_group"
          col_id="itPsGroup"
          col_tx="txTypePsGroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
          ]}
          required
          width='var(--ibs_control_width_md)'
        />

        <CxSearcher xref={rf_txTypePsSubgroup}
          label={trans('field.subgroup')}
          dbfield='t_ps_subgroup.txTypePsSubgroup' dbfieldkey='n_commission.itPsSubgroup'
          canOpenDialog={subgroupOpen}
          url="sett/ps_subgroup"
          col_id="itPsSubgroup"
          col_tx="txTypePsSubgroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
          ]}
          required
          width='var(--ibs_control_width_md)'
        />

        <CxNumber xref={rf_nuCommissionPerc} label={trans('field.commission')} dbfield="n_commission.nuCommissionPerc" decimals={4} decimalsfixed="true" required />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxEmployeeCommissionForm;
