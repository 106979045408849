import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxDat from '../../fx/FxDat';
import { useNav } from '../../hx/useNav';

import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreSupplier } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSupplierList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeSupplier, setStoreSupplier] = useSessionStorage('StoreSupplier', StoreSupplier);

  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSupplier', storeSupplier);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storeSupplier.inSupplier = null;
    } else {
      storeSupplier.inSupplier = row.original.inSupplier;
    }
    setStoreSupplier(storeSupplier);

    navTo("supplier-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inSupplier", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.supplier'), accessor: row => r_list.current.getTable().highlightText(row["txSupplier"]), width: '40%' });
        cols.push({ id: id++, Header: trans('field.type'), accessor: "txTypeSupplier", width: '20%' });
        cols.push({ id: id++, Header: trans('field.email'), accessor: "txEmail", width: '20%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '10%' });
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '10%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.supplier'), accessor: row => r_list.current.getTable().highlightText(row["txSupplier"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '30%' });
      }
      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false });

    } catch(error) {
      FxLog.errorx('MxSupplierList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.suppliers')}`}
        xref={r_list}
        tooltip={`${trans('field.supplier')}`}
        fullscreen
        url="suppliers"
        onOpenForm={openForm}
        colorRefs={ [ 'table_cell_gray|' + trans('field.inactive') ] }
        columns={getTableColumns()}
      />

    </div>
  );

}

export default MxSupplierList;
