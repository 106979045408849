import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettSupplier } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettSupplierList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettSupplier, setStoreSettSupplier] = useSessionStorage('StoreSettSupplier', StoreSettSupplier);

  React.useEffect(() => {
    clearStore('StoreSettSupplier', storeSettSupplier);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettSupplier.itSupplier = null;
    } else {
      storeSettSupplier.itSupplier = row.original.itSupplier;
    }
    setStoreSettSupplier(storeSettSupplier);
    navTo("sett-supplier-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.suppliers')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/supplier"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itSupplier", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.supplier')}`, accessor: "txTypeSupplier", width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettSupplierList;
